import { format } from 'url';
import PropTypes from 'prop-types';
import React from 'react';
import { ConfigContext } from '@mulesoft/exchange-react-hooks';
import ConfirmModal from '@mulesoft/exchange-ui-components/lib/components/ConfirmModal';
import styles from './ANGOnboardingModal.css';

class ANGOnboardingModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
  };

  renderBody() {
    return (
      <ConfigContext.Consumer>
        {(config) => (
          <React.Fragment>
            <h4>Filters</h4>
            <p>
              Filter assets by type, organization, category, tag and lifecycle
              state.
            </p>
            <img
              alt="Filters example"
              src={format({
                ...config.images.uri,
                pathname: 'filters-min.png'
              })}
            />
            <h4>Match details</h4>
            <p>
              See which API spec properties and page text matched the search
              term.
            </p>
            <img
              alt="Matching details example"
              src={format({
                ...config.images.uri,
                pathname: 'match-details-min.png'
              })}
            />
            <h4>Sort results</h4>
            <p>
              Sort results by relevance, name, last modified date or rating.
            </p>
            <img
              alt="Sort by example"
              src={format({
                ...config.images.uri,
                pathname: 'sort-results-min.png'
              })}
            />
            <h4>Saved searches</h4>
            <p>Save shortcuts to filters you have applied.</p>
            <img
              alt="Saved searches example"
              src={format({
                ...config.images.uri,
                pathname: 'saved-searches-min.png'
              })}
            />
          </React.Fragment>
        )}
      </ConfigContext.Consumer>
    );
  }

  renderFooter() {
    return (
      <p>
        This will not affect other users, and you can switch back at any time.{' '}
        <br /> We may temporarily disable the new search without warning for
        maintainance.
      </p>
    );
  }

  render() {
    return (
      <ConfirmModal
        className={styles.modal}
        title="New search features in Exchange"
        body={this.renderBody()}
        footer={this.renderFooter()}
        confirmButtonText="Start using new features"
        onConfirm={this.props.onConfirm}
        onCancel={this.props.onClose}
      />
    );
  }
}

export default ANGOnboardingModal;
