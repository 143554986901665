import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import ApiGroupCardTitle from './ApiGroupCardTitle';

const mapStateToProps = (state, { asset }) => {
  const organizationDomain = assetsSelectors.assetOrganizationDomain(
    state,
    asset
  );

  return { organizationDomain };
};

export default compose(withRouter, connect(mapStateToProps))(ApiGroupCardTitle);
