import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import AssetsLimitWarning from './AssetsLimitWarning';

const mapStateToProps = (state) => ({
  profile: commonSelectors.profile(state)
});

export default connect(mapStateToProps, null, null, {
  context: RootStoreContext
})(AssetsLimitWarning);
