import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '~/components/ErrorPage';
import { getDefaultCards } from '../utils';

const ServerErrorPage = ({ getPath }) => {
  return (
    <ErrorPage
      title="500 Internal Server Error"
      description="The server encountered an error."
      cards={getDefaultCards(getPath)}
    />
  );
};

ServerErrorPage.propTypes = {
  getPath: PropTypes.func.isRequired
};

export default ServerErrorPage;
