import { convertToArray } from '~/utils/arrays';
import { omit, shallowEquals } from '~/utils/objects';

export function buildSavedSearch(query) {
  return {
    name: query.name && query.name.trim(),
    search: query.search,
    types: convertToArray(query.type),
    updatedAt: query.updated_at,
    categories: convertToArray(query.category),
    fields: convertToArray(query.field),
    tags: convertToArray(query.tag),
    status: convertToArray(query.status),
    sort: query.sort || '',
    organizationIds: convertToArray(query.organizationId)
  };
}

export function getQuery(savedSearch) {
  const result = {
    search_id: savedSearch.id,
    search: savedSearch.search,
    type: savedSearch.types,
    updated_at: savedSearch.updatedAt,
    category: savedSearch.categories,
    field: savedSearch.fields,
    tag: savedSearch.tags,
    status: savedSearch.status,
    sort: savedSearch.sort,
    organizationId: savedSearch.organizationIds
  };

  return Object.keys(result).reduce((acum, current) => {
    const param = result[current];

    if (param && (!Array.isArray(param) || param.length)) {
      return {
        ...acum,
        [current]: param
      };
    }

    return acum;
  }, {});
}

function falsyCompare(...values) {
  return values.every((value) => !value || (value && !value.length));
}

export function isSavedSearchEqual(savedSearch, query) {
  const comparedSavedSearch = buildSavedSearch(query);
  const comparedSavedSearchKeys = Object.keys(
    omit(comparedSavedSearch, 'name')
  );

  return comparedSavedSearchKeys.every(
    (key) =>
      shallowEquals(savedSearch[key], comparedSavedSearch[key]) ||
      falsyCompare(savedSearch[key], comparedSavedSearch[key])
  );
}
