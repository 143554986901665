import PropTypes from 'prop-types';
import React from 'react';
import Button from '@mulesoft/anypoint-components/lib/Button';
import { InfoModal } from '~/components/Modals';

class NotFoundModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onGoBack: PropTypes.func,
    requestId: PropTypes.string
  };

  renderBody() {
    const { requestId } = this.props;

    return (
      <React.Fragment>
        <p>
          The resource you are trying to access could not be located. It was
          probably removed by someone else. If you think this is a mistake,
          contact your administrator.
        </p>
        <p display-if={requestId} data-test-id="not-found-modal-request-id">
          Request Id: <code>{requestId}</code>
        </p>
      </React.Fragment>
    );
  }

  renderFooter() {
    const { onClose, onGoBack } = this.props;

    return (
      <React.Fragment>
        <Button
          testId="not-found-stay-here"
          kind="tertiary"
          onClick={onClose}
          noFill
        >
          Close
        </Button>
        <Button testId="not-found-go-back" kind="secondary" onClick={onGoBack}>
          Go back
        </Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <InfoModal
        isDanger
        title="Resource not found"
        body={this.renderBody()}
        footer={this.renderFooter()}
      />
    );
  }
}

export default NotFoundModal;
