import * as qs from 'querystring';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import OpenIcon from '@mulesoft/anypoint-icons/lib/svg/open.svg';
import styles from './Links.css';

export const RouterLink = ({
  className,
  link: { isActive, to, query },
  children,
  ...commonProps
}) => {
  return (
    <NavLink
      to={`${to}?${query ? qs.stringify({ ...query, view: 'grid' }) : ''}`}
      className={classNames(
        className,
        isActive ? `${styles.active} is-active` : null
      )}
      activeClassName={
        isActive === undefined ? `${styles.active} is-active` : null
      }
      {...commonProps}
    >
      {children}
    </NavLink>
  );
};

RouterLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.object,
  children: PropTypes.node
};

export const AnchorLink = ({
  link: { href, target },
  children,
  ...commonProps
}) => {
  return (
    <a href={href} target={target} {...commonProps}>
      {children}
      <Icon
        display-if={target === '_blank'}
        className={styles.openIcon}
        size="xxs"
      >
        <OpenIcon />
      </Icon>
    </a>
  );
};

AnchorLink.propTypes = {
  link: PropTypes.object,
  children: PropTypes.node
};

class Links extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    testId: PropTypes.string.isRequired,
    className: PropTypes.string
  };

  renderItem = (item, index) => {
    if (item.type === 'title') {
      return (
        <h6 data-test-id="sidebar-title" className={styles.header} key={index}>
          {item.name}
        </h6>
      );
    }

    const ItemComponent = item.href !== undefined ? AnchorLink : RouterLink;

    return (
      <ItemComponent
        data-test-id="sidebar-link"
        key={index}
        className={classNames(
          styles.item,
          item.hierarchy ? styles[`hierarchy-${item.hierarchy}`] : null,
          item.isActive ? styles.active : null
        )}
        link={item}
        onClick={item.onClick}
      >
        <div className={styles.content}>
          <Icon
            data-test-id="link-icon"
            display-if={item.icon}
            size="xs"
            className={styles.icon}
          >
            {item.icon}
          </Icon>
          <span
            data-test-id="link-label"
            className={styles.label}
            title={item.name}
          >
            {item.name}
            {item.annotation ? (
              <span className={styles.annotation}>{item.annotation}</span>
            ) : null}
          </span>
        </div>
        {item.suffix ? (
          <span className={styles.suffix}>{item.suffix}</span>
        ) : null}
      </ItemComponent>
    );
  };

  render() {
    const { id = '', className, testId, items } = this.props;

    return (
      <div
        id={id}
        data-test-id={`${testId}-link${items.length > 1 ? 's' : ''}`}
        className={className}
      >
        {items.map(this.renderItem)}
      </div>
    );
  }
}

export default Links;
