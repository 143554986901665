import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mulesoft/anypoint-components/lib/Tooltip';
import { isAPIGroup } from '~/utils/types';
import styles from './ExternalRequestAccessTooltip.css';

const ExternalRequestAccessTooltip = ({ asset, children, testId }) => {
  const type = isAPIGroup(asset) ? 'API Group' : 'API';

  return (
    <Tooltip
      content={`Cannot request access to an ${type} shared by an external organization`}
      position="left"
      testId={testId}
      theme={styles}
    >
      {children}
    </Tooltip>
  );
};

ExternalRequestAccessTooltip.propTypes = {
  asset: PropTypes.object.isRequired,
  children: PropTypes.node,
  testId: PropTypes.string
};

ExternalRequestAccessTooltip.defaultProps = {
  testId: 'external-request-access-tooltip'
};

export default ExternalRequestAccessTooltip;
