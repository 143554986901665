import { compose } from 'redux';
import { connect } from 'react-redux';
import { withModal } from '@mulesoft/exchange-react-hooks';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { setModalName } from '~/components/Modals';
import * as objectsUtils from '~/utils/objects';
import RevertDeprecateAssetModal from './RevertDeprecateAssetModal';

const mapStateToProps = (state, { asset }) => ({
  asset,
  onError: assetsSelectors.assetUI(state).onDeprecateError,
  isPortalReArchitectureEnabled: sessionSelectors.isPIRAEnabled(state)
});

const mapDispatchToProps = (dispatch, { closeModal }) => ({
  onClose: () => closeModal(),
  onPublish: (asset) => {
    dispatch(
      assetsActions.revertDeprecateAsset(
        objectsUtils.pick(asset, 'groupId', 'assetId', 'version')
      )
    );
    closeModal();
  },
  onPublishMinor: (asset) => {
    dispatch(
      assetsActions.revertDeprecateAsset(
        objectsUtils.pick(asset, 'groupId', 'assetId', 'minorVersion')
      )
    );
    closeModal();
  }
});

export default compose(
  setModalName('RevertDeprecateAssetModal'),
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
)(RevertDeprecateAssetModal);
