import { combineReducers } from 'redux';
import * as actions from './actions';
import * as selectors from './selectors';
import reducer from './reducer';

export { actions, reducer, selectors };

export default function createReducerManager(initialReducers) {
  const reducers = { ...initialReducers };
  let combinedReducer = combineReducers(reducers);
  let keysToRemove = [];

  const remove = (key) => {
    if (!key || !reducers[key]) {
      return;
    }

    delete reducers[key];
    keysToRemove.push(key);
  };
  const reduce = (state, action) => {
    let newState = state;

    if (keysToRemove.length > 0) {
      newState = { ...state };
      for (const key of keysToRemove) {
        delete newState[key];
      }
      keysToRemove = [];
    }

    return combinedReducer(newState, action);
  };
  const load = (typeReducers) => {
    Object.keys(reducers).forEach((reducerKey) => {
      if (!initialReducers[reducerKey]) {
        remove(reducerKey);
      }
    });

    if (!typeReducers) {
      combinedReducer = combineReducers(reducers);

      return;
    }

    Object.keys(typeReducers).forEach((reducerKey) => {
      reducers[reducerKey] = typeReducers[reducerKey];
    });

    combinedReducer = combineReducers(reducers);
  };

  return {
    reduce,
    load
  };
}
