import config from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import RadioGroup from '@mulesoft/anypoint-components/lib/RadioGroup';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import Radio from '@mulesoft/anypoint-components/lib/Radio';
import { FormModal } from '~/components/Modals';
import { getAuthenticatedShowFilters } from '~/utils/ang/ANGFilters';
import * as savedSearchesUtils from '~/utils/savedSearches';
import styles from './CreateSavedSearchModal.css';

const SAVED_SEARCH_MAX_LENGTH = 64;

const findSavedSearchByName = (savedSearches, term) =>
  savedSearches.find((savedSearch) => savedSearch.name === term);

class CreateSavedSearchModal extends React.Component {
  static propTypes = {
    activeOrganization: PropTypes.object,
    userSavedSearches: PropTypes.array,
    savedSearches: PropTypes.array,
    isAdmin: PropTypes.bool,
    query: PropTypes.object,
    onClose: PropTypes.func,
    onSaveOrgSearch: PropTypes.func,
    onSaveUserSearch: PropTypes.func,
    profile: PropTypes.object,
    rootOrganizations: PropTypes.array
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      form: {
        name: this.getDefaultName().substring(0, SAVED_SEARCH_MAX_LENGTH),
        savedSearchType: 'user'
      }
    };
  }

  savedSearchNameValidation = {
    pattern: {
      validate: ({ value }) => {
        if (this.state.form.savedSearchType === 'organization') {
          return !this.props.savedSearches.some(
            (query) => query.name === value.trim()
          );
        }

        return !this.props.userSavedSearches.some(
          (query) => query.name === value.trim()
        );
      },
      message: 'A search with this name already exists. Try another name.'
    }
  };

  getDefaultName = () => {
    const { userSavedSearches, query } = this.props;
    const queryName = query.search;
    let count = 1;

    if (!queryName) {
      return '';
    }

    let resultName = queryName;

    while (findSavedSearchByName(userSavedSearches, resultName)) {
      resultName = queryName.concat(`-${count}`);
      count += 1;
    }

    return resultName;
  };

  handleChange = ({ value }) => {
    let name = '';
    const { savedSearchType } = value;

    if (value.name) {
      name = value.name.trim() ? value.name : '';
    }

    this.setState({ form: { name, savedSearchType } });
  };

  handleSaveSearch = () => {
    const {
      query,
      isAdmin,
      activeOrganization,
      onSaveOrgSearch,
      onSaveUserSearch,
      profile,
      rootOrganizations
    } = this.props;
    const { form } = this.state;
    const { show } = query;
    const isQueryEmpty = Object.keys(query).length === 0;

    const showFilters = getAuthenticatedShowFilters(
      show,
      profile,
      isQueryEmpty,
      rootOrganizations,
      config
    );

    if (form.name) {
      const createQuery = savedSearchesUtils.buildSavedSearch({
        ...query,
        ...showFilters,
        name: form.name
      });

      if (isAdmin && form.savedSearchType === 'organization') {
        onSaveOrgSearch({
          query: createQuery,
          organizationId: activeOrganization.id
        });
      } else {
        onSaveUserSearch({ query: createQuery });
      }
    }
  };

  bodyContent = () => {
    const { isAdmin, activeOrganization } = this.props;
    const { form } = this.state;

    return (
      <div data-test-id="save-search-form-body">
        <div className={styles.fields}>
          <TextField
            required
            id="name"
            name="name"
            label="Name"
            maxLength={SAVED_SEARCH_MAX_LENGTH}
            autoFocus
            value={form.name}
            testId="saved-search-name"
            placeholder="Name your search"
            onEnter={this.handleSaveSearch}
            validate={this.savedSearchNameValidation}
          />
        </div>
        <div display-if={isAdmin} className={styles.radioGroup}>
          <span className={styles.searchType}>Save as</span>
          <RadioGroup
            className={styles.typeRadioGroup}
            orientation="vertical"
            name="savedSearchType"
            value={form.savedSearchType}
            testId="saved-search-type"
          >
            <Radio testId="user-search-radio" label="My search" value="user" />
            <Radio
              testId="organization-search-radio"
              label={`"${activeOrganization.name}" search`}
              value="organization"
            />
          </RadioGroup>
        </div>
      </div>
    );
  };

  render() {
    const { onClose } = this.props;

    return (
      <FormModal
        title="Save search"
        testId="save-search-modal"
        submitButtonText="Save search"
        body={this.bodyContent()}
        form={this.state.form}
        onSubmit={this.handleSaveSearch}
        onChange={this.handleChange}
        onCancel={onClose}
      />
    );
  }
}

export default CreateSavedSearchModal;
