import { fetch } from '~/utils/fetch';
import { getAPIUrl } from '~/utils/api';

const getANGTypes = (types, assetTypesConfiguration) =>
  types?.reduce((accum, current) => {
    const angType = assetTypesConfiguration[current]?.angType;

    if (angType && !accum.some((includedType) => includedType === angType)) {
      return [...accum, angType];
    }

    return accum;
  }, []);

export async function getFacets({
  context,
  query,
  organizationId,
  facet,
  assetTypesConfiguration
}) {
  const angTypes = getANGTypes(query.type, assetTypesConfiguration);
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/${
      organizationId ? `organizations/${organizationId}/` : ''
    }ang/facets${facet ? `/${facet}` : ''}`,
    query: angTypes
      ? {
          ...query,
          type: angTypes
        }
      : query
  });
  const response = await fetch(url, { context });

  return response.json();
}

export async function getSavedSearches({
  organizationId,
  context,
  limit,
  offset
}) {
  const url = getAPIUrl({
    context,
    query: { limit, offset },
    pathname: `/api/v1/organizations/${organizationId}/queries`
  });
  const response = await fetch(url, { context });

  return response.json();
}

export async function createSavedSearch({ organizationId, query, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/queries`
  });
  const body = JSON.stringify(query);
  const response = await fetch(url, { method: 'POST', body, context });

  return response.json();
}

export function deleteSavedSearch({ id, organizationId, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/queries/${id}`
  });

  return fetch(url, { method: 'DELETE', context });
}

export async function editSavedSearch({ id, query, organizationId, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/queries/${id}`
  });
  const body = JSON.stringify(query);
  const response = await fetch(url, { method: 'PATCH', body, context });

  return response.json();
}

export async function createUserSavedSearch({ query, context }) {
  const url = getAPIUrl({ context, pathname: '/api/v1/users/me/queries' });
  const body = JSON.stringify(query);
  const response = await fetch(url, { method: 'POST', body, context });

  return response.json();
}

export async function getUserSavedSearches({ context, limit, offset }) {
  const url = getAPIUrl({
    context,
    query: { limit, offset },
    pathname: '/api/v1/users/me/queries'
  });
  const response = await fetch(url, { context });

  return response.json();
}

export function deleteUserSavedSearch({ id, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/users/me/queries/${id}`
  });

  return fetch(url, { method: 'DELETE', context });
}

export async function editUserSavedSearch({ id, query, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/users/me/queries/${id}`
  });
  const body = JSON.stringify(query);
  const response = await fetch(url, { method: 'PATCH', body, context });

  return response.json();
}
