import config from 'config';

/**
 * Validate the pageName against the predefined regex
 * @param {String} string
 */

// disallow the following characters: \ @ * + / _
const regexPageName = new RegExp(`^[^\\\\%@*+/_]*$`);

export function isValidPageName(pageName) {
  return (
    noDisallowedChars(pageName) &&
    lengthInRange(pageName) &&
    !startsWithDot(pageName)
  );
}

export function isTermsPageName(name) {
  return name === config.termsPageName;
}

export function isPagesOrderPageName(name) {
  return name === config.pagesOrderPageName;
}

export function isTermsPage(page) {
  return page ? isTermsPageName(page.path) : false;
}

export function isPagesOrderPage(page) {
  return page ? isPagesOrderPageName(page.path) : false;
}

export function isDefaultPageName(name, { caseSensitive }) {
  if (caseSensitive) {
    return name && config.defaultPageName
      ? name.toLowerCase() === config.defaultPageName.toLowerCase()
      : false;
  }

  return name === config.defaultPageName;
}

export function isDefaultPage(page) {
  return page ? isDefaultPageName(page.path, { caseSensitive: false }) : false;
}

export function isPredefinedPageName(name) {
  return (
    isDefaultPageName(name, { caseSensitive: true }) || isTermsPageName(name)
  );
}

export function isPredefinedPage(page) {
  return isDefaultPage(page) || isTermsPage(page) || isPagesOrderPage(page);
}

export function pageDoesAlreadyExist(pageName, pages) {
  return (
    pages.some((page) => pageName === page.name) ||
    isPredefinedPageName(pageName)
  );
}

export function getPageValidations(pages) {
  return {
    nonExistentName: {
      validate: ({ value }) =>
        // we don't want to show this message for default page name
        isDefaultPageName(value.trim(), { caseSensitive: true }) ||
        !pageDoesAlreadyExist(value.trim(), pages),
      message: 'Page name is already used.'
    },
    noInvalidCharacters: {
      validate: ({ value }) => noDisallowedChars(value),
      message: 'Page name cannot contain these characters: % @ * + / _ \\'
    },
    notHomePage: {
      validate: ({ value }) =>
        !isDefaultPageName(value.trim(), { caseSensitive: true }),
      message: `Page name cannot be the reserved name "${config.defaultPageName}".`
    },
    noDotStart: {
      validate: ({ value }) => !startsWithDot(value.trim()),
      message: 'Page name cannot start with "."'
    }
  };
}

export function getPredefinedPageName(page) {
  switch (page.path) {
    case config.termsPageName:
      return 'Terms and conditions';
    default:
      return page.name;
  }
}

export function replacePredefinedPageName(page) {
  if (!page) {
    return {};
  }

  return { ...page, name: getPredefinedPageName(page) };
}
export function replaceNameInPath({ oldPagePath, pagePath }) {
  const parsedPath = oldPagePath.split('/');

  if (parsedPath.length === 1) {
    return pagePath;
  }

  return `${parsedPath[0]}/${pagePath}`;
}

function noDisallowedChars(pageName) {
  return regexPageName.test(pageName);
}

function lengthInRange(pageName) {
  return pageName.length >= 1 && pageName.length <= config.maxPageNameLength;
}

function startsWithDot(pageName) {
  return pageName.startsWith('.');
}
