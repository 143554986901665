import PropTypes from 'prop-types';
import React from 'react';
import DropdownMenu from '@mulesoft/anypoint-components/lib/DropdownMenu';
import { getSortingOptions } from '~/utils/ang/sorting';
import { DEFAULT_SORT } from '~/utils/ang/constants';
import styles from './Sorting.css';

const Sorting = ({
  isANGSearchEnabled,
  isAuthenticated,
  onChange,
  sort,
  show,
  search,
  defaultSort
}) => {
  const onChangeCallback = React.useCallback(
    ({ value }) => onChange(value.key),
    [onChange]
  );

  if (!isANGSearchEnabled) {
    return null;
  }

  const sortingOptions = getSortingOptions(isAuthenticated, show, search);
  const options = Object.keys(sortingOptions).map((key) => ({
    key,
    label: sortingOptions[key]
  }));

  return (
    <div className={styles.sorting}>
      <span id="sort-by-label">Sort by:</span>
      <DropdownMenu
        alignment="right"
        options={options}
        testId="ang-sorting"
        value={
          options.find((option) => option.key === sort) ||
          options.find((option) => option.key === defaultSort) ||
          options[0]
        }
        onChange={onChangeCallback}
        ariaLabelledBy="sort-by-label"
      />
    </div>
  );
};

Sorting.propTypes = {
  isANGSearchEnabled: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  onChange: PropTypes.func,
  defaultSort: PropTypes.string,
  sort: PropTypes.string,
  show: PropTypes.string,
  search: PropTypes.string
};

Sorting.defaultProps = {
  isANGSearchEnabled: false,
  isAuthenticated: false,
  onChange: () => {},
  defaultSort: DEFAULT_SORT
};

export default Sorting;
