import React from 'react';
import classNames from 'classnames';
import ProfilePhoto from '@mulesoft/anypoint-components/lib/ProfilePhoto';
import { CreatedByType } from '@mulesoft/exchange-react-shapes';
import styles from './CreatedBy.css';

const CreatedBy = ({
  user: { firstName, lastName, profilePhoto } = {}
} = {}) => {
  return (
    <div
      data-test-id="asset-created-by"
      className={classNames(styles.createdBy, 'fs-block')}
    >
      <ProfilePhoto
        testId="assets"
        image={profilePhoto}
        theme={ProfilePhoto.themes.DarkTheme}
        className={styles.profilePhoto}
        size="s"
        firstName={firstName}
        lastName={lastName}
        aria-hidden
      />
      <span title={`${firstName} ${lastName}`}>
        {firstName} {lastName}
      </span>
    </div>
  );
};

CreatedBy.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: CreatedByType
};

export default CreatedBy;
