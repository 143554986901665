import { compose } from 'redux';
import { connect } from 'react-redux';
import withLayoutContext from '~/components/LayoutContextProvider';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as modalActions } from '~/domains/modal';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as customizationSelectors } from '~/domains/customization';
import LoginOptionsModal from './LoginOptionsModal';

const mapStateToProps = (state) => {
  const getPath = commonSelectors.getPortalPathFn(state);
  const domain = customizationSelectors.organizationDomain(state);
  const organizationName = commonSelectors.organizationName(state);

  return {
    getPath,
    organizationName,
    domain
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(modalActions.closeModal())
});

export default compose(
  registerModal,
  setModalType('LoginOptionsModal'),
  withLayoutContext(),
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(LoginOptionsModal);
