import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import PageEditor from '~/components/PageEditor';
import PageName from '~/components/PageName';
import DeletePage from '~/components/DeletePage';
import styles from './EditCustomPage.css';

class EditCustomPage extends PureComponent {
  static propTypes = {
    authorization: PropTypes.string,
    page: PropTypes.object,
    pages: PropTypes.arrayOf(PropTypes.object),
    onNameChange: PropTypes.func,
    onContentChange: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
    onFileUpload: PropTypes.func,
    isContentLoading: PropTypes.bool
  };

  static defaultProps = {
    page: {},
    pages: [],
    onNameChange: () => {},
    onContentChange: () => {},
    onOpenDeleteModal: () => {}
  };

  componentDidMount() {
    this.focusNameInput();
  }

  componentDidUpdate(prevProps) {
    const finishedLoading =
      !this.props.isContentLoading && prevProps.isContentLoading;

    if (finishedLoading) {
      this.focusNameInput();
    }
  }

  nameRef = React.createRef();

  focusNameInput() {
    if (this.nameRef.current) this.nameRef.current.input.focus();
  }

  handleNameChange = ({ pageName }) => {
    const { page, onNameChange } = this.props;

    onNameChange(pageName, page);
  };

  handleContentChange = ({ value }) => {
    const { page, onContentChange } = this.props;

    if (value !== page.markdown) {
      onContentChange(value, page);
    }
  };

  renderSpinner = () => (
    <div className={styles.spinner}>
      <Spinner size="l" />
    </div>
  );

  renderEditor = () => {
    const { authorization, page, pages, onOpenDeleteModal, onFileUpload } =
      this.props;

    return (
      <div
        className={styles.editorContainer}
        data-test-id="public-portal-custom-page-edit"
      >
        <div className={styles.pageNameBar}>
          <PageName
            ref={this.nameRef}
            page={page}
            pages={pages}
            onRenamePage={this.handleNameChange}
            canRenamePage
          />
          <DeletePage
            display-if={page.name}
            onOpenDeleteModal={onOpenDeleteModal}
          />
        </div>
        <PageEditor
          authorization={authorization}
          className={styles.editor}
          markdown={page.markdown}
          onChange={this.handleContentChange}
          onUpload={onFileUpload}
          page={page}
          pages={pages}
        />
      </div>
    );
  };

  render() {
    const { isContentLoading } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.main}>
          {isContentLoading ? this.renderSpinner() : this.renderEditor()}
        </div>
      </div>
    );
  }
}

export default EditCustomPage;
