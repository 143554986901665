import debug from 'debug';
import config from 'config';
import { handleUploadResourceError } from '~/utils/errors';
import { fetch } from '~/utils/fetch';
import { getAPIUrl } from '~/utils/api';

const log = debug('exchange:portal:service');

export async function uploadResource({
  context,
  groupId,
  assetId,
  version,
  file
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/assets/${groupId}/${assetId}/${version}/portal/draft/resources`
  });
  const formData = new global.FormData();

  log('uploading asset resource', { groupId, assetId, version });

  formData.append('data', file);

  let response;

  try {
    response = await fetch(url, {
      context,
      method: 'POST',
      headers: {
        'content-type': null
      },
      body: formData
    });
  } catch (err) {
    handleUploadResourceError(err, {
      url: config.docsLinks.assetPortalResources
    });
  }

  const { path } = await response.json();

  return {
    url: path,
    key: path
  };
}

export async function getResource({
  context,
  groupId,
  assetId,
  version,
  resourceName,
  isDraft
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/assets/${groupId}/${assetId}/${version}/portal${
      isDraft ? '/draft' : ''
    }/resources/${encodeURIComponent(resourceName)}`
  });

  log('getting asset resource', { groupId, assetId, version, isDraft });

  return fetch(url, {
    context,
    headers: { accept: '*/*' }
  });
}

export async function deleteDraft({
  organization,
  groupId,
  assetId,
  version,
  context
}) {
  log(`Deleting portal draft for asset ${groupId}:${assetId}:${version}`, {
    context
  });

  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organization.id}/assets/${groupId}/${assetId}/${version}/portal/draft`
  });

  return fetch(url, { context, method: 'DELETE' });
}
