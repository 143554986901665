import { compose } from 'redux';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';

import TooManyRequestsModal from './TooManyRequestsModal';

export default compose(
  registerModal,
  setModalType('TooManyRequestsModal')
)(TooManyRequestsModal);
