import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import withFocusRestore from '~/components/WithFocusRestore';
import * as urlUtils from '~/utils/url';
import CustomLayout from './CustomLayout';

const mergeParams = memoize((params, status) => ({
  ...params,
  status
}));

const mapStateToProps = (state, { match, location }) => {
  const routeParams = match.params;
  const status = urlUtils.getQueryParam(location, 'status')[0];
  const params = mergeParams(routeParams, status);
  const asset = assetsSelectors.asset(state, params);
  const isContentLoading = portalsSelectors.isPortalLoading(state);

  return {
    asset,
    isContentLoading,
    isVersionNavigation: !!match.params.version
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withFocusRestore
)(CustomLayout);
