import React from 'react';
import { formatDate } from '../../../utils/formatDate';
import styles from './FormattedDate.css';

interface FormattedDateProps {
  testId: string;

  date: Date | number | string;

  format?: Intl.DateTimeFormatOptions;
}

const FormattedDate = ({ testId, date, format }: FormattedDateProps) => {
  const formattedDate = React.useMemo(
    () => formatDate(date, format),
    [date, format]
  );

  return (
    <span
      className={styles.formattedDate}
      data-test-id={`${testId}-date`}
      title={formattedDate}
    >
      {formattedDate}
    </span>
  );
};

FormattedDate.defaultProps = {
  format: undefined
};

export default FormattedDate;
