import debug from 'debug';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { SEARCH_EXPERIMENT } from '~/domains/featureFlags/keys';
import { selectors as commonSelectors } from '~/domains/common';
import {
  identifyPayload,
  resetPayload,
  pagePayload,
  clientTrackPayload
} from '~/analytics/payload';
import { getContextMetadata } from '~/analytics/contexts';
import { getActiveOrganization } from '~/utils/organization';
import * as objectsUtils from '~/utils/objects';
import {
  ANALYTICS_LOGIN,
  ANALYTICS_LOGOUT,
  TRACK_PAGE,
  TRACK_EVENT
} from './actionTypes';

const log = debug('exchange:analytics:client');

export function analyticsLogin(profile) {
  return (dispatch, getState) => {
    const state = getState();
    const searchExperiment = featureFlagsSelectors.featureFlag(
      state,
      SEARCH_EXPERIMENT
    );

    dispatch({
      type: ANALYTICS_LOGIN,
      ...identifyPayload({ profile, searchExperiment: searchExperiment?.id })
    });
  };
}

export function analyticsLogout() {
  return {
    type: ANALYTICS_LOGOUT,
    ...resetPayload()
  };
}

export function trackPage({ name }, params) {
  log('tracking page', { name, params });

  return (dispatch, getState) => {
    const state = getState();
    const profile = commonSelectors.profile(state);
    let sessionProperties = {};

    if (profile) {
      const activeOrganization = getActiveOrganization(profile);

      if (activeOrganization) {
        sessionProperties = objectsUtils.pick(
          activeOrganization,
          'isExchangeAdmin',
          'isExchangeContributor',
          'isFederated',
          'isMaster'
        );
      }
    }

    dispatch({
      type: TRACK_PAGE,
      ...pagePayload({
        name,
        routeParams: params,
        properties: sessionProperties
      })
    });
  };
}

export function trackEvent(eventSchema, properties, options) {
  return (dispatch, getState) => {
    const state = getState();
    const route = commonSelectors.route(state);
    const analyticsContextMetadata = getContextMetadata(route, state);

    log(
      'tracking event',
      eventSchema,
      properties,
      analyticsContextMetadata,
      options
    );

    try {
      dispatch(
        {
          type: TRACK_EVENT,
          ...clientTrackPayload({
            eventSchema,
            properties,
            context: analyticsContextMetadata,
            options
          })
        },
        { skipErrorHandler: true }
      );
    } catch (error) {
      log('An error has occurred while trying to track the event', error);
    }
  };
}
