import { format } from 'url';
import { Standalone } from '@mulesoft/api-notebook';
import config from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import unescape from 'unescape-html';
import constants from './constants';
import packageInfo from '../../package.json';

const { dependencies } = packageInfo;
const OAUTH_CALLBACK = constants.oauthCallbackUrl;
const BASE_PATH = format({ ...config.platform.uri, pathname: '/' });

export const getWorkerPath = (domain) => {
  const pathname = `${config.externalURI.pathname}${
    domain ? `/portals/${domain}` : ''
  }/worker/${dependencies['@mulesoft/api-notebook']}/worker.js`;

  return format({
    ...config.externalURI,
    pathname
  });
};

export const ramlClientConfig = {
  basePath: BASE_PATH,
  oauthCallback: OAUTH_CALLBACK,
  proxy: null, // proxy was removed here: https://github.com/mulesoft/exchange-ui/pull/4974
  xhr: global.XMLHttpRequest
};

export const getRAMLClientConfig = ({ asset, fetchNotebookClient }) => {
  const enrichedClientConfig = asset
    ? { ...ramlClientConfig, asset }
    : ramlClientConfig;

  const parsedConfig = JSON.parse(JSON.stringify(enrichedClientConfig));

  return {
    ...parsedConfig,
    ramlResolver: fetchNotebookClient
  };
};

export function isAPIClientNotebook(notebookContent) {
  return notebookContent.indexOf('API.createClient') !== -1;
}

export function createNotebook(
  element,
  content,
  blockNumber,
  sandbox,
  fetchNotebookClient,
  beforeExecute,
  domain
) {
  const notebook = {};
  const notebookContent = unescape(content);
  const ramlConfig = getRAMLClientConfig({ fetchNotebookClient });

  ReactDOM.render(
    <Standalone
      ref={(c) => {
        notebook.instance = c;
      }}
      content={notebookContent}
      blockNumber={blockNumber}
      workerPath={getWorkerPath(domain)}
      sandbox={sandbox}
      ramlClientConfig={ramlConfig}
      beforeExecute={beforeExecute}
    />,
    element
  );

  return notebook;
}
