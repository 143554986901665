import PropTypes from 'prop-types';
import React from 'react';

const defaultMapContextToProps = (context) => context;

export default (mapContextToProps = defaultMapContextToProps) =>
  (Component) =>
    // eslint-disable-next-line react/prefer-stateless-function
    class LayoutContextProvider extends React.Component {
      static contextTypes = {
        isPublicPortal: PropTypes.bool,
        getPath: PropTypes.func,
        featureFlags: PropTypes.object
      };

      // This is a hack so the resolver is run if Component is a container
      static reduxAsyncConnect = Component.reduxAsyncConnect;

      // eslint-disable-next-line react/static-property-placement
      static get displayName() {
        const name = Component.displayName || Component.name;

        return name ? `WithLayoutContext(${name})` : 'WithLayoutContext';
      }

      render() {
        return (
          <Component
            {...this.props}
            {...mapContextToProps(this.context, this.props)}
            isPublicPortal={this.context.isPublicPortal}
            getPath={this.context.getPath}
            featureFlags={this.context.featureFlags}
          />
        );
      }
    };
