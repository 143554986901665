import config from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import Overview from '@mulesoft/exchange-ui-components/lib/components/Overview';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import Actions from '~/layouts/common/Actions';
import VersionsTable from '~/layouts/common/VersionsTable';
import LabelsList from '~/components/AssetDetail/LabelsList';
import Categories from '~/components/AssetDetail/Categories';
import { omit } from '~/utils/objects';
import styles from './Detail.css';

const removeStatusIfDeprecated = (asset) => {
  if (asset.status === 'deprecated') {
    return omit(asset, 'status');
  }

  return asset;
};

const Detail = ({
  asset,
  assetVersions,
  canEdit,
  isDraft,
  isEdit,
  isContentLoading,
  portal = {
    draftStatus: 'PUBLISHED'
  },
  pagePath = config.defaultPageName,
  onUpdateLabels,
  onAddNewVersion,
  onNewLabelClick,
  onLeaveCreatingLabelState,
  getPath,
  onUpdateContactInfo
}) => {
  return (
    <section aria-label="Asset detail">
      <Actions
        display-if={!isContentLoading && portal}
        asset={asset}
        isDraft={isDraft}
        isEdit={isEdit}
        pagePath={pagePath}
      />
      <div id="asset-detail" className={styles.detail}>
        <Overview
          asset={removeStatusIfDeprecated(asset)}
          canEdit={canEdit}
          updateContactInfo={onUpdateContactInfo}
        />
        <VersionsTable
          display-if={assetVersions.length}
          asset={asset}
          canEdit={canEdit}
          isDraft={isDraft}
          isEdit={isEdit}
          onAddNewVersion={onAddNewVersion}
        />
        <Categories asset={asset} canEdit={canEdit} getPath={getPath} />
        <LabelsList
          display-if={canEdit || (asset.labels && asset.labels.length > 0)}
          asset={asset}
          isEditable={canEdit}
          onUpdateLabels={onUpdateLabels}
          onNewLabelClick={onNewLabelClick}
          onLeaveCreatingState={onLeaveCreatingLabelState}
          getPath={getPath}
        />
      </div>
    </section>
  );
};

Detail.propTypes = {
  asset: AssetPropType,
  assetVersions: PropTypes.arrayOf(PropTypes.object),
  isDraft: PropTypes.bool,
  isEdit: PropTypes.bool,
  isContentLoading: PropTypes.bool,
  canEdit: PropTypes.bool,
  portal: PropTypes.object,
  pagePath: PropTypes.string,
  onUpdateLabels: PropTypes.func,
  onAddNewVersion: PropTypes.func,
  onNewLabelClick: PropTypes.func,
  onLeaveCreatingLabelState: PropTypes.func,
  getPath: PropTypes.func,
  onUpdateContactInfo: PropTypes.func
};

export default Detail;
