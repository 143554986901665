import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as assetSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/selectors';
import withLayoutContext from '~/components/LayoutContextProvider';
import ApiGroupInstance from './ApiGroupInstance';

const mapStateToProps = (state, { match: { params } }) => {
  const asset = assetSelectors.asset(state, params);

  return { dependencies: asset.dependencies };
};

export default compose(
  withRouter,
  withLayoutContext(),
  connect(mapStateToProps)
)(ApiGroupInstance);
