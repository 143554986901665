import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@mulesoft/exchange-ui-components';
import AssetDetailPanel from '~/components/AssetDetail/Detail';
import Actions from '~/layouts/common/Actions';
import DetailWrapper from '../../DetailWrapper';

const CustomLayoutDetail = ({
  asset,
  route,
  resolvedRoute,
  isContentLoading,
  isVersionNavigation
}) => {
  const isDraft = !!route.isDraft;
  const isEdit = !!route.isEdit;
  const isPublicPortal = !!route.isPublicPortal;
  const DetailComponent = resolvedRoute?.detail;

  if (!resolvedRoute) {
    return null;
  }

  if (DetailComponent === null) {
    return null;
  }

  if (!DetailComponent && !isPublicPortal) {
    const detail = <AssetDetailPanel route={route} />;

    return <DetailWrapper detail={detail} />;
  }

  if (!DetailComponent && isPublicPortal) {
    return null;
  }

  const detail = (
    <React.Fragment>
      <Actions
        display-if={!isContentLoading && !isPublicPortal}
        asset={asset}
        isDraft={isDraft}
        isEdit={isDraft}
      />
      <ErrorBoundary>
        <DetailComponent
          asset={asset}
          params={resolvedRoute.params}
          isDraft={isDraft}
          isEdit={isEdit}
          isPublicPortal={isPublicPortal}
          isVersionNavigation={isVersionNavigation}
          {...resolvedRoute.props}
        />
      </ErrorBoundary>
    </React.Fragment>
  );

  return <DetailWrapper detail={detail} />;
};

CustomLayoutDetail.propTypes = {
  asset: PropTypes.object,
  route: PropTypes.object,
  resolvedRoute: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isContentLoading: PropTypes.bool,
  isVersionNavigation: PropTypes.bool
};

export default CustomLayoutDetail;
