import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import config from 'config';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import Label from '@mulesoft/anypoint-components/lib/Label';
import PlusIcon from '@mulesoft/anypoint-icons/lib/svg/plus-small.svg';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import Form from '@mulesoft/anypoint-components/lib/Form';
import Tooltip from '@mulesoft/anypoint-components/lib/Tooltip';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import {
  isValidPageName,
  getPageValidations,
  pageDoesAlreadyExist
} from '~/utils/page';
import styles from './CreatePage.css';

class CreatePage extends PureComponent {
  static propTypes = {
    pages: PropTypes.arrayOf(PropTypes.object),
    hasUnsavedChanges: PropTypes.bool,
    onCreatePage: PropTypes.func,
    limitReached: PropTypes.bool,
    tooltipOffset: PropTypes.arrayOf(PropTypes.number)
  };

  static defaultProps = {
    limitReached: false,
    tooltipOffset: [0, 0]
  };

  constructor(props) {
    super(props);

    this.pageValidations = getPageValidations(props.pages);
  }

  state = {
    isCreating: false,
    form: { pageName: '' }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pages.length !== this.props.pages.length) {
      this.pageValidations = getPageValidations(this.props.pages);
    }
  }

  getAddNewPageButton(stateClassName, eventHandlers = {}) {
    return (
      <div
        data-test-id="add-new-page-button"
        display-if={!this.state.isCreating}
        aria-describedby="page-limit-tooltip-text"
        tabIndex="0"
        role="button"
        className={classNames(stateClassName, styles.newPage)}
        {...eventHandlers}
      >
        <Icon size="xs">
          <PlusIcon />
        </Icon>
        <span className={styles.label}>Add new page</span>
      </div>
    );
  }

  handleTextFieldKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.leaveCreatingState();
    }
  };

  handleNewPageClick = () => {
    this.setState({ isCreating: true });
  };

  leaveCreatingState = () => {
    this.setState({ isCreating: false, form: { pageName: '' } });
  };

  savePageName = () => {
    const pageName = this.state.form.pageName.trim();
    const { pages, onCreatePage } = this.props;

    if (
      pageName &&
      !pageDoesAlreadyExist(pageName, pages) &&
      isValidPageName(pageName)
    ) {
      onCreatePage(pageName);
      this.leaveCreatingState();
    } else if (!pageName) {
      this.leaveCreatingState();
    }
  };

  handleFormChange = ({ target, value }) => {
    this.setState((state) => ({
      form: {
        ...state.form,
        [target]: value[target]
      }
    }));
  };

  renderTooltipContent() {
    return (
      <span id="page-limit-tooltip-text">Page limit has been reached</span>
    );
  }

  render() {
    const { isCreating, form } = this.state;
    const { limitReached, tooltipOffset } = this.props;

    return (
      <div>
        <Form
          display-if={isCreating}
          className={styles.form}
          onChange={this.handleFormChange}
          onSubmit={this.savePageName}
          onBlur={this.savePageName}
          onKeyDown={this.handleTextFieldKeyDown}
          value={form}
        >
          <Label className="visually-hidden" htmlFor="add-new-page-name">
            Page Name
          </Label>
          <TextField
            id="add-new-page-name"
            testId="add-new-page-name"
            name="pageName"
            type="text"
            maxLength={config.maxPageNameLength}
            className={classNames(styles.action)}
            placeholder="New page name"
            validate={this.pageValidations}
            autoFocus
          />
        </Form>
        {limitReached ? (
          <Tooltip
            position="top"
            offset={tooltipOffset}
            content={this.renderTooltipContent()}
            testId="limit-reached-tooltip"
          >
            {this.getAddNewPageButton(styles.disabled)}
          </Tooltip>
        ) : (
          this.getAddNewPageButton(styles.action, {
            onClick: this.handleNewPageClick,
            onKeyDown: eventHandlerUtils.onEnterKey(this.handleNewPageClick)
          })
        )}
      </div>
    );
  }
}

export default CreatePage;
