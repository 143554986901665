import {
  Actions,
  Sidebar,
  VersionsTable,
  DeleteAssetModal
} from '@mulesoft/exchange-ui-portals-common';
import DeprecateAssetModal from '~/components/AssetDetail/Actions/DeprecateAssetModal';
import RevertDeprecateAssetModal from '~/components/AssetDetail/Actions/RevertDeprecateAssetModal';

export default {
  DeleteAssetModal,
  DeprecateAssetModal,
  RevertDeprecateAssetModal,
  Sidebar,
  Actions,
  VersionsTable
};
