import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import AssetDetail from '~/components/AssetDetail';
import Main from '~/layouts/common/Main';

const CustomLayoutMain = ({
  asset,
  isVersionNavigation,
  resolvedRoute,
  route
}) => {
  const isDraft = !!route.isDraft;
  const isEdit = !!route.isEdit;
  const isPublicPortal = !!route.isPublicPortal;
  const MainComponent = resolvedRoute?.main ?? resolvedRoute?.component;
  const isPortalReArchitectureEnabled = useSelector(
    sessionSelectors.isPIRAEnabled
  );

  if (!resolvedRoute) {
    return (
      <Main wrap-if={!isPortalReArchitectureEnabled}>
        <AssetDetail hideRating route={route} />
      </Main>
    );
  }

  return (
    <Main wrap-if={!isPortalReArchitectureEnabled}>
      <AssetDetail hideRating route={route}>
        <MainComponent
          asset={asset}
          params={resolvedRoute.params}
          isDraft={isDraft}
          isEdit={isEdit}
          isPublicPortal={isPublicPortal}
          isVersionNavigation={isVersionNavigation}
          {...resolvedRoute.props}
        />
      </AssetDetail>
    </Main>
  );
};

CustomLayoutMain.propTypes = {
  asset: PropTypes.object,
  isVersionNavigation: PropTypes.bool,
  isPortalReArchitectureEnabled: PropTypes.bool,
  resolvedRoute: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  route: PropTypes.object
};

export default CustomLayoutMain;
