import {
  addActiveOrganizationLink,
  addAllAssetsLink,
  addMasterOrganizationLink,
  addMulesoftLink,
  addPresetLinks,
  addSharedWithMe
} from './adders';

const authenticatedPipeline = (isHomePage) =>
  isHomePage
    ? [
        addAllAssetsLink,
        addMasterOrganizationLink,
        addActiveOrganizationLink,
        addMulesoftLink,
        addSharedWithMe
      ]
    : [addAllAssetsLink];

const unauthenticatedPipeline = (isHomePage) =>
  isHomePage ? [addMulesoftLink, addPresetLinks] : [];

export const getPipeline = (profile, isHomePage) => {
  return profile
    ? authenticatedPipeline(isHomePage)
    : unauthenticatedPipeline(isHomePage);
};
