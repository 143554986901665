import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import Card from './Card';

const mapRootStateToProps = (state) => ({
  homeQuery: commonSelectors.homeQuery(state)
});

export default connect(mapRootStateToProps, null, null, {
  context: RootStoreContext
})(Card);
