import { stringify } from 'querystring';
import { firstOrDefault } from '~/utils/arrays';

const notUndefined = (x) => x !== undefined;

export function openPopup(url, title, popupWidth, popupHeight) {
  const dualScreenLeft = firstOrDefault(
    [window.screenLeft, window.screenX],
    notUndefined
  );

  const dualScreenTop = firstOrDefault(
    [window.screenTop, window.screenY],
    notUndefined
  );

  const width = firstOrDefault(
    [window.innerWidth, document.documentElement.clientWidth],
    notUndefined
  );
  const height = firstOrDefault(
    [window.innerHeight, document.documentElement.clientHeight],
    notUndefined
  );

  const left = width / 2 - popupWidth / 2 + dualScreenLeft;
  const top = height / 2 - popupHeight / 2 + dualScreenTop;

  const options = stringify(
    {
      scrollbars: 'yes',
      width: popupWidth,
      height: popupHeight,
      top,
      left
    },
    ','
  );

  const newWindow = window.open(url, title, options);

  if (newWindow && newWindow.focus) {
    newWindow.focus();
  }

  return newWindow;
}
