import { LOCATION_CHANGE } from 'connected-react-router';
import { deepMerge, omit } from '~/utils/objects';
import { getHashMap, getArray, replaceInArray } from '~/utils/arrays';
import * as actions from './actionTypes';

export const initialState = {
  data: {},
  ui: {
    hasUnsavedChanges: false
  }
};

const updateOrganizationData = (state, organizationKey, payload) => {
  const { data } = state;
  const organizationData = { ...data[organizationKey] };
  const mergedData = deepMerge(organizationData, payload);

  return {
    ...state,
    data: {
      ...data,
      [organizationKey]: {
        ...organizationData,
        ...mergedData
      }
    }
  };
};

const updateHasUnsavedChanges = (state, hasUnsavedChanges) => ({
  ...state,
  ui: { ...state.ui, hasUnsavedChanges }
});

export default function reducer(state = initialState, action = {}) {
  const organizationKey = action.organizationDomain;
  const organizationDraftKey = `${action.organizationDomain}/draft`;
  const draftData = { ...state.data[organizationDraftKey] };
  const mainData = { ...state.data[organizationKey] };

  switch (action.type) {
    case actions.FETCH_CUSTOMIZATION_DATA_SUCCESS: {
      return updateOrganizationData(state, organizationKey, action.data);
    }

    case actions.FETCH_CUSTOMIZATION_DRAFT_SUCCESS: {
      return updateOrganizationData(state, organizationDraftKey, {
        ...action.data
      });
    }

    case actions.UPDATE_CUSTOMIZATION_TITLE: {
      const newState = updateHasUnsavedChanges(state, true);

      return updateOrganizationData(newState, organizationDraftKey, {
        welcomeSection: {
          ...draftData.welcomeSection,
          welcomeTitle: action.value
        }
      });
    }

    case actions.UPDATE_CUSTOMIZATION_COLOR: {
      const { colorType, value, destination } = action;
      const newState = updateHasUnsavedChanges(state, true);

      return updateOrganizationData(newState, organizationDraftKey, {
        [destination]: {
          ...draftData[destination],
          [colorType]: value
        }
      });
    }

    case actions.UPDATE_CUSTOMIZATION_TEXT: {
      const newState = updateHasUnsavedChanges(state, true);

      return updateOrganizationData(newState, organizationDraftKey, {
        welcomeSection: {
          ...draftData.welcomeSection,
          welcomeText: action.value
        }
      });
    }

    case actions.UPDATE_CUSTOMIZATION_IMAGE: {
      const { url, file, imageKind } = action;
      const destination =
        imageKind === 'heroImage' ? 'welcomeSection' : 'navbar';
      const newState = updateHasUnsavedChanges(state, true);

      return {
        ...newState,
        data: {
          ...newState.data,
          [organizationDraftKey]: {
            ...draftData,
            [destination]: {
              ...draftData[destination],
              [imageKind]: url
            },
            images: {
              ...draftData.images,
              [imageKind]: file
            }
          }
        }
      };
    }

    case actions.RENAME_CUSTOMIZATION_CUSTOM_PAGE: {
      const { renamedPage, oldPagePath } = action;
      const { customPages, customPagesOrder } = draftData;
      const newState = updateHasUnsavedChanges(state, true);

      const newPagesOrder = replaceInArray(customPagesOrder, renamedPage.path, {
        findFn: (item) => item === oldPagePath
      });

      const newPages = omit(
        {
          ...customPages,
          [renamedPage.path]: renamedPage
        },
        oldPagePath
      );

      return {
        ...newState,
        data: {
          ...newState.data,
          [organizationDraftKey]: {
            ...draftData,
            customPages: newPages,
            customPagesOrder: newPagesOrder
          }
        }
      };
    }

    case actions.UPDATE_CUSTOMIZATION_CUSTOM_PAGE_MARKDOWN: {
      const { content, pagePath } = action;
      const newState = updateHasUnsavedChanges(state, true);

      const dataToUpdate = {
        customPages: {
          ...draftData.customPages,
          [pagePath]: {
            ...(draftData.customPages && draftData.customPages[pagePath]
              ? draftData.customPages[pagePath]
              : {
                  path: pagePath,
                  name: pagePath
                }),
            markdown: content
          }
        }
      };

      return updateOrganizationData(
        newState,
        organizationDraftKey,
        dataToUpdate
      );
    }

    case actions.UPDATE_CUSTOMIZATION_CUSTOM_PAGE_HTML: {
      const { content, pagePath } = action;
      const newState = updateHasUnsavedChanges(state, true);

      const dataToUpdate = {
        customPages: {
          ...mainData.customPages,
          [pagePath]: {
            ...(mainData.customPages && mainData.customPages[pagePath]
              ? mainData.customPages[pagePath]
              : {
                  path: pagePath,
                  name: pagePath
                }),
            html: content
          }
        }
      };

      return updateOrganizationData(newState, organizationKey, dataToUpdate);
    }

    case actions.CREATE_CUSTOMIZATION_CUSTOM_PAGE: {
      const { name, path } = action;
      const existingPages = draftData.customPages;
      const newState = updateHasUnsavedChanges(state, true);

      const nextPages = {
        ...existingPages,
        [path]: { name, path }
      };

      return updateOrganizationData(newState, organizationDraftKey, {
        customPages: nextPages,
        customPagesOrder: [...draftData.customPagesOrder, path]
      });
    }

    case actions.DELETE_CUSTOMIZATION_CUSTOM_PAGE: {
      const newState = updateHasUnsavedChanges(state, true);

      return {
        ...newState,
        data: {
          ...newState.data,
          [organizationDraftKey]: {
            ...draftData,
            customPages: omit(draftData.customPages, action.pagePath),
            customPagesOrder: draftData.customPagesOrder.filter(
              (path) => path !== action.pagePath
            )
          }
        }
      };
    }

    case actions.SAVE_CUSTOMIZATION_DRAFT_REQUEST: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSaving: true
        }
      };
    }

    case actions.SAVE_CUSTOMIZATION_DRAFT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [organizationDraftKey]: {
            ...draftData,
            draft: true
          }
        },
        ui: {
          ...state.ui,
          isSaving: false,
          hasUnsavedChanges: false
        }
      };
    }

    case actions.SAVE_CUSTOMIZATION_DRAFT_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSaving: false
        }
      };
    }

    case actions.DISCARD_CUSTOMIZATION_DRAFT_REQUEST: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isDiscarding: true
        }
      };
    }

    case actions.DISCARD_CUSTOMIZATION_DRAFT_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isDiscarding: false,
          hasUnsavedChanges: false
        },
        data: omit(state.data, organizationDraftKey)
      };
    }

    case actions.DISCARD_CUSTOMIZATION_DRAFT_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isDiscarding: false
        }
      };
    }

    case actions.PUBLISH_CUSTOMIZATION_DRAFT_REQUEST: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isPublishing: true
        }
      };
    }

    case actions.PUBLISH_CUSTOMIZATION_DRAFT_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isPublishing: false
        }
      };
    }

    case actions.PUBLISH_CUSTOMIZATION_DRAFT_SUCCESS: {
      const customPages =
        draftData.customPages &&
        getHashMap(
          getArray(draftData.customPages).map((page) => omit(page, 'markdown')),
          'path'
        );

      return {
        ...state,
        data: {
          ...omit(state.data, organizationDraftKey),
          [organizationKey]: {
            ...mainData,
            welcomeSection: { ...draftData.welcomeSection },
            navbar: { ...draftData.navbar },
            customPages,
            customPagesOrder: draftData.customPagesOrder
          }
        },
        ui: {
          ...state.ui,
          isPublishing: false,
          hasUnsavedChanges: false
        }
      };
    }

    case actions.ENABLE_CONTENT_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isContentLoading: true
        }
      };
    }

    case actions.DISABLE_CONTENT_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isContentLoading: false
        }
      };
    }

    case actions.ENABLE_CUSTOMIZATION_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isCustomizationLoading: true
        }
      };
    }

    case actions.DISABLE_CUSTOMIZATION_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isCustomizationLoading: false
        }
      };
    }

    case actions.OPEN_CUSTOMIZATION_MOBILE_MENU: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isOpenMobileMenu: true
        }
      };
    }

    case LOCATION_CHANGE:
    case actions.CLOSE_CUSTOMIZATION_MOBILE_MENU: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isOpenMobileMenu: false
        }
      };
    }

    case actions.SAVE_CUSTOM_PAGES_ORDER_REQUEST: {
      const customPagesOrder = action.params.pages.map((page) => page.path);
      const organization = `${action.params.organizationDomain}/draft`;

      return updateOrganizationData(state, organization, {
        customPagesOrder
      });
    }

    case actions.SAVE_CUSTOM_PAGES_ORDER_SUCCESS: {
      const { pagesOrderRevision } = action.result;
      const newState = updateHasUnsavedChanges(state, true);
      const organization = `${action.params.organizationDomain}/draft`;

      return updateOrganizationData(newState, organization, {
        pagesOrderRevision
      });
    }

    case actions.SAVE_CUSTOM_PAGES_ORDER_FAILURE: {
      const organization = `${action.params.organizationDomain}/draft`;

      return updateOrganizationData(state, organization, {
        customPagesOrder: action.params.currentCustomPagesOrder
      });
    }

    default:
      return state;
  }
}
