import { connect } from 'react-redux';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import * as assetSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/selectors';
import DeleteAssetModal from './DeleteAssetModal';

const mapStateToProps = (state, { asset }) => {
  const apiGroupInstances = apiGroupSelectors.apiGroupInstances(state, asset);
  const organizationDomain = assetSelectors.assetOrganizationDomain(
    state,
    asset
  );

  return {
    apiGroupInstances,
    organizationDomain
  };
};

export default connect(mapStateToProps)(DeleteAssetModal);
