import PropTypes from 'prop-types';
import React from 'react';
import { SkipLinks } from '@mulesoft/exchange-ui-components';
import styles from './Settings.css';

const SettingsContainer = ({ children }) => {
  return (
    <section
      data-test-id="settings"
      className={styles.settings}
      aria-labelledby="settings-heading"
    >
      <SkipLinks />
      <h1 id="settings-heading">Settings</h1>
      <h2>Categories</h2>
      {children}
    </section>
  );
};

SettingsContainer.propTypes = {
  children: PropTypes.node
};

export default SettingsContainer;
