import { connect } from 'react-redux';
import { compose } from 'redux';
import { setAnnouncement } from '~/components/Announcements/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as localStorageActions } from '~/domains/localStorage';
import {
  selectors as searchSelectors,
  actions as searchActions
} from '~/domains/search';
import ANGSearchOptIn from './ANGSearchOptIn';

const OPT_IN_BANNER_DISABLED_KEY = 'isANGSearchOptInBannerDisabled';

const condition = searchSelectors.isANGSearchOptInBannerVisible;

const mapDispatchToProps = (dispatch) => ({
  onActivateANGSearch: () => dispatch(searchActions.optInToANGSearch()),
  onClose: () =>
    dispatch(localStorageActions.setItem(OPT_IN_BANNER_DISABLED_KEY, true))
});

export default compose(
  setAnnouncement(condition),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(ANGSearchOptIn);
