import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mulesoft/anypoint-components/lib/Button';
import { AssetTypesConfigurationContext } from '@mulesoft/exchange-react-hooks';
import ANGFilters from '@mulesoft/exchange-search-common/lib/ANGFilters';
import Sorting from '../Sorting';
import * as savedSearchesUtils from '~/utils/savedSearches';
import CreateSavedSearchModal from '~/components/NavigationSidebar/SavedSearches/CreateSavedSearchModal';
import { getActiveOrganization } from '~/utils/organization';
import styles from './ResultsInfo.css';

const ResultsInfo = ({
  totalResults,
  isANGSearchEnabled = false,
  profile,
  savedSearches = [],
  userSavedSearches = [],
  query = {},
  isSavedSearchEnabled = false,
  isPublicPortal = false,
  openModal = () => {}
}) => {
  const assetTypesConfiguration = React.useContext(
    AssetTypesConfigurationContext
  );

  const getOrganization = () => {
    return getActiveOrganization(profile);
  };

  const getMatchingSavedSearch = () => {
    const allSavedSearches = savedSearches.concat(userSavedSearches);

    return allSavedSearches.find((savedSearch) =>
      savedSearchesUtils.isSavedSearchEqual(savedSearch, query)
    );
  };

  const shouldDisplayCreateSavedSearch = () => {
    const hasMatchingSavedSearch = getMatchingSavedSearch();

    return (
      isSavedSearchEnabled &&
      new ANGFilters(query).hasQuery() &&
      !hasMatchingSavedSearch &&
      profile
    );
  };

  const canEditOrgSavedSearches = () => {
    const organization = getOrganization();

    return organization && organization.canEditOrgSavedSearches;
  };

  const handleClickSaveSearch = () => {
    openModal({
      modalType: CreateSavedSearchModal.modalType,
      modalProps: {
        query,
        activeOrganization: getOrganization(),
        savedSearches,
        userSavedSearches,
        isAdmin: canEditOrgSavedSearches()
      }
    });
  };

  const getSelectedAssetTypes = () => {
    if (!query.type) {
      return null;
    }

    let types;

    if (Array.isArray(query.type)) {
      types = query.type;
    } else {
      types = [query.type];
    }

    return types
      .filter(
        (type) =>
          assetTypesConfiguration[type] && assetTypesConfiguration[type].filter
      )
      .map((type) => assetTypesConfiguration[type].filter)
      .filter((key, index, array) => array.indexOf(key) === index)
      .join(', ');
  };

  const savedSearchQuery = () => {
    const { search } = query;

    if (!isPublicPortal) {
      return `${totalResults} result${totalResults > 1 ? 's' : ''}`;
    }

    if (isANGSearchEnabled && !isPublicPortal) {
      return `Showing ${totalResults} result${totalResults > 1 ? 's' : ''}`;
    }

    const showedType = getSelectedAssetTypes();
    const preText = 'Showing results for';
    const matchingSavedSearch = getMatchingSavedSearch();

    if (matchingSavedSearch) {
      return `${preText} saved search "${matchingSavedSearch.name}"`;
    }

    if (search && showedType) {
      return `${preText} "${search}" in ${showedType}`;
    }

    if (search) {
      return `${preText} "${search}"`;
    }

    if (!search && !showedType) {
      return '';
    }

    return `${preText} ${showedType}`;
  };

  const hasActionsSection = () => {
    if (!isPublicPortal && isANGSearchEnabled && totalResults === 0) {
      return false;
    }

    return new ANGFilters(query).hasQuery();
  };

  return (
    <div
      className={styles.actionsSection}
      display-if={savedSearchQuery() && hasActionsSection()}
    >
      <div
        data-test-id="search-result"
        className={styles.searchResultInfo}
        role="status"
      >
        {savedSearchQuery()}
        {shouldDisplayCreateSavedSearch() ? '.' : ''}
        <Button
          className={styles.saveSearchButton}
          testId="save-search-suggest-link"
          display-if={shouldDisplayCreateSavedSearch()}
          aria-describedby="save-this-search-description"
          onClick={handleClickSaveSearch}
          kind="secondary"
          noFill
          size="sm"
        >
          Save this search
          <span id="save-this-search-description" className="visually-hidden">
            Opens modal for creating a saved search
          </span>
        </Button>
      </div>
      {!isPublicPortal ? <Sorting /> : null}
    </div>
  );
};

ResultsInfo.propTypes = {
  totalResults: PropTypes.number,
  isANGSearchEnabled: PropTypes.bool,
  isSavedSearchEnabled: PropTypes.bool,
  isPublicPortal: PropTypes.bool,
  profile: PropTypes.object,
  savedSearches: PropTypes.array,
  userSavedSearches: PropTypes.array,
  openModal: PropTypes.func,
  query: PropTypes.object
};
export default ResultsInfo;
