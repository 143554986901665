import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withConfig } from '@mulesoft/exchange-react-hooks';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { withAssetFromRootStore } from '~/contexts/RootStoreContext';
import AssetDetailLayout from '~/layouts/common/AssetDetail/AssetDetailLayout';
import resolver from '~/layouts/common/AssetDetail/AssetDetailLayout.resolver';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import async from '~/utils/async';
import * as routesUtils from '~/utils/routes';
import * as urlUtils from '~/utils/url';

const mergeParams = memoize((params, status) => ({
  ...params,
  status
}));

const mapStateToProps = (
  state,
  { route, location, config, assetFromRootStore }
) => {
  const currentRoute = routesUtils.getCurrentRoute(route, location);
  const status = urlUtils.getQueryParam(location, 'status')[0];
  const { id: routeId, params: routeParams } = currentRoute;
  const params = mergeParams(routeParams, status);
  const asset = assetsSelectors.asset(state, params) || assetFromRootStore;
  const isContentLoading = portalsSelectors.isPortalLoading(state);
  const isPageComponent = routeId === 'page';
  const isHomePage =
    isPageComponent &&
    (!params.pagePath || params.pagePath === config.defaultPageName);
  const isTypeLoaded =
    !!asset?.type && reducerManagerSelectors.isTypeLoaded(state, asset.type);

  if (!asset) {
    return {};
  }

  const minorVersions = assetsSelectors.assetMinorVersions(state, asset);

  return {
    asset,
    assetContext: 'public',
    isHomePage,
    isContentLoading,
    isAdmin: false,
    isContributor: false,
    isPageComponent,
    isTypeLoaded,
    minorVersions
  };
};

export default compose(
  async(resolver),
  withAssetFromRootStore,
  withRouter,
  withConfig,
  connect(mapStateToProps)
)(AssetDetailLayout);
