import * as actions from '~/domains/customization/actions';
import homeResolver from '../Home/HomeLayout.resolver';

const resolve = async (params) => {
  await homeResolver({ ...params, isDraft: true });

  const { dispatch } = params;

  await dispatch(actions.fetchCustomizationDraft());
};

export default resolve;
