import { format } from 'url';
import config from 'config';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as selectors from './selectors';
import * as actionTypes from './actionTypes';
import * as commonSelectors from '../common/selectors';

const isPortalCustomizationRegExp = new RegExp(
  `^${config.contextPath}/portals/[\\s\\S]+?/customize`
);
const isExchangePath = (path) =>
  new RegExp(`^${config.contextPath}/portals/[\\s\\S]+?/`).test(path);
const isVanityDomainPath = (path, routingContext) =>
  routingContext.isForwardedPath &&
  new RegExp(`^${routingContext.path}`).test(path);

export const defaultFavicon = format({
  ...config.images.uri,
  pathname: config.images.favicon
});

export const changeFavicon =
  ({ getState }) =>
  (next) =>
  (action) => {
    const state = getState();

    switch (action.type) {
      case LOCATION_CHANGE: {
        const path = action.payload.location.pathname;
        const routingContext = commonSelectors.routingContext(state);
        let data;

        if (isPortalCustomizationRegExp.test(path)) {
          data = selectors.customizationDraft(state) || {};
        } else if (
          isExchangePath(path) ||
          isVanityDomainPath(path, routingContext)
        ) {
          data = selectors.customizationData(state) || {};
        }

        if (data && data.navbar && data.navbar.favicon) {
          updateFavicon(data.navbar.favicon);
        } else {
          updateFavicon(defaultFavicon);
        }

        break;
      }

      case actionTypes.FETCH_CUSTOMIZATION_DATA_SUCCESS:
      case actionTypes.FETCH_CUSTOMIZATION_DRAFT_SUCCESS: {
        const faviconURL = action.data.navbar.favicon;

        if (faviconURL) {
          updateFavicon(action.data.navbar.favicon);
        }

        break;
      }

      case actionTypes.UPDATE_CUSTOMIZATION_IMAGE: {
        if (action.imageKind === 'favicon' && action.url) {
          updateFavicon(action.url);
        }

        break;
      }

      default: {
        break;
      }
    }

    return next(action);
  };

export function updateFavicon(href) {
  const oldLink = document.querySelector('link[rel="icon"]');

  if (oldLink) {
    document.head.removeChild(oldLink);
  }

  if (href) {
    const link = document.createElement('link');

    link.rel = 'icon';
    link.href = href;

    document.head.appendChild(link);
  }
}
