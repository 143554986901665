import * as qs from 'querystring';
import config from 'config';
import memoize from 'memoize-one';
import { ANGFilters, parseQuery } from '@mulesoft/exchange-search-common';
import { parseSearch } from '../location';
import { getActiveOrganizationId } from '../organization';
import * as objectsUtils from '../objects';
import * as arrayUtils from '../arrays';
import * as constants from '../navigation/constants';

const BOOST_KEY = '_boost';

const multiFilters = [
  'type',
  'category',
  'organizationId',
  'tag',
  'status',
  'field',
  'sharedWithMe'
];

export const buildFiltersFromPreset = (presetFilters, profile) => {
  const { categories } = presetFilters ?? {};
  const category = (categories || []).map(
    ({ tagKey, value }) => `${tagKey}:${value}`
  );

  return {
    ...(profile && config ? { organizationId: [config.mulesoft.id] } : {}),
    ...(category.length ? { category } : {})
  };
};

export const getAuthenticatedShowFilters = (
  show,
  profile,
  isQueryEmpty,
  rootOrganizations
) => {
  const allOrganizationFilters = {
    organizationId: (rootOrganizations ?? []).sort()
  };

  if (isQueryEmpty) {
    return allOrganizationFilters;
  }

  switch (show) {
    case constants.ALL_ORGANIZATION_LINK_ID:
      return allOrganizationFilters;

    case constants.ACTIVE_ORGANIZATION_LINK_ID:
      return { organizationId: [getActiveOrganizationId(profile)] };

    case constants.MULESOFT_ORGANIZATION_LINK_ID:
      return { organizationId: [config?.mulesoft.id] };

    case constants.SHARED_WITH_ME_LINK_ID:
      return { sharedWithMe: ['true'] };

    default:
      return {};
  }
};

export const getUnauthenticatedShowFilters = (show) => {
  const presetLink = show
    ? config.presetLinks.find(({ id }) => id === show)
    : null;

  return presetLink ? buildFiltersFromPreset(presetLink.filters, config) : {};
};

export const transformMatchingGlobalSections = (query) => {
  const match = config.presetLinks.find((presetLink) => {
    const presetFilters = buildFiltersFromPreset(presetLink.filters);

    return arrayUtils.shallowEquals(query.category, presetFilters.category);
  });

  return match
    ? {
        ...objectsUtils.omit(query, 'category', '_angState'),
        show: match.id
      }
    : objectsUtils.omit(query, '_angState');
};

export const transformMatchingPrivateSections = (
  query,
  profile,
  rootOrganizations
) => {
  const { organizationId, sharedWithMe } = query;

  if (sharedWithMe?.[0] === 'true') {
    return {
      ...objectsUtils.omit(query, 'sharedWithMe', '_angState'),
      show: constants.SHARED_WITH_ME_LINK_ID
    };
  }

  const matches = (organizationIds) => {
    return arrayUtils.shallowEquals(organizationId, organizationIds, true);
  };

  const defaultQuery = objectsUtils.omit(
    { ...query, show: constants.ALL_ASSETS_LINK_ID },
    '_angState'
  );
  let show;

  if (!organizationId) {
    return defaultQuery;
  }

  if (matches([getActiveOrganizationId(profile)])) {
    show = constants.ACTIVE_ORGANIZATION_LINK_ID;
  } else if (matches(rootOrganizations ?? [])) {
    show = constants.ALL_ORGANIZATION_LINK_ID;
  } else if (matches([config?.mulesoft.id])) {
    show = constants.MULESOFT_ORGANIZATION_LINK_ID;
  }

  return show
    ? { ...objectsUtils.omit(query, 'organizationId', '_angState'), show }
    : defaultQuery;
};

export const getBoostConfiguration = memoize((search) => {
  const query = parseSearch(search);

  return arrayUtils.convertToArray(query[BOOST_KEY]);
});

export const buildFromLocation = memoize(
  (location, assetTypesConfiguration, rootOrganizations = [], profile) => {
    const query = qs.parse((location.search ?? '').replace('?', ''));
    const parsedQuery = parseQuery((query.search ?? '').toString());
    const filtersQuery = {
      search: parsedQuery.search
    };
    const isQueryEmpty = Object.keys(query).length === 0;

    multiFilters.forEach((key) => {
      filtersQuery[key] = arrayUtils
        .convertToArray(query[key])
        .concat(parsedQuery[key] ?? [])
        .sort();
    });

    let showFilters;
    const show = Array.isArray(query.show) ? query.show[0] : query.show;

    if (!profile) {
      showFilters = getUnauthenticatedShowFilters(show);
    } else {
      showFilters = getAuthenticatedShowFilters(
        show,
        profile,
        isQueryEmpty,
        rootOrganizations
      );
    }

    return new ANGFilters(
      { ...filtersQuery, ...showFilters },
      assetTypesConfiguration
    );
  }
);

export const getTargetQuery = (query, profile, rootOrganizations) => {
  if (!profile) {
    return transformMatchingGlobalSections(query);
  }

  return transformMatchingPrivateSections(query, profile, rootOrganizations);
};
