import { compose } from 'redux';
import { connect } from 'react-redux';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import * as reloginActions from '~/domains/relogin/actions';
import * as sessionActions from '~/portals/store/domains/session/actions';

import UnauthorizedModal from './UnauthorizedModal';

const mapPortalsDispatchToProps = (dispatch) => ({
  setPortalsToken: (accessToken, tokenType) => {
    dispatch(sessionActions.setToken(accessToken, tokenType));
  }
});

const mapDispatchToProps = (dispatch, { isPublicPortal, setPortalsToken }) => ({
  onRelogin: () => {
    const setToken = (accessToken, tokenType) => {
      dispatch(sessionActions.setToken(accessToken, tokenType));
      setPortalsToken(accessToken, tokenType);
    };

    dispatch(reloginActions.reloginUser({ isPublicPortal, setToken }));
  }
});

export default compose(
  registerModal,
  setModalType('UnauthorizedModal'),
  connect(null, mapPortalsDispatchToProps),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(UnauthorizedModal);
