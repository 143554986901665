import config from 'config';
import {
  getActiveOrganizationLink,
  getAllAssetsLink,
  getMasterOrganizationLink,
  getMuleSoftLink,
  getPresetLink,
  getSharedWithMeLink
} from './links';

export const addAllAssetsLink = ({
  links,
  query,
  isHomePage,
  isANGSearchEnabled
}) => {
  return [...links, getAllAssetsLink(query, isANGSearchEnabled, isHomePage)];
};

export const addMasterOrganizationLink = ({
  links,
  profile,
  query,
  isANGSearchEnabled
}) => {
  const masterOrganization = profile.organization;
  const onlyOneBG = !masterOrganization.subOrganizationIds.length;

  if (onlyOneBG) return links;

  return [
    ...links,
    getMasterOrganizationLink(query, masterOrganization, isANGSearchEnabled)
  ];
};

export const addActiveOrganizationLink = ({
  links,
  profile,
  query,
  isANGSearchEnabled
}) => {
  return [
    ...links,
    getActiveOrganizationLink(query, profile, isANGSearchEnabled)
  ];
};

export const addMulesoftLink = ({
  links,
  profile,
  query,
  isANGSearchEnabled
}) => {
  return [...links, getMuleSoftLink(query, profile, isANGSearchEnabled)];
};

export const addPresetLinks = ({ links, query, presetLinkToEnabledMap }) => {
  const presetLinks = config.presetLinks.reduce(
    (accum, link) =>
      presetLinkToEnabledMap[link.id]
        ? [...accum, getPresetLink(query, link)]
        : accum,
    []
  );

  return [...links, ...presetLinks];
};

export const addSharedWithMe = ({ links, query, isANGSearchEnabled }) => {
  return [...links, getSharedWithMeLink(query, isANGSearchEnabled)];
};
