import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import styles from './AssetMetrics.css';

const Loading = () => {
  return (
    <div className={styles.loading} data-test-id="metrics-spinner">
      <Spinner size="l" />
    </div>
  );
};

export const AssetMetrics = loadable(
  () =>
    import(
      /* webpackChunkName: "asset-versions-metrics" */ '@mulesoft/exchange-reuse-metrics-components/lib/components/AssetVersionsMetrics'
    ),
  { fallback: <Loading /> }
);

AssetMetrics.displayName = 'AssetMetrics';

const AssetVersionMetrics = (props) => {
  if (!props.asset) {
    return <Loading />;
  }

  return <AssetMetrics {...props} />;
};

AssetVersionMetrics.propTypes = {
  asset: PropTypes.object
};

export default AssetVersionMetrics;
