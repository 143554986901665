import * as assetsActions from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/actions';
import * as assetsSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/selectors';
import { fetch } from '~/utils/fetch';
import { parseRAML, parseRAMLFiles, unzipBlob } from '~/utils/raml';
import * as apiConsoleService from '~/services/apiConsoleService';

export function fetchJSONAPI({ domain, groupId, assetId, version }) {
  return async (dispatch, getState) => {
    const state = getState();
    const assetFromState = assetsSelectors.asset(state, {
      groupId,
      assetId,
      version
    });
    const asset = assetFromState?.isCompleted
      ? assetFromState
      : await dispatch(
          assetsActions.getAsset({
            domain,
            groupId,
            assetId,
            version,
            showLoading: false
          })
        );

    const fatRAML = asset.files.find((file) => file.classifier === 'fat-raml');

    if (fatRAML) {
      return apiConsoleService
        .fetchAPIZip(fatRAML.externalLink)
        .then(unzipBlob)
        .then(parseRAMLFiles)
        .then(expandAPI);
    }

    return Promise.reject(new Error('This asset has no RAML attached'));
  };
}

export function fetchNotebookClient({
  domain,
  groupId,
  assetId,
  version,
  url
}) {
  return (dispatch) => {
    if (url) {
      return fetch(url).then((response) => {
        const contentType = response.headers.get('content-type');

        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json();
        }

        return parseRAML(url).then(expandAPI);
      });
    }

    if (groupId && assetId && version) {
      return dispatch(
        fetchJSONAPI({ domain, groupId, assetId, version, showLoading: false })
      );
    }

    return Promise.reject(new Error('You need to provide one of url or GAV.'));
  };
}

const jsonOptions = {
  serializeMetadata: false,
  dumpSchemaContents: false,
  rootNodeDetails: false
};

function expandAPI(api) {
  const result = api.expand ? api.expand(true) : api;

  return result.toJSON(jsonOptions);
}
