import * as localStorageUtils from '~/utils/localStorage';
import * as actions from './actionTypes';

const KEY_IDENTIFIER = 'exchange:';

export function init() {
  return (dispatch) => {
    const exchangeKeys = Object.keys(global.localStorage);
    const initialState = exchangeKeys.reduce((acum, key) => {
      if (key.startsWith(KEY_IDENTIFIER)) {
        try {
          const storageValue = global.localStorage.getItem(key);

          if (!storageValue) {
            return acum;
          }

          const value = JSON.parse(storageValue);
          const stateKey = key.substring(KEY_IDENTIFIER.length);

          return {
            ...acum,
            [stateKey]: value
          };
        } catch (error) {
          return acum;
        }
      }

      return acum;
    }, {});

    dispatch({
      type: actions.INIT_LOCAL_STORAGE,
      initialState
    });
  };
}

export function setItem(key, value) {
  return (dispatch) => {
    localStorageUtils.setItem(key, value);

    dispatch({
      type: actions.SET_LOCAL_STORAGE_ITEM,
      key,
      value
    });
  };
}

export function removeItem(key) {
  return (dispatch) => {
    localStorageUtils.removeItem(key);

    dispatch({
      type: actions.REMOVE_LOCAL_STORAGE_ITEM,
      key
    });
  };
}
