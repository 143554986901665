import PropTypes from 'prop-types';
import React from 'react';
import ClientApplicationDetail from '~/components/ClientApplication/ClientApplicationDetail';
import BaseLayout from '~/layouts/BaseLayout';

class ClientApplicationDetailLayout extends BaseLayout {
  static propTypes = {
    dispatch: PropTypes.func
  };

  renderMain() {
    return <ClientApplicationDetail />;
  }
}

export default ClientApplicationDetailLayout;
