import { compose } from 'redux';
import { connect } from 'react-redux';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import BadRequestModal from './BadRequestModal';

export default compose(
  registerModal,
  setModalType('PublicationFailureModal'),
  connect(null, null, null, { context: RootStoreContext })
)(BadRequestModal);
