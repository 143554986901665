import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import OpenIcon from '@mulesoft/anypoint-icons/lib/svg/open.svg';
import Link from '@mulesoft/anypoint-components/lib/Link';
import styles from './InstanceOverview.css';

class InstanceOverview extends React.PureComponent {
  static propTypes = {
    getPath: PropTypes.func.isRequired,
    apiInstance: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isAdminMode: PropTypes.bool.isRequired,
    onAssetApiVersionRedirect: PropTypes.func.isRequired,
    testId: PropTypes.string
  };

  static defaultProps = {
    testId: 'instance-overview'
  };

  renderApi() {
    const {
      getPath,
      apiInstance,
      isDisabled,
      onAssetApiVersionRedirect,
      testId
    } = this.props;
    const { groupId, assetId, assetName, minorVersion } = apiInstance;

    if (isDisabled) {
      return (
        <span
          data-test-id={`${testId}-api-restricted`}
          className={styles.apiRestricted}
          title="You need permissions to view this API"
        >
          {assetName}
          <span className={styles.minorVersionPill}>{minorVersion}.x</span>
        </span>
      );
    }

    return (
      <Link
        className={styles.apiLink}
        onClick={onAssetApiVersionRedirect}
        testId={`${testId}-api-link`}
        display-if={!isDisabled}
        to={getPath('page', {
          type: 'api-group',
          groupId,
          assetId,
          minorVersion
        })}
        tabIndex="0"
        target="_blank"
      >
        <span className="visually-hidden">Go to API minor version</span>
        {assetName}
        <span className={styles.minorVersionPill}>{minorVersion}.x</span>
        <Icon className={styles.openIcon} size="xxs">
          <OpenIcon />
        </Icon>
      </Link>
    );
  }

  render() {
    const { apiInstance, isAdminMode, testId } = this.props;
    const { environmentName, instanceLabel, name, endpointUri, isPublic } =
      apiInstance;

    return (
      <tr className={styles.row} data-test-id={`${testId}-row`}>
        <td data-test-id={`${testId}-api`}>{this.renderApi()}</td>
        <td>{instanceLabel || name}</td>
        <td>{environmentName}</td>
        <td>{endpointUri}</td>
        <td display-if={isAdminMode}>{isPublic ? 'Public' : 'Private'}</td>
      </tr>
    );
  }
}

export default InstanceOverview;
