export const SET_ROUTE = 'Set Route';
export const SET_HOME_QUERY = 'Set Home Query';
export const SET_ERROR = 'Set Error';
export const SET_ALERT = 'Set Alert';
export const SET_HEADER = 'Set Header';
export const CLEAR_ALERT = 'Clear Alert';
export const SET_NAVBAR_VISIBILITY = 'Set Navbar Visibility';
export const SET_ORGANIZATION_METADATA = 'Set organization metadata';
export const ANALYTICS_LOGIN = 'Analytics login';
export const ANALYTICS_LOGOUT = 'Analytics logout';
export const SCRIPT_LOAD_START = 'Script load start';
export const SCRIPT_LOAD_FINISH = 'Script load finish';
export const SET_SERVER_REDIRECT_LOCATION = 'Set server redirect location';
export const SET_PUBLIC_PORTAL_STATUS = 'Set public portal status';
export const SET_PUBLIC_PORTAL_DOMAIN =
  'Set organization domain received at server';
export const SET_ROUTING_CONTEXT = 'Set routing context';
export const SET_IS_MARKETING_SITE = 'Set is marketing site';
