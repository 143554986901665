import PropTypes from 'prop-types';
import React from 'react';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import Button from '@mulesoft/anypoint-components/lib/Button';
import styles from './DiscardModal.css';

const propTypes = {
  onClose: PropTypes.func,
  onDiscard: PropTypes.func,
  isDiscarding: PropTypes.bool
};

const DiscardModal = ({ onClose, onDiscard, isDiscarding }) => {
  const labelId = 'discard-modal-title';

  return (
    <Modal
      renderInBody={false}
      className={styles.modal}
      onEsc={onClose}
      onClickOverlay={onClose}
      ariaLabelledBy={labelId}
      role="alertdialog"
    >
      <ModalHeader onClose={onClose} isDanger className={styles.header}>
        <h2 id={labelId} className={styles.title}>
          Discard all unpublished changes?
        </h2>
      </ModalHeader>
      <ModalBody className={styles.body}>
        <p>All your unpublished changes will be discarded.</p>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button
          kind="tertiary"
          onClick={onClose}
          noFill
          testId="keep-editing-button"
        >
          No, keep editing
        </Button>
        <Button
          kind="danger"
          onClick={onDiscard}
          noFill
          testId="discard-changes-button"
          isLoading={isDiscarding}
        >
          Yes, discard changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const noOp = () => {};

DiscardModal.defaultProps = {
  onClose: noOp,
  onDiscard: noOp
};

DiscardModal.propTypes = propTypes;

export default DiscardModal;
