import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { InfoModal } from '~/components/Modals';

class TooManyRequestsModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func
  };

  renderBody() {
    return (
      <p>
        Your request could not be processed because the server is at capacity.
        Please wait one minute and try again.
      </p>
    );
  }

  render() {
    return (
      <InfoModal
        isDanger
        title="Server at capacity"
        body={this.renderBody()}
        buttonText="Close"
        onClose={this.props.onClose}
      />
    );
  }
}

export default TooManyRequestsModal;
