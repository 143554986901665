import config from 'config';
import debug from 'debug';
import { loadScript } from '~/utils/scripts';
import { isClient } from '~/utils/window';

const log = debug('exchange:utils:raml');

let ramlParserPromise;
let jsZipPromise;

if (isClient()) {
  // load raml parser as soon as possible
  ramlParserPromise = loadScript(config.ramlParserPath).then(
    () => global.RAML?.Parser
  );
  jsZipPromise = loadScript(config.jsZipPath).then(() => window.JSZip);
}

export function getRAMLParser() {
  if (window.RAML && window.RAML.Parser) {
    return Promise.resolve(window.RAML.Parser);
  }

  return ramlParserPromise;
}

async function getJSZip() {
  if (window.JSZip) {
    return window.JSZip;
  }

  return jsZipPromise;
}

export async function unzipBlob(blob) {
  const JSZip = await getJSZip();

  try {
    return JSZip.loadAsync(blob);
  } catch (error) {
    log(error);
    throw new Error(`Unable to read API archive.`);
  }
}

function readEntryContent(zipEntry) {
  return zipEntry.async('text');
}

export async function parseRAMLFiles(zipFile) {
  const exchangeJSONEntry = zipFile.file('exchange.json');

  if (!exchangeJSONEntry) {
    throw new Error('RAML zip does not contain an exchange.json file');
  }

  const exchangeJSONContent = await readEntryContent(exchangeJSONEntry);
  const exchangeJSONObject = parseExchangeJSON(exchangeJSONContent);
  const parser = await getRAMLParser();
  const ramlConfig = {
    fsResolver: {
      contentAsync: (path) => readEntryContent(zipFile.file(path.substring(1)))
    }
  };

  return parser.loadApi(exchangeJSONObject.main, ramlConfig);
}

function parseExchangeJSON(exchangeJSONContent) {
  try {
    return JSON.parse(exchangeJSONContent);
  } catch (err) {
    throw new Error('Error trying to read exchange.json file from RAML zip');
  }
}

export function parseRAML(url) {
  return getRAMLParser()
    .then((parser) => parser.loadApi(url))
    .catch((err) => {
      throw Object.assign(err, {
        message: `Unable to parse RAML. ${err.message}`
      });
    });
}
