import React from 'react';
import ReactDOM from 'react-dom';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import MulesoftFooter from '~/components/Layout/MulesoftFooter';
import GDPRCookieBanner from '~/components/Layout/GDPRCookieBanner';
import { ONE_TRUST_ENABLED } from '~/domains/featureFlags/keys';
import * as featureFlagsSelectors from '~/domains/featureFlags/selectors';
import * as localStorageUtils from '~/utils/localStorage';

const LOCAL_STORAGE_KEY = 'gdprBannerDisabled';
const gdprDisabledState = localStorageUtils.storageFactory(LOCAL_STORAGE_KEY);

export const renderHomeFooter = (store) => {
  const state = store.getState();
  const isPublicPortal = sessionSelectors.isPublicPortal(state);
  const featureFlags = featureFlagsSelectors.featureFlags(state);
  const profile = sessionSelectors.profile(state);
  const isOneTrustEnabled = featureFlags?.[ONE_TRUST_ENABLED];
  const element = document.getElementById('react-home-footer-view');
  const isOneTrustPresent = isOneTrustEnabled && !profile;
  const showGDPRBanner =
    !gdprDisabledState.get() && !isOneTrustPresent && !isPublicPortal;

  if (!element) {
    return;
  }

  if (showGDPRBanner) {
    const onClose = () => gdprDisabledState.set(true);
    const component = <GDPRCookieBanner onClose={onClose} />;

    ReactDOM.render(component, element);
  } else if (isOneTrustPresent) {
    const component = <MulesoftFooter />;

    ReactDOM.render(component, element);
  }
};
