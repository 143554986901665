import { format, parse } from 'url';
import config from 'config';
import debug from 'debug';
import { fetch } from '~/utils/fetch';
import { camelizeUnderscoreObjProps } from '~/utils/strings';
import * as customizationService from '~/services/customizationService';
import { getAPIUrl } from '~/utils/api';

const log = debug('exchange:auth:service');

export function getCallbackURL({ isPublicPortal, query } = {}) {
  const pathname = isPublicPortal
    ? `${config.contextPath}/portals/callback`
    : `${config.contextPath}/callback`;

  return format({
    ...config.externalURI,
    pathname,
    query
  });
}

export function getClientAuthenticationURL({
  isPublicPortal,
  redirectURL,
  domain
}) {
  const query = {
    response_type: 'token',
    client_id: config.app.implicitClientId,
    redirect_uri: redirectURL
  };

  if (isPublicPortal) {
    return format({
      ...config.services.login.uri,
      pathname: `${config.services.login.uri.pathname}/domain/${domain}`,
      query
    });
  }

  return format({
    ...config.services.csExternal.uri,
    pathname: `/oauth2/authorize/${domain}`,
    query
  });
}

export async function login(body) {
  const url = format({
    ...config.services.csExternal.uri,
    pathname: '/login'
  });
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  return camelizeUnderscoreObjProps(await response.json());
}

export function getPublicPortalLoginURL({
  clientId,
  state = {},
  ssoRedirect = false
}) {
  const callbackURL = getCallbackURL({ isPublicPortal: true });
  const query = getLoginQueryStr({
    clientId,
    callbackURL,
    state,
    signInWithAnypoint: !ssoRedirect
  });

  const url = format({
    ...config.services.csExternal.uri,
    pathname: `${config.services.cs.paths.authorize(state.domain)}`,
    query
  });

  log('using login URL', url);

  return url;
}

export function getLoginURL({ clientId, state = {}, isPublicPortal } = {}) {
  const callbackURL = getCallbackURL({ isPublicPortal });

  const url = format({
    ...config.services.csExternal.uri,
    pathname: config.services.cs.paths.authorize(state.domain),
    query: getLoginQueryStr({ clientId, callbackURL, state })
  });

  log('using login URL', url);

  return url;
}

export async function validateRedirectURI({
  organizationDomain,
  redirectURI,
  context
}) {
  if (!redirectURI) {
    return false;
  }

  try {
    const { host, pathname } = parse(redirectURI);

    // this is because `parse('https://pepe%2pepito')` has host "pepe" and pathname "%2pepito", but in the browser
    // that redirects to https://pepe.pepito
    if (!pathname?.startsWith('/')) {
      return false;
    }

    if (config.externalURI.host === host) {
      return true;
    }

    if (!organizationDomain) {
      return false;
    }

    const publicPortalDomain = await customizationService.getDomain({
      organizationDomain,
      context
    });

    if (publicPortalDomain !== host) {
      return false;
    }
  } catch (error) {
    return false;
  }

  return true;
}

export function getLogoutURL({ redirectURI } = {}) {
  const url = { ...config.services.csExternal.uri };

  url.pathname = config.services.cs.paths.logout;
  url.query = {
    redirect_uri: redirectURI
  };

  return format(url);
}

export async function getOrganizationMetadata({ context, domain }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/portals/${domain}/metadata`
  });
  let response;

  try {
    response = await fetch(url);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to get domain metadata', error);

    return {};
  }

  return response.json();
}

export async function logout({ context, getPathFn }) {
  const url = format({
    pathname: getPathFn('logout')
  });
  const options = { context, method: 'POST', credentials: 'same-origin' };
  const response = await fetch(url, options);

  return response.json();
}

export async function exchangeToken(
  context,
  { code, clientId, clientSecret, isPublicPortal } = {}
) {
  if (!code) {
    const error = new Error('Code is required');

    error.status = 400;

    throw error;
  }

  const redirectURI = getCallbackURL({ isPublicPortal });
  const query = {
    code,
    grant_type: 'authorization_code',
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri: redirectURI
  };
  const options = {
    context,
    method: 'POST',
    body: JSON.stringify(query)
  };
  const url = format({
    ...config.services.cs.uri,
    pathname: config.services.cs.paths.exchangeToken
  });

  const response = await fetch(url, options);
  const tokenInfo = await response.json();

  return camelizeUnderscoreObjProps(tokenInfo);
}

function getLoginQueryStr({
  clientId,
  callbackURL,
  state,
  signInWithAnypoint
}) {
  const loginQuery = {
    redirect_uri: callbackURL,
    response_type: 'code',
    client_id: clientId,
    apintent: config.app.apintent,
    state: JSON.stringify(state)
  };

  if (signInWithAnypoint) {
    loginQuery.sign_in_with_anypoint = true;
  }

  return loginQuery;
}
