import { compose } from 'redux';
import { connect } from 'react-redux';
import { EVENT_TYPES } from '~/analytics/events';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { closeModal } from '~/domains/modal/actions';
import { actions as analyticsActions } from '~/domains/analytics';
import { actions as searchActions } from '~/domains/search';
import ANGOptOutModal from './ANGOptOutModal';

const mapDispatchToProps = (dispatch) => ({
  onSubmit: ({ optOutReason, otherReasonText, missingFilterText }) => {
    dispatch(closeModal());
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.ANG_SEARCH_OPT_OUT, {
        optOutReason,
        otherReasonText,
        missingFilterText
      })
    );
    dispatch(searchActions.disableANGSearch());
  }
});

export default compose(
  registerModal,
  setModalType('ANGOptOutModal'),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(ANGOptOutModal);
