import { getGAVKey } from '~/utils/types';
import { isClient } from '~/utils/window';
import * as actions from './actionTypes';

export function loadType(assetType) {
  return {
    type: actions.LOAD_TYPE,
    assetType
  };
}

export function unloadType() {
  return {
    type: actions.UNLOAD_TYPE
  };
}

let lastLoadedType;
let lastLoadedGAV;

// for testing purposes
export const cleanLoadedType = () => {
  lastLoadedType = null;
  lastLoadedGAV = null;
};

export const loadReducers = ({
  asset,
  reducerManager,
  portalConfiguration
}) => {
  return (dispatch) => {
    const { type } = asset;

    if (isClient() && type === lastLoadedType) {
      if (lastLoadedGAV === getGAVKey(asset)) {
        return null;
      }

      lastLoadedGAV = getGAVKey(asset);

      return portalConfiguration.default.onLoad;
    }

    lastLoadedType = type;
    reducerManager.load(portalConfiguration.default.reducers);
    dispatch(loadType(type));
    lastLoadedGAV = getGAVKey(asset);

    return portalConfiguration.default.onLoad;
  };
};
