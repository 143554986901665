import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import EditIcon from '@mulesoft/anypoint-icons/lib/svg/edit-small.svg';
import styles from './CustomizeButton.css';

const propTypes = {
  isVisible: PropTypes.bool,
  organization: PropTypes.object,
  getPath: PropTypes.func
};

const CustomizeButton = ({ isVisible, organization, getPath }) => {
  return (
    <Link
      to={getPath('home', { organization }, { isEditMode: true })}
      tabIndex="-1"
      display-if={isVisible}
    >
      <Button kind="primary" className={styles.button}>
        <Icon className={styles.editIcon} size="s">
          <EditIcon />
        </Icon>
        <span className={styles.label} data-test-id="button-title">
          Customize
        </span>
      </Button>
    </Link>
  );
};

CustomizeButton.propTypes = propTypes;

export default CustomizeButton;
