import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import InfoIcon from '@mulesoft/anypoint-icons/lib/svg/info-small.svg';
import LaunchIcon from '@mulesoft/anypoint-icons/lib/svg/deploy-small.svg';
import Announcement from '~/components/Announcements/Announcement';
import styles from './ANGSearchOptIn.css';

const componentName = 'ANGSearchOptIn';

const ANGSearchOptIn = ({ onActivateANGSearch, onClose }) => {
  const icon = <LaunchIcon />;

  return (
    <Announcement onClose={onClose} icon={icon} name={componentName}>
      Say hello to new search features in Exchange.
      <Button
        className={styles.button}
        testId="announcement-activate-ang-button"
        onClick={onActivateANGSearch}
        kind="secondary"
        noFill
      >
        Return to enhanced search
        <Icon size="xs">
          <InfoIcon />
        </Icon>
      </Button>
    </Announcement>
  );
};

ANGSearchOptIn.displayName = componentName;
ANGSearchOptIn.propTypes = {
  onActivateANGSearch: PropTypes.func,
  onClose: PropTypes.func
};

export default ANGSearchOptIn;
