import PropTypes from 'prop-types';
import React from 'react';
import DocumentMeta from 'react-document-meta';
import { renderRoutes } from 'react-router-config';
import memoize from 'memoize-one';
import { RouterContext } from '@mulesoft/exchange-react-hooks';
import { ErrorBoundary } from '@mulesoft/exchange-ui-components';
import UnhandledClientError from '~/components/ErrorPage/UnhandledClientError';
import AlertMessage from '~/components/Layout/AlertMessage';
import AssetsLimitWarning from '~/components/Layout/AssetsLimitWarning';
import { ModalRoot } from '~/components/Modals';
import { getPathWithQuery, getPath } from '~/utils/routes';
import './App.css';

const getPreviousRoutesSectionFn = memoize(
  (previousRoutesSection) => () => previousRoutesSection
);

export default class App extends React.Component {
  static propTypes = {
    isHome: PropTypes.bool,
    header: PropTypes.object,
    route: PropTypes.object,
    profile: PropTypes.object,
    alert: PropTypes.object,
    onCloseAlert: PropTypes.func.isRequired,
    onSignout: PropTypes.func,
    onOpenNavbarMenu: PropTypes.func,
    onCloseNavbarMenu: PropTypes.func,
    previousRoutesSection: PropTypes.object,
    goBack: PropTypes.func,
    setDocumentMeta: PropTypes.func,
    push: PropTypes.func,
    featureFlags: PropTypes.object,
    routingContext: PropTypes.object,
    isPortalReArchitectureEnabled: PropTypes.bool,
    location: PropTypes.object
  };

  static childContextTypes = {
    isPublicPortal: PropTypes.bool,
    getPath: PropTypes.func,
    featureFlags: PropTypes.object
  };

  getChildContext() {
    return {
      isPublicPortal: false,
      getPath,
      featureFlags: this.props.featureFlags
    };
  }

  render() {
    const {
      alert,
      route,
      header,
      isHome,
      onCloseAlert,
      previousRoutesSection,
      goBack,
      setDocumentMeta,
      push,
      routingContext,
      isPortalReArchitectureEnabled,
      location
    } = this.props;

    return (
      <RouterContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          location,
          context: routingContext,
          getPreviousRoutesSection: getPreviousRoutesSectionFn(
            previousRoutesSection
          ),
          getPath: isPortalReArchitectureEnabled
            ? getPathWithQuery(location)
            : getPath,
          push,
          goBack,
          setDocumentMeta
        }}
      >
        <DocumentMeta {...header} auto={{ ograph: true }} />
        <ModalRoot />
        <AssetsLimitWarning display-if={isHome} />
        <ErrorBoundary errorFallbackComponent={UnhandledClientError}>
          {renderRoutes(route.routes)}
        </ErrorBoundary>
        <AlertMessage
          {...alert}
          display-if={alert}
          onCloseAlert={onCloseAlert}
        />
      </RouterContext.Provider>
    );
  }
}
