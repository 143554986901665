import React from 'react';
import { createStoreHook } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import * as commonActions from '~/domains/common/actions';
import * as routesUtils from '~/utils/routes';

const useStore = createStoreHook(RootStoreContext);

export default (Component) =>
  function (props) {
    const { dispatch } = useStore();
    const location = useLocation();

    React.useEffect(() => {
      const {
        route,
        match: { params }
        // eslint-disable-next-line react/prop-types
      } = routesUtils.getCurrentRoute(props.route, location);

      if (route.id) {
        dispatch(
          commonActions.setRoute({
            id: route.id,
            sectionId: route.sectionId,
            params
          })
        );
        dispatch(analyticsActions.trackPage(route, params));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, location.search]);

    return <Component {...props} />;
  };
