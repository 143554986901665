import { actions as assetsActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as commonSelectors } from '~/domains/common';
import {
  hasCreateAssetPermissions,
  isAssetContributor,
  isLoggedIn
} from '~/utils/validations/profile';

export const createAsset = async ({ getState, helpers: { setHeader } }) => {
  const state = getState();
  const profile = commonSelectors.profile(state);

  isLoggedIn(profile);
  hasCreateAssetPermissions(profile);

  setHeader({ title: 'Upload asset' });
};

export const createAssetVersion = async ({
  dispatch,
  getState,
  match: { params },
  helpers: { setHeader }
}) => {
  const state = getState();
  const profile = commonSelectors.profile(state);
  const asset = await dispatch(assetsActions.getAsset(params));

  isLoggedIn(profile);
  isAssetContributor(asset);

  setHeader({ title: 'Add new version' });
};
