import React from 'react';
import memoize from 'memoize-one';
import { getDefault } from '~/utils/types';
import defaultComponents from '~/portals/components';
import portalsDefinition from '~/portals/definition';
import { getFilesConfiguration } from '~/portals/files';
import { getRoutesConfiguration } from '~/portals/utils/config';
import AssetPortalSidebar from './Sidebar';
import AssetPortalVersionsTable from './VersionsTable';

const getPortalComponents = memoize((type, portalConfiguration) => {
  const localPortalConfiguration = portalsDefinition[type];
  const portalConfigurationComponents = {};

  if (portalConfiguration?.default?.sections) {
    portalConfigurationComponents.Sidebar = function (props) {
      return (
        <AssetPortalSidebar
          portalConfiguration={portalConfiguration.default}
          {...props}
        />
      );
    };
  }

  if (portalConfiguration?.default?.metadata) {
    portalConfigurationComponents.VersionsTable = function (props) {
      return (
        <AssetPortalVersionsTable
          portalConfiguration={portalConfiguration.default}
          {...props}
        />
      );
    };
  }

  return {
    ...defaultComponents,
    ...localPortalConfiguration,
    ...portalConfiguration?.default?.components,
    ...portalConfigurationComponents
  };
});

export const buildAssetContext = memoize(
  (asset, isDraft, isEdit, portalConfiguration, assetTypesConfiguration) => {
    const assetTypeConfig = assetTypesConfiguration[asset.type];

    return {
      asset,
      isDraft,
      isEdit,
      isTypeLoaded: !!portalConfiguration,
      components: getPortalComponents(asset.type, portalConfiguration),
      files: getFilesConfiguration(
        assetTypesConfiguration,
        asset.type,
        portalConfiguration
      ),
      routes: getRoutesConfiguration(portalConfiguration),
      typeAttributes: getDefault(assetTypesConfiguration, asset.type),
      versionsNavigation: portalConfiguration?.default?.versionsNavigation,
      search: portalConfiguration?.default?.search,
      onLoad: portalConfiguration?.default?.onLoad,
      isVersionsTableDisabled:
        portalConfiguration?.default?.isVersionsTableDisabled,
      metadata: portalConfiguration?.default?.metadata,
      metrics: portalConfiguration?.default?.metrics,
      sections: portalConfiguration?.default?.sections,
      visibility: assetTypeConfig?.visibility || 'private'
    };
  }
);
