import isKeyCombo from 'is-key-combo';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import TextArea from '@mulesoft/anypoint-components/lib/TextArea';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import EditIcon from '@mulesoft/anypoint-icons/lib/svg/edit-small.svg';
import CheckIcon from '@mulesoft/anypoint-icons/lib/svg/check.svg';
import CloseIcon from '@mulesoft/anypoint-icons/lib/svg/close-small.svg';
import Label from '@mulesoft/anypoint-components/lib/Label';
import { RefType, AssetPropType } from '@mulesoft/exchange-react-shapes';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import * as refUtils from '~/utils/refs';
import styles from './Description.css';

const textAreaStyles = TextArea.themes.DefaultTheme;

class DescriptionEdit extends React.Component {
  static propTypes = {
    description: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
  };

  static maxLength = 255;

  componentDidMount() {
    // Use TextArea when it provides a ref of the DOM element
    refUtils.focus(this.textAreaRef);
  }

  textAreaRef = React.createRef();

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.props.onCancel();
    }
    if (isKeyCombo(event, 'cmd+enter')) {
      this.props.onSave();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={classNames(
            textAreaStyles.textarea,
            styles.editDescription
          )}
          data-test-id="edit-asset-description"
        >
          <textarea
            ref={this.textAreaRef}
            name="asset-description"
            label="Description"
            placeholder="Add description"
            onKeyDown={this.handleKeyDown}
            onChange={(event) => this.props.onChange(event.target.value)}
            value={this.props.description}
            maxLength={DescriptionEdit.maxLength}
            required
            data-test-id="edit-asset-description-textarea"
          />
        </div>
        <div className={styles.actionsFooter}>
          <span>
            {(this.props.description && this.props.description.length) || 0}/
            {DescriptionEdit.maxLength} characters
          </span>
          <div className={styles.actions}>
            <Icon
              data-test-id="description-save-action"
              role="button"
              aria-label="Save description"
              className={classNames(styles.icon, styles.action)}
              tabIndex="0"
              size={20}
              onClick={this.props.onSave}
              onKeyDown={eventHandlerUtils.onEnterKey(this.props.onSave)}
            >
              <CheckIcon />
            </Icon>
            <Icon
              data-test-id="description-cancel-action"
              role="button"
              aria-label="Cancel description edit"
              className={classNames(styles.icon, styles.action)}
              tabIndex="0"
              size={20}
              onClick={this.props.onCancel}
              onKeyDown={eventHandlerUtils.onEnterKey(this.props.onCancel)}
            >
              <CloseIcon />
            </Icon>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class DescriptionView extends React.Component {
  static propTypes = {
    description: PropTypes.string,
    innerRef: RefType,
    onClick: PropTypes.func,
    canEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    unsavedDescription: PropTypes.string
  };

  static defaultProps = {
    isLoading: false
  };

  renderDescription = () => {
    const { isLoading, unsavedDescription, canEdit, description } = this.props;

    if (isLoading && unsavedDescription !== '') {
      return unsavedDescription;
    }
    if ((canEdit && !description) || unsavedDescription === '') {
      return 'Add description';
    }

    return description;
  };

  render() {
    const { innerRef, description, canEdit, onClick, isLoading } = this.props;

    return (
      <div
        role="button"
        ref={innerRef}
        data-test-id="asset-description"
        tabIndex="0"
        onClick={canEdit && !isLoading ? onClick : null}
        onKeyDown={canEdit ? eventHandlerUtils.onEnterKey(onClick) : null}
        className={
          description ? styles.assetDescription : styles.assetNoDescription
        }
      >
        <Label
          className="visually-hidden"
          htmlFor="edit-asset-description"
          display-if={canEdit && !isLoading}
        >
          Edit asset description
        </Label>
        <span
          className={classNames(styles.description, {
            [styles.disabled]: isLoading
          })}
          id="edit-asset-description"
        >
          {this.renderDescription()}
          <Icon
            display-if={canEdit && !isLoading}
            data-test-id="edit-asset-description-icon" // this value should not be changed because it is used in AppCue
            className={styles.editIcon}
            size="xs"
          >
            <EditIcon />
          </Icon>
        </span>
      </div>
    );
  }
}

const DescriptionViewWithRef = React.forwardRef((props, ref) => (
  <DescriptionView {...props} innerRef={ref} />
));

class DescriptionController extends React.Component {
  static propTypes = {
    asset: AssetPropType,
    canEdit: PropTypes.bool,
    onSaveDescription: PropTypes.func
  };

  state = {
    assetDescription: this.props.asset.description,
    isEditing: false,
    isLoading: false,
    unsavedDescription: null
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.asset.description !== this.props.asset.description) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ assetDescription: this.props.asset.description });
    }

    if (prevState.isEditing && !this.state.isEditing) {
      refUtils.focus(this.descriptionRef);
    }
  }

  descriptionRef = React.createRef();

  editDescription = () => {
    this.setState({
      isEditing: true
    });
  };

  cancelEditDescription = () => {
    this.setState({
      isEditing: false
    });
  };

  handleDescriptionChange = (description) => {
    this.setState({ assetDescription: description });
  };

  saveDescription = () => {
    const { onSaveDescription, asset } = this.props;
    const description = this.state.assetDescription
      ? this.state.assetDescription.trim()
      : '';

    if (description !== asset.description) {
      this.setState(
        {
          isLoading: true,
          unsavedDescription: description
        },
        async () => {
          await onSaveDescription(asset, description);
          this.setState({
            isLoading: false
          });
        }
      );
    }
    this.cancelEditDescription();
  };

  render() {
    const { asset, canEdit } = this.props;
    const { isEditing } = this.state;

    return (
      <div>
        {isEditing ? (
          <DescriptionEdit
            description={this.state.assetDescription}
            onChange={this.handleDescriptionChange}
            onSave={this.saveDescription}
            onCancel={this.cancelEditDescription}
          />
        ) : (
          <DescriptionViewWithRef
            ref={this.descriptionRef}
            description={asset.description}
            canEdit={canEdit}
            onClick={this.editDescription}
            isLoading={this.state.isLoading}
            unsavedDescription={this.state.unsavedDescription}
          />
        )}
      </div>
    );
  }
}

export default DescriptionController;
