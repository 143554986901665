import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import { getActiveOrganizationId } from '~/utils/organization';
import constants from '~/utils/constants';

/* eslint-disable react/jsx-no-undef */
const NavigationBar = ({
  profile,
  isStudio,
  isOneTrustEnabled,
  onOrganizationChange,
  onSignOut
}) => {
  return global.NavBar ? (
    <global.NavBar
      activeProductName="exchange2"
      profile={profile}
      isOneTrustEnabled={isOneTrustEnabled && !profile}
      activeOrganizationId={getActiveOrganizationId(profile)}
      activeProduct={constants.navbarActiveProduct}
      signInLink={`${config.contextPath}/login?clean_sess=1`}
      studio={isStudio}
      onOrganizationChange={onOrganizationChange}
      onSignout={onSignOut}
    />
  ) : null;
};
/* eslint-enable react/jsx-no-undef */

NavigationBar.propTypes = {
  profile: PropTypes.object,
  isStudio: PropTypes.bool,
  isOneTrustEnabled: PropTypes.bool,
  onOrganizationChange: PropTypes.func,
  onSignOut: PropTypes.func
};

export default NavigationBar;
