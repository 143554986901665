import { compose } from 'redux';
import { connect, batch } from 'react-redux';
import { withRouter } from 'react-router';
import { withAssetTypesConfiguration } from '@mulesoft/exchange-react-hooks';
import withLayoutContext from '~/components/LayoutContextProvider';
import withCustomizationLeaveHandling from '~/components/Customization/LeaveHookProvider';
import withFocusRestore from '~/components/WithFocusRestore';
import RootStoreContext from '~/contexts/RootStoreContext';
import { EVENT_TYPES } from '~/analytics/events';
import { actions as analyticsActions } from '~/domains/analytics';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '~/domains/assets';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as searchSelectors } from '~/domains/search';
import { parseSearch } from '~/utils/location';
import AssetView from './AssetView';

const portalAssetViewState = (state, { location }) => {
  const query = parseSearch(location.search);

  return {
    assets: assetsSelectors.assets(state),
    isFetchingAssets: assetsSelectors.isFetchingAssets(state),
    hasUserClickedSearchResult:
      commonSelectors.hasUserClickedSearchResult(state),
    hasMoreAssets: assetsSelectors.hasMoreAssets(state),
    profile: commonSelectors.profile(state),
    query
  };
};

export const assetViewState = (state, { location }) => {
  const query = parseSearch(location.search);
  const profile = commonSelectors.profile(state);
  const isSearchTriggered = assetsSelectors.isSearchTriggered(state);
  const assets = assetsSelectors.assets(state);
  const limit = assetsSelectors.limit(state);
  const isANGSearchEnabled = searchSelectors.isANGSearchEnabled(state);
  const isFetchingAssets = assetsSelectors.isFetchingAssets(state);

  return {
    profile,
    assets: isANGSearchEnabled ? assets.slice(0, limit) : assets,
    isANGSearchEnabled,
    isFetchingAssets,
    showExtendedCards: isANGSearchEnabled && isSearchTriggered,
    hasMoreAssets: assetsSelectors.hasMoreAssets(state),
    hasUserClickedSearchResult:
      commonSelectors.hasUserClickedSearchResult(state),
    query
  };
};

const portalAssetViewActions = (
  dispatch,
  { params, assetTypesConfiguration }
) => ({
  onLoadAssets: (payload) =>
    dispatch(
      assetsActions.loadPortalAssets({
        query: { ...params, ...payload },
        assetTypesConfiguration
      })
    ),
  onReachResultsEnd: () =>
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.PORTAL_RESULTS_END_REACHED)
    ),
  onAssetClick: (
    { organization: { id: organizationId }, groupId, assetId, type },
    view,
    {
      resultPosition,
      resultCount,
      isFirstTimeClicked,
      reciprocalRank,
      relativeClickRank,
      weightedRelativeClickRank,
      query
    }
  ) => {
    batch(() => {
      dispatch(assetsActions.selectSearchResult());
      dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.ASSETS_LIST_RESULT_CLICKED, {
          organizationId,
          groupId,
          assetId,
          type,
          view,
          resultPosition,
          resultCount,
          isFirstTimeClicked,
          reciprocalRank,
          relativeClickRank,
          weightedRelativeClickRank,
          query
        })
      );
    });
  }
});

export const assetViewActions = (
  dispatch,
  { params, assetTypesConfiguration }
) => ({
  onLoadAssets: (payload, { isANGSearchEnabled }) =>
    isANGSearchEnabled
      ? dispatch(assetsActions.showMoreAssets())
      : dispatch(
          assetsActions.loadAssets({
            query: { ...params, ...payload },
            assetTypesConfiguration
          })
        ),
  onReachResultsEnd: () =>
    dispatch(analyticsActions.trackEvent(EVENT_TYPES.RESULTS_END_REACHED)),
  onAssetClick: (
    { organization: { id: organizationId }, groupId, assetId, type },
    view,
    analyticsProps
  ) => {
    batch(() => {
      dispatch(assetsActions.selectSearchResult());
      dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.ASSETS_LIST_RESULT_CLICKED, {
          ...analyticsProps,
          organizationId,
          groupId,
          assetId,
          type,
          view
        })
      );
    });
  }
});

const mapStateToProps = (state, ownProps) => {
  const mapStateToPropsStrategy = ownProps.isPublicPortal
    ? portalAssetViewState
    : assetViewState;

  return mapStateToPropsStrategy(state, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const mapDispatchToPropsStrategy = ownProps.isPublicPortal
    ? portalAssetViewActions
    : assetViewActions;

  return mapDispatchToPropsStrategy(dispatch, ownProps);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onLoadAssets: (payload) => dispatchProps.onLoadAssets(payload, stateProps)
});

const mapContextToProps = ({ isPublicPortal, getPath }) => ({
  getPath,
  isPublicPortal
});

export default compose(
  withLayoutContext(mapContextToProps),
  withRouter,
  withAssetTypesConfiguration,
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  }),
  withCustomizationLeaveHandling,
  withFocusRestore
)(AssetView);
