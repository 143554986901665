import { actions as clientApplicationsActions } from '~/domains/clientApplications';
import { selectors as commonSelectors } from '~/domains/common';
import * as actions from '~/domains/customization/actions';
import { isLoggedIn } from '~/utils/validations/profile';

const resolve = async ({ dispatch, getState, location: { query } }) => {
  const profile = commonSelectors.profile(getState());

  isLoggedIn(profile);

  const masterOrganization = profile.organization;

  await dispatch(
    clientApplicationsActions.loadApplications(masterOrganization.id, query)
  );
  await dispatch(actions.fetchCustomizationDraft());
};

export default resolve;
