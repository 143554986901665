import { compose } from 'redux';
import { connect } from 'react-redux';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { closeModal } from '~/domains/modal/actions';

import AssetLimitModal from './AssetLimitModal';

const mapDispatchToProps = (dispatch, { onClose }) => ({
  onClose: () => {
    dispatch(closeModal());

    if (onClose) {
      onClose();
    }
  }
});

export default compose(
  registerModal,
  setModalType('AssetLimitModal'),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(AssetLimitModal);
