import { createSelector } from 'reselect';
import semver from 'semver';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { parseSearch, equalsQuery } from '~/utils/location';
import { omit } from '~/utils/objects';
import { getPath, getPortalPath } from '~/utils/routes';

export const error = (state) => state.common.error;
export const alert = (state) => state.common.alert;
export const location = createSelector(
  (state) => state.router?.location.search,
  (state) => state.router?.location.pathname,
  ($search, $pathname) => ({
    query: parseSearch($search),
    pathname: $pathname
  })
);
export const definitions = (state) => state.session?.definitions;
export const route = (state) => state.common?.route;
export const homeQuery = (state) => state.common.homeQuery;

export const hasHomeQueryChanged = (state, { query }) => {
  const locationQuery = omit(query, 'view');
  let currentHomeQuery = homeQuery(state);

  currentHomeQuery = currentHomeQuery && omit(currentHomeQuery, 'view');

  return !equalsQuery(locationQuery, currentHomeQuery);
};

export const hasUserClickedSearchResult = (state) =>
  state.common.hasUserClickedSearchResult;

export const previousRoutesSection = createSelector(
  (state) => state.common.sectionNavigationLog,
  ($sectionNavigationLog) => {
    // only one group navigated
    if ($sectionNavigationLog.length < 2) {
      return null;
    }

    // get the one before the last
    const beforeLastGroup =
      $sectionNavigationLog[$sectionNavigationLog.length - 2];
    const targetLocation =
      beforeLastGroup.locations[beforeLastGroup.locations.length - 1];

    return {
      path: `${targetLocation.pathname}${targetLocation.search ?? ''}`,
      id: beforeLastGroup.sectionId
    };
  }
);

export const profile = (state) => state.session?.profile;
export const isLoggedIn = (state) => !!profile(state);
export const context = createSelector(
  (state) => state.session.context,
  (state) => state.session.featureFlags,
  ($context, $featureFlags) => ({
    ...$context,
    featureFlags: $featureFlags
  })
);
export const isOrgFederated = (state) =>
  state.common.organizationMetadata?.isFederated;
export const organizationName = (state) =>
  state.common.organizationMetadata?.name;
export const header = (state) => state.common.header;
export const showNavbar = (state) => !!state.common.showNavbar;
export const serverRedirectLocation = (state) =>
  state.common.serverRedirectLocation;
export const contextPath = (state) => state.common?.routingContext?.path;
export const routingContext = (state) => state.common?.routingContext || {};
export const isVanityDomainSession = createSelector(
  routingContext,
  ($routingContext) =>
    $routingContext.isForwardedPath && $routingContext.isPortalsSession
);
export const getPortalPathFn = createSelector(
  routingContext,
  sessionSelectors.publicPortalDomain,
  ($routingContext, $publicPortalDomain) => {
    if ($publicPortalDomain) {
      return getPortalPath({
        routingContext: $routingContext,
        organizationDomain: $publicPortalDomain
      });
    }

    return getPath;
  }
);

/*
studioMetadata contains:
runtimes:
  [{
    id: 'org.mule.tooling.server.3.9.0.ee',
    label: 'Mule Server 3.9.0 EE',
    version: { major: 3, minor: 9, micro: 0, qualifier: {} }
  }]
*/
export const isLegacyStudioVersion = createSelector(
  (state) => state.session.studioMetadata?.version,
  ($version) => !$version || semver.lt($version, '7.0.0')
);

export const isStudio = (state) => !!state.session.studioMetadata?.version;

export const studioMetadata = createSelector(
  (state) => state.session.studioMetadata,
  isLegacyStudioVersion,
  ($studioMetadata, $isLegacy) => ({
    ...$studioMetadata,
    isLegacy: $isLegacy
  })
);

export const studioType = createSelector(
  (state) => state.session.studioMetadata?.perspective,
  ($perspective) => {
    if ($perspective === 'api') {
      return 'rest-api';
    }

    if ($perspective === 'wsdl') {
      return 'soap-api';
    }

    return $perspective;
  }
);

export const profileOrganizationDomain = createSelector(
  profile,
  ($profile = {}) => {
    if (!$profile.organization) {
      return '';
    }

    return $profile.organization.domain;
  }
);

export const organizationId = createSelector(profile, ($profile = {}) => {
  if (!$profile.organization) {
    return '';
  }

  return $profile.organization.id;
});

export const organization = createSelector(
  profile,
  ($profile = {}) => $profile.organization
);

export const isScriptLoading = (state, scriptKey) =>
  state.common.scripts.loading.includes(scriptKey);
export const isScriptLoaded = (state, scriptKey) =>
  state.common.scripts.loaded.includes(scriptKey);
