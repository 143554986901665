import PropTypes from 'prop-types';
import React from 'react';
import ProfilePhoto from '@mulesoft/anypoint-components/lib/ProfilePhoto';
import { AssetPropType, RefType } from '@mulesoft/exchange-react-shapes';
import ReviewEdition from '~/components/AssetDetail/Reviews/ReviewEdition';
import styles from '../Review/Review.css';

const DOM_ID = 'create-review';

const initialState = {
  rating: 0,
  title: '',
  text: ''
};

export class CreateReview extends React.Component {
  static emphasize = () => {
    const createReviewElement = document.getElementById(DOM_ID);

    if (!createReviewElement) {
      return;
    }

    createReviewElement.focus();
    createReviewElement.scrollIntoView(true);
    createReviewElement.classList.remove('emphasize');

    // Allow for css changes to settle first
    setTimeout(() => createReviewElement.classList.add('emphasize'), 0);
  };

  static propTypes = {
    asset: AssetPropType,
    isFirstReview: PropTypes.bool,
    profile: PropTypes.object,
    innerRef: RefType,
    onCreateReview: PropTypes.func,
    onRatingChange: PropTypes.func
  };

  render() {
    const {
      innerRef,
      asset,
      isFirstReview,
      profile,
      onCreateReview,
      onRatingChange
    } = this.props;

    return (
      <div className={styles.container}>
        <ProfilePhoto
          testId="create-review-profile-photo"
          theme={ProfilePhoto.themes.DarkTheme}
          className={styles.profilePhoto}
          size="s"
          image={profile.profilePhoto}
          firstName={profile.firstName}
          lastName={profile.lastName}
          aria-hidden
        />

        <ReviewEdition
          ref={innerRef}
          id={DOM_ID}
          testId="create-review-form"
          rating={initialState.rating}
          text={initialState.text}
          title={initialState.title}
          asset={asset}
          isTitleFocus
          isFirstReview={isFirstReview}
          onAcceptOperation={onCreateReview}
          onRatingChange={onRatingChange}
          acceptButtonName="Publish"
        />
      </div>
    );
  }
}

const CreateReviewWithRef = (props, ref) => {
  return <CreateReview innerRef={ref} {...props} />;
};

export default React.forwardRef(CreateReviewWithRef);
