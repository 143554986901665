import React from 'react';
import Home from '~/components/Home';
import BaseLayout from '~/layouts/BaseLayout';

class HomeLayout extends BaseLayout {
  renderMain() {
    const { route } = this.props;

    return <Home route={route} />;
  }
}

export default HomeLayout;
