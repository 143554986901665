/*
  eslint
    global-require: 0
*/

const portalsDefinition = {
  'api-group': require('./api-group').default,
  'rest-api': require('./rest-api').default,
  'soap-api': require('./soap-api').default,
  'http-api': require('./http-api').default
};

module.exports = portalsDefinition;
