import * as actions from './actionTypes';

const initialState = {
  featureFlags: {},
  studioMetadata: {},
  history: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_TOKEN: {
      const { accessToken, tokenType } = action;

      return {
        ...state,
        context: {
          ...state.context,
          authorization: `${tokenType} ${accessToken}`
        }
      };
    }

    case actions.SET_SESSION:
      return {
        ...state,
        profile: action.profile,
        context: action.context,
        featureFlags: action.featureFlags,
        studioMetadata: action.studioMetadata,
        isMarketingSite: action.isMarketingSite,
        publicPortalDomain: action.publicPortalDomain,
        definitions: action.definitions
      };

    case actions.INCREASE_PROFILE_ASSETS_COUNT:
      if (!state.profile) return state;

      return {
        ...state,
        profile: {
          ...state.profile,
          organization: {
            ...state.profile.organization,
            assetsLimit: {
              ...state.profile.organization.assetsLimit,
              assetCount: state.profile.organization.assetsLimit.assetCount + 1
            }
          }
        }
      };

    case actions.DECREASE_PROFILE_ASSETS_COUNT:
      if (!state.profile) return state;

      return {
        ...state,
        profile: {
          ...state.profile,
          organization: {
            ...state.profile.organization,
            assetsLimit: {
              ...state.profile.organization.assetsLimit,
              assetCount: state.profile.organization.assetsLimit.assetCount - 1
            }
          }
        }
      };

    case actions.SET_ACTIVE_ORGANIZATION:
      if (!state.profile) return state;

      return {
        ...state,
        profile: {
          ...state.profile,
          properties: {
            ...state.profile.properties,
            cs_auth: {
              ...state.profile.properties.cs_auth,
              activeOrganizationId: action.organization.id
            }
          }
        }
      };

    case actions.SET_USER_PROPERTY:
      if (!state.profile) return state;

      return {
        ...state,
        profile: {
          ...state.profile,
          properties: {
            ...state.profile.properties,
            [action.namespace]: {
              ...state.profile.properties[action.namespace],
              [action.key]: action.value
            }
          }
        }
      };

    default:
      return state;
  }
}
