import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import TrashIcon from '@mulesoft/anypoint-icons/lib/svg/trash-small.svg';
import RedoIcon from '@mulesoft/anypoint-icons/lib/svg/redo-small.svg';
import MoreIcon from '@mulesoft/anypoint-icons/lib/svg/more-small.svg';
import Popover from '@mulesoft/anypoint-components/lib/Popover';
import Button from '@mulesoft/anypoint-components/lib/Button';
import MenuItem from '@mulesoft/anypoint-components/lib/MenuItem';
import ClientApplicationModal from '@mulesoft/client-applications-ui/raw/components/ClientApplicationModal';
import { ModalContext } from '@mulesoft/exchange-react-hooks';
import ResetClientSecretConfirmModal from './ResetClientSecretConfirmModal';
import DeleteApplicationConfirmModal from './DeleteApplicationConfirmModal';
import styles from './Actions.css';

class ClientApplicationDetailActions extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    application: PropTypes.object,
    onEditApplication: PropTypes.func,
    openModal: PropTypes.func
  };

  static contextType = ModalContext;

  onEdit = () => {
    const openModal = this.context?.openModal || this.props.openModal;
    const { application, onEditApplication } = this.props;

    openModal(
      ClientApplicationModal,
      { application, onEditApplication },
      {
        applicationId: application.id,
        isEditingApplication: true
      }
    );
  };

  onResetSecret = () => {
    const openModal = this.context?.openModal || this.props.openModal;
    const { application } = this.props;

    openModal(
      ResetClientSecretConfirmModal,
      { application },
      { applicationId: application.id }
    );
  };

  onDelete = () => {
    const openModal = this.context?.openModal || this.props.openModal;
    const { application } = this.props;

    openModal(
      DeleteApplicationConfirmModal,
      { application },
      { applicationId: application.id }
    );
  };

  render() {
    const { className } = this.props;

    return (
      <div className={`${className} ${styles.actionsContainer}`}>
        <Button
          testId="edit-client-application-button"
          kind="secondary"
          type="button"
          className={styles.action}
          noFill
          aria-describedby="edit-application-modal-description"
          onClick={this.onEdit}
        >
          Edit
        </Button>
        <span
          id="edit-application-modal-description"
          className="visually-hidden"
        >
          Opens edit application modal
        </span>

        <div
          data-test-id="more-actions"
          className={styles.moreActions}
          ref={(element) => {
            this.popoverContainer = element;
          }}
        >
          <Popover
            testId="client-application-actions"
            anchorPosition="br"
            popupPosition="tr"
            triggerOn={['click']}
            className={styles.popover}
            content={
              <div
                data-test-id="more-actions-submenu"
                className={styles.moreActionsMenu}
              >
                <MenuItem
                  className={styles.resetClientSecret}
                  onClick={this.onResetSecret}
                  data-test-id="reset-secret-button"
                >
                  <button
                    type="button"
                    className={styles.moreActionsItemButton}
                    aria-describedby="reset-client-secret-modal-description"
                  >
                    <Icon className={styles.icon} role="presentation" size="xs">
                      <RedoIcon />
                    </Icon>
                    Reset client secret
                  </button>
                </MenuItem>
                <span
                  id="reset-client-secret-modal-description"
                  className="visually-hidden"
                >
                  Opens reset client secret modal
                </span>
                <hr className={styles.moreActionsSeparator} />
                <MenuItem
                  className={styles.deleteApplication}
                  onClick={this.onDelete}
                  data-test-id="delete-application-button"
                >
                  <button
                    type="button"
                    className={styles.moreActionsItemButton}
                    aria-describedby="delete-application-modal-description"
                  >
                    <Icon className={styles.icon} role="presentation" size="xs">
                      <TrashIcon />
                    </Icon>
                    Delete application
                  </button>
                </MenuItem>
                <span
                  id="delete-application-modal-description"
                  className="visually-hidden"
                >
                  Opens delete application modal
                </span>
              </div>
            }
            getPopoverContainer={() => this.popoverContainer}
          >
            <Icon
              className={styles.moreActionsIcon}
              aria-label="Show more actions"
              size="s"
            >
              <MoreIcon />
            </Icon>
          </Popover>
        </div>
      </div>
    );
  }
}

export default ClientApplicationDetailActions;
