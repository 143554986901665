import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import styles from './SearchBox.css';

class SearchBox extends React.PureComponent {
  static propTypes = {
    ariaLabel: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    onSearch: PropTypes.func,
    query: PropTypes.object,
    maxLength: PropTypes.string
  };

  state = { value: this.props.query.search };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { query } = nextProps;

    if (this.state.value !== query.search) {
      this.setState({ value: query.search });
    }
  }

  handleChange = ({ value, isClear }) => {
    this.setState({ value }, () => {
      if (isClear) {
        this.props.onSearch({ search: '' });
      }
    });
  };

  render() {
    const { ariaLabel, ariaLabelledby, onSearch, query, maxLength } =
      this.props;
    const { value = '' } = this.state;

    return (
      <form
        data-test-id="search-form"
        className={classNames(styles.search)}
        method="GET"
        onSubmit={(event) => event.preventDefault()}
      >
        <TextField
          id="search"
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledby}
          className={styles.textField}
          testId="search-bar"
          name="search"
          type="search"
          onChange={(event) => this.handleChange(event)}
          onEnter={(event) => onSearch({ search: event.value.trim() })}
          placeholder="Search"
          value={value}
          maxLength={maxLength || ''}
        />
        <input
          type="hidden"
          name="view"
          value={query.view || ''}
          onChange={() => {}}
        />
        <input
          type="hidden"
          name="type"
          value={query.type || ''}
          onChange={() => {}}
        />
      </form>
    );
  }
}

export default SearchBox;
