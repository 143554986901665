/**
* recieves an array and returns a hash map using the key param as the hash key
*
* Example:

  getHashMap([{ id: 'foo', name: 'Foo' }, { id: 'bar', name: 'Bar' }]) =>
  {
    foo: {
      id: 'foo',
      name: 'Foo'
    },
    bar: {
      id: 'bar',
      name: 'Bar'
    }
  }

*
* @param {Array} array
* @param {String} key
*/
export function getHashMap(array, key = 'id') {
  return array.reduce((prev, current) => {
    // eslint-disable-next-line no-param-reassign
    prev[current[key]] = current;

    return prev;
  }, {});
}

/**
* recieves a hash map and returns an array
*

  getArray({ foo: 'bar', baz: 'quux' }) =>
  ['bar', 'quux']

* @param {Object} hashMap
*/
export function getArray(hashMap) {
  return hashMap ? Object.keys(hashMap).map((key) => hashMap[key]) : undefined;
}

/**
 * Receives an object or an array and returns an array. Useful for query params
 *
 * @param  {Array} objectOrArray
 * @return {Array}
 */
export const convertToArray = (objectOrArray) => {
  if (!objectOrArray) {
    return [];
  }

  if (Array.isArray(objectOrArray)) {
    return objectOrArray;
  }

  return [objectOrArray];
};

/**
 * Returns the first element of the array that satisfies a condition
 * or a default value if no such element is found.
 * @param  {Array} array
 * @param  {Function} predicate
 * @return {Any} [defaultValue]
 */
const defaultPredicate = (value) => !!value;

export function firstOrDefault(
  array = [],
  predicate = defaultPredicate,
  defaultValue = null
) {
  for (let i = 0; i < array.length; i += 1) {
    if (predicate(array[i], i, array)) {
      return array[i];
    }
  }

  return defaultValue;
}

export function isEmpty(array) {
  return !array || array.length === 0;
}

export function replaceInArray(array, item, { index, findFn }) {
  const idx = findFn ? array.findIndex(findFn) : index;

  return [...array.slice(0, idx), item, ...array.slice(idx + 1, array.length)];
}

export function insertInArray(array, item, { index, findFn }) {
  const idx = findFn ? array.findIndex(findFn) : index;
  const indexToInsert = idx === -1 ? array.length : idx;
  const newArray = [...array];

  newArray.splice(indexToInsert, 0, item);

  return newArray;
}

export function removeFromArray(array, { index, findFn }) {
  const idx = findFn ? array.findIndex(findFn) : index;

  return [...array.slice(0, idx), ...array.slice(idx + 1, array.length)];
}

export function getComparatorByPropertyName(propertyName) {
  return (a, b) => {
    const propertyA = (a[propertyName] || '').toUpperCase();
    const propertyB = (b[propertyName] || '').toUpperCase();

    if (propertyA < propertyB) {
      return -1;
    }

    if (propertyA > propertyB) {
      return 1;
    }

    return 0;
  };
}

export function shallowEquals(a, b, anyOrder = true) {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  if (anyOrder) {
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();

    return sortedA.every((val, index) => val === sortedB[index]);
  }

  return a.every((val, index) => val === b[index]);
}
