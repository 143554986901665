import memoize from 'memoize-one';
import * as arrayUtils from '~/utils/arrays';
import * as objectsUtils from '~/utils/objects';

export const parseSearch = memoize((search) => {
  const queryParams = new URLSearchParams(search);
  const query = {};

  for (const [param, value] of queryParams) {
    if (!query[param]) {
      query[param] = value;
    } else if (Array.isArray(query[param])) {
      query[param] = [...query[param], value];
    } else {
      query[param] = [query[param], value];
    }
  }

  return query;
});

export const equalsQuery = objectsUtils.equalsFactory(
  (firstElement, otherElement) => {
    if (typeof firstElement === 'string' && Array.isArray(otherElement)) {
      return otherElement.length === 1 && otherElement[0] === firstElement;
    }

    if (Array.isArray(firstElement) && typeof otherElement === 'string') {
      return firstElement.length === 1 && firstElement[0] === otherElement;
    }

    if (typeof firstElement !== typeof otherElement) {
      return false;
    }

    if (Array.isArray(firstElement)) {
      return arrayUtils.shallowEquals(firstElement, otherElement, true);
    }

    return firstElement === otherElement;
  }
);
