import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { actions as pagesActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { actions as portalsActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { isClient } from '~/utils/window';

const resolve = async ({
  getState,
  dispatch,
  params,
  helpers: { getPath, push }
}) => {
  const state = getState();
  const asset = assetsSelectors.asset(state, params);

  if (!asset) {
    return;
  }

  const {
    organization: { id: organizationId },
    groupId,
    assetId,
    version
  } = asset;
  const { pagePath } = params;

  const getMarkdown = () =>
    dispatch(
      pagesActions.getPageMarkdown({
        organizationId,
        groupId,
        assetId,
        version,
        pagePath,
        isDraft: true,
        pushFn: push,
        getPath
      })
    );

  if (isClient()) {
    dispatch(portalsActions.pageLoading());

    await getMarkdown();

    dispatch(portalsActions.pageLoaded());

    return;
  }

  await getMarkdown();
};

export default resolve;
