// this is an extension of redux-connect that allow us to handle multiple stores
import React from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { withRouter, Route } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { loadAsyncConnect } from 'redux-connect/lib/helpers/utils';
import { getMutableState } from 'redux-connect/lib/helpers/state';
import { beginGlobalLoad, endGlobalLoad } from 'redux-connect/lib/store';

class AsyncConnect extends React.Component {
  static propTypes = {
    render: PropTypes.func,
    beginGlobalLoad: PropTypes.func.isRequired,
    endGlobalLoad: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    helpers: PropTypes.any,
    reduxConnectStore: PropTypes.object.isRequired
  };

  static defaultProps = {
    helpers: {},
    render({ routes }) {
      return renderRoutes(routes);
    }
  };

  componentDidMount() {
    const dataLoaded = this.isLoaded();

    // we dont need it if we already made it on server-side
    if (!dataLoaded) {
      this.loadAsyncData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const navigated = location !== nextProps.location;

    // Allow a user supplied function to determine if an async reload is necessary
    if (navigated) {
      this.loadAsyncData(nextProps);
    }
  }

  isLoaded() {
    const { reduxConnectStore } = this.props;

    return getMutableState(reduxConnectStore.getState()).reduxAsyncConnect
      .loaded;
  }

  loadAsyncData({ reduxConnectStore, stores = [], ...otherProps }) {
    const loadResult = Promise.all(
      [reduxConnectStore, ...stores].map((store) =>
        loadAsyncConnect({
          ...otherProps,
          store
        })
      )
    );

    this.props.beginGlobalLoad();

    return loadResult.then(() => {
      this.props.endGlobalLoad();
    });
  }

  render() {
    const { location, render } = this.props;

    return <Route location={location} render={() => render(this.props)} />;
  }
}

const AsyncConnectWithContext = ({ context, ...otherProps }) => {
  const Context = context || ReactReduxContext;

  return (
    <Context.Consumer>
      {({ store: reduxConnectStore }) => (
        <AsyncConnect reduxConnectStore={reduxConnectStore} {...otherProps} />
      )}
    </Context.Consumer>
  );
};

AsyncConnectWithContext.propTypes = {
  context: PropTypes.object
};

export default connect(null, {
  beginGlobalLoad,
  endGlobalLoad
})(withRouter(AsyncConnectWithContext));
