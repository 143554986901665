import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import EditIcon from '@mulesoft/anypoint-icons/lib/svg/edit-small.svg';
import TrashIcon from '@mulesoft/anypoint-icons/lib/svg/trash-small.svg';
import ProfilePhoto from '@mulesoft/anypoint-components/lib/ProfilePhoto';
import Rating from '@mulesoft/exchange-ui-components/lib/components/Rating';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import FormattedDate from '~/components/Layout/FormattedDate';
import ReviewEdition from '~/components/AssetDetail/Reviews/ReviewEdition';
import * as refUtils from '~/utils/refs';
import { ReviewPropTypes } from '~/components/Asset/Review';
import styles from './Review.css';

const dateFormat = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

class Review extends React.Component {
  static propTypes = {
    asset: AssetPropType,
    review: ReviewPropTypes,
    onDeleteReview: PropTypes.func,
    onEditReview: PropTypes.func,
    onUnmount: PropTypes.func
  };

  static defaultProps = {
    onUnmount: () => {}
  };

  state = {
    isDeleting: false,
    isEditing: false
  };

  componentDidUpdate(prevProps, prevState) {
    const stoppedEditing = prevState.isEditing && !this.state.isEditing;
    const stoppedDeleting = prevState.isDeleting && !this.state.isDeleting;
    const startedDeleting = !prevState.isDeleting && this.state.isDeleting;

    if (stoppedEditing) {
      refUtils.focus(this.editButtonRef);
    }

    if (stoppedDeleting) {
      refUtils.focus(this.deleteButtonRef);
    }

    if (startedDeleting) {
      refUtils.focus(this.deleteReviewRef);
    }
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  editButtonRef = React.createRef();

  deleteButtonRef = React.createRef();

  deleteReviewRef = React.createRef();

  hideDeleteOption = () => {
    this.setState({ isDeleting: false });
  };

  hideEditOption = () => {
    this.setState({ isEditing: false });
  };

  showDeleteOption = () => {
    this.setState({ isDeleting: true });
  };

  handleDeleteReview = () => {
    const { review } = this.props;

    this.props.onDeleteReview(review);
  };

  handleEditReview = (review) => {
    this.props.onEditReview(review);
    this.hideEditOption();
  };

  toggleEditState = () => {
    this.setState({ isEditing: true });
  };

  render() {
    const { isDeleting, isEditing } = this.state;
    const { asset, review } = this.props;
    const { createdBy, updatedDate, rating, text, title, canDelete, canEdit } =
      review;

    return (
      <article
        className={styles.container}
        data-test-id="review"
        aria-label={`${rating} Stars. By ${createdBy.firstName} ${createdBy.lastName}`}
      >
        <ProfilePhoto
          testId="review-profile-photo"
          className={classNames(styles.profilePhoto, 'fs-block')}
          theme={ProfilePhoto.themes.DarkTheme}
          size="s"
          image={createdBy.profilePhoto}
          firstName={createdBy.firstName}
          lastName={createdBy.lastName}
        />
        <div className={styles.reviewBox}>
          <ReviewEdition
            testId="edit-review-form"
            display-if={isEditing}
            rating={rating}
            text={text}
            title={title}
            review={review}
            asset={asset}
            isFirstReview={false}
            onAcceptOperation={this.handleEditReview}
            onCancelOperation={this.hideEditOption}
            acceptButtonName="Save"
            collapsed={false}
          />
          <div className={styles.box} display-if={!isEditing}>
            <div className={styles.createdBy} data-test-id="review-created-by">
              <span
                className={classNames(styles.name, 'fs-block')}
                data-test-id="review-created-by-name"
              >
                {createdBy.firstName} {createdBy.lastName}
              </span>
              <span className={styles.separator} />
              <FormattedDate
                testId="review-created-date"
                date={updatedDate}
                format={dateFormat}
              />
            </div>
            <div className={styles.header}>
              <Rating
                testId="review-rating"
                className={styles.rating}
                rating={rating}
              />
              <span className={styles.title} data-test-id="review-title">
                {title}
              </span>
            </div>
            <div className={styles.text} data-test-id="review-text">
              {text}
            </div>
          </div>

          <div
            display-if={isDeleting}
            ref={this.deleteReviewRef}
            tabIndex="-1"
            className={styles.deleteOption}
            data-test-id="delete-option"
          >
            <span className={styles.text}>
              Are you sure you want to delete this review?
            </span>
            <Button
              testId="cancel-delete-review-button"
              kind="tertiary"
              type="button"
              onClick={this.hideDeleteOption}
            >
              Cancel
            </Button>
            <Button
              testId="delete-review-button"
              className={styles.delete}
              kind="primary"
              type="submit"
              onClick={this.handleDeleteReview}
            >
              Delete
            </Button>
          </div>

          <div
            className={styles.reviewActions}
            data-test-id="review-actions"
            display-if={!isEditing && !isDeleting}
          >
            <button
              type="button"
              display-if={canEdit}
              ref={this.editButtonRef}
              className={styles.action}
              data-test-id="review-edit"
              onClick={this.toggleEditState}
            >
              <Icon
                size="xs"
                className={styles.icon}
                data-test-id="edit-review-icon"
              >
                <EditIcon />
              </Icon>
              Edit
            </button>
            <button
              type="button"
              display-if={canDelete}
              ref={this.deleteButtonRef}
              className={styles.action}
              data-test-id="review-delete"
              onClick={this.showDeleteOption}
            >
              <Icon
                size="xs"
                className={styles.icon}
                data-test-id="delete-review-icon"
              >
                <TrashIcon />
              </Icon>
              Delete
            </button>
          </div>
        </div>
      </article>
    );
  }
}

export default Review;
