import config from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import styles from './GDPRCookieBanner.css';

class GDPRCookieBanner extends React.Component {
  static propTypes = {
    onClose: PropTypes.func
  };

  static defaultProps = {
    onClose: () => {}
  };

  state = {
    isDisabled: false
  };

  handleClose = () => {
    this.setState({
      isDisabled: true
    });

    this.props.onClose();
  };

  render() {
    const { isDisabled } = this.state;

    if (isDisabled) {
      return null;
    }

    return (
      <div
        role="banner"
        data-test-id="gdpr-banner-container"
        className={styles.gdpr}
      >
        <div className={styles.copyContainer}>
          <p>
            We use cookies to make interactions with our websites and services
            easy and meaningful, to better understand how they are used and to
            tailor advertising. You can read more and make your cookie choices{' '}
            <a
              href={config.privacyPolicyURL}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to privacy policy site"
            >
              here
            </a>
            . By continuing to use this site you are giving us your consent to
            do this.
          </p>
          <span
            role="button"
            className={styles.close}
            data-test-id="gdpr-close"
            tabIndex="0"
            onClick={this.handleClose}
            onKeyDown={eventHandlerUtils.onEnterKey(this.handleClose)}
          >
            &times;
          </span>
        </div>
      </div>
    );
  }
}

export default GDPRCookieBanner;
