import React from 'react';
import ClientApplicationList from '~/components/ClientApplication/ClientApplicationList';
import BaseCustomizationLayout from '../BaseCustomizationLayout';

class EditClientApplicationListLayout extends BaseCustomizationLayout {
  renderMain() {
    const { route } = this.props;

    return <ClientApplicationList route={route} />;
  }
}

export default EditClientApplicationListLayout;
