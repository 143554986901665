import * as actions from './actionTypes';

const initialState = { isLoaded: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_LOCAL_STORAGE: {
      return {
        ...state,
        isLoaded: true,
        ...action.initialState
      };
    }

    case actions.SET_LOCAL_STORAGE_ITEM: {
      return {
        ...state,
        [action.key]: action.value
      };
    }

    case actions.REMOVE_LOCAL_STORAGE_ITEM: {
      const newState = { ...state };

      delete newState[action.key];

      return newState;
    }

    default:
      return state;
  }
}
