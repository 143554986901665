import PropTypes from 'prop-types';
import React from 'react';
import Page from '~/components/Page/Page';
import * as refUtils from '~/utils/refs';
import styles from './CustomPage.css';

class CustomPage extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    canEdit: PropTypes.bool,
    pathToEdit: PropTypes.string,
    isContentLoading: PropTypes.bool
  };

  static defaultProps = {
    page: {},
    pathToEdit: ''
  };

  componentDidMount() {
    refUtils.focus(this.mainContentRef);
  }

  mainContentRef = React.createRef();

  render() {
    const { page, canEdit, pathToEdit, isContentLoading } = this.props;

    return (
      <div
        ref={this.mainContentRef}
        className={styles.main}
        tabIndex="-1"
        data-test-id="public-portal-custom-page"
      >
        <Page
          page={page}
          canEdit={canEdit}
          pathToEdit={pathToEdit}
          actionName="Customize"
          hasTitle={!!page.name}
          isContentLoading={isContentLoading}
        />
      </div>
    );
  }
}

export default CustomPage;
