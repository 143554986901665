import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './AlertMessage.css';

class AlertMessage extends React.PureComponent {
  static propTypes = {
    hint: PropTypes.object,
    message: PropTypes.string,
    details: PropTypes.object,
    requestId: PropTypes.string,
    type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    onCloseAlert: PropTypes.func.isRequired,
    isHTML: PropTypes.bool
  };

  static defaultProps = {
    type: 'error',
    isHTML: false
  };

  state = {
    showDetails: false
  };

  toggleDetails = () => {
    const { showDetails } = this.state;

    this.setState({ showDetails: !showDetails });
  };

  render() {
    const { message, hint, requestId, details, type, onCloseAlert, isHTML } =
      this.props;
    const { showDetails } = this.state;

    return (
      <div className={styles.wrapper} data-test-id="alert-message">
        <div
          className={classNames(styles.alertMessage, styles[type])}
          data-test-id="alert-message-type"
        >
          <div
            role="alert"
            // This is redundant for a lot of screen readers but increases general support
            aria-live="assertive"
            className={styles.message}
            data-test-id="message"
          >
            <div>
              {isHTML ? (
                <div
                  data-test-id="error-message"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              ) : (
                <span data-test-id="error-message">{message}</span>
              )}
              <br display-if={hint} />
              {hint}
            </div>
            <button
              type="button"
              data-test-id="close-alert-message"
              className={styles.close}
              aria-label="close"
              onClick={onCloseAlert}
            >
              &times;
            </button>
          </div>
          <button
            type="button"
            display-if={requestId || details?.errors?.length}
            className={styles.details}
            data-test-id="alert-message-details-id"
            onClick={this.toggleDetails}
          >
            {showDetails ? 'Hide details ∧' : 'Show more details ∨'}
          </button>
          {details?.errors?.map((error, index) => (
            <div
              key={index}
              display-if={showDetails}
              data-test-id={`alert-details-errors-id-${index}`}
              className={styles.errorDetails}
            >
              - {error}
            </div>
          ))}
          <div
            display-if={showDetails && requestId}
            data-test-id="alert-details-id"
            className={styles.requestId}
          >
            - Request Id: {requestId}
          </div>
        </div>
      </div>
    );
  }
}

export default AlertMessage;
