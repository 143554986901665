import { connect } from 'react-redux';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import DeprecationHeader from './DeprecationHeader';

const mapStateToProps = (state, { asset }) => {
  return {
    asset,
    isMinorDeprecated: assetsSelectors.isMinorDeprecated(state, asset)
  };
};

export default connect(mapStateToProps)(DeprecationHeader);
