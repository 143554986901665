import semver from 'semver';
import { extractAssetAgnosticProperties } from '~/utils/assets';
import { getGAKey } from '~/utils/types';

export function mergeIncompleteVersion(state, assetVersion) {
  const assetPath = getGAKey(assetVersion);
  const assetVersions = (state.data[assetPath] || {}).versions || {};

  if (
    assetVersions[assetVersion.version] &&
    assetVersions[assetVersion.version].isCompleted &&
    !assetVersions[assetVersion.version].isMinorVersionTransition
  ) {
    return state;
  }

  return mergeVersion(state, assetVersion);
}

export function mergeVersion(state, assetVersion) {
  const assetPath = getGAKey(assetVersion);
  const assetProperties = extractAssetAgnosticProperties(assetVersion);
  const currentAssetVersions = (state.data[assetPath] || {}).versions || {};

  const newAssetVersion = {
    ...currentAssetVersions[assetVersion.version],
    ...assetVersion
  };

  const newAssetVersions = {
    ...currentAssetVersions,
    [assetVersion.version]: {
      ...newAssetVersion,
      files: newAssetVersion.files || [],
      labels: newAssetVersion.labels || [],
      organization:
        newAssetVersion.organization ||
        (newAssetVersion.organizationId && {
          id: newAssetVersion.organizationId
        })
    }
  };

  const lastVersion = Object.keys(newAssetVersions)
    .filter((version) => !newAssetVersions[version].isDeleted)
    .sort((v1, v2) => semver.rcompare(v1, v2))[0];

  return {
    ...state,
    data: {
      ...state.data,
      [assetPath]: {
        ...state.data[assetPath],
        ...assetProperties,
        lastVersion,
        versions: newAssetVersions
      }
    }
  };
}
