import { compose } from 'redux';
import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as modalActions } from '~/domains/modal';
import { selectors as commonSelectors } from '~/domains/common';
import { actions as searchesActions } from '~/domains/searches';
import { selectors as searchSelectors } from '~/domains/search';
import { setModalType, registerModal } from '~/components/Modals';
import CreateSavedSearchModal from './CreateSavedSearchModal';

const mapStateToProps = (
  state,
  { query, isAdmin, savedSearches, userSavedSearches, activeOrganization }
) => {
  const profile = commonSelectors.profile(state);
  const rootOrganizations = searchSelectors.rootOrganizations(state);

  return {
    query,
    isAdmin,
    savedSearches,
    userSavedSearches,
    profile,
    rootOrganizations,
    activeOrganization
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(modalActions.closeModal()),
  onSaveOrgSearch: (query) => dispatch(searchesActions.saveSearch(query)),
  onSaveUserSearch: (query) => dispatch(searchesActions.saveUserSearch(query))
});

export default compose(
  registerModal,
  setModalType('CreateSavedSearchModal'),
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(CreateSavedSearchModal);
