import debug from 'debug';
import NotFoundModal from '~/components/Modals/NotFoundModal';
import TooManyRequestsModal from '~/components/Modals/TooManyRequestsModal';
import UnauthorizedModal from '~/components/Modals/UnauthorizedModal';
import BadRequestModal from '~/components/Modals/BadRequestModal';
import * as commonActions from '~/domains/common/actions';
import * as commonSelectors from '~/domains/common/selectors';
import * as modalActions from '~/domains/modal/actions';
import * as modalSelectors from '~/domains/modal/selectors';
import * as reloginActions from '~/domains/relogin/actions';
import { isClient } from '~/utils/window';

const log = debug('exchange:store:error');

export function handleError(error, action) {
  return (dispatch, getState) => {
    if (isClient()) {
      const state = getState();
      const currentModalType = modalSelectors.modalType(state);

      if (error.status === 401 && commonSelectors.isLoggedIn(state)) {
        if (action) {
          dispatch(reloginActions.addPendingAction(action));
        }

        if (currentModalType !== UnauthorizedModal.modalType) {
          dispatch(
            modalActions.openModal({ modalType: UnauthorizedModal.modalType })
          );
        }
      } else if (error.status === 404) {
        dispatch(
          modalActions.pushModal({
            modalType: NotFoundModal.modalType,
            modalProps: {
              requestId: error.requestId
            }
          })
        );
      } else if (
        error.status === 429 &&
        currentModalType !== TooManyRequestsModal.modalType
      ) {
        dispatch(
          modalActions.openModal({ modalType: TooManyRequestsModal.modalType })
        );
      } else if (error.status === 400) {
        dispatch(
          modalActions.pushModal({
            modalType: BadRequestModal.modalType,
            modalProps: {
              error
            }
          })
        );
      } else {
        log('Dispatching alert', error);
        // eslint-disable-next-line no-console
        console.error(error);

        dispatch(
          commonActions.setAlert({
            type: 'error',
            hint: error.hint,
            message: error.message,
            isHTML: error.isHTML,
            requestId: error.requestId,
            details: error.details
          })
        );
      }
    } else {
      log('Dispatching error', error);

      dispatch(commonActions.setError(error));
    }
  };
}
