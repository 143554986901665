import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mulesoft/anypoint-components/lib/Button';
import DropdownMenu from '@mulesoft/anypoint-components/lib/DropdownMenu';
import AssetImage from '@mulesoft/exchange-ui-components/lib/components/AssetImage';
import Rating from '@mulesoft/exchange-ui-components/lib/components/Rating';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import Actions from '~/layouts/common/Actions';
import AssetTitle from '~/components/Asset/Title';
import { CreateReview } from '~/components/AssetDetail/Reviews/CreateReview';
import constants from '~/utils/constants';
import { getIconURL } from '~/utils/routes';
import { getGAVKey } from '~/utils/types';
import DropdownMinorTheme from './DropdownMinorTheme.css';
import NonAPIDropdownMinorTheme from './NonAPIDropdownMinorTheme.css';
import NoDropdownTheme from './NoDropdownTheme.css';
import Download from './Download';
import styles from './Header.css';

export class Header extends PureComponent {
  static propTypes = {
    asset: AssetPropType,
    assetMinorVersions: PropTypes.arrayOf(PropTypes.object),
    profile: PropTypes.object,
    isEdit: PropTypes.bool,
    isPublicPortal: PropTypes.bool,
    isStudio: PropTypes.bool,
    hideReviewLink: PropTypes.bool,
    hideRating: PropTypes.bool,
    showActions: PropTypes.bool,
    showVersionDropdown: PropTypes.bool,
    getVersionPath: PropTypes.func,
    isDraft: PropTypes.bool,
    canEdit: PropTypes.bool,
    onDownload: PropTypes.func,
    onMinorVersionChange: PropTypes.func,
    onBackToPrevious: PropTypes.func,
    onSaveName: PropTypes.func,
    onSaveIcon: PropTypes.func,
    onDeleteIcon: PropTypes.func,
    onLoadReview: PropTypes.func,
    getPath: PropTypes.func,
    isPortalReArchitectureEnabled: PropTypes.bool,
    isMarketingSite: PropTypes.bool,
    isPublicPortalPIRAEnabled: PropTypes.bool,
    versionsNavigation: PropTypes.string,
    reviews: PropTypes.object
  };

  async componentDidUpdate() {
    const { asset, onLoadReview, reviews } = this.props;
    const gavKey = getGAVKey(asset);

    this.isLoading = this.isLoading ?? false;

    if (
      this.isLoading !== gavKey &&
      asset.isCompleted &&
      !asset.isDeleted &&
      (!reviews || reviews.rating === null || reviews.numberOfRates === null)
    ) {
      this.isLoading = gavKey;
      await onLoadReview(asset);
      this.isLoading = false;
    }
  }

  isVersionGroupNavigation = () => {
    const { versionsNavigation } = this.props;

    return versionsNavigation === 'version-group';
  };

  getMinorVersionsOptions = () => {
    const { assetMinorVersions } = this.props;

    return assetMinorVersions.reduce(this.renderMinorVersionOption, []);
  };

  handleReviewLinkClick = () => {
    CreateReview.emphasize();
  };

  handleMinorVersionChange = (event) => {
    const { asset, assetMinorVersions, getVersionPath } = this.props;
    const selectedMinor = event.value;

    const path = getVersionPath({ ...asset, minorVersion: selectedMinor });

    this.props.onMinorVersionChange(
      asset,
      assetMinorVersions,
      selectedMinor,
      path
    );
  };

  generateVersionGroupSeparator = (accum, versionGroup) => {
    if (
      accum[accum.length - 1] &&
      accum[accum.length - 1].versionGroup === versionGroup
    ) {
      return [];
    }

    return [
      {
        label: versionGroup,
        type: 'label'
      }
    ];
  };

  getDropdownMenuTheme = () => {
    const { assetMinorVersions } = this.props;

    if (assetMinorVersions.length === 1) {
      return NoDropdownTheme;
    }

    if (this.isVersionGroupNavigation()) {
      return DropdownMinorTheme;
    }

    return NonAPIDropdownMinorTheme;
  };

  isSelectedMinorVersion = (minorVersion) => {
    const { asset } = this.props;

    return minorVersion === asset.minorVersion;
  };

  renderMinorVersionOption = (accum, option) => {
    const { minorVersion } = option;
    const { asset, getVersionPath, showVersionDropdown } = this.props;
    const versionPath = getVersionPath({ ...asset, minorVersion });

    const separator = this.isVersionGroupNavigation()
      ? this.generateVersionGroupSeparator(accum, option.versionGroup)
      : [];

    return [
      ...accum,
      ...separator,
      {
        key: minorVersion,
        versionGroup: option.versionGroup,
        label: (
          <div
            title={`${minorVersion}.x`}
            role="option"
            aria-selected={false}
            className={classNames(
              styles.minorVersion,
              showVersionDropdown ? styles.minorOfVersionGroup : '',
              this.isSelectedMinorVersion(minorVersion) ? styles.selected : ''
            )}
          >
            {versionPath ? (
              <Link to={versionPath}>{minorVersion}.x</Link>
            ) : (
              <span>{minorVersion}.x</span>
            )}
          </div>
        )
      }
    ];
  };

  renderCurrentMinorVersionLabel = () => {
    const { asset } = this.props;

    return (
      <span
        data-test-id={`minor-version-placeholder-${asset.minorVersion}`}
        className={styles.dropdownValue}
      >
        {`${asset.minorVersion}.x`}
      </span>
    );
  };

  render() {
    const {
      asset,
      assetMinorVersions,
      profile,
      canEdit,
      isEdit,
      isDraft,
      isPublicPortal,
      isStudio,
      onBackToPrevious,
      onSaveName,
      onSaveIcon,
      onDeleteIcon,
      onDownload,
      hideRating,
      showActions,
      hideReviewLink,
      getPath,
      isPortalReArchitectureEnabled,
      isMarketingSite,
      isPublicPortalPIRAEnabled,
      reviews
    } = this.props;

    return (
      <header
        id="asset-header"
        className={styles.container}
        data-test-id="asset-detail-header"
        aria-label="Asset basic info"
      >
        <div className={styles.header}>
          <AssetImage
            key={asset.id}
            onDeleteIcon={onDeleteIcon}
            onSaveIcon={onSaveIcon}
            type={asset.type}
            icon={getIconURL(asset)}
            alt={asset.name}
            isEditable={canEdit}
            isCertified={(asset.labels || []).some(
              (label) => label === constants.mulesoftCertifiedTag
            )}
          />
          <div className={styles.title} data-test-id="asset-title">
            <div
              className={styles.mainHeading}
              data-test-id="asset-main-heading"
            >
              <div
                className={styles.mainSubHeading}
                data-test-id="asset-main-sub-heading"
              >
                <AssetTitle
                  asset={asset}
                  canEdit={canEdit}
                  onSaveName={onSaveName}
                />
                <div className={styles.backToPrevious}>
                  <Button
                    type="button"
                    testId="back-to-previous"
                    display-if={
                      isPortalReArchitectureEnabled &&
                      (!isPublicPortal || isPublicPortalPIRAEnabled) &&
                      !isStudio &&
                      !isMarketingSite &&
                      !!profile
                    }
                    onClick={onBackToPrevious}
                    aria-describedby="back-to-previous-description"
                    className={styles.button}
                    size="sm"
                    kind="primary"
                    noFill
                  >
                    Back to new UI
                    <span
                      id="back-to-previous-description"
                      className="visually-hidden"
                    >
                      Changes display to look like new UI
                    </span>
                  </Button>
                  <div className={styles.dropdownContainer}>
                    <span
                      display-if={this.isVersionGroupNavigation()}
                      className={styles.versionGroupLabel}
                      data-test-id="version-group-label"
                      title={asset.versionGroup}
                    >
                      {asset.versionGroup}
                    </span>
                    <span
                      display-if={this.isVersionGroupNavigation()}
                      data-test-id="dropdown-separator"
                      className={classNames(
                        styles.separator,
                        styles.mainSeparator
                      )}
                    />
                    <DropdownMenu
                      display-if={
                        assetMinorVersions.length && asset.minorVersion
                      }
                      alignment="right"
                      theme={this.getDropdownMenuTheme()}
                      placeholder={this.renderCurrentMinorVersionLabel()}
                      aria-label="Asset minor versions"
                      valueKey="key"
                      labelKey="label"
                      id="asset-minor-versions-dropdown"
                      testId="asset-minor-versions"
                      onChange={this.handleMinorVersionChange}
                      options={this.getMinorVersionsOptions()}
                      value={asset.minorVersion}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.actions}
                data-test-id="asset-actions"
                display-if={showActions}
              >
                <Download
                  key="asset-header-download"
                  testId="asset-action-download"
                  getPath={getPath}
                  asset={asset}
                  onDownload={onDownload}
                  isStudio={isStudio}
                />
                <Actions asset={asset} isDraft={isDraft} isEdit={isEdit} />
              </div>
            </div>
            <div className={styles.subHeading} display-if={!isEdit}>
              <div data-test-id="rating-header" className={styles.ratingHeader}>
                <span className={styles.ratingLabel}>
                  <Rating
                    display-if={!hideRating}
                    className={styles.rating}
                    testId="asset-rating"
                    rating={reviews?.rating}
                  />
                  <span
                    display-if={!hideRating}
                    className={styles.ratingDetail}
                  >
                    ({reviews?.numberOfRates}{' '}
                    {reviews?.numberOfRates === 1 ? 'review' : 'reviews'})
                  </span>
                </span>
                <button
                  type="button"
                  display-if={profile && !isDraft && !hideReviewLink}
                  data-test-id="rate-and-review-link"
                  className={styles.reviewButton}
                  onClick={this.handleReviewLinkClick}
                >
                  Rate and review
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
