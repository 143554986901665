import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from '@mulesoft/exchange-react-hooks';
import RootStoreContext from '~/contexts/RootStoreContext';
import ApplicationName from './ApplicationName';

const mapStateToProps = (state, { router }) => ({ ...router });

export default compose(
  withRouter,
  connect(mapStateToProps, null, null, { context: RootStoreContext })
)(ApplicationName);
