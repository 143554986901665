import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import MuleIcon from '@mulesoft/anypoint-icons/lib/svg/mulesoft-logo.svg';
import CloseSmall from '@mulesoft/anypoint-icons/lib/svg/close-small.svg';
import classNames from 'classnames';
import styles from './LoginOptionsModal.css';

class LoginOptionsModal extends PureComponent {
  static propTypes = {
    organizationName: PropTypes.string,
    domain: PropTypes.string,
    getPath: PropTypes.func,
    onClose: PropTypes.func
  };

  render() {
    const { onClose, organizationName, getPath, domain } = this.props;
    const signInLink = getPath('login', { domain });
    const ssoLink = `${signInLink}?ssoRedirect=true`;

    return (
      <Modal
        onEsc={onClose}
        onClickOverlay={onClose}
        className={styles.modal}
        renderInBody={false}
      >
        <Button
          onClick={onClose}
          aria-label="Close"
          data-test-id="close-modal-button"
          className={styles.closeButton}
          kind="tertiary"
        >
          <Icon size="xs">
            <CloseSmall />
          </Icon>
        </Button>

        <a
          aria-label={`Sign in with ${organizationName} SSO`}
          className={classNames([styles.link, styles.separator])}
          href={ssoLink}
          data-test-id="sso-link"
        >
          <Button kind="secondary" tabIndex="-1" className={styles.loginButton}>
            Sign in with {organizationName} SSO
          </Button>
        </a>
        <a
          aria-label="Sign in with Anypoint Platform"
          className={styles.link}
          href={signInLink}
          data-test-id="anypoint-link"
        >
          <Button tabIndex="-1" className={styles.loginButton}>
            <Icon className={styles.muleIcon} size="xs">
              <MuleIcon />
            </Icon>
            Sign in with Anypoint Platform
          </Button>
        </a>
      </Modal>
    );
  }
}

export default LoginOptionsModal;
