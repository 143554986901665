import config from 'config';
import { AssetService } from '@mulesoft/exchange-api-client';
import { selectors as commonSelectors } from '~/domains/common';

const assetService = new AssetService(config);
const MARKETO_SUBJECT = 'Anypoint Exchange Interaction';
const VERSION_GROUP_REDIRECT = 'Redirecting to minor from version group';

export function trackDownloadForMarketo(asset, isInstall = false) {
  return (dispatch, getState) => {
    const state = getState();
    const profile = commonSelectors.profile(state);
    const isStudio = commonSelectors.isStudio(state);

    if (!profile || !profile.marketoKey) return;

    const labels = (asset.labels || []).join(',');
    const attributes = (asset.attributes || [])
      .map(({ value }) => value)
      .join(',');

    let payload = {
      subject: MARKETO_SUBJECT,
      Email: profile.email,
      id: profile.id,
      username: profile.username,
      FirstName: profile.firstName,
      LastName: profile.lastName,
      Company: profile.organization.name,
      exchangeAuthorName: asset.organization.name,
      exchangeObjectName: asset.name,
      exchangeObjectType: asset.type,
      exchangeAssetTags: `${labels},${attributes}`,
      isInstall
    };

    if (isStudio) {
      payload = {
        ...payload,
        StudioVersion: commonSelectors.studioMetadata(state).version
      };
    }

    if (global.Munchkin) {
      global.Munchkin.munchkinFunction(
        global.Munchkin.ASSOCIATE_LEAD,
        payload,
        profile.marketoKey
      );
    }
  };
}

export function redirectVersionGroupToMinorVersionPage({
  groupId,
  assetId,
  domain,
  currentPath,
  versionGroup,
  pushFn,
  trackEvent
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const minorVersions = await assetService.getMinorVersions({
      context,
      groupId,
      assetId,
      domain
    });
    const selectedMinorVersion = minorVersions.find(
      (minorVersion) => minorVersion.versionGroup === versionGroup
    );

    if (!selectedMinorVersion) {
      const notFoundError = new Error('Path does not exist');

      notFoundError.status = 404;
      throw notFoundError;
    }

    const pathToRedirect = currentPath.replace(
      `version/${encodeURIComponent(versionGroup)}`,
      `minor/${selectedMinorVersion.minorVersion}`
    );

    trackEvent(VERSION_GROUP_REDIRECT);
    pushFn(pathToRedirect, 301);
  };
}
