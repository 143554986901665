import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getModalDescriptiveName } from '~/utils/modals';
import { getModalComponent } from '../registry';
import styles from './ModalView.css';
import HiddenModalTheme from './HiddenModalTheme.css';
import { calculateModalTopOffset } from './utils';

class ModalView extends React.PureComponent {
  static propTypes = {
    modalStack: PropTypes.arrayOf(
      PropTypes.shape({
        modalType: PropTypes.string,
        modalProps: PropTypes.object,
        targetElementAfterClose: PropTypes.object
      })
    ).isRequired,
    onClose: PropTypes.func
  };

  componentDidMount() {
    if (global.parentIFrame) {
      this.calculateModalPosition();
    }
  }

  calculateModalPosition = () => {
    const modal = document.querySelector('[data-anypoint-component="Modal"]');
    const modalHeight = modal.clientHeight;

    global.parentIFrame.getPageInfo((pageInfo) => {
      modal.style.top = `${calculateModalTopOffset(pageInfo, modalHeight)}px`;
      modal.style.position = 'absolute';
    });
  };

  render() {
    const { onClose, modalStack } = this.props;

    return modalStack.map(({ modalType, modalComponent, modalProps }, i) => {
      const ModalComponent = modalComponent || getModalComponent(modalType);
      const isHidden = i > 0;
      const componentKey = getModalDescriptiveName({
        modalType,
        modalComponent
      });

      return (
        <ModalComponent
          className={classNames({ [styles.hidden]: isHidden })}
          isHidden={isHidden}
          theme={isHidden ? HiddenModalTheme : {}}
          key={componentKey}
          onClose={onClose}
          {...modalProps}
        />
      );
    });
  }
}

export default ModalView;
