const isSameLocation = (location1, location2) => {
  if (!location1 || !location2) {
    return false;
  }

  return (
    location1.pathname === location2.pathname &&
    (location1.search === location2.search ||
      (!location1.search && !location2.search))
  );
};

export const getSectionNavigationLog = ({
  previousNavigationLog,
  location,
  sectionId
}) => {
  // first iteration, set the new location in a new group
  if (previousNavigationLog?.length === 0) {
    return [
      {
        sectionId,
        locations: [location]
      }
    ];
  }

  // special case for first render in the client, keep it as it is
  if (
    isSameLocation(
      location,
      previousNavigationLog?.[previousNavigationLog.length - 1].locations?.[
        previousNavigationLog[previousNavigationLog.length - 1].locations
          .length - 1
      ]
    )
  ) {
    return previousNavigationLog;
  }

  // sectionId is the same, add new location to the existing group
  if (
    previousNavigationLog?.[previousNavigationLog.length - 1]?.sectionId ===
    sectionId
  ) {
    return [
      ...previousNavigationLog.slice(0, previousNavigationLog.length - 1),
      {
        sectionId,
        locations: [
          ...previousNavigationLog[previousNavigationLog.length - 1].locations,
          location
        ]
      }
    ];
  }

  // new sectionId, add it to the navigation array
  return [
    ...previousNavigationLog,
    {
      sectionId,
      locations: [location]
    }
  ];
};
