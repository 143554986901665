import config from 'config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import { selectors as commonSelectors } from '~/domains/common';
import {
  actions as searchesActions,
  selectors as searchesSelectors
} from '~/domains/searches';
import { getActiveOrganization } from '~/utils/organization';
import { EVENT_TYPES } from '~/analytics/events';
import SavedSearches from './SavedSearches';

const mapStateToProps = (state, { type, title, testId, view }) => {
  const profile = commonSelectors.profile(state);
  const activeOrganization = getActiveOrganization(profile);
  const activeOrganizationId = activeOrganization && activeOrganization.id;
  const canEditOrgSavedSearches =
    activeOrganization && activeOrganization.canEditOrgSavedSearches;

  if (type === 'organization') {
    return {
      type,
      title,
      testId,
      view,
      canEdit: canEditOrgSavedSearches,
      defaultItemCount: config.savedSearches.defaultSavedSearches,
      organizationId: activeOrganizationId,
      isFetching: searchesSelectors.isFetchingSavedSearches(
        state,
        activeOrganizationId
      ),
      hasMore: !searchesSelectors.hasAllSavedSearches(
        state,
        activeOrganizationId
      ),
      items: searchesSelectors.savedSearches(state, activeOrganizationId) || []
    };
  }

  if (type === 'user') {
    return {
      type,
      title,
      testId,
      view,
      canEdit: true,
      defaultItemCount: config.savedSearches.defaultUserSavedSearches,
      isFetching: searchesSelectors.isFetchingUserSavedSearches(state),
      hasMore: !searchesSelectors.hasAllUserSavedSearches(state),
      items: searchesSelectors.userSavedSearches(state) || []
    };
  }

  throw new Error('Invalid type');
};

const mapDispatchToProps = (dispatch, { type }) => {
  const segmentActions = {
    onClickShowMore: () =>
      dispatch(
        analyticsActions.trackEvent(
          EVENT_TYPES.SHOW_MORE_SAVED_SEARCHES_CLICKED
        )
      ),
    onClickShowLess: () =>
      dispatch(
        analyticsActions.trackEvent(
          EVENT_TYPES.SHOW_LESS_SAVED_SEARCHES_CLICKED
        )
      )
  };

  if (type === 'organization') {
    return {
      ...segmentActions,
      onGetSavedSearches: (payload) =>
        dispatch(searchesActions.getSavedSearches(payload)),
      onEditSavedSearch: (payload) =>
        dispatch(searchesActions.editSavedSearch(payload)),
      onDeleteSavedSearch: (payload) =>
        dispatch(searchesActions.deleteSavedSearch(payload))
    };
  }

  if (type === 'user') {
    return {
      ...segmentActions,
      onGetSavedSearches: (payload) =>
        dispatch(searchesActions.getUserSavedSearches(payload)),
      onEditSavedSearch: (payload) =>
        dispatch(searchesActions.editUserSavedSearch(payload)),
      onDeleteSavedSearch: (payload) =>
        dispatch(searchesActions.deleteUserSavedSearch(payload))
    };
  }

  throw new Error('Invalid type');
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(SavedSearches);
