import * as qs from 'querystring';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import MoreIcon from '@mulesoft/anypoint-icons/lib/svg/more-small.svg';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import { NavLink } from 'react-router-dom';
import Menu from '@mulesoft/anypoint-components/lib/Menu';
import Label from '@mulesoft/anypoint-components/lib/Label';
import MenuItem from '@mulesoft/anypoint-components/lib/MenuItem';
import Popover from '@mulesoft/anypoint-components/lib/Popover';
import TextFieldWithValidation from '@mulesoft/exchange-ui-components/lib/components/TextFieldWithValidation';
import { RefType } from '@mulesoft/exchange-react-shapes';
import { getTargetQuery } from '~/utils/ang/ANGFilters';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import { getPath } from '~/utils/routes';
import * as savedSearchesUtils from '~/utils/savedSearches';
import { equalsQuery } from '~/utils/location';
import * as objectsUtils from '~/utils/objects';
import { getNameValidations } from '~/utils/validations/savedSearches';
import {
  ANG_EXTERNAL_QUERY_PARAMS,
  ALL_ASSETS_LINK_ID
} from '~/utils/navigation/constants';
import styles from './Item.css';

const getLinkQuery = memoize((location, profile, rootOrganizations) => {
  const query = objectsUtils.omit(
    qs.parse(location.search.substring(1)),
    ANG_EXTERNAL_QUERY_PARAMS
  );

  if (query.show) {
    return query;
  }

  return getTargetQuery(query, profile, rootOrganizations);
});

export class Item extends React.Component {
  static maxLength = 64;

  static propTypes = {
    innerRef: RefType,
    isANGSearchEnabled: PropTypes.bool,
    query: PropTypes.object,
    canEdit: PropTypes.bool,
    testId: PropTypes.string.isRequired,
    organizationId: PropTypes.string,
    rootOrganizations: PropTypes.object,
    profile: PropTypes.object,
    checkNameAvailability: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    currentSavedSearches: PropTypes.array
  };

  state = {
    isEditing: false,
    isNameValid: true,
    currentName: this.props.query.name
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { query } = this.props;

    if (query.name !== nextProps.query.name) {
      this.setState({ currentName: nextProps.query.name, isEditing: false });
    }
  }

  onDelete = () => {
    const { query, organizationId, onDelete } = this.props;

    return onDelete({ id: query.id, organizationId });
  };

  onEdit = () => {
    const { currentName } = this.state;
    const { query, organizationId, onEdit, checkNameAvailability } = this.props;
    const trimmedName = currentName.trim();

    if (currentName === query.name) {
      this.setState({ isEditing: false });
    } else if (checkNameAvailability(trimmedName) && trimmedName) {
      this.setState({ isEditing: false });

      onEdit({ id: query.id, organizationId, query: { name: trimmedName } });
    }
  };

  onEnableEdit = (event) => {
    this.setState({ isEditing: true, isNameValid: true });

    return event && event.preventDefault();
  };

  onCancelEdit = () => {
    const { query } = this.props;

    this.setState({ isEditing: false, currentName: query.name });
  };

  onNameChange = (name) => {
    const { query, checkNameAvailability } = this.props;
    const trimmedName = name.trim();

    if (
      (checkNameAvailability(trimmedName) && trimmedName) ||
      name === query.name
    ) {
      this.setState({ isNameValid: true });
    } else {
      this.setState({ isNameValid: false });
    }

    this.setState({ currentName: name });
  };

  popoverContainerRef = React.createRef();

  getQuery = () => {
    const { isANGSearchEnabled, query } = this.props;

    if (isANGSearchEnabled) {
      return savedSearchesUtils.getQuery(query);
    }

    return {
      type: query.types.length ? query.types : undefined,
      search: query.search,
      view: query.view,
      show: ALL_ASSETS_LINK_ID
    };
  };

  renderSavedSearchLabel = () => {
    const { isEditing } = this.state;

    return (
      <Label
        className="visually-hidden"
        htmlFor="edit-saved-search-name"
        display-if={isEditing}
      >
        Saved search name
      </Label>
    );
  };

  renderEditNameTextField = () => {
    const { currentName, isNameValid } = this.state;
    const { query, currentSavedSearches } = this.props;

    return (
      <TextFieldWithValidation
        id="edit-saved-search-name"
        aria-invalid={!isNameValid}
        testId="edit-saved-search-name"
        name="saved-search-name"
        type="text"
        autoFocus
        isValid={isNameValid}
        isDirty={!isNameValid}
        value={currentName}
        className={styles.textField}
        errorClassName={styles.errorItemMessage}
        onEnter={this.onEdit}
        onBlur={this.onCancelEdit}
        onChange={({ value }) => this.onNameChange(value)}
        validate={getNameValidations(query.name, currentSavedSearches)}
        maxLength={Item.maxLength}
        validateOnBlur
      />
    );
  };

  renderSavedSearchLink = () => {
    const { currentName } = this.state;
    const { innerRef, profile, rootOrganizations } = this.props;
    const searchQuery = this.getQuery();
    const queryParams = qs.stringify(searchQuery);

    return (
      <NavLink
        innerRef={innerRef}
        data-test-id="saved-search-link"
        to={`${getPath('home')}?${queryParams}`}
        className={styles.savedLink}
        isActive={(match, location) =>
          equalsQuery(
            getTargetQuery(
              objectsUtils.omit(searchQuery, 'search_id'),
              profile,
              rootOrganizations
            ),
            getLinkQuery(location, profile, rootOrganizations)
          )
        }
        activeClassName={`${styles.active} is-active`}
      >
        <span
          aria-label={`Show results for saved search ${currentName}`}
          data-test-id="link-label"
          className={styles.label}
          title={currentName}
        >
          {currentName}
        </span>
      </NavLink>
    );
  };

  renderActionsMenu = () => {
    return (
      <Menu className={`${styles.menu} popover-if-enter-pressed-ignore-close`}>
        <MenuItem
          className={styles.menuItem}
          data-test-id="edit-action-item"
          key="edit-action-item"
          onKeyDown={eventHandlerUtils.onEnterKey(this.onEnableEdit)}
          onClick={this.onEnableEdit}
        >
          Rename
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          data-test-id="delete-action-item"
          key="delete-action-item"
          onKeyDown={eventHandlerUtils.onEnterKey(this.onDelete)}
          onClick={this.onDelete}
        >
          Delete
        </MenuItem>
      </Menu>
    );
  };

  renderActionsPopover = () => {
    const { isEditing } = this.state;
    const { canEdit } = this.props;
    const actionsMenu = this.renderActionsMenu();

    return (
      <div className={styles.triggerContainer} ref={this.popoverContainerRef}>
        <Popover
          getPopoverContainer={() => this.popoverContainerRef.current}
          testId="saved-search-action-options"
          display-if={canEdit && !isEditing}
          className={styles.actionPopover}
          anchorPosition="bd"
          popupPosition="td"
          triggerOn={['click']}
          closeOnClickOutside
          closeOnClickInside
          closeOnEnterInside
          content={actionsMenu}
        >
          <Icon
            data-test-id="saved-search-options-icon"
            aria-label="Show search actions"
            className={styles.icon}
            size="xs"
          >
            <MoreIcon />
          </Icon>
        </Popover>
      </div>
    );
  };

  render() {
    const { isEditing } = this.state;
    const { testId } = this.props;

    const savedSearchLabel = this.renderSavedSearchLabel();
    const editNameTextField = this.renderEditNameTextField();
    const savedSearchLink = this.renderSavedSearchLink();
    const actionsPopover = this.renderActionsPopover();

    return (
      <div data-test-id={testId} className={styles.saved}>
        {savedSearchLabel}
        {isEditing ? editNameTextField : savedSearchLink}
        {actionsPopover}
      </div>
    );
  }
}

const ItemWithRef = (props, ref) => {
  return <Item innerRef={ref} {...props} />;
};

export default React.forwardRef(ItemWithRef);
