import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mulesoft/anypoint-components/lib/Button';
import KeyIcon from '@mulesoft/anypoint-icons/lib/svg/key-small.svg';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import ExternalRequestAccessTooltip from '~/components/AssetDetail/ExternalRequestAccessTooltip';

const RequestAccessButton = ({
  asset,
  isExternalAsset,
  isPublicPortal,
  isPortalReArchitectureEnabled,
  onRequestAccess,
  hasManagedInstances,
  className,
  testId
}) => {
  if (!asset) {
    return null;
  }

  const button = (
    <Button
      display-if={hasManagedInstances && !isPortalReArchitectureEnabled}
      testId={testId}
      className={className}
      onClick={() => onRequestAccess(asset)}
      disabled={isExternalAsset}
      kind="primary"
      noFill={isPublicPortal}
    >
      <Icon size="xs">
        <KeyIcon />
      </Icon>
      <span>Request access</span>
    </Button>
  );

  if (isExternalAsset) {
    return (
      <ExternalRequestAccessTooltip>{button}</ExternalRequestAccessTooltip>
    );
  }

  return button;
};

RequestAccessButton.propTypes = {
  asset: PropTypes.object,
  isExternalAsset: PropTypes.bool.isRequired,
  isPublicPortal: PropTypes.bool.isRequired,
  isPortalReArchitectureEnabled: PropTypes.bool.isRequired,
  hasManagedInstances: PropTypes.bool.isRequired,
  onRequestAccess: PropTypes.func.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string
};

RequestAccessButton.defaultProps = {
  testId: 'request-access-button'
};

export default RequestAccessButton;
