import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import {
  withAnalytics,
  withModal,
  withRouter as withRouterActions
} from '@mulesoft/exchange-react-hooks';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import {
  actions as pagesActions,
  selectors as pagesSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { EVENT_TYPES } from '~/analytics/events';
import ConfirmDeletePageModal from '~/components/DeletePage/ConfirmDeletePageModal';
import { selectors as commonSelectors } from '~/domains/common';
import * as notebooksUtils from '~/portals/utils/notebooks';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import { selectors as customizationSelectors } from '~/domains/customization';
import { notebooksRanMode } from '~/analytics/events/notebooks';
import * as assetPortalService from '~/services/assetPortalService';
import {
  isPredefinedPage,
  isDefaultPage,
  replacePredefinedPageName
} from '~/utils/page';
import * as routesUtils from '~/utils/routes';
import * as typesUtils from '~/utils/types';
import StatefulPageEdit from './StatefulPageEdit';

const mapStateToProps = (state, { match }) => {
  const params = routesUtils.decodeParams(match.params);
  const page = replacePredefinedPageName(
    pagesSelectors.page(state, {
      ...params,
      isDraft: true
    })
  );
  const context = commonSelectors.context(state);
  const isAdmin = assetsSelectors.canAdmin(state, params);
  const isContributor = assetsSelectors.canEdit(state, params);
  const asset = assetsSelectors.asset(state, params);
  const isAPIGroup = typesUtils.isAPIGroup(asset);

  return {
    asset,
    context,
    version: params.version || asset?.version,
    page,
    pages: pagesSelectors.pages(state, { ...params, isDraft: true }),
    isContentLoading:
      customizationSelectors.isContentLoading(state) ||
      portalsSelectors.isPortalLoading(state),
    hasUnpublishedChanges: portalsSelectors.hasUnpublishedChanges(state, {
      ...params,
      isDraft: true
    }),
    isLoading: pagesSelectors.pageUI(state).isLoading,
    hasTitle: page && !isDefaultPage(page),
    canRenamePage: page && !isPredefinedPage(page),
    canDeletePage: page && !isDefaultPage(page),
    isAdmin,
    isContributor,
    hasUnderlyingTerms:
      isAPIGroup && reducerManagerSelectors.loadedType(state, asset.type)
        ? apiGroupSelectors.hasUnderlyingTerms(state, asset)
        : false
  };
};

const mapDispatchToProps = (
  dispatch,
  { closeModal, openModal, trackEvent, router: { getPath, push } }
) => ({
  onSavePage: (payload) =>
    dispatch(pagesActions.savePage({ ...payload, pushFn: push, getPath })),
  onRenamePage: (payload) =>
    dispatch(pagesActions.renamePage({ ...payload, pushFn: push, getPath })),
  onDeletePage: (payload) => {
    dispatch(pagesActions.deletePage({ ...payload, pushFn: push, getPath }));
    closeModal();
  },
  onOpenDeleteModal: (page, onDeletePage) =>
    openModal(ConfirmDeletePageModal, {
      page,
      onDeletePage
    }),
  onDiscardChanges: (payload) =>
    dispatch(
      pagesActions.discardChanges({ ...payload, pushFn: push, getPath })
    ),
  onPageContentChange: (payload) =>
    dispatch(pagesActions.updatePageContentStatus(payload)),
  onFetchNotebookClient: (payload) =>
    dispatch(notebooksUtils.fetchNotebookClient(payload)),
  onFileUpload: (file, { context, groupId, assetId, version }) =>
    assetPortalService.uploadResource({
      context,
      file,
      groupId,
      assetId,
      version
    }),
  onAddNotebook: () => trackEvent(EVENT_TYPES.NOTEBOOK_CREATED),
  onPlayNotebook: (stateProps) =>
    trackEvent(EVENT_TYPES.NOTEBOOK_RAN, {
      mode: notebooksRanMode.EDIT,
      isAdmin: stateProps.isAdmin,
      isContributor: stateProps.isContributor
    }),
  onPlayNotebookSnippet: (stateProps) =>
    trackEvent(EVENT_TYPES.NOTEBOOK_SNIPPET_RAN, {
      mode: notebooksRanMode.EDIT,
      isAdmin: stateProps.isAdmin,
      isContributor: stateProps.isContributor
    })
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onFileUpload: (file) =>
    dispatchProps.onFileUpload(file, {
      context: stateProps.context,
      groupId: stateProps.asset.groupId,
      assetId: stateProps.asset.assetId,
      version: stateProps.asset.version
    }),
  onOpenDeleteModal: () => {
    const { asset, page } = stateProps;
    const onDeletePage = () =>
      dispatchProps.onDeletePage({
        type: asset.type,
        organizationId: asset.organization.id,
        groupId: asset.groupId,
        assetId: asset.assetId,
        version: asset.version,
        minorVersion: asset.minorVersion,
        versionGroup: asset.versionGroup,
        pagePath: page.path
      });

    dispatchProps.onOpenDeleteModal(stateProps.page, onDeletePage);
  },
  onPlayNotebook: () => dispatchProps.onPlayNotebook(stateProps),
  onPlayNotebookSnippet: () => dispatchProps.onPlayNotebookSnippet(stateProps)
});

export default compose(
  withAnalytics,
  withModal,
  withRouter,
  withRouterActions,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(StatefulPageEdit);
