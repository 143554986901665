import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import * as apiGroupActions from '@mulesoft/exchange-assets-definitions/lib/api-group/store/actions';
import { withAnalytics } from '@mulesoft/exchange-react-hooks';
import ApiGroupVisibilityToggle from './ApiGroupVisibilityToggle';

const mapStateToProps = (state) => ({
  isUpdatingVisibility: apiGroupSelectors.isUpdatingVisibility(state)
});

const mapDispatchToProps = (dispatch, { trackEvent }) => ({
  onVisibilityChange: (params) => {
    dispatch(apiGroupActions.updateAPIGroupInstance(params, trackEvent));
  }
});

export default compose(
  withAnalytics,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ApiGroupVisibilityToggle);
