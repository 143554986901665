import * as localStorageUtils from '~/utils/localStorage';

const LOCAL_STORAGE_KEY = 'assetsLimitWarningOpen';
const LIMIT_ALMOST_REACHED_PERCENTAGE = 0.8;
const assetsLimitWarningOpenState =
  localStorageUtils.storageFactory(LOCAL_STORAGE_KEY);

export function isAssetsLimitWarningVisible(profile) {
  if (!global.localStorage || !profile) {
    return false;
  }

  const currentWarningState = getState(profile);
  const { currentLimitState, currentUser } = currentWarningState;
  const newLimitState = calculateLimitState(profile);

  if (currentLimitState !== newLimitState || currentUser !== profile.id) {
    const warningState = {
      isWarningVisible: true,
      currentUser: profile.id,
      currentLimitState: newLimitState
    };

    assetsLimitWarningOpenState.set(warningState);

    return true;
  }

  return currentWarningState.isWarningVisible;
}

export function changeAssetsLimitWarningVisibility(
  profile,
  isWarningVisible = false
) {
  if (!global.localStorage || !profile) {
    return;
  }

  const currentWarningState = getState(profile);
  const warningState = {
    isWarningVisible,
    currentUser: currentWarningState.currentUser,
    currentLimitState: currentWarningState.currentLimitState
  };

  assetsLimitWarningOpenState.set(warningState);
}

export function getWarningMessage(profile) {
  if (!profile) {
    return '';
  }

  const { assetsLimit } = profile.organization;

  if (isAssetsLimitReached(assetsLimit)) {
    return `Your organization has reached its limit of ${assetsLimit.assetCountLimit} asset versions in Exchange. Please contact your administrator to increase the limit.`;
  }

  if (isAssetsLimitOverWarning(assetsLimit)) {
    return `Your organization is approaching its limit of ${
      assetsLimit.assetCountLimit
    } asset versions in Exchange. You have ${
      assetsLimit.assetCountLimit - assetsLimit.assetCount
    } left. Please contact your administrator to increase the limit.`;
  }

  return '';
}

export function isAssetsLimitOverWarning(assetsLimit) {
  return (
    assetsLimit.assetCount >=
    assetsLimit.assetCountLimit * LIMIT_ALMOST_REACHED_PERCENTAGE
  );
}

export function isAssetsLimitReached(assetsLimit) {
  return assetsLimit.assetCount >= assetsLimit.assetCountLimit;
}

function getState(profile) {
  const currentWarningState = assetsLimitWarningOpenState.get();

  if (!currentWarningState) {
    const currentLimitState = calculateLimitState(profile);
    const warningState = {
      isWarningVisible: true,
      currentUser: profile.id,
      currentLimitState
    };

    assetsLimitWarningOpenState.set(warningState);

    return warningState;
  }

  return currentWarningState;
}

function calculateLimitState(profile) {
  const { assetsLimit } = profile.organization;

  if (isAssetsLimitReached(assetsLimit)) {
    return 'LIMIT_REACHED';
  }

  if (isAssetsLimitOverWarning(assetsLimit)) {
    return 'LIMIT_ALMOST_REACHED';
  }

  return 'LIMIT_SAFE';
}
