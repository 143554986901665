import { connect } from 'react-redux';
import { compose } from 'redux';
import { setAnnouncement } from '~/components/Announcements/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as localStorageActions } from '~/domains/localStorage';
import { selectors as searchSelectors } from '~/domains/search';
import ANGTemporarilyDisabled from './ANGTemporarilyDisabled';

const TEMP_DISABLED_BANNER_LAST_TIME_SHOWN_KEY =
  'lastTimeANGTemporarilyDisabledShown';

const condition = searchSelectors.isANGTemporarilyDisabledBannerVisible;

const mapDispatchToProps = (dispatch) => ({
  onClose: () =>
    dispatch(
      localStorageActions.setItem(
        TEMP_DISABLED_BANNER_LAST_TIME_SHOWN_KEY,
        Date.now()
      )
    )
});

export default compose(
  setAnnouncement(condition),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(ANGTemporarilyDisabled);
