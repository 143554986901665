/**
 * Applies a new format to date arguments
 *
 * @example formatDate('2016-07-12T15:08:43.143Z') // 'July 12, 2016'
 * @param date The date to be converted
 * @param format The format for the date
 * @returns A string with the following format: '{Month Name} {Date Number}, {Year}'
 */
export function formatDate(
  date: string | number | Date,
  format: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
): string {
  const newDate = new Date(date);

  if (newDate.toString() === 'Invalid Date') {
    return 'Invalid Date';
  }

  return newDate.toLocaleDateString('en-US', format);
}
