import React from 'react';
import PageEdit from '~/components/AssetDetail/PageEdit';
import AssetBaseLayout from '../AssetBaseLayout';

class PageEditLayout extends AssetBaseLayout {
  renderComponent() {
    return <PageEdit />;
  }
}

export default PageEditLayout;
