import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import PlusIcon from '@mulesoft/anypoint-icons/lib/svg/plus-small.svg';
import { RefType } from '@mulesoft/exchange-react-shapes';
import styles from './AddPillButton.css';

export const AddPillButton = React.memo(
  ({ innerRef, className, 'data-test-id': testId, children, ...props }) => (
    <button
      ref={innerRef}
      data-test-id={`${testId}-button`}
      className={classNames(className, styles.button)}
      type="button"
      {...props}
    >
      <Icon size="xs">
        <PlusIcon />
      </Icon>
      <span data-test-id={`${testId}-span`}>{children}</span>
    </button>
  )
);

AddPillButton.propTypes = {
  innerRef: RefType,
  className: PropTypes.string,
  'data-test-id': PropTypes.string,
  children: PropTypes.node
};

const AddPillButtonWithRef = (props, ref) => {
  return <AddPillButton innerRef={ref} {...props} />;
};

export default React.forwardRef(AddPillButtonWithRef);
