import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import {
  actions as modalActions,
  selectors as modalSelectors
} from '~/domains/modal';
import ModalView from './ModalView';

const mapStateToProps = (state) => ({
  modalStack: modalSelectors.modalStack(state),
  targetElementAfterClose: modalSelectors.targetElementAfterClose(state)
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(modalActions.closeModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  context: RootStoreContext
})(ModalView);
