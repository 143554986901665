import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { RefType } from '@mulesoft/exchange-react-shapes';
import Star from './Star';
import styles from './RatingSelector.css';

// Key codes
const LEFT_ARROW_KEY_CODE = 37;
const RIGHT_ARROW_KEY_CODE = 39;

// We use a 0 star to make the component more accessible
const HIGHEST_RATING = 5;
const TOTAL_STARS = HIGHEST_RATING + 1;

// We reverse the star order to allow hover effect
const STARS = Array.from({ length: TOTAL_STARS }, (_, i) => i).reverse();

export class RatingSelector extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    innerRef: RefType,
    onChange: PropTypes.func,
    rating: PropTypes.number,
    testId: PropTypes.string
  };

  static defaultProps = {
    onChange: () => {},
    rating: 0
  };

  handleStarClick = (rating) => {
    this.props.onChange(rating);
  };

  handlePressLeftArrow = (event) => {
    event.preventDefault();

    const { rating } = this.props;

    if (rating === 0) {
      return;
    }

    this.props.onChange(rating - 1);
  };

  handlePressRightArrow = () => {
    const { rating } = this.props;

    if (rating === HIGHEST_RATING) {
      return;
    }

    this.props.onChange(rating + 1);
  };

  handleKeyDown = (event) => {
    if (event.keyCode === LEFT_ARROW_KEY_CODE) {
      this.handlePressLeftArrow(event);
    } else if (event.keyCode === RIGHT_ARROW_KEY_CODE) {
      this.handlePressRightArrow(event);
    }
  };

  render() {
    const { id, innerRef, className, testId, rating } = this.props;

    return (
      <span
        id={id}
        ref={innerRef}
        role="radiogroup"
        aria-label="Edit rating"
        className={classNames(className, styles.rating)}
        data-test-id={testId}
        tabIndex="0"
        onKeyDown={this.handleKeyDown}
      >
        {STARS.map((starValue) => (
          <Star
            key={starValue}
            className={classNames({
              'visually-hidden': starValue === 0
            })}
            value={starValue}
            outOf={TOTAL_STARS}
            selected={rating === starValue}
            onClick={this.handleStarClick}
          />
        ))}
      </span>
    );
  }
}

const RatingSelectorWithRef = (props, ref) => {
  return <RatingSelector innerRef={ref} {...props} />;
};

export default React.forwardRef(RatingSelectorWithRef);
