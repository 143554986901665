const getOrganization = (state, organizationId) =>
  state.searches.data[organizationId];

export const isFetchingUserSavedSearches = (state) =>
  state.searches.userList.isFetching;
export const hasAllUserSavedSearches = (state) =>
  state.searches.userList.hasAll;
export const userSavedSearches = (state) => state.searches.userList.searches;
export const hasAllSavedSearches = (state, organizationId) =>
  getOrganization(state, organizationId) &&
  getOrganization(state, organizationId).hasAll;
export const savedSearches = (state, organizationId) =>
  getOrganization(state, organizationId) &&
  getOrganization(state, organizationId).searches;
export const isFetchingSavedSearches = (state, organizationId) =>
  getOrganization(state, organizationId) &&
  getOrganization(state, organizationId).isFetching;
