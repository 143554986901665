import { sanitizePayload } from '~/analytics/payload';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '~/domains/assets';
import {
  actions as commonActions,
  selectors as commonSelectors
} from '~/domains/common';
import { actions as customizationActions } from '~/domains/customization';
import { parseSearch } from '~/utils/location';

const resolve = async ({
  dispatch,
  getState,
  params,
  location,
  isDraft = false,
  helpers: { trackEvent, assetTypesConfiguration }
}) => {
  const state = getState();
  const query = parseSearch(location.search);
  const mergedQuery = { ...params, ...query };

  if (commonSelectors.hasHomeQueryChanged(state, { query })) {
    await dispatch(
      assetsActions.getPortalAssets({
        query: mergedQuery,
        assetTypesConfiguration
      })
    );
  }

  await dispatch(commonActions.setHomeQuery(query));
  await dispatch(customizationActions.setCustomizationHeader({ isDraft }));
  trackSearchExecuted({ getState, trackEvent, query: mergedQuery });
};

function trackSearchExecuted({ getState, trackEvent, query }) {
  const newState = getState();
  const assets = assetsSelectors.listAssets(newState);
  const resultCount = assets.length;

  trackEvent('Public portal search executed', {
    query: sanitizePayload(query),
    resultCount
  });
}

export default resolve;
