import PropTypes from 'prop-types';
import React from 'react';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Links from '~/components/Layout/Sidebar/Links';
import { getActiveOrganization } from '~/utils/organization';
import { isAdminOfSomeOrganization } from '~/utils/validations/profile';
import { getLinks } from '~/utils/navigation';
import { getPath } from '~/utils/routes';
import SavedSearches from './SavedSearches';
import styles from './NavigationSidebar.css';
import PublishButton from '../Home/PublishButton';

export default class NavigationSidebar extends React.PureComponent {
  static propTypes = {
    isInitialized: PropTypes.bool,
    isStudio: PropTypes.bool,
    isPublicPortalEnabled: PropTypes.bool,
    isReuseMetricsEnabled: PropTypes.bool,
    isANGSearchEnabled: PropTypes.bool,
    isANGSearchOptInVisible: PropTypes.bool,
    isANGSearchOptOutVisible: PropTypes.bool,
    onShowANGSearchOptIn: PropTypes.func,
    onShowANGSearchOptOut: PropTypes.func,
    onActivateANGSearch: PropTypes.func,
    onDeactivateANGSearch: PropTypes.func,
    profile: PropTypes.object,
    location: PropTypes.object,
    presetLinkToEnabledMap: PropTypes.object
  };

  static defaultProps = {
    isStudio: false,
    isPublicPortalEnabled: true,
    isANGSearchEnabled: false,
    isReuseMetricsEnabled: false,
    isANGSearchOptInVisible: false,
    onShowANGSearchOptIn: () => {},
    onShowANGSearchOptOut: () => {},
    onActivateANGSearch: () => {},
    presetLinkToEnabledMap: {}
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isInitialized && this.props.isInitialized) {
      const {
        isANGSearchOptInVisible,
        isANGSearchOptOutVisible,
        onShowANGSearchOptIn,
        onShowANGSearchOptOut
      } = this.props;
      const isHomePage = this.isHomePage();
      const showANGSearchOptIn = isHomePage && isANGSearchOptInVisible;
      const showANGSearchOptOut = isHomePage && isANGSearchOptOutVisible;

      if (showANGSearchOptIn) {
        onShowANGSearchOptIn();
      }

      if (showANGSearchOptOut) {
        onShowANGSearchOptOut();
      }
    }
  }

  getNavigationLinks = () => {
    const { profile, isStudio, isPublicPortalEnabled, isReuseMetricsEnabled } =
      this.props;

    if (!isStudio && profile) {
      const navigationLinks = [];

      if (isReuseMetricsEnabled && isAdminOfSomeOrganization(profile)) {
        navigationLinks.push({
          name: 'Asset engagement',
          to: getPath('metrics')
        });
      }

      navigationLinks.push({
        name: 'My applications',
        to: getPath('applications')
      });

      if (isPublicPortalEnabled) {
        navigationLinks.push({
          name: 'Public portal',
          href: getPath('publicPortal', { organization: profile.organization }),
          target: '_blank'
        });
      }

      if (profile.organization.isExchangeAdmin) {
        navigationLinks.push({
          name: 'Settings',
          to: getPath('settings')
        });
      }

      return navigationLinks;
    }

    return [];
  };

  isHomePage = () => {
    const { location } = this.props;

    return getPath('home') === location.pathname;
  };

  render() {
    const {
      isANGSearchEnabled,
      isANGSearchOptInVisible,
      isANGSearchOptOutVisible,
      presetLinkToEnabledMap,
      profile,
      location,
      onActivateANGSearch,
      onDeactivateANGSearch
    } = this.props;
    const links = getLinks(
      profile,
      location,
      isANGSearchEnabled,
      presetLinkToEnabledMap
    );
    const navigationLinks = this.getNavigationLinks();
    const activeOrganization = getActiveOrganization(profile);
    const isHomePage = this.isHomePage();

    return (
      <nav data-test-id="navigation-sidebar" aria-label="Home">
        <PublishButton />
        <Links id="asset-filters" items={links} testId="home-links" />
        <SavedSearches
          display-if={isHomePage}
          id="organization-saved-searches"
          testId="home-bg-saved-searches"
          title={`${activeOrganization && activeOrganization.name} searches`}
          type="organization"
        />
        <SavedSearches
          display-if={isHomePage}
          id="user-saved-searches"
          testId="home-user-saved-searches"
          title="My searches"
          type="user"
        />
        <div id="other-links" display-if={navigationLinks.length > 0}>
          <hr display-if={isHomePage} />
          <Links items={navigationLinks} testId="navigation-links" />
        </div>
        <Button
          className={styles.newSearch}
          testId="activate-ang-search"
          title="Return to enhanced search"
          display-if={isHomePage && isANGSearchOptInVisible}
          kind="secondary"
          noFill
          onClick={onActivateANGSearch}
        >
          Return to enhanced search
        </Button>
        <Button
          className={styles.newSearch}
          testId="deactivate-ang-search"
          title="Back to legacy search"
          display-if={isHomePage && isANGSearchOptOutVisible}
          kind="secondary"
          noFill
          onClick={onDeactivateANGSearch}
        >
          Back to legacy search
        </Button>
      </nav>
    );
  }
}
