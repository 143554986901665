import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ActionModal } from '~/components/Modals';

class UnauthorizedModal extends PureComponent {
  static propTypes = {
    onRelogin: PropTypes.func
  };

  renderBody() {
    return (
      <p>
        Your session has expired.
        <br />
        To complete your action, please log in again.
      </p>
    );
  }

  render() {
    const { onRelogin } = this.props;

    return (
      <ActionModal
        title="Session has expired"
        body={this.renderBody()}
        buttonText="Log in"
        onButtonClick={onRelogin}
      />
    );
  }
}

export default UnauthorizedModal;
