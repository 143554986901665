import React from 'react';
import { useGetPath } from '@mulesoft/exchange-react-hooks';
import ErrorPage from '~/components/ErrorPage';
import { getDefaultCards } from '../utils';

const UnhandledClientError = () => {
  const getPath = useGetPath();

  return (
    <ErrorPage
      title="Something went wrong"
      description="Please reload the page and try again. If the problem persists, contact customer support."
      cards={getDefaultCards(getPath)}
    />
  );
};

export default UnhandledClientError;
