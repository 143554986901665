import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withConfig } from '@mulesoft/exchange-react-hooks';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { withAssetFromRootStore } from '~/contexts/RootStoreContext';
import AssetDetailLayout from '~/layouts/common/AssetDetail/AssetDetailLayout';
import resolver from '~/layouts/common/AssetDetail/AssetDetailLayout.resolver';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import async from '~/utils/async';
import * as routesUtils from '~/utils/routes';
import * as urlUtils from '~/utils/url';

const mergeParams = memoize((params, status) => ({
  ...params,
  status
}));

const mapStateToProps = (
  state,
  { location, route, config, assetFromRootStore }
) => {
  const currentRoute = routesUtils.getCurrentRoute(route, location);
  const {
    route: { id: routeId, isDraft },
    params: routeParams
  } = currentRoute;
  const status = urlUtils.getQueryParam(location, 'status')[0];
  const params = mergeParams(routeParams, status);
  const isEdit = routeId === 'pageEdit';
  const asset = assetsSelectors.asset(state, params) || assetFromRootStore;
  const profile = sessionSelectors.profile(state);
  const isContentLoading = portalsSelectors.isPortalLoading(state);
  const isPageComponent = routeId === 'page' || isEdit;
  const isHomePage =
    isPageComponent &&
    (!params.pagePath || params.pagePath === config.defaultPageName);
  const isAdmin = assetsSelectors.canAdmin(state, params);
  const isContributor = assetsSelectors.canEdit(state, params);
  const isTypeLoaded =
    !!asset?.type && reducerManagerSelectors.isTypeLoaded(state, asset.type);

  if (!asset) {
    return {};
  }

  const minorVersions = assetsSelectors.assetMinorVersions(state, asset);

  return {
    asset,
    assetContext: 'private',
    profile,
    isDraft,
    isEdit,
    isHomePage,
    isPageComponent,
    isTypeLoaded,
    minorVersions,
    isContentLoading,
    isAdmin,
    isContributor,
    status
  };
};

export default compose(
  async(resolver),
  withAssetFromRootStore,
  withRouter,
  withConfig,
  connect(mapStateToProps)
)(AssetDetailLayout);
