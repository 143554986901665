import App from '~/components/App';
import NotFound from '~/components/NotFound';

import CustomLayout from '~/layouts/common/Custom';
import Home from '~/layouts/private/Home';
import { CreateAsset, CreateAssetVersion } from '~/layouts/private/CreateAsset';
import ClientApplicationList from '~/layouts/private/ClientApplicationList';
import ClientApplicationDetail from '~/layouts/private/ClientApplicationDetail';
import AssetMetrics from '~/layouts/private/AssetDetail/Metrics';
import TopRankMetrics from '~/layouts/private/TopRankMetrics';
import Settings from '~/layouts/private/Settings';
import AssetDetail from '~/layouts/private/AssetDetail';
import Page from '~/layouts/private/AssetDetail/Page';
import PageEdit from '~/layouts/private/AssetDetail/PageEdit';
import APIGroupDetail from '~/layouts/private/APIGroupDetail';

import { redirect } from '~/routes/utils';

export default (context) => {
  const versionGroupPath = `${context.path}/:groupId/:assetId/version/:versionGroup/`;
  const minorVersionPath = `${context.path}/:groupId/:assetId/minor/:minorVersion/`;
  const versionPath = `${context.path}/:groupId/:assetId/:version([0-9]+\\.[0-9]+\\.[0-9]+[a-zA-Z0-9\\-\\.]*)/`;

  const getPageRoutes = (basePath) => [
    {
      name: 'Asset Portal',
      path: basePath,
      exact: true,
      id: 'page',
      component: Page
    },
    {
      name: 'Asset Portal',
      path: `${basePath}pages/:pagePath+/`,
      exact: true,
      component: Page,
      id: 'page'
    }
  ];
  const getPortalRoutes = (basePath, props = {}) => [
    {
      name: 'Asset API Group detail',
      path: `${basePath}detail/`,
      exact: true,
      component: APIGroupDetail,
      id: 'apiGroup',
      ...props
    },
    {
      name: 'Metrics',
      path: `${basePath}metrics/`,
      exact: true,
      component: AssetMetrics,
      id: 'metrics',
      ...props
    }
  ];

  const getAssetDetailRoutes = (basePath, includeRoot) => {
    const result = [
      {
        path: `${basePath}draft/edit/:pagePath+/`,
        name: 'Asset Detail Edit Draft',
        id: 'assetDetailEditDraft',
        component: AssetDetail,
        isDraft: true,
        isEdit: true,
        routes: [
          {
            path: `${basePath}draft/edit/:pagePath+/`,
            name: 'Asset Portal Edit Draft',
            exact: true,
            component: PageEdit,
            id: 'pageEdit',
            isDraft: true
          }
        ]
      },
      {
        path: `${basePath}draft/pages/:pagePath+/`,
        name: 'Asset Detail Draft',
        id: 'assetDetailViewDraft',
        component: AssetDetail,
        isDraft: true,
        routes: [
          {
            path: `${basePath}draft/pages/:pagePath+/`,
            name: 'Asset Portal Draft',
            exact: true,
            component: Page,
            isDraft: true,
            id: 'page'
          }
        ]
      },
      {
        path: `${basePath}draft/`,
        name: 'Asset Detail Draft',
        id: 'assetDetailDraft',
        component: AssetDetail,
        isDraft: true,
        routes: [
          ...getPortalRoutes(`${basePath}draft/`, { isDraft: true }),
          {
            path: `${basePath}draft/`,
            component: CustomLayout,
            name: 'Custom Draft',
            id: 'customDraft',
            isPublicPortal: false,
            isDraft: true
          }
        ]
      },
      {
        path: basePath,
        name: 'Asset Detail',
        id: 'assetDetail',
        component: AssetDetail,
        routes: [
          ...getPageRoutes(basePath),
          ...getPortalRoutes(basePath),
          {
            path: basePath,
            component: CustomLayout,
            isPublicPortal: false,
            name: 'Custom',
            isDraft: false
          }
        ]
      },
      ...(includeRoot
        ? [
            {
              path: `${context.path}/:groupId/:assetId/`,
              name: 'Asset Detail',
              id: 'assetDetail',
              component: AssetDetail,
              routes: [
                {
                  path: `${context.path}/:groupId/:assetId/`,
                  exact: true,
                  name: 'Asset Portal',
                  id: 'page',
                  component: Page
                }
              ]
            }
          ]
        : [])
    ];

    function setSectionId(routesList) {
      routesList.forEach((route) => {
        // eslint-disable-next-line no-param-reassign
        route.sectionId = 'assetPortal';

        if (route.routes) {
          setSectionId(route.routes);
        }
      });

      return routesList;
    }

    return setSectionId(result);
  };

  const routes = [
    {
      component: App,
      path: `${context.path}/`,
      routes: [
        {
          path: `${context.path}/`,
          exact: true,
          name: 'Home',
          id: 'home',
          component: Home
        },
        {
          name: 'Create Asset',
          path: `${context.path}/create/`,
          component: CreateAsset,
          id: 'createAsset'
        },
        {
          name: 'Create Asset Version',
          path: `${context.path}/:groupId/:assetId/versions/create/`,
          component: CreateAssetVersion,
          id: 'newVersion'
        },
        {
          path: `${context.path}/metrics/`,
          exact: true,
          name: 'TopRankMetrics',
          id: 'topRankMetrics',
          component: TopRankMetrics
        },
        {
          path: `${context.path}/applications/`,
          exact: true,
          name: 'Client Applications List',
          id: 'applications',
          sectionId: 'applications',
          component: ClientApplicationList
        },
        {
          path: `${context.path}/applications/:applicationId/`,
          exact: true,
          name: 'Client Applications Detail',
          id: 'applicationDetail',
          sectionId: 'applications',
          component: ClientApplicationDetail
        },
        {
          path: `${context.path}/settings/`,
          exact: true,
          name: 'Settings Home',
          id: 'settings',
          sectionId: 'settings',
          component: Settings
        },
        {
          path: `${context.path}/settings/add/`,
          exact: true,
          name: 'Settings Add',
          id: 'settingsAdd',
          sectionId: 'settings',
          isAdd: true,
          component: Settings
        },
        {
          path: `${context.path}/settings/:categoryId/edit/`,
          exact: true,
          name: 'Settings Edit',
          id: 'settingsEdit',
          sectionId: 'settings',
          isEdit: true,
          component: Settings
        },
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/settings/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/settings/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/console/summary/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/console/summary/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/draft/console/method/:modelId/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/draft/console/method/:modelId/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/console/:modelType/:modelId/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/console/:modelType/:modelId/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/minor/:minorVersion/policy/usages/`,
          to: `${context.path}/:groupId/:assetId/minor/:minorVersion/usages/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/pages/:pagePath+/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/pages/:pagePath+/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/draft/pages/:pagePath+/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/draft/pages/:pagePath+/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/api/:versionGroup/draft/edit/:pagePath+/`,
          to: `${context.path}/:groupId/:assetId/version/:versionGroup/draft/edit/:pagePath+/`
        }),
        ...getAssetDetailRoutes(versionGroupPath),
        ...getAssetDetailRoutes(versionPath),
        ...getAssetDetailRoutes(minorVersionPath, true),
        {
          path: `${context.path}/`,
          component: NotFound
        }
      ]
    }
  ];

  return routes;
};
