import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { isAdminOfSomeOrganization } from '~/utils/validations/profile';

const resolve = async ({ getState }) => {
  const state = getState();
  const isReuseMetricsEnabled = sessionSelectors.isMetricsEnabled(state);
  const profile = sessionSelectors.profile(state);

  if (!isReuseMetricsEnabled || !isAdminOfSomeOrganization(profile)) {
    const error = new Error('Page not enabled');

    error.status = 404;

    throw error;
  }
};

export default resolve;
