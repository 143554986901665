import React from 'react';
import EditCustomPage from '~/components/Customization/EditCustomPage';
import BaseCustomizationLayout from '../BaseCustomizationLayout';

class EditCustomPageLayout extends BaseCustomizationLayout {
  renderMain() {
    const { route } = this.props;

    return <EditCustomPage route={route} />;
  }
}

export default EditCustomPageLayout;
