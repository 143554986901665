import { fetch, defaultHeaders } from '~/utils/fetch';

const noHeaders = Object.keys(defaultHeaders).reduce(
  (acum, current) => ({
    ...acum,
    [current]: null
  }),
  {}
);

export async function fetchAPIZip(url) {
  const response = await fetch(url, {
    headers: noHeaders
  });
  const blob = await response.blob();

  return new Blob([blob], { type: 'application/zip' }); // force content type since it's not being set currently
}
