import { LOCATION_CHANGE } from 'connected-react-router';
import * as actions from './actionTypes';

const initialState = {
  fetchingFacetsErrorDate: null,
  facetsHasBeenRefreshed: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FACETS: {
      return {
        ...state,
        facets: action.facets,
        fetchingFacetsErrorDate: null,
        facetsHasBeenRefreshed: true
      };
    }

    case actions.FETCHING_FACETS_ERROR: {
      return {
        ...state,
        fetchingFacetsErrorDate: Date.now(),
        facetsHasBeenRefreshed: false,
        rootOrganizations: null
      };
    }

    case actions.UPDATE_FACETS: {
      return {
        ...state,
        facets: { ...state.facets, ...action.facets },
        fetchingFacetsErrorDate: null,
        facetsHasBeenRefreshed: false
      };
    }

    case actions.SET_ROOT_ORGANIZATIONS: {
      return {
        ...state,
        rootOrganizations: action.rootOrganizations
      };
    }

    case LOCATION_CHANGE: {
      // handles initial load on the client
      if (!state.latestURL) {
        return {
          ...state,
          latestURL: action.payload.pathname
        };
      }

      if (action.payload.pathname !== state.latestURL) {
        return {
          ...state,
          latestURL: action.payload.pathname
        };
      }

      return state;
    }

    default:
      return state;
  }
}
