import React from 'react';
import { useStore } from 'react-redux';
import {
  useConfig,
  useDynamicScript,
  useGetPath,
  usePush,
  useAnalyticsTracking,
  useSetDocumentMeta,
  useFeatureFlag,
  useFeatureFlags
} from '@mulesoft/exchange-react-hooks';
import {
  ASSETS_DEFINITIONS_VERSION,
  FEDERATED_ASSETS_DEFINITIONS_ENABLED
} from '~/domains/featureFlags/keys';
import { loadReducers } from '~/portals/store/reducerManager/actions';

const noCachingQuery = `?v=${Date.now()}`;
const portalConfigurationCache = {};

async function loadComponent(scope, module) {
  await __webpack_init_sharing__('default');

  const container = window[scope];

  // eslint-disable-next-line camelcase
  await container.init(__webpack_share_scopes__.default);

  const factory = await window[scope].get(module);
  const Module = factory();

  return Module;
}

export const usePortalsConfiguration = ({ asset, route, disableEvents }) => {
  const config = useConfig();
  const { definitions } = config;
  const version = useFeatureFlags()[ASSETS_DEFINITIONS_VERSION];
  const isFederatedDefinitionsEnabled = useFeatureFlag(
    FEDERATED_ASSETS_DEFINITIONS_ENABLED
  );
  const typeVersion = useFeatureFlag(`${asset.type}-version`);
  const noCacheQuery = version === 'dev' ? noCachingQuery : '';
  const { ready, failed } = useDynamicScript(
    `${
      isFederatedDefinitionsEnabled
        ? definitions.federatedCDNPath
        : definitions.exchangeCDNPath
    }/${isFederatedDefinitionsEnabled ? typeVersion || version : version}/${
      definitions.filename
    }${noCacheQuery}`
  );
  const [portalConfiguration, setPortalConfiguration] = React.useState(
    portalConfigurationCache[asset.type] ?? null
  );
  const [isPrepared, setIsPrepared] = React.useState(false);
  const [portalConfigurationFailed, setPortalConfigurationFailed] =
    React.useState(false);
  const store = useStore();
  const getPath = useGetPath();
  const push = usePush();
  const trackEvent = useAnalyticsTracking();
  const setDocumentMeta = useSetDocumentMeta();

  const helpers = React.useMemo(
    () => ({
      getPath,
      push,
      trackEvent,
      setDocumentMeta
    }),
    [getPath, push, trackEvent, setDocumentMeta]
  );

  React.useEffect(() => {
    if (ready && !portalConfiguration) {
      loadComponent('assets_definitions', `./${asset.type}-portal`)
        .then((result) => {
          setPortalConfiguration(result);
          portalConfigurationCache[asset.type] = result;
        })
        .catch(() => setPortalConfigurationFailed(true));
    }
  }, [ready, asset.type, portalConfiguration]);

  React.useEffect(() => {
    async function prepare() {
      const { onPrepare } = portalConfiguration.default;

      if (!onPrepare) {
        setIsPrepared(true);

        return;
      }

      try {
        await onPrepare({
          asset,
          isDraft: route.isDraft,
          isEdit: route.isEdit,
          helpers,
          config
        });

        setIsPrepared(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    if (!portalConfiguration?.default || isPrepared || disableEvents) {
      return;
    }

    if (asset.isCompleted) {
      prepare();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    asset.groupId,
    asset.assetId,
    asset.isCompleted,
    portalConfiguration,
    disableEvents
  ]);

  React.useEffect(() => {
    if (disableEvents) {
      return;
    }

    if (asset.isCompleted && portalConfiguration && isPrepared) {
      if (!portalConfiguration?.default) {
        // leaving this to troubleshoot a weird bug with federated modules
        // eslint-disable-next-line no-console
        console.error(
          'Failed to retrieve portalConfiguration',
          portalConfiguration
        );

        return;
      }

      const onLoad = store.dispatch(
        loadReducers({
          asset,
          reducerManager: store.reducerManager,
          portalConfiguration
        })
      );

      if (onLoad) {
        store.dispatch(
          onLoad({
            asset,
            isDraft: route.isDraft,
            isEdit: route.isEdit,
            helpers,
            config
          })
        );
      }
    }
  }, [
    asset,
    portalConfiguration,
    config,
    helpers,
    store,
    route,
    isPrepared,
    disableEvents
  ]);

  return {
    portalConfiguration,
    failed: failed || portalConfigurationFailed
  };
};
