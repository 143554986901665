import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mulesoft/anypoint-components/lib/Button';
import styles from './PublishButton.css';

const PublishButton = ({ canCreateAsset, getPath, onClickNewAsset }) => {
  const buttonStyles = Button.themes.DefaultTheme;

  if (!canCreateAsset) {
    return null;
  }

  return (
    <div className={styles.publish}>
      <Link
        id="create-asset"
        to={getPath('createAsset')}
        data-test-id="create-button"
        onClick={onClickNewAsset}
        className={classNames(
          styles.createAsset,
          buttonStyles.button,
          buttonStyles.primary,
          buttonStyles.md
        )}
      >
        Publish new asset
      </Link>
    </div>
  );
};

PublishButton.propTypes = {
  canCreateAsset: PropTypes.bool,
  getPath: PropTypes.func,
  onClickNewAsset: PropTypes.func
};

export default PublishButton;
