import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '~/components/ErrorPage';
import { getDefaultCards } from '../utils';

const TooManyRequestPage = ({ getPath }) => {
  return (
    <ErrorPage
      title="429 Too Many Request"
      description="Try again after 1 minute."
      cards={getDefaultCards(getPath)}
    />
  );
};

TooManyRequestPage.propTypes = {
  getPath: PropTypes.func.isRequired
};

export default TooManyRequestPage;
