import config from 'config';
import React from 'react';
import PropTypes from 'prop-types';
import Filter from '@mulesoft/anypoint-components/lib/Filter';
import { AssetTypesConfigurationContext } from '@mulesoft/exchange-react-hooks';
import * as arrayUtils from '~/utils/arrays';
import { getName } from '~/utils/types';
import ViewSwitcher from './ViewSwitcher';
import SearchBox from './SearchBox';
import filterTheme from './Filter.css';
import styles from './Search.css';

class Search extends React.Component {
  static propTypes = {
    onGetAssets: PropTypes.func,
    onClearFilters: PropTypes.func,
    location: PropTypes.object,
    filterOptions: PropTypes.array,
    onSwitchView: PropTypes.func,
    onChangeSelectedType: PropTypes.func,
    studioType: PropTypes.string
  };

  static defaultProps = {
    onGetAssets: () => {},
    onClearFilters: () => {},
    filterOptions: [],
    onSwitchView: () => {},
    onChangeSelectedType: () => {}
  };

  static contextType = AssetTypesConfigurationContext;

  state = {
    selectedType: this.props.filterOptions.find((filter) => {
      const queryTypes = arrayUtils.convertToArray(
        this.props.location.query.type
      );

      return filter.keys.some((key) => queryTypes.some((type) => type === key));
    })
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      filterOptions,
      location: { query: previousQuery }
    } = this.props;
    const {
      location: { query: nextQuery }
    } = nextProps;

    // Allow type to be unset by query param change
    if (previousQuery.type && !nextQuery.type) {
      this.setState({
        selectedType: {}
      });
    }

    // if query type change, change the filter option
    if (nextQuery.type && previousQuery.type !== nextQuery.type) {
      const queryTypes = arrayUtils.convertToArray(nextQuery.type);

      this.setState({
        selectedType: filterOptions.find((filter) =>
          filter.keys.some((key) => queryTypes.some((type) => type === key))
        )
      });
    }
  }

  isListView = () => {
    const {
      location: { query }
    } = this.props;

    return query.view === 'list';
  };

  getFilterOptions = (filterOptions) =>
    filterOptions.map((option) => ({
      ...option,
      value: (
        <div>
          {option.value}{' '}
          <span className={styles.newTag} display-if={option.isNew}>
            New
          </span>
        </div>
      )
    }));

  handleChangeSelectedType = ({ value: selectedOption }) => {
    const { onChangeSelectedType } = this.props;

    this.setState({ selectedType: selectedOption }, () =>
      onChangeSelectedType(selectedOption)
    );
  };

  handleClearSelectedType = () => {
    const { onClearFilters } = this.props;

    this.handleChangeSelectedType({});
    onClearFilters();
  };

  render() {
    const { selectedType } = this.state;
    const {
      filterOptions,
      onGetAssets,
      onSwitchView,
      studioType,
      location: { query, pathname }
    } = this.props;

    const studioTypeName = getName(this.context, studioType);
    const maxLength = config.homeSearchMaxLength;

    return (
      <section
        id="asset-search"
        data-test-id="home-toolbar"
        className={styles.search}
        aria-label="Asset search"
      >
        <Filter
          testId="home"
          checkboxId="home"
          display-if={!studioType}
          className={styles.filter}
          theme={filterTheme}
          aria-label="Filter by asset type"
          options={this.getFilterOptions(filterOptions)}
          placeholder="All types"
          showClearButton={false}
          selectedOption={selectedType}
          onOptionChange={this.handleChangeSelectedType}
          onClear={this.handleClearSelectedType}
        />
        <span
          display-if={studioType}
          className={styles.perspective}
          data-test-id="home-perspective"
        >
          {studioTypeName}
        </span>
        <SearchBox
          ariaLabel="Search term"
          query={query}
          onSearch={onGetAssets}
          maxLength={maxLength}
          theme={this.isListView() ? 'white' : 'grey'}
        />
        <ViewSwitcher
          query={query}
          pathname={pathname}
          onSwitch={onSwitchView}
        />
      </section>
    );
  }
}

export default Search;
