import { compose } from 'redux';
import { connect } from 'react-redux';
import { EVENT_TYPES } from '~/analytics/events';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import { closeModal } from '~/domains/modal/actions';
import { actions as searchActions } from '~/domains/search';
import ANGOnboardingModal from './ANGOnboardingModal';

const mapDispatchToProps = (dispatch) => ({
  onConfirm: () => {
    dispatch(closeModal());
    dispatch(analyticsActions.trackEvent(EVENT_TYPES.ANG_SEARCH_OPT_IN));
    dispatch(searchActions.enableANGSearch());
  }
});

export default compose(
  registerModal,
  setModalType('ANGOnboardingModal'),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(ANGOnboardingModal);
