import config from 'config';
import memoize from 'memoize-one';
import { removeFilters } from '~/domains/assets/utils';
import * as objectsUtils from '~/utils/objects';
import { getActiveOrganization } from '~/utils/organization';
import { getPath } from '~/utils/routes';
import {
  ALL_ASSETS_LINK_ID,
  ALL_ORGANIZATION_LINK_ID,
  ACTIVE_ORGANIZATION_LINK_ID,
  MULESOFT_ORGANIZATION_LINK_ID,
  SHARED_WITH_ME_LINK_ID
} from './constants';

export const getAllAssetsLink = memoize(
  (query, isANGSearchEnabled, isHomePage) => {
    return {
      name: 'All assets',
      title: 'All assets',
      annotation: null,
      to: getPath('home'),
      query: {
        ...(isANGSearchEnabled ? null : removeFilters(query)),
        show: ALL_ASSETS_LINK_ID
      },
      isActive: isHomePage
    };
  }
);

export const getMasterOrganizationLink = memoize(
  (query, masterOrganization, isANGSearchEnabled) => {
    let isActive;
    let linkQuery;

    if (isANGSearchEnabled) {
      const hasNoQuery = Object.keys(query).length === 0;
      const show = ALL_ORGANIZATION_LINK_ID;

      linkQuery = { show };
      isActive = hasNoQuery || query.show === show;
    } else {
      const clearedQuery = removeFilters(query);

      linkQuery = clearedQuery;
      isActive = objectsUtils.shallowEquals(query, clearedQuery);
    }

    return {
      name: `All ${masterOrganization.name}`,
      title: `All ${masterOrganization.name} assets`,
      annotation: null,
      to: getPath('home'),
      query: linkQuery,
      isActive,
      hierarchy: 2
    };
  }
);

export const getActiveOrganizationLink = memoize(
  (query, profile, isANGSearchEnabled) => {
    const masterOrganization = profile.organization;
    const activeOrganization =
      getActiveOrganization(profile) || masterOrganization;
    const { name } = activeOrganization;
    const clearedQuery = removeFilters(query);
    const queryWithOrgId = {
      ...clearedQuery,
      organizationId: activeOrganization.id
    };
    const onlyOneBG = !masterOrganization.subOrganizationIds.length;
    const hasNoQuery = Object.keys(query).length === 0 && onlyOneBG;
    const isActive =
      (objectsUtils.shallowEquals(query, clearedQuery) && onlyOneBG) ||
      objectsUtils.shallowEquals(query, queryWithOrgId) ||
      hasNoQuery;
    const linkQuery = { show: ACTIVE_ORGANIZATION_LINK_ID };

    return {
      name,
      title: `${name} assets`,
      annotation:
        activeOrganization.isMaster || activeOrganization.isRoot
          ? '(root)'
          : null,
      to: getPath('home'),
      query: isANGSearchEnabled ? linkQuery : queryWithOrgId,
      isActive: isANGSearchEnabled
        ? query.show === linkQuery.show || hasNoQuery
        : isActive,
      hierarchy: 2
    };
  }
);

const getMuleSoftAuthenticatedLink = (query, isANGSearchEnabled) => {
  const mulesoftQuery = {
    ...removeFilters(query),
    organizationId: config.mulesoft.id
  };
  const show = MULESOFT_ORGANIZATION_LINK_ID;
  const featuredSortQuery = {
    sort: 'featured'
  };

  const isActive = isANGSearchEnabled
    ? query.show === show
    : objectsUtils.shallowEquals(query, mulesoftQuery);

  return {
    query: isANGSearchEnabled ? { show, ...featuredSortQuery } : mulesoftQuery,
    hierarchy: 2,
    isActive
  };
};

const getMuleSoftUnauthenticatedLink = (query, isANGSearchEnabled) => {
  const { show } = query;
  const featuredSortQuery = {
    sort: 'featured'
  };

  return {
    query: isANGSearchEnabled
      ? featuredSortQuery
      : objectsUtils.omit(query, 'show'),
    hierarchy: 1,
    isActive: !show || show === MULESOFT_ORGANIZATION_LINK_ID
  };
};

export const getMuleSoftLink = memoize((query, profile, isANGSearchEnabled) => {
  return {
    name: `Provided by ${config.mulesoft.name}`,
    title: `Assets provided by ${config.mulesoft.name}`,
    annotation: null,
    to: getPath('home'),
    ...(profile
      ? getMuleSoftAuthenticatedLink(query, isANGSearchEnabled)
      : getMuleSoftUnauthenticatedLink(query, isANGSearchEnabled))
  };
});

export const getPresetLink = (query, { id, name, title }) => {
  return {
    name,
    title,
    annotation: null,
    to: getPath('home'),
    query: {
      show: id,
      sort: 'featured'
    },
    hierarchy: 1,
    isActive: query.show === id
  };
};

export const getSharedWithMeLink = memoize((query, isANGSearchEnabled) => {
  const clearedQuery = removeFilters(query);
  const sharedWithMeQuery = {
    ...clearedQuery,
    sharedWithMe: true
  };
  const show = SHARED_WITH_ME_LINK_ID;

  return {
    name: 'Shared with me',
    title: 'Shared assets',
    annotation: null,
    to: getPath('home'),
    query: isANGSearchEnabled ? { show } : sharedWithMeQuery,
    isActive: isANGSearchEnabled ? query.show === show : !!query.sharedWithMe,
    hierarchy: 2
  };
});
