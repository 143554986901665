import announcementsEvents from './announcements';
import assetsEvents from './assets';
import categoriesEvents from './categories';
import applicationsEvents from './clientApplicationList';
import sessionEvents from './session';
import customizationEvents from './customization';
import homeEvents from './home';
import modalEvents from './modal';
import navbarEvents from './navbar';
import pagesEvents from './pages';
import notebooksEvents from './notebooks';
import publicPortal from './publicPortal';
import reviewsEvents from './reviews';
import savedSearchesEvents from './savedSearches';
import tagsEvents from './tags';
import redirectEvents from './redirect';
import searchEvents from './search';

export const EVENTS_SCHEMA = {
  ...announcementsEvents,
  ...assetsEvents,
  ...categoriesEvents,
  ...applicationsEvents,
  ...sessionEvents,
  ...customizationEvents,
  ...homeEvents,
  ...modalEvents,
  ...navbarEvents,
  ...pagesEvents,
  ...notebooksEvents,
  ...publicPortal,
  ...reviewsEvents,
  ...savedSearchesEvents,
  ...tagsEvents,
  ...redirectEvents,
  ...searchEvents
};

export const EVENT_TYPES = Object.keys(EVENTS_SCHEMA).reduce((acum, key) => {
  // eslint-disable-next-line no-param-reassign
  acum[key] = EVENTS_SCHEMA[key];

  return acum;
}, {});
