import { connect } from 'react-redux';
import LoginOptionsModal from '~/components/Modals/LoginOptionsModal';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import {
  actions as commonActions,
  selectors as commonSelectors
} from '~/domains/common';
import {
  actions as customizationActions,
  selectors as customizationSelectors
} from '~/domains/customization';
import CustomizeNavbar from './CustomizeNavbar';
import * as modalActions from '~/domains/modal/actions';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { ONE_TRUST_ENABLED } from '~/domains/featureFlags/keys';

const mapStateToProps = (state, { isEditMode }) => {
  const profile = commonSelectors.profile(state);
  const isOrgFederated = commonSelectors.isOrgFederated(state);
  const isVanityDomainSession = commonSelectors.isVanityDomainSession(state);
  const domain = customizationSelectors.organizationDomain(state);
  const organization = { domain };
  const canEditPortal = customizationSelectors.canEditPortalPage(state);
  const pages = isEditMode
    ? customizationSelectors.draftCustomPages(state)
    : customizationSelectors.publishedCustomPages(state);
  const cookieConsentId = customizationSelectors.cookieConsentId(state);
  const logo = customizationSelectors.logoImage(state, { isEditMode });
  const getPath = commonSelectors.getPortalPathFn(state);
  const isUserLoggedIn = !!profile && !!profile.id;
  const isOneTrustEnabled = featureFlagsSelectors.featureFlag(
    state,
    ONE_TRUST_ENABLED
  );
  const customization = {
    navbar: {
      backgroundColor: customizationSelectors.navbarBackgroundColor(state, {
        isEditMode
      }),
      textColor: customizationSelectors.navbarTextColor(state, { isEditMode }),
      textColorActive: customizationSelectors.navbarTextColorActive(state, {
        isEditMode
      })
    }
  };

  return {
    customization,
    cookieConsentId,
    canEdit: canEditPortal,
    getPath,
    pages,
    isEditMode,
    isOrgFederated,
    isOpenMobileMenu: customizationSelectors.isOpenMobileMenu(state),
    isVanityDomainSession,
    isOneTrustEnabled,
    profile,
    isUserLoggedIn,
    organization: { ...organization, logo }
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => {
    dispatch(analyticsActions.analyticsLogout());
    dispatch(commonActions.logout());
  },
  getOrganizationMetadata: (domain) =>
    dispatch(commonActions.getOrganizationMetadata(domain)),
  openLoginOptionsModal: () =>
    dispatch(
      modalActions.openModal({ modalType: LoginOptionsModal.modalType })
    ),
  openMobileMenu: () => dispatch(customizationActions.openMobileMenu()),
  closeMobileMenu: () => dispatch(customizationActions.closeMobileMenu())
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  context: RootStoreContext
})(CustomizeNavbar);
