import React, { useEffect } from 'react';
import { createDispatchHook } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as routesUtils from '~/utils/routes';
import { actions as analyticsActions } from '~/domains/analytics';
import RootStoreContext from '~/contexts/RootStoreContext';

const TRACK_DWELL_TIME = 'Analytics track dwell time';

const useDispatch = createDispatchHook(RootStoreContext);

export default (Component) =>
  function (props) {
    const location = useLocation();
    const dispatch = useDispatch();

    const {
      route,
      match
      // eslint-disable-next-line react/prop-types
    } = routesUtils.getCurrentRoute(props.route, location);

    const assetParams = routesUtils.getAssetParams(match, location);

    useEffect(() => {
      let startTime = Date.now();
      let dwellTime = 0;

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          startTime = Date.now();
        } else if (document.visibilityState === 'hidden') {
          const endTime = Date.now();

          dwellTime += endTime - startTime;
        }
      };

      const handleLeavePage = (isTimer = true) => {
        // Calculate the final dwell time when the user navigates away from the page
        const endTime = Date.now();
        const timeInMs = dwellTime + (endTime - startTime);
        const finalDwellTime = Math.floor(timeInMs / 1000);

        dispatch(
          analyticsActions.trackEvent(TRACK_DWELL_TIME, {
            pageName: route?.name,
            pageId: route?.id,
            pathname: location?.pathname,
            groupId: assetParams?.groupId,
            assetId: assetParams?.assetId,
            dwellTime: finalDwellTime,
            isTimer
          })
        );
      };

      const timeoutId = setTimeout(handleLeavePage, 20000);

      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', handleLeavePage);

      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
        window.removeEventListener('beforeunload', handleLeavePage);

        handleLeavePage(false);
        clearTimeout(timeoutId);
      };
    }, [assetParams?.groupId, assetParams?.assetId]);

    return <Component {...props} />;
  };
