export const exchangeTypesConfiguration = {
  extension: { order: 0 },
  connector: { order: 1 },
  template: { order: 1 },
  example: { order: 2 },
  policy: { order: 3 },
  'api-group': { order: 4, isNew: true },
  'rest-api': { order: 5 },
  'soap-api': { order: 6 },
  'data-weave-library': { order: 7, isNew: true },
  'evented-api': { order: 7, isNew: true },
  'http-api': { order: 7 },
  'raml-fragment': { order: 8 },
  custom: { order: 9 },
  default: { isNew: false, order: 99 }
};

export function getExchangeTypeConfiguration(type, property) {
  const exchangeTypeConfiguration = exchangeTypesConfiguration[type];

  return exchangeTypeConfiguration
    ? exchangeTypeConfiguration[property]
    : exchangeTypesConfiguration.default[property];
}
