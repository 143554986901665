import { EVENT_TYPES } from '~/analytics/events';
import { clientTrackPayload } from '~/analytics/payload';
import { getModalTarget, getModalDescriptiveName } from '~/utils/modals';
import * as selectors from './selectors';
import * as actionTypes from './actionTypes';

export function addModalProps({ modalComponent, modalType, modalProps }) {
  return {
    type: actionTypes.ADD_MODAL_PROPS,
    ...getModalTarget({ modalType, modalComponent }),
    modalProps
  };
}

export function openModal({
  modalType,
  modalComponent,
  modalProps,
  trackPayloadProps
}) {
  return {
    type: actionTypes.OPEN_MODAL,
    targetElementAfterClose: document.activeElement,
    ...getModalTarget({ modalType, modalComponent }),
    ...(modalProps ? { modalProps } : {}),
    ...clientTrackPayload({
      eventSchema: EVENT_TYPES.MODAL_OPENED,
      properties: {
        modal: getModalDescriptiveName({ modalComponent, modalType }),
        ...trackPayloadProps
      }
    })
  };
}

export function pushModal({
  modalType,
  modalComponent,
  modalProps,
  trackPayloadProps
}) {
  return {
    type: actionTypes.PUSH_MODAL,
    targetElementAfterClose: document.activeElement,
    ...getModalTarget({ modalType, modalComponent }),
    ...(modalProps ? { modalProps } : {}),
    ...clientTrackPayload({
      eventSchema: EVENT_TYPES.MODAL_OPENED,
      properties: {
        modal: getModalDescriptiveName({ modalType, modalComponent }),
        ...trackPayloadProps
      }
    })
  };
}

export function closeModal({ trackPayloadProps } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const modalName = selectors.modalName(state);

    dispatch({
      type: actionTypes.CLOSE_MODAL,
      ...clientTrackPayload({
        eventSchema: EVENT_TYPES.MODAL_CLOSED,
        properties: {
          modal: modalName,
          ...trackPayloadProps
        }
      })
    });
  };
}

export function closeAllModals() {
  return {
    type: actionTypes.CLOSE_ALL_MODALS
  };
}
