import PropTypes from 'prop-types';
import React from 'react';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import EmptyResult from '@mulesoft/anypoint-components/lib/EmptyResult';
import withLayoutContext from '~/components/LayoutContextProvider';
import InfiniteScroll from '~/components/Layout/InfiniteScroll';
import styles from './AssetCardGrid.css';
import AssetCard from './Card';

class AssetCardGrid extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    assets: PropTypes.arrayOf(AssetPropType),
    query: PropTypes.object,
    onLoadAssets: PropTypes.func.isRequired,
    getPath: PropTypes.func,
    isPublicPortal: PropTypes.bool,
    hasMoreAssets: PropTypes.bool,
    onReachEnd: PropTypes.func,
    onAssetClick: PropTypes.func
  };

  loadMoreAssets = () => {
    const query = {
      offset: this.props.assets.length,
      ...this.props.query
    };

    this.props.onReachEnd();

    return this.props.onLoadAssets(query);
  };

  renderGridCells = () => {
    const { assets, getPath, isPublicPortal, onAssetClick } = this.props;

    return assets.map((asset, ix) => (
      <div
        data-test-id="asset-grid-cell-wrapper"
        className={styles.wrapper}
        key={`${asset.id}-${ix}`}
      >
        <div data-test-id="asset-grid-cell" className={styles.cell}>
          <AssetCard
            testId="asset-card"
            asset={asset}
            getPath={getPath}
            isPublicPortal={isPublicPortal}
            onClick={onAssetClick}
          />
        </div>
      </div>
    ));
  };

  render() {
    const { id = '', assets, hasMoreAssets } = this.props;

    return (
      <InfiniteScroll
        hasMore={hasMoreAssets}
        onLoadMore={this.loadMoreAssets}
        loadingMoreMessage="Loading more assets ..."
        loadMoreMessage="Load more assets"
        testId="assets"
      >
        <div
          id={id}
          role={assets.length === 0 ? 'alert' : 'list'}
          data-test-id="asset-grid"
          className={styles.gridLayout}
        >
          {assets.length === 0 ? (
            <EmptyResult
              className={styles.emptyResult}
              message="We couldn't find results that match your criteria"
              testId="assets-empty-result"
            />
          ) : (
            this.renderGridCells()
          )}
        </div>
      </InfiniteScroll>
    );
  }
}

export { AssetCardGrid };

export default withLayoutContext()(AssetCardGrid);
