import config from 'config';
import * as searchActions from '~/domains/search/actionTypes';
import * as angConstants from '~/utils/ang/constants';
import * as actions from '../actionTypes';

const initialState = {
  results: [],
  data: [],
  isLoading: true,
  hasMoreAssets: true,
  limit: angConstants.ANG_PAGINATION_SIZE
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case searchActions.CHANGE_RESULTS: {
      return {
        ...state,
        isLoading: action.isLoading && action.results.length === 0,
        isSearchTriggered: true,
        hasMoreAssets: state.limit < action.results.length,
        data: action.results
      };
    }

    case actions.SHOW_MORE_ASSETS: {
      const limit = state.limit + angConstants.ANG_PAGINATION_SIZE;

      return {
        ...state,
        limit,
        hasMoreAssets: limit < state.data.length
      };
    }

    case actions.FETCH_ASSETS_SUCCESS: {
      return {
        ...state,
        data: action.result.map((asset) => ({
          groupId: asset.groupId,
          assetId: asset.assetId
        })),
        isLoading: false,
        isSearchTriggered: false,
        hasMoreAssets: action.result.length === config.assetsLimit
      };
    }

    case actions.LOAD_ASSETS_SUCCESS: {
      return {
        ...state,
        data: state.data.concat(
          action.result.map((asset) => ({
            groupId: asset.groupId,
            assetId: asset.assetId
          }))
        ),
        isLoading: false,
        hasMoreAssets: action.result.length === config.assetsLimit
      };
    }

    default:
      return state;
  }
}
