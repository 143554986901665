import { createSelector } from 'reselect';
import semver from 'semver';

export const profile = (state) => state.session.profile;
export const isLoggedIn = (state) => !!state.session.profile;
export const context = createSelector(
  (state) => state.session.context,
  (state) => state.session.featureFlags,
  ($context, $featureFlags) => ({
    ...$context,
    featureFlags: $featureFlags
  })
);

/*
studioMetadata contains:
runtimes:
  [{
    id: 'org.mule.tooling.server.3.9.0.ee',
    label: 'Mule Server 3.9.0 EE',
    version: { major: 3, minor: 9, micro: 0, qualifier: {} }
  }]
*/
export const isLegacyStudioVersion = createSelector(
  (state) => state.session.studioMetadata?.version,
  ($version) => !$version || semver.lt($version, '7.0.0')
);

export const isStudio = (state) => !!state.session.studioMetadata?.version;

export const studioMetadata = createSelector(
  (state) => state.session.studioMetadata,
  isLegacyStudioVersion,
  ($studioMetadata, $isLegacy) => ({
    ...$studioMetadata,
    isLegacy: $isLegacy
  })
);

export const studioType = createSelector(
  (state) => state.session.studioMetadata?.perspective,
  ($perspective) => {
    if ($perspective === 'api') {
      return 'rest-api';
    }

    if ($perspective === 'wsdl') {
      return 'soap-api';
    }

    return $perspective;
  }
);

export const profileOrganizationDomain = createSelector(
  profile,
  ($profile = {}) => {
    if (!$profile.organization) {
      return '';
    }

    return $profile.organization.domain;
  }
);

export const organizationId = createSelector(profile, ($profile = {}) => {
  if (!$profile.organization) {
    return '';
  }

  return $profile.organization.id;
});

export const organization = createSelector(
  profile,
  ($profile = {}) => $profile.organization
);
