import React from 'react';
import PropTypes from 'prop-types';
import styles from './WelcomeSection.css';

const propTypes = {
  heroImage: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string
};

const WelcomeSection = ({ heroImage, title, text, textColor }) => {
  const dynamicStyles = {};

  if (heroImage) {
    dynamicStyles.backgroundImage = `url(${heroImage})`;
  }

  const textStyle = {
    color: textColor
  };

  return (
    <div
      className={styles.container}
      style={dynamicStyles}
      data-test-id="welcome-section-container"
    >
      <header
        className={styles.content}
        data-test-id="welcome-section"
        aria-labelledby="welcome-title"
      >
        <h1
          id="welcome-title"
          className={styles.title}
          style={textStyle}
          data-test-id="welcome-title"
        >
          {title}
        </h1>
        <h2
          className={styles.text}
          style={textStyle}
          data-test-id="welcome-text"
        >
          {text}
        </h2>
      </header>
    </div>
  );
};

WelcomeSection.defaultProps = {
  heroImage: '',
  title: '',
  text: '',
  textColor: '#FFF'
};

WelcomeSection.propTypes = propTypes;

export default WelcomeSection;
