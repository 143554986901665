import { EVENT_TYPES } from '~/analytics/events';
import { clientTrackPayload } from '~/analytics/payload';
import * as userService from '~/services/userService';
import { getPathAfterOrganizationChange } from '~/utils/navigation';
import { getActiveOrganizationId } from '~/utils/organization';
import * as sessionSelectors from './selectors';
import * as actions from './actionTypes';

export function setSession(session) {
  return {
    type: actions.SET_SESSION,
    ...session
  };
}

export function setToken(accessToken, tokenType) {
  return {
    accessToken,
    tokenType,
    type: actions.SET_TOKEN
  };
}

export function setFeatureFlags(featureFlags) {
  return {
    type: actions.SET_FEATURE_FLAGS,
    featureFlags
  };
}

export function setUserProperty({ namespace, key, value }) {
  return async (dispatch, getState) => {
    const state = getState();
    const profile = sessionSelectors.profile(state);

    if (!profile) {
      throw new Error('Profile not found');
    }

    const {
      id: userId,
      organization: { id: organizationId }
    } = profile;
    const context = sessionSelectors.context(state);

    await userService.setUserProperty({
      context,
      organizationId,
      userId,
      namespace,
      key,
      value
    });

    dispatch({
      type: actions.SET_USER_PROPERTY,
      namespace,
      key,
      value
    });
  };
}

export function increaseProfileAssetsCount() {
  return {
    type: actions.INCREASE_PROFILE_ASSETS_COUNT
  };
}

export function decreaseProfileAssetsCount() {
  return {
    type: actions.DECREASE_PROFILE_ASSETS_COUNT
  };
}

export function updateActiveOrganization(organization, homeQuery, push) {
  return (dispatch, getState) => {
    const state = getState();
    const profile = sessionSelectors.profile(state);
    const activeOrganization = getActiveOrganizationId(profile);
    const newPath = getPathAfterOrganizationChange(
      homeQuery,
      activeOrganization,
      organization.id
    );

    dispatch(setActiveOrganization(organization));
    push(newPath);
  };
}

export function setActiveOrganization(organization) {
  return {
    type: actions.SET_ACTIVE_ORGANIZATION,
    organization,
    ...clientTrackPayload({ eventSchema: EVENT_TYPES.ORGANIZATION_CHANGED })
  };
}
