import React from 'react';
import { AssetPortalContext } from '@mulesoft/exchange-react-hooks';

const Actions = (props) => {
  const {
    components: { Actions: PortalActions }
  } = React.useContext(AssetPortalContext);

  return React.createElement(PortalActions, props);
};

export default Actions;
