import debug from 'debug';
import * as commonSelectors from '~/domains/common/selectors';
import * as authenticationService from '~/services/authenticationService';
import constants from '~/utils/constants';
import { getHashProperties } from '~/utils/url';
import * as analyticsActions from '~/domains/analytics/actions';
import { EVENT_TYPES } from '~/analytics/events';
import { openPopup } from '~/utils/popup';
import { closeModal } from '~/domains/modal/actions';
import * as actions from './actionTypes';
import * as selectors from './selectors';

const log = debug('exchange:relogin');

export function cleanPendingActions() {
  return {
    type: actions.CLEAN_PENDING_ACTIONS
  };
}

export function executePendingActions() {
  return (dispatch, getState) => {
    const state = getState();
    const pendingActions = selectors.pendingActions(state);

    log(`dispatching ${pendingActions.length} pending actions`);
    dispatch(cleanPendingActions());
    pendingActions.forEach((action) => action());
  };
}

export function addPendingAction(action) {
  return {
    type: actions.ADD_PENDING_ACTION,
    action
  };
}

export function reloginUser({ isPublicPortal, setToken }) {
  return (dispatch, getState) => {
    const state = getState();
    const profile = commonSelectors.profile(state);
    const organizationId = profile.organization.id;
    const loginURL = authenticationService.getClientAuthenticationURL({
      isPublicPortal,
      redirectURL: constants.oauthCallbackUrl,
      domain: profile.organization.domain
    });

    window.authenticateOAuth = (responseUrl) => {
      const props = getHashProperties(responseUrl);
      const accessToken = props && props.access_token;
      const tokenType = props && props.token_type;

      setToken(accessToken, tokenType);

      dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.USER_RELOGGED_IN, {
          organizationId
        })
      );
      dispatch(closeModal());
      dispatch(executePendingActions());
    };

    openPopup(loginURL, 'Login', 400, 400);
  };
}
