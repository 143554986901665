import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import * as assetSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/selectors';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import UnderlyingAPITermsSelector from './UnderlyingAPITermsSelector';

const mapStateToProps = (state, { match: { params } }) => {
  const asset = assetSelectors.asset(state, params);
  // someday, when we finally move out this code from exchange-ui to exchange-assets-definitions,
  // we will be able to remove this selector
  const isAPIGroupLoaded = reducerManagerSelectors.isTypeLoaded(
    state,
    'api-group'
  );

  if (!isAPIGroupLoaded) {
    return {
      asset,
      apiGroupInstances: []
    };
  }
  const apiGroupInstances = apiGroupSelectors.apiGroupInstances(state, asset);

  return {
    asset,
    apiGroupInstances
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(UnderlyingAPITermsSelector);
