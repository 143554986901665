import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StarIcon from '@mulesoft/anypoint-icons/lib/svg/star.svg';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import * as refUtils from '~/utils/refs';
import styles from './RatingSelector.css';

export default class Star extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    selected: PropTypes.bool,
    outOf: PropTypes.number,
    onClick: PropTypes.func
  };

  static defaultProps = {
    selected: false,
    outOf: -1
  };

  componentDidUpdate(prevProps) {
    if (this.props.selected && !prevProps.selected) {
      this.focus();
    }
  }

  starRef = React.createRef();

  focus = () => {
    refUtils.focus(this.starRef);
  };

  handleClick = () => {
    const { onClick, value } = this.props;

    this.focus();

    onClick(value);
  };

  render() {
    const { className, value, outOf, selected } = this.props;

    return (
      <span
        ref={this.starRef}
        role="radio"
        tabIndex={selected ? '0' : '-1'}
        aria-checked={selected}
        aria-label={`${value} Star`}
        aria-posinset={value + 1}
        aria-setsize={outOf}
        className={classNames(
          className,
          {
            [styles.selected]: selected
          },
          styles.star
        )}
        onClick={this.handleClick}
        onKeyDown={eventHandlerUtils.onEnterKey(this.handleClick)}
      >
        <StarIcon />
      </span>
    );
  }
}
