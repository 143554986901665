import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from 'config';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import BackIcon from '@mulesoft/anypoint-icons/lib/svg/back-small.svg';
import WarningIcon from '@mulesoft/anypoint-icons/lib/svg/warning-small.svg';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import Label from '@mulesoft/anypoint-components/lib/Label';
import CreatePage from '~/components/CreatePage';
import ImageUploader from '~/components/Customization/ImageUploader';
import SidebarActions from '~/components/Customization/SidebarActions';
import DraggableTree from '~/components/DraggableTree';
import * as refUtils from '~/utils/refs';
import styles from './CustomizeSidebar.css';

class CustomizeSidebar extends Component {
  static propTypes = {
    isHome: PropTypes.bool,
    isDraftExist: PropTypes.bool,
    heroImageName: PropTypes.string,
    logoImageName: PropTypes.string,
    faviconName: PropTypes.string,
    getPath: PropTypes.func,
    isCustomizationLoading: PropTypes.bool,
    textColor: PropTypes.string,
    navbar: PropTypes.object,
    pages: PropTypes.array,
    organization: PropTypes.object,
    onImageChange: PropTypes.func,
    onColorChange: PropTypes.func,
    onCreatePage: PropTypes.func,
    onOrderPages: PropTypes.func.isRequired,
    welcomeTitle: PropTypes.string,
    welcomeText: PropTypes.string,
    onTitleChange: PropTypes.func.isRequired,
    onTextChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    textColor: '#ffffff',
    navbar: {
      backgroundColor: '#121314',
      textColor: '#ffffff',
      textColorActive: '#00a2df'
    },
    pages: [],
    onImageChange: () => {},
    onColorChange: () => {}
  };

  componentDidMount() {
    refUtils.focus(this.firstInputRef);
  }

  firstInputRef = React.createRef();

  handleFaviconUpload = ({ url, file }) =>
    this.props.onImageChange({ url, file, imageKind: 'favicon' });

  handleLogoImageUpload = ({ url, file }) =>
    this.props.onImageChange({ url, file, imageKind: 'logoImage' });

  handleHeroImageUpload = ({ url, file }) =>
    this.props.onImageChange({ url, file, imageKind: 'heroImage' });

  handleWelcomeTextColorChange = ({ value }) =>
    this.props.onColorChange(value, 'textColor', 'welcomeSection');

  handleNavbarBackgroundColorChange = ({ value }) =>
    this.props.onColorChange(value, 'backgroundColor', 'navbar');

  handleNavbarTextColorChange = ({ value }) =>
    this.props.onColorChange(value, 'textColor', 'navbar');

  handleNavbarActiveTextColorChange = ({ value }) =>
    this.props.onColorChange(value, 'textColorActive', 'navbar');

  handleOrderPages = (pages) => {
    const { onOrderPages, organization } = this.props;

    onOrderPages({
      organizationDomain: organization.domain,
      pages
    });
  };

  renderSpinner = () => (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );

  renderMainContent = () => {
    const {
      isHome,
      textColor,
      navbar,
      getPath,
      heroImageName,
      logoImageName,
      faviconName,
      pages,
      organization,
      onCreatePage,
      isDraftExist,
      welcomeTitle,
      welcomeText,
      onTitleChange,
      onTextChange
    } = this.props;

    return (
      <div data-test-id="customize-sidebar-main-content">
        <div
          className={styles.draftMsg}
          display-if={isDraftExist}
          data-test-id="customize-sidebar-unpublished-draft-message"
        >
          <Icon size="xs" className={styles.draftMsgIcon}>
            <WarningIcon />
          </Icon>
          There are unpublished changes
        </div>

        <h3>Top bar</h3>

        <Label className={styles.label} htmlFor="logo-image-uploader-button">
          Logo
        </Label>
        <ImageUploader
          ref={this.firstInputRef}
          id="logo-image-uploader"
          testId="logo-image-uploader"
          placeholder={logoImageName}
          onUpload={this.handleLogoImageUpload}
          aria-label="Choose logo image"
        />

        <Label className={styles.label} htmlFor="favicon-uploader-button">
          Favicon
        </Label>
        <ImageUploader
          id="favicon-uploader"
          testId="favicon-uploader"
          placeholder={faviconName}
          onUpload={this.handleFaviconUpload}
          aria-label="Choose favicon"
        />

        <Label className={styles.label}>Background color</Label>
        <TextField
          type="color"
          className={styles.colorPicker}
          testId="navbar-background-color-picker"
          placeholder="Background color"
          value={navbar.backgroundColor}
          onChange={this.handleNavbarBackgroundColorChange}
        />

        <div className={styles.columns}>
          <div>
            <Label className={styles.label}>Text color</Label>
            <TextField
              type="color"
              className={styles.colorPicker}
              testId="navbar-text-color-picker"
              placeholder="Text color"
              value={navbar.textColor}
              onChange={this.handleNavbarTextColorChange}
            />
          </div>

          <div>
            <Label className={styles.label}>Text color (active)</Label>
            <TextField
              type="color"
              className={styles.colorPicker}
              testId="navbar-active-text-color-picker"
              placeholder="Text color (Active)"
              value={navbar.textColorActive}
              onChange={this.handleNavbarActiveTextColorChange}
            />
          </div>
        </div>

        <hr />

        <h3>Welcome section</h3>

        <Label className={styles.label} htmlFor="welcome-title-input">
          Title
        </Label>
        <TextField
          id="welcome-title-input"
          aria-label="Welcome title"
          data-test-id="welcome-title-input"
          value={welcomeTitle}
          onChange={onTitleChange}
          maxLength="100"
        />
        <Label className={styles.label} htmlFor="welcome-subtitle-input">
          Subtitle
        </Label>
        <TextField
          id="welcome-subtitle-input"
          aria-label="Welcome subtitle"
          data-test-id="welcome-text-input"
          value={welcomeText}
          onChange={onTextChange}
          maxLength="300"
        />
        <Label className={styles.label} htmlFor="hero-image-uploader-button">
          Hero image
        </Label>
        <ImageUploader
          id="hero-image-uploader"
          testId="hero-image-uploader"
          placeholder={heroImageName}
          onUpload={this.handleHeroImageUpload}
          aria-label="Choose hero image"
        />

        <Label className={styles.label}>Text color</Label>
        <TextField
          type="color"
          className={styles.colorPicker}
          testId="welcome-text-color-picker"
          placeholder="Text color"
          value={textColor}
          onChange={this.handleWelcomeTextColorChange}
        />

        <h3 className={styles.customPages}>
          <span>Custom pages</span>
          <Link
            display-if={!isHome}
            to={getPath('home', { organization }, { isEditMode: true })}
          >
            <Icon
              data-test-id="back-to-home-icon"
              className={styles.icon}
              size="xs"
            >
              <BackIcon />
            </Icon>
            Back to home
          </Link>
        </h3>
        <div className={styles.pagesTree}>
          <DraggableTree pages={pages} onOrderPages={this.handleOrderPages} />
        </div>
        <CreatePage
          pages={pages}
          onCreatePage={onCreatePage}
          limitReached={pages.length >= config.maxCustomPageCount}
          tooltipOffset={[25, 0]}
        />
      </div>
    );
  };

  render() {
    const { isCustomizationLoading } = this.props;

    return (
      <section
        id="customize-sidebar"
        tabIndex="-1"
        className={styles.container}
        aria-label="Customize public portal"
      >
        <SidebarActions />
        <div className={styles.main}>
          {isCustomizationLoading
            ? this.renderSpinner()
            : this.renderMainContent()}
        </div>
      </section>
    );
  }
}

export default CustomizeSidebar;
