import PropTypes from 'prop-types';
import React from 'react';
import { InfoModal } from '~/components/Modals';

class BadRequestModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    error: PropTypes.object
  };

  getDetails({ details }) {
    if (Array.isArray(details)) {
      const detailsArguments = details[0].arguments;

      // v1 error
      if (Array.isArray(detailsArguments)) {
        return detailsArguments.find((element) => element.key === 'errors')
          ?.value;
      }
    }

    const errorsList = details?.errors;

    // v2 error
    if (Array.isArray(errorsList)) {
      return errorsList;
    }

    return [];
  }

  renderBody() {
    const { error } = this.props;

    const errorDetails = this.getDetails(error);

    return (
      <React.Fragment>
        <p display-if={error.summary} data-test-id="error-summary">
          {error.summary}
        </p>
        <p>{`Error message: ${error.message}`}</p>
        <span
          display-if={errorDetails && errorDetails.length > 0}
          data-test-id="error-details-container"
        >
          <p>Details:</p>
          <ul>
            {errorDetails.map(($error, index) => (
              <li key={`error-${index}`}>{$error}</li>
            ))}
          </ul>
        </span>
        <p
          display-if={error.requestId}
          data-test-id="bad-request-modal-request-id"
        >
          Request Id: <code>{error.requestId}</code>
        </p>
      </React.Fragment>
    );
  }

  render() {
    const { error, onClose } = this.props;

    return (
      <InfoModal
        isDanger
        title={error.title || 'Bad Request'}
        body={this.renderBody()}
        buttonText="Close"
        onClose={onClose}
      />
    );
  }
}

export default BadRequestModal;
