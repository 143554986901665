import { compose } from 'redux';
import { connect } from 'react-redux';
import { withModal } from '@mulesoft/exchange-react-hooks';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { setModalName } from '~/components/Modals';
import * as objectsUtils from '~/utils/objects';
import DeprecateAssetModal from './DeprecateAssetModal';

const mapStateToProps = (state, { asset }) => ({
  asset,
  onDeprecateError: assetsSelectors.assetUI(state).onDeprecateError,
  isPortalReArchitectureEnabled: sessionSelectors.isPIRAEnabled(state)
});

const mapDispatchToProps = (dispatch, { closeModal }) => ({
  onClose: () => closeModal(),
  onDeprecate: (asset) => {
    dispatch(
      assetsActions.deprecateAsset(
        objectsUtils.pick(asset, 'groupId', 'assetId', 'version')
      )
    );
    closeModal();
  },
  onDeprecateByMinor: (asset) => {
    dispatch(
      assetsActions.deprecateAsset(
        objectsUtils.pick(asset, 'groupId', 'assetId', 'minorVersion')
      )
    );
    closeModal();
  }
});

export default compose(
  setModalName('DeprecateAssetModal'),
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
)(DeprecateAssetModal);
