export function loadScript(url) {
  return new Promise((resolve) => {
    // to avoid unhandled rejection on tests
    if (global.document && global.document.createElement) {
      const scriptTag = global.document.createElement('script');

      scriptTag.async = true;
      scriptTag.src = url;
      scriptTag.addEventListener('load', resolve);

      if (global.document.body.appendChild) {
        global.document.body.appendChild(scriptTag);
      }
    }
  });
}
