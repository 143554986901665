import React from 'react';
import Detail from '~/portals/api-group/Detail';
import AssetBaseLayout from '../AssetDetail/AssetBaseLayout';

class APIGroupDetailLayout extends AssetBaseLayout {
  constructor(props) {
    super(props);

    this.hideRating = true;
  }

  renderComponent() {
    const { route } = this.props;

    return <Detail route={route} />;
  }

  renderDetail() {
    return null;
  }
}

export default APIGroupDetailLayout;
