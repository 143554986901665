import React from 'react';
import Home from '~/components/Home';
import BaseCustomizationLayout from '../BaseCustomizationLayout';

class EditHomeLayout extends BaseCustomizationLayout {
  renderMain() {
    const { route } = this.props;

    return <Home route={route} />;
  }
}

export default EditHomeLayout;
