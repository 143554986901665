import * as searchService from '~/services/searchService';
import { actions as modalActions } from '~/domains/modal';
import { actions as analyticsActions } from '~/domains/analytics';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as searchesSelectors } from '~/domains/searches';
import { getActiveOrganization } from '~/utils/organization';
import { EVENT_TYPES } from '~/analytics/events';
import * as actions from './actionTypes';

export function fetchSavedSearchesSuccess({
  savedSearches,
  organizationId,
  hasAll
}) {
  return {
    type: actions.FETCH_SAVED_SEARCHES_SUCCESS,
    savedSearches,
    organizationId,
    hasAll
  };
}

export function fetchSavedSearchesRequest({ organizationId }) {
  return {
    type: actions.FETCH_SAVED_SEARCHES_REQUEST,
    organizationId
  };
}

export function fetchSavedSearchFailure({ organizationId }) {
  return {
    type: actions.FETCH_SAVED_SEARCHES_FAILURE,
    organizationId
  };
}

export function fetchCreateSavedSearchesSuccess({
  savedSearch,
  organizationId
}) {
  return {
    type: actions.FETCH_CREATE_SAVED_SEARCHES_SUCCESS,
    savedSearch,
    organizationId
  };
}

export function fetchDeleteSavedSearchesRequest({ id, organizationId }) {
  return {
    type: actions.FETCH_DELETE_SAVED_SEARCH_REQUEST,
    id,
    organizationId
  };
}

export function fetchEditSavedSearchRequest({ id, query, organizationId }) {
  return {
    type: actions.FETCH_EDIT_SAVED_SEARCH_REQUEST,
    id,
    query,
    organizationId
  };
}

export function fetchUserSavedSearchesSuccess({ savedSearches, hasAll }) {
  return {
    type: actions.FETCH_USER_SAVED_SEARCHES_SUCCESS,
    savedSearches,
    hasAll
  };
}

export function fetchUserSavedSearchesFails() {
  return {
    type: actions.FETCH_USER_SAVED_SEARCHES_FAILURE
  };
}

export function fetchUserSavedSearchesRequest() {
  return {
    type: actions.FETCH_USER_SAVED_SEARCHES_REQUEST
  };
}

export function fetchCreateUserSavedSearchesSuccess(savedSearch) {
  return {
    type: actions.FETCH_CREATE_USER_SAVED_SEARCHES_SUCCESS,
    savedSearch
  };
}

export function fetchDeleteUserSavedSearchesRequest(id) {
  return {
    type: actions.FETCH_DELETE_USER_SAVED_SEARCH_REQUEST,
    id
  };
}

export function fetchEditUserSavedSearchRequest({ id, query }) {
  return {
    type: actions.FETCH_EDIT_USER_SAVED_SEARCH_REQUEST,
    id,
    query
  };
}

export function getSavedSearches({ organizationId, limit, offset } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const profile = commonSelectors.profile(state);
    const activeOrganization = getActiveOrganization(profile);
    const activeOrganizationId = activeOrganization && activeOrganization.id;
    const canViewSavedSearches =
      activeOrganization && activeOrganization.canViewOrgSavedSearches;
    const currentSavedSearches = searchesSelectors.savedSearches(
      state,
      activeOrganizationId
    );
    const hasAllSavedSearches = searchesSelectors.hasAllSavedSearches(
      state,
      organizationId
    );

    if (!canViewSavedSearches) {
      return Promise.resolve();
    }

    if (currentSavedSearches && hasAllSavedSearches) {
      return Promise.resolve(currentSavedSearches);
    }

    dispatch(fetchSavedSearchesRequest({ organizationId }));

    return searchService
      .getSavedSearches({ organizationId, context, limit, offset })
      .then((savedSearches) =>
        savedSearches.map((savedSearch) =>
          Object.assign(savedSearch, { organizationId })
        )
      )
      .then((savedSearches) => {
        const hasAll = !limit || savedSearches.length < limit;

        return dispatch(
          fetchSavedSearchesSuccess({ savedSearches, organizationId, hasAll })
        );
      })
      .catch((err) => {
        dispatch(fetchSavedSearchFailure({ organizationId }));

        throw err;
      });
  };
}

export function saveSearch({ query, organizationId }) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    dispatch(modalActions.closeModal());

    return searchService
      .createSavedSearch({ organizationId, query, context })
      .then((savedSearch) => {
        dispatch(
          fetchCreateSavedSearchesSuccess({ savedSearch, organizationId })
        );
        dispatch(
          analyticsActions.trackEvent(
            EVENT_TYPES.ORGANIZATION_SAVED_SEARCH_CREATED,
            {
              query
            }
          )
        );
      });
  };
}

export function deleteSavedSearch({ id, organizationId }) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    dispatch(fetchDeleteSavedSearchesRequest({ id, organizationId }));

    return searchService.deleteSavedSearch({ id, organizationId, context });
  };
}

export function editSavedSearch({ id, query, organizationId }) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    const currentSavedSearches = searchesSelectors.savedSearches(
      state,
      organizationId
    );
    const currentSavedSearch =
      currentSavedSearches &&
      currentSavedSearches.find((saved) => saved.id === id);

    dispatch(fetchEditSavedSearchRequest({ id, query, organizationId }));

    return searchService
      .editSavedSearch({ id, query, organizationId, context })
      .catch((err) => {
        dispatch(
          fetchEditSavedSearchRequest({
            id,
            query: currentSavedSearch,
            organizationId
          })
        );

        throw err;
      });
  };
}

export function saveUserSearch({ query }) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    dispatch(modalActions.closeModal());

    return searchService
      .createUserSavedSearch({ query, context })
      .then((savedSearch) => {
        dispatch(fetchCreateUserSavedSearchesSuccess(savedSearch));
        dispatch(
          analyticsActions.trackEvent(EVENT_TYPES.USER_SAVED_SEARCH_CREATED, {
            query
          })
        );
      });
  };
}

export function getUserSavedSearches({ limit, offset } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const currentUserSavedSearches = searchesSelectors.userSavedSearches(state);
    const hasAllUserSavedSearches =
      searchesSelectors.hasAllUserSavedSearches(state);
    const profile = commonSelectors.profile(state);

    if (!profile) {
      return Promise.resolve();
    }

    if (currentUserSavedSearches && hasAllUserSavedSearches) {
      return Promise.resolve(currentUserSavedSearches);
    }

    dispatch(fetchUserSavedSearchesRequest());

    return searchService
      .getUserSavedSearches({ context, limit, offset })
      .then((savedSearches) => {
        const hasAll = !limit || savedSearches.length < limit;

        return dispatch(
          fetchUserSavedSearchesSuccess({ savedSearches, hasAll })
        );
      })
      .catch((err) => {
        dispatch(fetchUserSavedSearchesFails());

        throw err;
      });
  };
}

export function deleteUserSavedSearch({ id }) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    dispatch(fetchDeleteUserSavedSearchesRequest(id));

    return searchService.deleteUserSavedSearch({ id, context });
  };
}

export function editUserSavedSearch({ id, query }) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    const currentUserSavedSearches = searchesSelectors.userSavedSearches(state);
    const currentUserSavedSearch =
      currentUserSavedSearches &&
      currentUserSavedSearches.find((saved) => saved.id === id);

    dispatch(fetchEditUserSavedSearchRequest({ id, query }));

    return searchService
      .editUserSavedSearch({ id, query, context })
      .catch((err) => {
        dispatch(
          fetchEditUserSavedSearchRequest({ id, query: currentUserSavedSearch })
        );

        throw err;
      });
  };
}
