import { format } from 'url';
import config from 'config';
import { createSelector } from 'reselect';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { firstOrDefault } from '~/utils/arrays';
import { getExternalHost } from '~/utils/api';
import { isAbsolutePath } from '~/utils/url';
import * as commonSelectors from '~/domains/common/selectors';

const PROPERTY_TYPES = {
  URL: 'url',
  COLOR: 'color'
};

export const defaults = {
  navbar: {
    backgroundColor: '#262728',
    textColor: '#ffffff',
    textColorActive: '#00a2df'
  },
  welcomeSection: {
    heroImage: format({
      ...config.images.uri,
      pathname: 'hero-image-default.png'
    }),
    textColor: '#ffffff',
    welcomeTitle: 'Welcome to your developer portal!',
    welcomeText:
      'Build your application network faster! Get started with powerful tools, intuitive interface, and best in class documentation experience.'
  }
};

export const customization = (state) => state.customization;

export const organizationDomain = createSelector(
  sessionSelectors.publicPortalDomain,
  commonSelectors.location,
  (publicPortalDomain, location) => {
    if (location) {
      const { pathname = '' } = location;
      const pathnameParts = pathname.split('/');
      const portalsIndex = pathnameParts.findIndex(
        (part) => part === 'portals'
      );

      return portalsIndex !== -1
        ? pathnameParts[portalsIndex + 1]
        : publicPortalDomain;
    }

    if (publicPortalDomain) {
      return publicPortalDomain;
    }

    return null;
  }
);

export const customizationDraft = createSelector(
  customization,
  organizationDomain,
  ($customization, $organizationDomain) =>
    $customization && $customization.data[`${$organizationDomain}/draft`]
);

export const customizationData = createSelector(
  customization,
  organizationDomain,
  ($customization, $organizationDomain) =>
    $customization?.data[$organizationDomain]
);

export const profileCustomizationData = createSelector(
  customization,
  commonSelectors.profileOrganizationDomain,
  ($customization, $profileOrganizationDomain) =>
    $customization?.data[$profileOrganizationDomain]
);

export const canEditPortalPage = createSelector(
  organizationDomain,
  commonSelectors.profileOrganizationDomain,
  commonSelectors.profile,
  ($portalOrganizationDomain, $userOrganizationDomain, $profile) =>
    !!$profile &&
    $profile.organization.isExchangeAdmin &&
    $portalOrganizationDomain === $userOrganizationDomain
);

export const cookieConsentId = createSelector(
  customizationData,
  ($customizationData) => $customizationData?.cookieConsentId
);

export const isProfilePublicPortalEnabled = createSelector(
  commonSelectors.routingContext,
  ($routingContext) => $routingContext.publicPortalStatus === 'enabled'
);

export const isCustomizationEditMode = (state, params) =>
  params && params.isEditMode;

export const isDraftExist = createSelector(
  customizationDraft,
  ($customizationDraft) => !!$customizationDraft && $customizationDraft.draft
);

export const hasUnsavedChanges = createSelector(
  customization,
  ($customization) => !!$customization && $customization.ui.hasUnsavedChanges
);

export function portalAbsolutePath(state, { isDraft = false } = {}) {
  const context = commonSelectors.context(state);
  const host = config.parallelTest
    ? null
    : commonSelectors.routingContext(state).host;
  const externalHost = getExternalHost(context);
  const portalPath = commonSelectors.getPortalPathFn(state);

  return format({
    host: host || externalHost,
    protocol: config.externalURI.protocol,
    pathname: isDraft ? `${portalPath('home')}draft/` : `${portalPath('home')}`
  });
}

export const createCustomizationDataSelector = (
  propName,
  sectionName,
  { propType } = {}
) =>
  createSelector(
    customization,
    organizationDomain,
    isCustomizationEditMode,
    isDraftExist,
    hasUnsavedChanges,
    portalAbsolutePath,
    (
      $customization,
      $organizationDomain,
      $isEditMode,
      $isDraftExist,
      $hasUnsavedChanges,
      $portalAbsolutePath
    ) => {
      const draftData =
        $customization.data[`${$organizationDomain}/draft`] || {};
      const sectionDraftData = draftData[sectionName] || {};
      const mainData = $customization.data[$organizationDomain] || {};
      const sectionMainData = mainData[sectionName] || {};
      const stringPredicate = (item) => !!item || item === '';
      const isUnsavedDraft =
        $isEditMode && ($isDraftExist || $hasUnsavedChanges);
      const sections = isUnsavedDraft
        ? [sectionDraftData[propName], sectionMainData[propName]]
        : [sectionMainData[propName]];
      const result = firstOrDefault(
        sections,
        stringPredicate,
        defaults[sectionName][propName]
      );

      if (result) {
        if (propType === PROPERTY_TYPES.URL && !isAbsolutePath(result)) {
          return `${$portalAbsolutePath}${$isEditMode ? 'draft/' : ''}${result}`;
        }

        if (propType === PROPERTY_TYPES.COLOR) {
          return /^#[0-9A-Fa-f]{6}$/.test(result) ? result : null;
        }
      }

      return result;
    }
  );

export const isOpenMobileMenu = createSelector(
  customization,
  ($customization) => $customization && !!$customization.ui.isOpenMobileMenu
);

export const welcomeTitle = createCustomizationDataSelector(
  'welcomeTitle',
  'welcomeSection'
);

export const welcomeText = createCustomizationDataSelector(
  'welcomeText',
  'welcomeSection'
);

export const textColor = createCustomizationDataSelector(
  'textColor',
  'welcomeSection'
);

export const heroImage = createCustomizationDataSelector(
  'heroImage',
  'welcomeSection',
  { propType: PROPERTY_TYPES.URL }
);

export const logoImage = createCustomizationDataSelector(
  'logoImage',
  'navbar',
  { propType: PROPERTY_TYPES.URL }
);

export const favicon = createCustomizationDataSelector('favicon', 'navbar', {
  propType: PROPERTY_TYPES.URL
});

export const navbarBackgroundColor = createCustomizationDataSelector(
  'backgroundColor',
  'navbar',
  { propType: PROPERTY_TYPES.COLOR }
);

export const navbarTextColor = createCustomizationDataSelector(
  'textColor',
  'navbar',
  { propType: PROPERTY_TYPES.COLOR }
);

export const navbarTextColorActive = createCustomizationDataSelector(
  'textColorActive',
  'navbar',
  { propType: PROPERTY_TYPES.COLOR }
);

export const publishedCustomPages = createSelector(
  customizationData,
  ($customizationData) => {
    if (!$customizationData) {
      return [];
    }
    const { customPages, customPagesOrder } = $customizationData;

    return (customPagesOrder || []).map((path) => customPages[path]);
  }
);

export const draftCustomPages = createSelector(
  customizationDraft,
  ($customizationDraft) => {
    if (!$customizationDraft) {
      return [];
    }
    const { customPages, customPagesOrder } = $customizationDraft;

    return (customPagesOrder || []).map((path) => customPages[path]);
  }
);

export const draftCustomPagesWithURL = createSelector(
  draftCustomPages,
  organizationDomain,
  commonSelectors.getPortalPathFn,
  ($draftCustomPages = [], $organizationDomain, $getPath) => {
    const organization = { domain: $organizationDomain };

    return $draftCustomPages.map((page) => ({
      ...page,
      url: $getPath(
        'customPage',
        { organization, pagePath: page.path },
        { isEditMode: true }
      )
    }));
  }
);

const selectCustomPageTo = (source) =>
  createSelector(
    source,
    (state, params) => params,
    ($source = {}, $pagePath) =>
      ($source.customPages && $source.customPages[$pagePath]) || {}
  );

export const customPageToEdit = selectCustomPageTo(customizationDraft);

export const customPageToView = selectCustomPageTo(customizationData);

export const isSaving = createSelector(
  customization,
  ($customization) => $customization && !!$customization.ui.isSaving
);

export const isPublishing = createSelector(
  customization,
  ($customization) => $customization && !!$customization.ui.isPublishing
);

export const isDiscarding = createSelector(
  customization,
  ($customization) => $customization && !!$customization.ui.isDiscarding
);

export const isContentLoading = createSelector(
  customization,
  ($customization) => $customization && !!$customization.ui.isContentLoading
);

export const isCustomizationLoading = createSelector(
  customization,
  ($customization) =>
    $customization && !!$customization.ui.isCustomizationLoading
);
