import * as actions from './actionTypes';

const initialState = {
  loadedType: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.LOAD_TYPE: {
      return {
        ...state,
        loadedType: action.assetType
      };
    }

    case actions.UNLOAD_TYPE: {
      return {
        ...state,
        loadedType: null
      };
    }

    default:
      return state;
  }
}
