const basicAssetProperties = [
  'organizationId',
  'groupId',
  'assetId',
  'version',
  'name',
  'type',
  'originalFormat',
  'originalFormatVersion'
];

const basicVersionGroupProperties = [
  'organizationId',
  'groupId',
  'assetId',
  'versionGroup',
  'type'
];

export const assetProperties = ({ asset }) => ({
  organizationId: asset.organization.id,
  groupId: asset.groupId,
  assetId: asset.assetId,
  version: asset.version,
  name: asset.name,
  type: asset.type,
  originalFormat: asset.originalFormat,
  originalFormatVersion: asset.originalFormatVersion
});

export const versionAgnosticProps = ({ asset }) => ({
  name: asset.name,
  type: asset.type,
  icon: asset.icon
});

export const assetDownloadProperties = ({ asset, file, isInstall }) => ({
  ...assetProperties({ asset }),
  classifier: file.classifier,
  isInstall
});

export default {
  ASSET_CREATED: {
    name: 'Asset created',
    required: basicAssetProperties
  },
  ASSET_NAME_EDITED: {
    name: 'Asset name edited',
    required: basicAssetProperties
  },
  ASSET_ICON_EDITED: {
    name: 'Asset icon edited',
    required: basicAssetProperties
  },
  ASSET_ICON_DELETED: {
    name: 'Asset icon deleted',
    required: basicAssetProperties
  },
  ASSET_DESCRIPTION_EDITED: {
    name: 'Asset description edited',
    required: basicAssetProperties
  },
  NEW_VERSION_ADDED: {
    name: 'New version added',
    required: basicAssetProperties
  },
  API_INSTANCE_MADE_PUBLIC: {
    name: 'API instance made public',
    required: [...basicAssetProperties, 'instanceType']
  },
  API_INSTANCE_MADE_PRIVATE: {
    name: 'API instance made private',
    required: [...basicAssetProperties, 'instanceType']
  },
  API_GROUP_INSTANCE_MADE_PUBLIC: {
    name: 'API Group instance made public',
    required: basicAssetProperties
  },
  API_GROUP_INSTANCE_MADE_PRIVATE: {
    name: 'API Group instance made private',
    required: basicAssetProperties
  },
  API_GROUP_VERSION_MADE_PUBLIC: {
    name: 'API Group version made public',
    required: basicVersionGroupProperties
  },
  API_GROUP_VERSION_MADE_PRIVATE: {
    name: 'API Group version made private',
    required: basicVersionGroupProperties
  },
  API_VERSION_MADE_PUBLIC: {
    name: 'API version made public',
    required: basicVersionGroupProperties
  },
  API_VERSION_MADE_PRIVATE: {
    name: 'API version made private',
    required: basicVersionGroupProperties
  },
  ASSET_DOWNLOADED: {
    name: 'Downloaded asset',
    required: [...basicAssetProperties, 'classifier']
  },
  BACK_TO_ASSETS_LIST_CLICKED: 'Back to assets list clicked',
  ASSET_DETAIL_LOADED: {
    name: 'Asset detail loaded',
    required: [...basicVersionGroupProperties, 'version']
  },
  ASSET_DETAIL_LOADED_BY_CONTRIBUTOR: {
    name: 'Asset detail loaded - by contributor',
    required: [...basicVersionGroupProperties, 'version']
  },
  ASSETS_LIST_RESULT_CLICKED: {
    name: 'Assets list result clicked',
    required: [
      'organizationId',
      'groupId',
      'assetId',
      'type',
      'view',
      'isPublicPortal',
      'resultPosition',
      'resultCount',
      'isFirstTimeClicked',
      'reciprocalRank',
      'relativeClickRank',
      'weightedRelativeClickRank',
      'query'
    ]
  },
  VERSION_DROPDOWN_CLICKED: {
    name: 'Version dropdown clicked',
    required: ['organizationId', 'groupId', 'assetId', 'type']
  },
  ASSET_DEPENDENCY_CLICKED: 'Asset dependency clicked',
  API_GROUP_DEPENDENCY_CLICKED: 'API Group dependency clicked'
};
