export const FETCH_CUSTOMIZATION_DATA_SUCCESS =
  'Fetch Customization Data Success';
export const FETCH_CUSTOMIZATION_DRAFT_SUCCESS =
  'Fetch Customization Draft Success';
export const UPDATE_CUSTOMIZATION_TITLE = 'Update Customization Title';
export const UPDATE_CUSTOMIZATION_COLOR = 'Update Customization Color';
export const UPDATE_CUSTOMIZATION_TEXT = 'Update Customization Text';
export const UPDATE_CUSTOMIZATION_IMAGE = 'Update Customization Image';
export const RENAME_CUSTOMIZATION_CUSTOM_PAGE =
  'Rename Customization Custom Page';
export const UPDATE_CUSTOMIZATION_CUSTOM_PAGE_MARKDOWN =
  'Update Customization Custom Page Markdown';
export const UPDATE_CUSTOMIZATION_CUSTOM_PAGE_HTML =
  'Update Customization Custom Page HTML';
export const CREATE_CUSTOMIZATION_CUSTOM_PAGE =
  'Create Customization Custom Page';
export const DELETE_CUSTOMIZATION_CUSTOM_PAGE =
  'Delete Customization Custom Page';
export const SAVE_CUSTOMIZATION_DRAFT_REQUEST =
  'Save Customization Draft Request';
export const SAVE_CUSTOMIZATION_DRAFT_SUCCESS =
  'Save Customization Draft Success';
export const SAVE_CUSTOMIZATION_DRAFT_FAILURE =
  'Save Customization Draft Failure';
export const DISCARD_CUSTOMIZATION_DRAFT_REQUEST =
  'Discard Customization Draft Request';
export const DISCARD_CUSTOMIZATION_DRAFT_SUCCESS =
  'Discard Customization Draft Success';
export const DISCARD_CUSTOMIZATION_DRAFT_FAILURE =
  'Discard Customization Draft Failure';
export const PUBLISH_CUSTOMIZATION_DRAFT_REQUEST =
  'Publish Customization Draft Request';
export const PUBLISH_CUSTOMIZATION_DRAFT_FAILURE =
  'Publish Customization Draft Failure';
export const PUBLISH_CUSTOMIZATION_DRAFT_SUCCESS =
  'Publish Customization Draft Success';
export const ENABLE_CUSTOMIZATION_LOADING = 'Enable Customization Loading';
export const DISABLE_CUSTOMIZATION_LOADING = 'Disable Customization Loading';
export const ENABLE_CONTENT_LOADING = 'Enable Content Loading';
export const DISABLE_CONTENT_LOADING = 'Disable Content Loading';
export const OPEN_CUSTOMIZATION_DISCARD_MODAL =
  'Open Customization Discard Modal';
export const CLOSE_CUSTOMIZATION_DISCARD_MODAL =
  'Close Customization Discard Modal';
export const OPEN_CUSTOMIZATION_MOBILE_MENU = 'Open Customization Mobile Menu';
export const CLOSE_CUSTOMIZATION_MOBILE_MENU =
  'Close Customization Mobile Menu';
export const SAVE_CUSTOM_PAGES_ORDER = 'Save Custom Pages Order';
export const SAVE_CUSTOM_PAGES_ORDER_REQUEST =
  'Save Custom Pages Order Request';
export const SAVE_CUSTOM_PAGES_ORDER_SUCCESS =
  'Save Custom Pages Order Success';
export const SAVE_CUSTOM_PAGES_ORDER_FAILURE =
  'Save Custom Pages Order Failure';
