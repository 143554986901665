export const setItem = (key, value) => {
  if (!global.localStorage) {
    return;
  }

  const stringifiedValue = JSON.stringify(value);

  global.localStorage.setItem(`exchange:${key}`, stringifiedValue);
};

export const getItem = (key) => {
  if (!global.localStorage) {
    return null;
  }

  const item = global.localStorage.getItem(`exchange:${key}`);

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (error) {
    return null;
  }
};

export const removeItem = (key) => {
  if (!global.localStorage) {
    return;
  }

  global.localStorage.removeItem(`exchange:${key}`);
};

export const storageFactory = (key) => {
  return {
    get: () => getItem(key),
    set: (value) => setItem(key, value)
  };
};
