import * as actions from './actionTypes';

const initialState = {
  data: {},
  ui: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.params.masterOrganizationId || action.params.organizationId]:
            action.result
        }
      };

    case actions.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.params.organizationId]: (
            state.data[action.params.organizationId] || []
          ).concat(action.result)
        }
      };

    case actions.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.params.organizationId]: (
            state.data[action.params.organizationId] || []
          ).map((category) => {
            if (category.tagKey === action.params.tagKey) {
              return {
                ...category,
                ...action.params
              };
            }

            return category;
          })
        }
      };

    case actions.REMOVE_CATEGORY_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingCategory: true
        }
      };

    case actions.REMOVE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.params.organizationId]: (
            state.data[action.params.organizationId] || []
          ).filter((category) => category.tagKey !== action.params.tagKey)
        },
        ui: {
          ...state.ui,
          isDeletingCategory: false,
          hasDeleteCategoryConflict: false
        }
      };

    case actions.REMOVE_CATEGORY_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingCategory: false
        }
      };

    case actions.REMOVE_CATEGORY_CONFLICT:
      return {
        ...state,
        ui: {
          ...state.ui,
          hasDeleteCategoryConflict: true
        }
      };

    case actions.CANCEL_REMOVE_CATEGORY:
      return {
        ...state,
        ui: {
          ...state.ui,
          hasDeleteCategoryConflict: false
        }
      };

    default:
      return state;
  }
}
