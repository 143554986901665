import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ConfirmModal from '@mulesoft/exchange-ui-components/lib/components/ConfirmModal';

class DeleteApplicationConfirmModal extends PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired
  };

  handleCancel = () => {
    const { application, onCancel } = this.props;

    onCancel({
      trackPayloadProps: {
        applicationId: application.id,
        closeReason: 'cancel'
      }
    });
  };

  renderBody() {
    const { application } = this.props;

    return (
      <div>
        <p>
          Are you sure you want to delete <strong>{application.name}</strong>?
        </p>
        <p>This action cannot be reversed.</p>
      </div>
    );
  }

  render() {
    return (
      <ConfirmModal
        title="Delete application"
        confirmButtonText="Delete application"
        body={this.renderBody()}
        isDanger
        {...this.props}
        onCancel={this.handleCancel}
      />
    );
  }
}

export default DeleteApplicationConfirmModal;
