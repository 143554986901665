import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAnalytics } from '@mulesoft/exchange-react-hooks';
import { EVENT_TYPES } from '~/analytics/events';
import withLayoutContext from '~/components/LayoutContextProvider';
import InstanceOverviewComponent from './InstanceOverview';

const mapDispatchToProps = (dispatch, { trackEvent }) => ({
  onAssetApiVersionRedirect: () =>
    trackEvent(EVENT_TYPES.API_GROUP_DEPENDENCY_CLICKED)
});

const InstanceOverview = compose(
  withAnalytics,
  withLayoutContext(),
  connect(null, mapDispatchToProps)
)(InstanceOverviewComponent);

export default InstanceOverview;
