import { createSelector } from 'reselect';
import { getModalDescriptiveName } from '~/utils/modals';

export const modalStack = (state) =>
  (state.modal && state.modal.modalStack) || [];

export const modal = createSelector(modalStack, (stack) => stack[0]);

export const targetElementAfterClose = (state) =>
  state.modal.targetElementAfterClose;

export const modalType = createSelector(modal, (topModal) =>
  topModal ? topModal.modalType : null
);

export const modalName = createSelector(modal, (topModal) =>
  topModal ? getModalDescriptiveName(topModal) : null
);
