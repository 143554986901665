import PropTypes from 'prop-types';
import React from 'react';

export default (Component) =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    static propTypes = {
      isPublicPortal: PropTypes.bool,
      hasUnsavedChanges: PropTypes.bool
    };

    static defaultProps = {
      hasUnsavedChanges: false,
      isPublicPortal: false
    };

    componentDidMount() {
      if (this.props.isPublicPortal) {
        global.addEventListener('beforeunload', this.handleRouteLeave);
      }
    }

    componentWillUnmount() {
      if (this.props.isPublicPortal) {
        global.removeEventListener('beforeunload', this.handleRouteLeave);
      }
    }

    handleRouteLeave = (event) => {
      const { hasUnsavedChanges } = this.props;

      if (!hasUnsavedChanges) {
        // eslint-disable-next-line no-param-reassign
        delete event.returnValue;

        return;
      }

      const confirmMessage =
        'There are unsaved changes. Do you want to leave the page without saving them?';

      /* eslint-disable no-param-reassign */
      event.returnValue = confirmMessage;

      // eslint-disable-next-line
      return confirmMessage;
    };

    render() {
      return <Component {...this.props} />;
    }
  };
