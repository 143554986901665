import Button from '@mulesoft/anypoint-components/lib/Button';
import PropTypes from 'prop-types';
import React from 'react';
import * as arrayUtils from '~/utils/arrays';
import styles from './InfiniteScroll.css';

class InfiniteScroll extends React.Component {
  static propTypes = {
    hasMore: PropTypes.bool,
    testId: PropTypes.string.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    loadingMoreMessage: PropTypes.string,
    renderItem: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.node,
    loadMoreMessage: PropTypes.string,
    ariaLive: PropTypes.string
  };

  static defaultProps = {
    loadingMoreMessage: 'Loading more ...',
    loadMoreMessage: 'Load more items',
    ariaLive: 'off'
  };

  state = { isLoading: false };

  componentDidMount() {
    this.updateScrollListener();
    this.fillScreen();
  }

  componentDidUpdate() {
    this.updateScrollListener();
    this.fillScreen();
  }

  componentWillUnmount() {
    global.removeEventListener('scroll', this.handleScroll);
  }

  fillScreen = () => {
    if (this.props.hasMore && this.atBottom() && !this.state.isLoading) {
      this.loadMore();
    }
  };

  updateScrollListener = () => {
    if (this.props.hasMore) {
      global.addEventListener('scroll', this.handleScroll);
    } else {
      global.removeEventListener('scroll', this.handleScroll);
    }
  };

  /**
   * Returns true when the scroll is at the bottom of the page
   *
   * Document.body.offsetHeight:
   *    Height of the body including vertical padding and borders.
   *    Increases when the user scrolls to bottom.
   * global.scrollY:
   *    Number of pixels that the document has already been scrolled vertically.
   *    You can use this property to simulate the user scroll action.
   *    The Ceil function is applied to thsi value to work with the window zoom.
   * global.innerHeight:
   *    Height of the browser window's viewport.
   *    This value is fixed.
   */
  // eslint-disable-next-line class-methods-use-this
  atBottom = () => {
    if (typeof document !== 'undefined' && document.body) {
      return (
        (global.innerHeight + global.pageYOffset) /
          document.body.scrollHeight >=
        0.99
      );
    }

    return false;
  };

  handleScroll = () => {
    if (this.atBottom() && !this.state.isLoading) {
      this.loadMore();
    }
  };

  loadMore = async () => {
    this.setState({ isLoading: true });
    await this.props.onLoadMore();
    this.setState({ isLoading: false });
  };

  renderList = () => {
    const { children, items, renderItem } = this.props;

    if (renderItem && items) {
      return arrayUtils.convertToArray(items).map(renderItem);
    }

    return children;
  };

  render() {
    const { testId, hasMore, loadingMoreMessage, loadMoreMessage, ariaLive } =
      this.props;
    const { isLoading } = this.state;

    return (
      <div data-test-id={`infinite-scroll-${testId}`} aria-live={ariaLive}>
        {this.renderList()}
        <Button
          className={styles.loadMore}
          kind="secondary"
          onClick={this.loadMore}
          disabled={isLoading}
          isLoading={isLoading}
          display-if={hasMore}
        >
          {isLoading ? loadingMoreMessage : loadMoreMessage}
        </Button>
      </div>
    );
  }
}

export default InfiniteScroll;
