import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import ModalView from '~/components/Modals/ModalView';

class ModalRoot extends React.PureComponent {
  static propTypes = {
    hasVisibleModal: PropTypes.bool.isRequired,
    targetElementAfterClose: PropTypes.object
  };

  componentDidMount() {
    const { hasVisibleModal } = this.props;

    if (hasVisibleModal) {
      this.handleModalOpen();
    }
  }

  componentDidUpdate(prevProps) {
    const { hasVisibleModal } = this.props;
    const { hasVisibleModal: hadVisibleModal } = prevProps;

    if (!hadVisibleModal && hasVisibleModal) {
      this.handleModalOpen();
    }

    if (hadVisibleModal && !hasVisibleModal) {
      this.handleModalClose(prevProps.targetElementAfterClose);
    }
  }

  componentWillUnmount() {
    this.handleModalClose();
  }

  childKey = 0;

  handleModalOpen = () => {
    // Subscribe to every focus event in the document
    global.document.body.classList.add('prevent-scroll');
  };

  handleModalClose = (element) => {
    global.document.body.classList.remove('prevent-scroll');

    if (element) {
      this.focusElement(element);
    }
  };

  focusElement = (element) => {
    global.setTimeout(() => element.focus({ preventScroll: true }), 0);
  };

  render() {
    const { hasVisibleModal } = this.props;

    if (!hasVisibleModal) {
      return null;
    }

    this.childKey += 1;

    return (
      <FocusLock autoFocus key={this.childKey}>
        <ModalView />
      </FocusLock>
    );
  }
}

export default ModalRoot;
