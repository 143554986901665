import { Sandbox } from '@mulesoft/api-notebook';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import WarningIcon from '@mulesoft/anypoint-icons/lib/svg/warning-small.svg';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import PlayNotebook from '@mulesoft/exchange-markdown-editor/lib/components/PlayNotebook';
import NewEmptyPage from '@mulesoft/exchange-ui-portals-common/lib/components/EmptyPage';
import PageEditor from '~/components/PageEditor';
import UnderlyingAPITermsSelector from '~/components/UnderlyingAPITermsSelector';
import RelativeLinkTrap from '~/components/common/RelativeLinkTrap';
import { createNotebook, isAPIClientNotebook } from '~/utils/notebook';
import { isTermsPage } from '~/utils/page';
import styles from './Page.css';
import EmptyPage from './EmptyPage';

import '@mulesoft/exchange-markdown-editor/lib/styles.css';

export default class Page extends React.PureComponent {
  static propTypes = {
    isAPIGroup: PropTypes.bool,
    pathToEdit: PropTypes.string,
    domain: PropTypes.string,
    page: PropTypes.object,
    canEdit: PropTypes.bool,
    isContentLoading: PropTypes.bool,
    isMarketingSite: PropTypes.bool,
    hasUnsavedChanges: PropTypes.bool,
    actionName: PropTypes.string,
    hasTitle: PropTypes.bool,
    hasUnderlyingTerms: PropTypes.bool,
    onNotebooksLoaded: PropTypes.func,
    onPlayNotebook: PropTypes.func,
    onPlayNotebookSnippet: PropTypes.func,
    onFetchNotebookClient: PropTypes.func,
    isPortalReArchitectureEnabled: PropTypes.bool
  };

  static defaultProps = {
    page: {},
    isMarketingSite: false,
    onNotebooksLoaded: () => {},
    onPlayNotebookSnippet: () => {},
    onPlayNotebook: () => {}
  };

  state = {
    hasNotebooks: false
  };

  componentDidMount() {
    this.sandbox = new Sandbox();
    this.generateNotebooks();

    const hasNotebooks = !!this.getNotebooks().length;

    if (hasNotebooks) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(
        {
          hasNotebooks
        },
        () => {
          this.props.onNotebooksLoaded();
        }
      );
    } else {
      this.props.onNotebooksLoaded();
    }

    if (this.props.canEdit) {
      PageEditor.preload();
    }
  }

  componentDidUpdate(prevProps) {
    const doNotebooksExist = !!this.getNotebookTemplates().length;

    if (doNotebooksExist) {
      this.generateNotebooks();
    }

    const hasNotebooks = !!this.getNotebooks().length;

    if (this.state.hasNotebooks !== hasNotebooks) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasNotebooks
      });
    }

    if (!prevProps.canEdit && this.props.canEdit) {
      PageEditor.preload();
    }
  }

  componentWillUnmount() {
    this.notebooks = [];
  }

  getNotebookTemplates = () => {
    if (this.pageElement) {
      return [
        ...this.pageElement.querySelectorAll(
          'script[type="text/x-api-notebook"]'
        )
      ];
    }

    return [];
  };

  getNotebooks = () => {
    if (this.pageElement) {
      return [
        ...this.pageElement.querySelectorAll('[data-test-id="api-notebook"]')
      ];
    }

    return [];
  };

  notebooks = [];

  beforeExecuteNotebook = ({ content }) => {
    const { onPlayNotebookSnippet } = this.props;
    const { isPlayingNotebook } = this.state;

    if (!isPlayingNotebook) {
      onPlayNotebookSnippet();
    }

    if (!isAPIClientNotebook(content)) {
      return this.notebooks
        .filter((notebook) =>
          isAPIClientNotebook(notebook.instance.state.notebookState.content)
        )
        .reduce(
          (chain, { instance }) =>
            chain.then(() => {
              if (!instance.state.notebookState.isExecuted) {
                return instance.handleExecute();
              }

              return Promise.resolve();
            }),
          Promise.resolve()
        )
        .catch(() => {});
    }

    return Promise.resolve();
  };

  generateNotebooks = () => {
    const { onFetchNotebookClient, domain } = this.props;

    this.notebooks = this.getNotebookTemplates().map(
      (apiNotebookScript, notebookIndex) => {
        const apiNotebookSnippet = document.createElement('div');
        const content = apiNotebookScript.innerHTML;

        apiNotebookSnippet.setAttribute('data-test-id', 'api-notebook');
        apiNotebookScript.parentNode.insertBefore(
          apiNotebookSnippet,
          apiNotebookScript
        );
        apiNotebookScript.parentNode.removeChild(apiNotebookScript);

        return createNotebook(
          apiNotebookSnippet,
          content,
          notebookIndex,
          this.sandbox,
          onFetchNotebookClient,
          this.beforeExecuteNotebook,
          domain
        );
      }
    );
  };

  handlePlayNotebooks = () => {
    const { onPlayNotebook } = this.props;

    this.setState({
      isPlayingNotebook: true
    });
    onPlayNotebook();

    return this.notebooks
      .reduce(
        (chain, { instance }) => chain.then(() => instance.handleExecute()),
        Promise.resolve()
      )
      .catch(() => {})
      .then(() => {
        this.setState({
          isPlayingNotebook: false
        });
      });
  };

  renderEmptyPage() {
    const {
      pathToEdit,
      canEdit,
      hasUnsavedChanges,
      actionName,
      isPortalReArchitectureEnabled,
      page
    } = this.props;

    // if a 422 error was thrown, then it means that asset portals service couldn't parse the markdown and convert it
    // to HTML. Show an error in that case
    if (page?.htmlError?.status === 422) {
      return (
        <div>
          <Icon size="s">
            <WarningIcon />
          </Icon>
          <p>There was an error while loading the portal page</p>
        </div>
      );
    }

    return !isPortalReArchitectureEnabled ? (
      <EmptyPage
        pathToEdit={pathToEdit}
        canEdit={canEdit}
        hasUnsavedChanges={hasUnsavedChanges}
        actionName={actionName}
      />
    ) : (
      <NewEmptyPage
        canEdit={canEdit}
        hasUnsavedChanges={hasUnsavedChanges}
        actionName={actionName}
      />
    );
  }

  renderPageHTML() {
    const { isAPIGroup, page, hasUnderlyingTerms, isMarketingSite } =
      this.props;
    const Wrapper = isMarketingSite ? RelativeLinkTrap : React.Fragment;
    const emptySpan = '<span></span>';

    if (page.targetType === 'text/markdown' && page.html === page.markdown) {
      return (
        <pre className={styles.markdown}>
          <code>{page.markdown}</code>
        </pre>
      );
    }

    return (
      <Wrapper>
        <div
          data-test-id="asset-page-content"
          className={styles.page}
          // workaround for fixing margins on empty terms page
          display-if={page.html !== emptySpan}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: page.html }}
        />
        <UnderlyingAPITermsSelector
          display-if={isTermsPage(page) && isAPIGroup && hasUnderlyingTerms}
        />
      </Wrapper>
    );
  }

  renderSpinner() {
    return (
      <div className={styles.spinner}>
        <Spinner size="l" />
      </div>
    );
  }

  render() {
    const { page, isContentLoading, hasTitle } = this.props;
    const { hasNotebooks, isPlayingNotebook } = this.state;
    let content;

    if (isContentLoading) {
      content = this.renderSpinner();
    } else {
      content = page.html ? this.renderPageHTML() : this.renderEmptyPage();
    }

    return (
      <article
        id="asset-page-content"
        className={styles.assetPage}
        data-test-id="asset-page"
        ref={(ref) => {
          this.pageElement = ref;
        }}
        aria-labelledby="asset-page-name"
      >
        <div className={styles.header}>
          <h2
            className={classNames(
              { 'visually-hidden': !hasTitle },
              styles.pageName
            )}
            id="asset-page-name"
            data-test-id="asset-page-name"
          >
            {page.name}
          </h2>
        </div>
        <div className={styles.content}>
          {content}
          <PlayNotebook
            display-if={hasNotebooks}
            onPlayNotebook={this.handlePlayNotebooks}
            isPlayingNotebook={isPlayingNotebook}
          />
        </div>
      </article>
    );
  }
}
