import searchExperienceContextSelector from './selectors/searchExperience';

const contextMetadataMapping = {
  home: searchExperienceContextSelector,
  page: searchExperienceContextSelector
};

export const getContextMetadata = (route, state) => {
  const contextMetadataFn = route && contextMetadataMapping[route.id];

  if (!contextMetadataFn) {
    return null;
  }

  return contextMetadataFn(state);
};
