import config from 'config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withAnalytics } from '@mulesoft/exchange-react-hooks';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { assetProperties } from '~/analytics/events/assets';
import { withAssetFromRootStore } from '~/contexts/RootStoreContext';
import withLayoutContext from '~/components/LayoutContextProvider';
import { EVENT_TYPES } from '~/analytics/events';
import * as routesUtils from '~/utils/routes';
import Detail from './Detail';

const mapStateToProps = (
  state,
  { assetFromRootStore, match, route, isReadOnly }
) => {
  const params = routesUtils.decodeParams(match.params);
  const asset = assetsSelectors.asset(state, params) || assetFromRootStore;
  const { id: routeId, isDraft } = route;
  const isEdit = routeId === 'pageEdit';
  const assetVersions = assetsSelectors.assetVersions(state, asset);
  const isContentLoading = portalsSelectors.isPortalLoading(state);

  return {
    asset,
    assetVersions,
    isDraft,
    isEdit,
    canEdit: isReadOnly ? false : assetsSelectors.canEdit(state, { ...params }),
    isContentLoading,
    portal: portalsSelectors.portal(state, { ...params, isDraft }),
    pagePath: params.pagePath || config.defaultPageName
  };
};

const mapDispatchToProps = (dispatch, { trackEvent }) => ({
  onUpdateLabels: (payload) => dispatch(assetsActions.updateLabels(payload)),
  onUpdateContactInfo: (asset, actionMethod, fieldName, fieldValue) =>
    dispatch(assetsActions[actionMethod]({ asset, [fieldName]: fieldValue })),
  onAddNewVersion: (asset) =>
    trackEvent(EVENT_TYPES.NEW_VERSION_ADDED, assetProperties({ asset })),
  onNewLabelClick: () => trackEvent(EVENT_TYPES.NEW_TAG_CLICKED),
  onLeaveCreatingLabelState: () => trackEvent(EVENT_TYPES.TAG_CREATION_CANCELED)
});

export default compose(
  withAssetFromRootStore,
  withRouter,
  withAnalytics,
  withLayoutContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(Detail);
