import { getActiveOrganization } from '~/utils/organization';

export function isLoggedIn(profile) {
  if (!profile) {
    const error = new Error('Must be logged in to access to this page.');

    error.status = 401;
    throw error;
  }
}

export function isMasterOrgAdmin(profile) {
  isLoggedIn(profile);

  if (!profile.organization.isExchangeAdmin) {
    const error = new Error('User must be an admin to access this page.');

    error.status = 403;
    throw error;
  }
}

export function isContributor(profile) {
  isLoggedIn(profile);

  const activeOrganization = getActiveOrganization(profile);

  if (!activeOrganization || !activeOrganization.isExchangeContributor) {
    const error = new Error('User must be a contributor to access this page.');

    error.status = 403;
    throw error;
  }
}

export function hasCreateAssetPermissions(profile) {
  isLoggedIn(profile);

  const activeOrganization = getActiveOrganization(profile);

  if (
    !activeOrganization ||
    !(
      activeOrganization.isExchangeContributor ||
      activeOrganization.isExchangeCreator
    )
  ) {
    const error = new Error(
      'User must have asset creation permissions to access this page.'
    );

    error.status = 403;
    throw error;
  }
}

export function isAssetContributor(asset) {
  if (!asset || asset.permissions.indexOf('edit') === -1) {
    const error = new Error(
      'User must be a contributor of the asset to access this page.'
    );

    error.status = 403;
    throw error;
  }
}

export function isMasterOrganizationExchangeAdmin(profile) {
  return profile && profile.isMasterOrganizationExchangeAdmin;
}

export function isExchangeAdminOfOrganization(profile, organization) {
  const organizationFromProfile = profile.contributorOfOrganizations.find(
    ({ id }) => id === organization.id
  );

  return organizationFromProfile
    ? organizationFromProfile.isExchangeAdmin
    : false;
}

export function isAdminOfSomeOrganization(profile) {
  return profile?.contributorOfOrganizations?.some(
    (organization) => organization?.isExchangeAdmin
  );
}
