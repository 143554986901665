import config from 'config';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import customMiddlewares from '~/domains/middlewares';
import createRootReducer from '~/domains/reducers';
import { handleError } from '~/domains/errors/actions';
import { thunk } from '~/portals/store';
import { isClient } from '~/utils/window';

export default function configureStore(history, initialState) {
  const middlewares = [
    thunk({ errorHandler: handleError }),
    routerMiddleware(history)
  ];

  if (config.analytics.apiKey) {
    const analyticsMiddleware = customMiddlewares.analyticsMiddleware();

    middlewares.push(analyticsMiddleware);
  }

  if (isClient()) {
    middlewares.push(customMiddlewares.changeFavicon);
  }

  if (config.isLocalEnvironment && config.showReduxLogger && isClient()) {
    // eslint-disable-next-line global-require
    const { logger } = require(`redux-logger`);

    middlewares.push(logger);
  }

  const middleware = applyMiddleware(...middlewares);
  const enhancers = getEnhancers(middleware);
  const store = createStore(
    createRootReducer(history),
    initialState,
    enhancers
  );

  store.context = RootStoreContext;

  return store;
}

function getEnhancers(middleware) {
  // eslint-disable-next-line no-underscore-dangle
  if (process.env.__DEVELOPMENT__ && isClient()) {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('@redux-devtools/extension');

    return composeWithDevTools(middleware);
  }

  return compose(middleware);
}
