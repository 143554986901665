import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { Prompt } from 'react-router-dom';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import DeletePage from '~/components/DeletePage';
import PageEditor from '~/components/PageEditor';
import { isAPIGroup } from '~/utils/types';
import { isTermsPage } from '~/utils/page';
import StatelessPageName from './StatelessPageName';
import styles from './StatelessPageEdit.css';

const LEAVE_MESSAGE =
  'You have unsaved changes. Would you like to leave anyway?';

class StatelessPageEdit extends React.PureComponent {
  static propTypes = {
    asset: AssetPropType,
    isContentLoading: PropTypes.bool,
    page: PropTypes.object,
    pages: PropTypes.arrayOf(PropTypes.object),
    onPageNameChange: PropTypes.func,
    pageEditorState: PropTypes.object,
    onFetchNotebookClient: PropTypes.func,
    onFileUpload: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
    onPageContentChange: PropTypes.func,
    onAddNotebook: PropTypes.func,
    onPlayNotebook: PropTypes.func,
    onPlayNotebookSnippet: PropTypes.func,
    canDeletePage: PropTypes.bool,
    canRenamePage: PropTypes.bool,
    hasTitle: PropTypes.bool,
    hasUnderlyingTerms: PropTypes.bool
  };

  componentDidMount() {
    global.addEventListener('beforeunload', this.handleLeavePage);
  }

  componentWillUnmount() {
    global.removeEventListener('beforeunload', this.handleLeavePage);
  }

  isForgettingToSave = () => {
    const { page, pageEditorState } = this.props;

    if (!page || !pageEditorState) {
      return false;
    }

    return pageEditorState.hasUnsavedChanges;
  };

  handleLeavePage = (event) => {
    if (this.isForgettingToSave()) {
      if (event) {
        /* eslint-disable no-param-reassign */
        event.returnValue = LEAVE_MESSAGE;
      }

      return LEAVE_MESSAGE;
    }

    delete event.returnValue;

    // eslint-disable-next-line
    return;
  };

  handleChangeContent = ({ value }) => {
    this.props.onPageContentChange(value);
  };

  handleRenamePage = (pageName) => {
    this.props.onPageNameChange(pageName);
  };

  renderEditor = () => {
    const {
      asset,
      page,
      pages,
      pageEditorState,
      onFetchNotebookClient,
      onFileUpload,
      onAddNotebook,
      onPlayNotebook,
      onPlayNotebookSnippet,
      hasUnderlyingTerms
    } = this.props;
    const showNotebookOption =
      asset.type === 'rest-api' || asset.type === 'custom';

    return (
      <div data-test-id="editor" className={styles.editorPage}>
        <PageEditor
          className={styles.editor}
          markdown={pageEditorState.pageContent}
          name="markdown"
          onChange={this.handleChangeContent}
          showNotebookOption={showNotebookOption}
          onFetchNotebookClient={onFetchNotebookClient}
          onUpload={onFileUpload}
          onAddNotebook={onAddNotebook}
          onPlayNotebook={onPlayNotebook}
          onPlayNotebookSnippet={onPlayNotebookSnippet}
          asset={asset}
          page={page}
          pages={pages}
        />
        <span
          className={styles.underlyingApisNote}
          display-if={
            isAPIGroup(asset) && isTermsPage(page) && hasUnderlyingTerms
          }
          data-test-id="underlying-apis-note"
        >
          <strong>NOTE:</strong> Terms and conditions of underlying APIs will be
          automatically displayed underneath group terms and conditions.
        </span>
      </div>
    );
  };

  renderSpinner = () => (
    <div className={styles.spinner} data-test-id="page-edit-loading">
      <Spinner size="l" />
    </div>
  );

  render() {
    const {
      asset,
      pageEditorState,
      onOpenDeleteModal,
      page,
      pages,
      isContentLoading,
      canDeletePage,
      canRenamePage,
      hasTitle
    } = this.props;

    return (
      <div data-test-id="page-edit" className={styles.pageEdit}>
        <Prompt
          message={() => {
            if (this.isForgettingToSave()) {
              return LEAVE_MESSAGE;
            }

            return true;
          }}
        />
        <div
          display-if={!isContentLoading}
          data-test-id="page-name-bar"
          className={styles.pageNameBar}
        >
          <StatelessPageName
            pageName={pageEditorState.pageName}
            page={page}
            pages={pages}
            onRenamePage={this.handleRenamePage}
            display-if={!isContentLoading && hasTitle}
            canRenamePage={canRenamePage}
          />
          <DeletePage
            asset={asset}
            display-if={!isContentLoading && canDeletePage}
            onOpenDeleteModal={onOpenDeleteModal}
          />
        </div>
        <div
          display-if={isContentLoading}
          className={styles.pageLoading}
          data-test-id="page-creating-loading"
        >
          <ContentLoader height={37} viewBox="0 0 120 20">
            <rect x={0} y={0} rx={3} ry={3} width="100%" height={37} />
          </ContentLoader>
        </div>
        {isContentLoading ? this.renderSpinner() : this.renderEditor()}
      </div>
    );
  }
}

export default StatelessPageEdit;
