import escapeHTML from 'escape-html';
import escapeStringRegexp from 'escape-string-regexp';

const queryRegExp = (search) => {
  const queries = search.split(/ |,/).filter((value) => value);

  return new RegExp(
    `(.*?)(${queries.map(escapeStringRegexp).join('|')})`,
    'ig'
  );
};

/**
 * Takes a string and a search param and highlight matches with <em>
 * tokenizing the search param by " " and ",". Returns null if no match.
 *
 * Example: highlight('my long value', 'long value')
 * // 'my <em>long</em> <em>value</em>'
 *
 * @param value
 * @param search
 */
export const highlightMatches = (value, search) => {
  const regExp = queryRegExp(search);
  let result = '';
  let matches;
  let lastIndex;

  // eslint-disable-next-line no-cond-assign
  while ((matches = regExp.exec(value))) {
    result += escapeHTML(matches[1]);
    result += `<em>${escapeHTML(matches[2])}</em>`;
    // eslint-disable-next-line prefer-destructuring
    lastIndex = regExp.lastIndex;
  }

  if (result) {
    result += escapeHTML(value.substr(lastIndex));

    return result;
  }

  return null;
};

/**
 * Converts a string with underscore to camelCase
 * @param {String} string with underscores
 */
export function camelizeUnderscore(string) {
  return string.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
}

/**
 * Converts an object with underscore props to camelCase props
 * @param {Object} object with underscore props
 */
export function camelizeUnderscoreObjProps(obj) {
  return applyPropsConversion(obj, camelizeUnderscore);
}

/**
 * Converts an object with uppercase props to lowercase props
 * @param {Object} object with uppercase props
 */
export function lowercaseProps(obj) {
  return applyPropsConversion(obj, (key) => key.toLowerCase());
}

function applyPropsConversion(obj, conversion) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Array) {
    return obj.map((prop) => applyPropsConversion(prop, conversion));
  }

  return Object.keys(obj).reduce((result, key) => {
    let prop = obj[key];

    prop = applyPropsConversion(prop, conversion);

    // eslint-disable-next-line no-param-reassign
    result[conversion(key)] = prop;

    return result;
  }, {});
}

export function sanitizeRegExpString(text) {
  const matchOperatorsRegex = /[#-.]|[[-^]|[?|{}]/g;

  return text.replace(matchOperatorsRegex, '\\$&');
}

export function splitMatchingCharacters(label, search) {
  return label.split(new RegExp(`(${sanitizeRegExpString(search)})`, 'gi'));
}

export function slugify(input) {
  return input
    ? input
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '')
    : '';
}
