import PropTypes from 'prop-types';
import React from 'react';
import config from 'config';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import { isValidPageName, isPredefinedPageName } from '~/utils/page';
import styles from './PageName.css';

class PageName extends React.PureComponent {
  static propTypes = {
    onRenamePage: PropTypes.func,
    page: PropTypes.object,
    pages: PropTypes.arrayOf(PropTypes.object),
    canRenamePage: PropTypes.bool
  };

  state = {
    isValid: true,
    isRenaming: false,
    isDirty: false,
    pageName: this.props.page.name,
    canRenamePage: this.props.canRenamePage
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.page.name !== nextProps.page.name) {
      this.setState({
        pageName: nextProps.page.name,
        canRenamePage: nextProps.canRenamePage,
        isValid: true,
        isRenaming: false
      });
    }
  }

  savePageName = async () => {
    const { page, pages, onRenamePage } = this.props;
    const { pageName } = this.state;
    const trimmedPageName = pageName.trim();

    if (
      trimmedPageName === page.name ||
      !isValidPageName(trimmedPageName) ||
      this.pageDoesAlreadyExist(trimmedPageName, pages)
    ) {
      this.input.blur();

      return false;
    }

    this.setState({ isValid: true, isRenaming: true });
    await onRenamePage({
      pagePath: trimmedPageName,
      pageName: trimmedPageName,
      oldPagePath: page.path
    });
    this.input.blur();

    return true;
  };

  afterPageSaveHandler = (canSave) => {
    this.setState({ isRenaming: false });
    if (!canSave) {
      this.setState({ pageName: this.props.page.name, isValid: true });
    }
  };

  handleBlur = () => {
    this.savePageName().then(this.afterPageSaveHandler);
  };

  handleChange = (value) => {
    const pageName = value;
    const trimmedPageName = pageName.trim();
    const { page, pages } = this.props;

    const invalid =
      trimmedPageName !== page.name &&
      (!isValidPageName(trimmedPageName) ||
        this.pageDoesAlreadyExist(trimmedPageName, pages));

    this.setState({ pageName, isDirty: true, isValid: !invalid });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.savePageName().then(this.afterPageSaveHandler);
    }

    if (event.key === 'Escape') {
      this.setState({ pageName: this.props.page.name, isValid: true }, () => {
        this.input.blur();
      });
    }
  };

  pageDoesAlreadyExist = (pageName, pages) =>
    pages.find((page) => page.name === pageName) ||
    isPredefinedPageName(pageName);

  render() {
    const { pageName, isValid, isDirty, isRenaming, canRenamePage } =
      this.state;

    return (
      <div className={styles.pageName} data-test-id="page-name">
        <TextField
          // eslint-disable-next-line no-return-assign
          setRef={(ref) => (this.input = ref)}
          aria-invalid={!isValid}
          isDirty={isDirty}
          isValid={isValid}
          aria-label="Page title"
          data-test-id="page-name-input"
          name="page-name-input"
          type="text"
          className={styles.pageNameInput}
          onKeyDown={this.handleKeyDown}
          onChange={({ value }) => this.handleChange(value)}
          onBlur={this.handleBlur}
          value={pageName}
          maxLength={config.maxPageNameLength}
          disabled={isRenaming || !canRenamePage}
        />
      </div>
    );
  }
}

export default PageName;
