import config from 'config';

export const isManaged = ({ type }) => type === 'managed';

export const hasManagedApiInstances = ({
  asset: { instances },
  filterDeprecated,
  isPublicPortal
}) => {
  return !!instances?.some((instance) => {
    if (filterDeprecated && instance.deprecated) {
      return false;
    }
    if (isPublicPortal && !instance.isPublic) {
      return false;
    }

    return isManaged(instance);
  });
};

export const apiManagerURI = (organizationDomain) =>
  `${config.apiManagerPath}/${organizationDomain}`;

export const apiManagerInstanceUrl = (instance, masterOrganization) => {
  const { groupId, environmentId, id } = instance;
  const { domain } = masterOrganization;
  const baseUri = apiManagerURI(domain);

  return `${baseUri}/#/organizations/${groupId}/environments/${environmentId}/apis/${id}/settings`;
};
