import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setModalType, registerModal } from '~/components/Modals';
import RootStoreContext from '~/contexts/RootStoreContext';
import {
  actions as customizationActions,
  selectors as customizationSelectors
} from '~/domains/customization';
import { selectors as commonSelectors } from '~/domains/common';
import { actions as modalActions } from '~/domains/modal';
import { isPortalHomePage } from '~/utils/routes';
import DiscardModal from './DiscardModal';

const mapStateToProps = (state) => {
  const location = commonSelectors.location(state);
  const domain = customizationSelectors.organizationDomain(state);
  const organization = { domain };
  const isDiscarding = customizationSelectors.isDiscarding(state);
  const getPath = commonSelectors.getPortalPathFn(state);

  return {
    organization,
    location,
    getPath,
    isDiscarding
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    location: { pathname },
    organization,
    getPath
  } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    ...ownProps,
    ...stateProps,
    onClose: () => dispatch(modalActions.closeModal()),
    onDiscard: () => {
      dispatch(customizationActions.discardCustomizationDraft());

      if (!isPortalHomePage(pathname, organization, getPath)) {
        dispatch(push(getPath('home', { organization })));
      }
    }
  };
};

export default compose(
  registerModal,
  setModalType('CustomizationDiscardModal'),
  connect(mapStateToProps, null, mergeProps, { context: RootStoreContext })
)(DiscardModal);
