export default {
  ANG_SEARCH_OPT_OUT: {
    name: 'ANG search disabled'
  },
  ANG_SEARCH_OPT_IN: 'ANG search enabled',
  ANG_OPT_IN_VIEWED: {
    name: 'ANG opt-in Viewed',
    required: ['announcementVisible']
  },
  ANG_OPT_OUT_VIEWED: {
    name: 'ANG opt-out Viewed',
    required: ['announcementVisible']
  },
  SEARCH_EXECUTED: 'Search executed',
  SEARCH_FILTER_POPULATED: {
    name: 'Search filter populated',
    required: ['filter', 'query', 'context', 'count', 'values', 'responseTime']
  }
};
