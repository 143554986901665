import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as customizationSelectors } from '~/domains/customization';
import { isPortalHomePage } from '~/utils/routes';
import CustomizeButton from './CustomizeButton';

const mapStateToProps = (state, { isEditMode }) => {
  const location = commonSelectors.location(state);
  const domain = customizationSelectors.organizationDomain(state);
  const getPath = commonSelectors.getPortalPathFn(state);
  const isHome = isPortalHomePage(location.pathname, { domain }, getPath);
  const canEditPortal = customizationSelectors.canEditPortalPage(state);

  return {
    isVisible: !isEditMode && canEditPortal && isHome,
    organization: { domain },
    getPath
  };
};

export default connect(mapStateToProps, null, null, {
  context: RootStoreContext
})(CustomizeButton);
