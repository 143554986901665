import { format } from 'url';
import config from 'config';
import { sanitizePayload } from '~/analytics/payload';
import { EVENT_TYPES } from '~/analytics/events';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '~/domains/assets';
import {
  actions as commonActions,
  selectors as commonSelectors
} from '~/domains/common';
import {
  actions as searchActions,
  selectors as searchSelectors
} from '~/domains/search';
import * as arrayUtils from '~/utils/arrays';
import { parseSearch } from '~/utils/location';
import { isClient } from '~/utils/window';

const resolve = async ({
  dispatch,
  getState,
  location,
  helpers: { assetTypesConfiguration, trackEvent }
}) => {
  const state = getState();
  const query = parseSearch(location.search);
  const isANGSearchEnabled = searchSelectors.isANGSearchEnabled(state);
  const isSearchTrigger = commonSelectors.hasHomeQueryChanged(state, {
    query
  });
  const promises = [];
  const isClientSide = isClient();
  const isSearchPermitted =
    !isANGSearchEnabled || !isSearchTrigger || isClientSide;

  if (isANGSearchEnabled) {
    await dispatch(searchActions.getRootOrganizations());
  }

  if (isSearchTrigger && !isANGSearchEnabled) {
    promises.push(
      dispatch(assetsActions.getAssets({ query, assetTypesConfiguration }))
    );
  }

  if (isSearchPermitted) {
    dispatch(commonActions.setHomeQuery(query));
    dispatch(
      commonActions.setHeader(buildHeader(query, assetTypesConfiguration))
    );
  }

  await Promise.all(promises);

  if (isSearchPermitted && isSearchTrigger) {
    trackSearchExecuted({
      getState,
      trackEvent,
      query,
      assetTypesConfiguration
    });
  }
};

export function trackSearchExecuted({ getState, trackEvent, query = {} }) {
  const newState = getState();
  const isANGSearchEnabled = searchSelectors.isANGSearchEnabled(newState);

  if (isANGSearchEnabled) {
    return;
  }

  const assets = assetsSelectors.listAssets(newState);
  const resultCount = assets.length;

  trackEvent(EVENT_TYPES.SEARCH_EXECUTED, {
    query: sanitizePayload(query),
    resultCount,
    context: {
      searchExperience: 'exchange'
    }
  });
}

export function buildHeader(query = {}, assetTypesConfiguration) {
  const queryTypes = arrayUtils.convertToArray(query.type);
  const typesNames = queryTypes
    .reduce((accum, current) => {
      const type = assetTypesConfiguration[current];

      if (!type) {
        return accum;
      }

      const name = type.filter;

      if (accum.includes(name)) {
        return accum;
      }

      return [...accum, name];
    }, [])
    .join(', ');

  return {
    title: typesNames.length ? `${typesNames} - ${config.title}` : config.title,
    description: config.description.replace(
      // eslint-disable-next-line no-template-curly-in-string
      '${ASSETS}',
      typesNames.length ? `${typesNames}` : 'assets'
    ),
    canonical: format({ ...config.mulesoft.site, query: { type: query.type } }),
    meta: {
      property: {
        'og:site_name': config.title
      }
    }
  };
}

export default resolve;
