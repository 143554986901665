import config from 'config';
import { push } from 'connected-react-router';
import debug from 'debug';
import React from 'react';
import ReactDOM from 'react-dom';
import NavigationBar from '~/components/NavigationBar';
import { EVENT_TYPES } from '~/analytics/events';
import { ONE_TRUST_ENABLED } from '~/domains/featureFlags/keys';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import * as analyticsActions from '~/domains/analytics/actions';
import * as commonActions from '~/domains/common/actions';
import * as commonSelectors from '~/domains/common/selectors';
import * as sessionActions from '~/portals/store/domains/session/actions';
import * as scriptsUtils from '~/utils/scripts';

export const renderNavbar = async (rootStore, portalsStore) => {
  const onCloseNavbarMenu = () =>
    rootStore.dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.NAVBAR_MENU_CLOSED)
    );
  const onOpenNavbarMenu = () =>
    rootStore.dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.NAVBAR_MENU_OPENED)
    );
  const onOrganizationChange = (organization) => {
    const homeQuery = commonSelectors.homeQuery(rootStore.getState());
    const pushFn = (...args) => rootStore.dispatch(push(...args));

    [rootStore, portalsStore].forEach(({ dispatch }) =>
      dispatch(
        sessionActions.updateActiveOrganization(organization, homeQuery, pushFn)
      )
    );
  };
  const onSignOut = () => {
    rootStore.dispatch(analyticsActions.analyticsLogout());
    rootStore.dispatch(commonActions.logout());
  };
  const state = rootStore.getState();
  const isStudio = commonSelectors.isStudio(state);
  const profile = commonSelectors.profile(state);
  const featureFlags = featureFlagsSelectors.featureFlags(state);
  const isOneTrustEnabled = featureFlags && featureFlags[ONE_TRUST_ENABLED];
  const log = debug('exchange:navbar');
  const navbarElement = document.getElementById('react-navbar-view');

  if (!navbarElement || !global.__RENDER_NAVBAR__) {
    return;
  }

  await scriptsUtils.loadScript(config.navbar.uri);

  const shouldForceRelogin = !profile || profile.type === 'proxy';

  if (shouldForceRelogin) {
    try {
      const loginService = global.NavBar.loginServiceFactory();
      const csProfile = await loginService.getProfileWithoutAccessToken({
        client_id: config.app.clientId
      });

      window.location.assign(
        `${config.contextPath}/login?domain=${csProfile.organization.domain}`
      );
    } catch (error) {
      log('Could not get profile info', error);
    }
  }

  const navbar = (
    <NavigationBar
      profile={profile}
      isStudio={isStudio}
      isOneTrustEnabled={isOneTrustEnabled}
      onClose={onCloseNavbarMenu}
      onOpen={onOpenNavbarMenu}
      onOrganizationChange={onOrganizationChange}
      onSignOut={onSignOut}
    />
  );

  ReactDOM.render(navbar, navbarElement);
};
