import Radio from '@mulesoft/anypoint-components/lib/Radio';
import RadioGroup from '@mulesoft/anypoint-components/lib/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { FormModal } from '~/components/Modals';
import TextAreaWithValidation from '~/components/common/TextAreaWithValidation';
import styles from './ANGOptOutModal.css';

class ANGOptOutModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
  };

  state = {
    form: { optOutReason: null },
    reasonText: '',
    isReasonTextDirty: false
  };

  notEmptyValidation = {
    isNotEmpty: {
      validate: ({ value }) => !!value,
      message: 'Required'
    }
  };

  handleChange = ({ value }) => {
    this.setState({ form: value, reasonText: '', isReasonTextDirty: false });
  };

  handleOtherReasonTextChange = ({ value }) => {
    this.setState({ reasonText: value, isReasonTextDirty: true });
  };

  handleMissingFilterTextChange = ({ value }) => {
    this.setState({ reasonText: value, isReasonTextDirty: true });
  };

  handleSubmit = () => {
    const { reasonText, form } = this.state;
    const { optOutReason } = form;
    let otherReasonText = '';
    let missingFilterText = '';

    if ((this.isOtherReason() || this.isMissingFilter()) && !reasonText) {
      this.setState({ isReasonTextDirty: true });

      return;
    }

    if (this.isOtherReason()) {
      otherReasonText = reasonText;
    } else if (this.isMissingFilter()) {
      missingFilterText = reasonText;
    }

    this.props.onSubmit({ optOutReason, otherReasonText, missingFilterText });
  };

  isOtherReason = () => this.state.form.optOutReason === 'other';

  isMissingFilter = () => this.state.form.optOutReason === 'missing-filter';

  renderBody() {
    const { form, reasonText, isReasonTextDirty } = this.state;

    return (
      <React.Fragment>
        <p>
          Thanks for trying the new search features in Exchange. You can enable
          them again at any time.
        </p>
        <p className={styles.mandatory}>Why are you switching back?</p>
        <RadioGroup
          className={styles.radioGroup}
          orientation="vertical"
          name="optOutReason"
          value={form.optOutReason}
          testId="opt-out-reason"
          skipDefaultValue
          required
        >
          <Radio
            testId="search-result-radio"
            label="I can’t find the assets I’m looking for"
            value="trouble-finding-assets"
          />
          <Radio
            testId="see-more-results-radio"
            label="I want to see more search results"
            value="need-more-results"
          />
          <Radio
            testId="filter-use-radio"
            label="I don’t find the filters useful"
            value="filters-not-useful"
          />
          <Radio
            testId="missing-filter-radio"
            label="A filter I need is missing"
            value="missing-filter"
          />
          <TextAreaWithValidation
            className={styles.reasonTextarea}
            aria-label="Opt out reason"
            testId="missing-filter"
            placeholder="What filter do you need?"
            name="missingFilterText"
            containerClassName={styles.reasonContainer}
            onChange={this.handleMissingFilterTextChange}
            display-if={this.isMissingFilter()}
            required={this.isMissingFilter()}
            isDirty={isReasonTextDirty}
            validate={this.notEmptyValidation}
            value={reasonText}
          />
          <Radio
            testId="grid-view-preferred-radio"
            label="I prefer the grid view"
            value="grid-view-preferred"
          />
          <Radio
            testId="list-view-preferred-radio"
            label="I prefer the list (table) view"
            value="list-view-preferred"
          />
          <Radio testId="other-reason-radio" label="Other" value="other" />
          <TextAreaWithValidation
            className={styles.reasonTextarea}
            aria-label="Opt out reason"
            testId="other-reason"
            placeholder="Enter your feedback"
            name="otherReasonText"
            containerClassName={styles.reasonContainer}
            onChange={this.handleOtherReasonTextChange}
            display-if={this.isOtherReason()}
            required={this.isOtherReason()}
            isDirty={isReasonTextDirty}
            validate={this.notEmptyValidation}
            value={reasonText}
          />
        </RadioGroup>
      </React.Fragment>
    );
  }

  render() {
    const { form } = this.state;
    const { optOutReason } = form;

    return (
      <FormModal
        className={styles.modal}
        form={form}
        title="Back to the legacy search"
        body={this.renderBody()}
        disableText="Please select a reason"
        disableSubmitButton={!optOutReason}
        submitButtonText="Switch back"
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        onCancel={this.props.onClose}
      />
    );
  }
}

export default ANGOptOutModal;
