import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as reviewsActions from '@mulesoft/exchange-ui-portals-store/lib/domains/reviews/actions';
import * as reviewsSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/reviews/selectors';
import * as commonSelectors from '~/domains/common/selectors';

import Reviews from './Reviews';

const mapStateToProps = (state, { asset }) => ({
  reviews: reviewsSelectors.comments(state, asset),
  profile: commonSelectors.profile(state)
});

const mapDispatchToProps = (dispatch, { asset }) => ({
  onCreateReview: (review) =>
    dispatch(reviewsActions.createReview({ ...asset, review })),
  onLoadReviews: (nextAsset) => {
    const navigationParams = {
      groupId: asset.groupId,
      assetId: asset.assetId,
      minorVersion: asset.minorVersion,
      organizationId: nextAsset.organization.id,
      version: nextAsset.version
    };

    return dispatch(reviewsActions.getReviews(navigationParams));
  },
  onDeleteReview: (review) =>
    dispatch(reviewsActions.deleteReview({ ...asset, reviewId: review.id })),
  onEditReview: (review) =>
    dispatch(reviewsActions.editReview({ ...asset, review }))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Reviews);
