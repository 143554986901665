import React from 'react';
import { useDispatch, createStoreHook } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  AssetPortalContext,
  useGetPath,
  usePush,
  useAnalyticsTracking,
  useSetDocumentMeta
} from '@mulesoft/exchange-react-hooks';
import RootStoreContext from '~/contexts/RootStoreContext';
import * as commonActions from '~/domains/common/actions';
import { actions as analyticsActions } from '~/domains/analytics';
import { resolveRouteParams } from '~/portals/utils/config';

const useStore = createStoreHook(RootStoreContext);

export const useCustomRoute = ({ match, asset, route }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const rootStore = useStore();
  const { routes } = React.useContext(AssetPortalContext);
  const resolvedRoute = resolveRouteParams(match, location, routes);
  const getPath = useGetPath();
  const push = usePush();
  const trackEvent = useAnalyticsTracking();
  const setDocumentMeta = useSetDocumentMeta();

  const routeTitle = resolvedRoute?.title ?? 'Custom';
  const { params } = match;

  React.useEffect(() => {
    if (!asset) {
      return;
    }

    if (!route) {
      return;
    }

    if (resolvedRoute?.onLoad) {
      const helpers = {
        getPath,
        push,
        trackEvent,
        setDocumentMeta
      };

      const onLoadResult = resolvedRoute.onLoad({
        asset,
        helpers,
        params,
        isDraft: route.isDraft,
        isEdit: route.isEdit
      });

      if (onLoadResult) {
        dispatch(onLoadResult);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, match, resolvedRoute, route]);

  React.useEffect(() => {
    rootStore.dispatch(
      commonActions.setRoute({
        id: routeTitle,
        sectionId: 'assetPortal',
        params
      })
    );

    rootStore.dispatch(
      analyticsActions.trackPage({ name: routeTitle }, params)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeTitle]);

  return resolvedRoute;
};
