import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withLayoutContext from '~/components/LayoutContextProvider';
import RootStoreContext from '~/contexts/RootStoreContext';
import { EVENT_TYPES } from '~/analytics/events';
import { actions as analyticsActions } from '~/domains/analytics';
import { selectors as commonSelectors } from '~/domains/common';
import { getActiveOrganization } from '~/utils/organization';
import PublishButton from './PublishButton';

const mapStateToProps = (state, { isPublicPortal }) => {
  const isStudio = commonSelectors.isStudio(state);
  const profile = commonSelectors.profile(state);
  const organization = getActiveOrganization(profile);
  const canCreateAsset =
    !isPublicPortal &&
    !isStudio &&
    organization &&
    (organization.isExchangeContributor || organization.isExchangeCreator);

  return {
    canCreateAsset
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickNewAsset: () =>
    dispatch(analyticsActions.trackEvent(EVENT_TYPES.NEW_ASSET_CLICKED))
});

export default compose(
  withLayoutContext(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(PublishButton);
