import * as actions from '~/domains/customization/actions';
import { isClient } from '~/utils/window';

const resolve = async ({ dispatch, params: { pagePath } }) => {
  const fetchPromise = dispatch(actions.fetchCustomizationDraft());

  dispatch(actions.setCustomizationHeader({ isDraft: true }));

  if (isClient()) {
    dispatch(actions.fetchCustomPageMarkdown(pagePath), {
      skipErrorHandler: () => true
    }).catch((error) =>
      dispatch(actions.handleClientCustomPageDraftError(error, pagePath))
    );

    return;
  }

  await fetchPromise;
  await dispatch(actions.fetchCustomPageMarkdown(pagePath));
};

export default resolve;
