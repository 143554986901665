import PropTypes from 'prop-types';
import React from 'react';
import Select from '@mulesoft/anypoint-components/lib/Select';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import { removeFromArray } from '~/utils/arrays';
import AddPillButton from '~/components/AddPillButton';
import * as refUtils from '~/utils/refs';
import Category from './Category';
import styles from './Categories.css';

const mapOptions = (categories = []) =>
  categories.map((category) => ({
    label: category.displayName,
    value: category
  }));

class Categories extends React.Component {
  static propTypes = {
    asset: AssetPropType,
    organizationCategories: PropTypes.array.isRequired,
    onUpdateAssetCategory: PropTypes.func.isRequired,
    onRemoveAssetCategory: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    getPath: PropTypes.func.isRequired
  };

  state = {
    isCreating: false,
    currentCategories: this.props.asset.categories || []
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.asset.categories !== nextProps.asset.categories) {
      this.setState({
        currentCategories: nextProps.asset.categories || []
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategories.length > this.state.currentCategories.length
    ) {
      refUtils.focus(this.addCategoryRef);
    }
  }

  getAcceptedCategoryValues(assetCategory) {
    const { organizationCategories = [] } = this.props;
    const orgCategory = organizationCategories.find(
      (category) => category.tagKey === assetCategory.key
    ) || { acceptedValues: [] };

    return orgCategory.acceptedValues;
  }

  addCategoryRef = React.createRef();

  enterCreatingState = () => {
    this.setState({ isCreating: true });
  };

  leaveCreatingState = () => {
    this.setState({ isCreating: false });
  };

  addCategory = ({ itemsSelected }) => {
    this.setState({
      currentCategories: this.state.currentCategories.concat([
        {
          key: itemsSelected.value.tagKey,
          displayName: itemsSelected.value.displayName,
          value: []
        }
      ]),
      isCreating: false
    });
  };

  handleRemoveAssetCategory = (removedCategory) => {
    const { asset, onRemoveAssetCategory } = this.props;

    if (removedCategory.value.length > 0) {
      onRemoveAssetCategory({ ...asset, category: removedCategory });
    } else {
      const { currentCategories } = this.state;
      const removedCategoryIndex = currentCategories.findIndex(
        (category) => category.key === removedCategory.key
      );
      const newCategories = removeFromArray(currentCategories, {
        index: removedCategoryIndex
      });

      this.setState({
        currentCategories: newCategories
      });
    }
  };

  handleUpdateAssetCategory = (category) => {
    const { asset, onUpdateAssetCategory } = this.props;

    onUpdateAssetCategory({ ...asset, category });
  };

  render() {
    const {
      isEditable = false,
      organizationCategories = [],
      asset,
      getPath
    } = this.props;
    const { isCreating, currentCategories } = this.state;

    const applicableCategories = organizationCategories.filter(
      (organizationCategory) =>
        organizationCategory.assetTypeRestrictions &&
        (organizationCategory.assetTypeRestrictions.length === 0 ||
          organizationCategory.assetTypeRestrictions.includes(asset.type))
    );
    const filteredCategories = applicableCategories.filter(
      (organizationCategory) =>
        !currentCategories.some(
          (category) => category.key === organizationCategory.tagKey
        )
    );

    if (!isEditable && currentCategories.length === 0) {
      return null;
    }

    if (filteredCategories.length === 0 && currentCategories.length === 0) {
      return null;
    }

    return (
      <section
        data-test-id="asset-categories"
        aria-labelledby="categories-heading"
      >
        <h4 id="categories-heading">Categories</h4>
        <div className={styles.categories}>
          {currentCategories.map((category, index) => (
            <Category
              key={index}
              category={category}
              acceptedValues={this.getAcceptedCategoryValues(category)}
              isEditable={isEditable}
              onUpdateAssetCategory={this.handleUpdateAssetCategory}
              onRemoveAssetCategory={this.handleRemoveAssetCategory}
              getPath={getPath}
            />
          ))}
          <Select
            testId="category"
            display-if={isCreating}
            name="category"
            placeholder="Select a category"
            noResultsText="No results"
            options={mapOptions(filteredCategories)}
            onChange={this.addCategory}
            onBlur={this.leaveCreatingState}
            className={styles.select}
            openAfterFocus
            autofocus
            autosize={false}
          />
          <AddPillButton
            display-if={!isCreating && isEditable && filteredCategories.length}
            data-test-id="add-category"
            ref={this.addCategoryRef}
            tabIndex="0"
            onClick={this.enterCreatingState}
          >
            Add category
          </AddPillButton>
        </div>
      </section>
    );
  }
}

export default Categories;
