import PropTypes from 'prop-types';
import React from 'react';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import Button from '@mulesoft/anypoint-components/lib/Button';
import styles from './ConfirmModal.css';

const propTypes = {
  onClose: PropTypes.func,
  onSaveForLater: PropTypes.func,
  onPublish: PropTypes.func,
  isSaving: PropTypes.bool,
  isPublishing: PropTypes.bool
};

const ConfirmModal = ({
  onClose,
  onSaveForLater,
  onPublish,
  isSaving,
  isPublishing
}) => {
  const labelId = 'customization-confirm-modal-title';

  return (
    <Modal
      renderInBody={false}
      className={styles.modal}
      onEsc={onClose}
      onClickOverlay={onClose}
      ariaLabelledBy={labelId}
      data-test-id="customization-confirm-modal"
    >
      <ModalHeader onClose={onClose} className={styles.header}>
        <h2 id={labelId} className={styles.title}>
          Publish changes?
        </h2>
      </ModalHeader>
      <ModalBody className={styles.body}>
        <p>Do you want to publish the changes to the public portal?</p>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button
          kind="tertiary"
          onClick={onSaveForLater}
          noFill
          testId="save-changes-button"
          isLoading={isSaving}
        >
          No, save for later
        </Button>
        <Button
          kind="primary"
          onClick={onPublish}
          testId="publish-changes-button"
          isLoading={isPublishing}
        >
          Yes, publish
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const noOp = () => {};

ConfirmModal.defaultProps = {
  onClose: noOp,
  onPublish: noOp,
  onSaveForLater: noOp
};

ConfirmModal.propTypes = propTypes;

export default ConfirmModal;
