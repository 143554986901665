import * as actions from './actionTypes';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST_STARTED:
      return [
        ...state,
        {
          identifier: action.identifier,
          params: action.params,
          status: 'STARTED'
        }
      ];

    case actions.REQUEST_SUCCESS:
      return state.map((request) => {
        if (
          request.identifier === action.identifier &&
          request.params === action.params &&
          request.status === 'STARTED'
        ) {
          return {
            identifier: action.identifier,
            params: action.params,
            status: 'SUCCEEDED'
          };
        }

        return request;
      });

    case actions.REQUEST_FAILURE:
      return state.map((request) => {
        if (
          request.identifier === action.identifier &&
          request.params === action.params &&
          request.status === 'STARTED'
        ) {
          return {
            identifier: action.identifier,
            params: action.params,
            status: 'FAILED'
          };
        }

        return request;
      });
    default:
      return state;
  }
}
