import PropTypes from 'prop-types';
import React from 'react';
import BasicCallout from '@mulesoft/exchange-ui-components/lib/components/BasicCallout';
import EmptySection from '@mulesoft/exchange-ui-components/lib/components/EmptySection';
import * as apiGroupUtils from '~/portals/api-group/utils';
import ApiGroupCard from './ApiGroupCard';
import RequestAccessButton from './RequestAccessButton';
import styles from './Detail.css';

const Detail = ({ asset, apiGroupInstances, isAdminMode }) => {
  if (apiGroupInstances.length > 0) {
    const { versionGroup } = asset;
    const isContradictory = apiGroupUtils.isAssetPublicPortalContradictory({
      asset,
      apiGroupInstances
    });
    const isExpanded = apiGroupInstances.length === 1;

    return (
      <React.Fragment>
        <BasicCallout
          className={styles.callout}
          testId="api-group-private-instances-callout"
          type="warning"
          title={`The following Group instances included in the public version ${versionGroup} are not public.`}
          subtitle="Make a group instance public to show it on the public portal."
          display-if={isAdminMode && isContradictory}
        />
        <span className={styles.header}>
          <h2 data-test-id="group-instances-title">Group Instances</h2>
          <RequestAccessButton
            asset={asset}
            testId="asset-action-request-access"
            className={styles.action}
          />
        </span>
        {apiGroupInstances.map((apiGroupInstance, i) => (
          <ApiGroupCard
            asset={asset}
            isExpanded={isExpanded}
            apiGroupInstance={apiGroupInstance}
            isAdminMode={isAdminMode}
            testId={`group-instance-${i}`}
            key={`group-instance-${i}`}
          />
        ))}
      </React.Fragment>
    );
  }

  return (
    <EmptySection
      className={styles.emptySection}
      title="No group instances available"
    >
      <p className={styles.emptyDescription}>
        Group instances provide a quick way to request access to one or more API
        instances at the same time.
      </p>
    </EmptySection>
  );
};

Detail.propTypes = {
  asset: PropTypes.object,
  apiGroupInstances: PropTypes.array.isRequired,
  isAdminMode: PropTypes.bool.isRequired
};

export default Detail;
