import { actions as clientApplicationsActions } from '~/domains/clientApplications';
import { selectors as commonSelectors } from '~/domains/common';
import { isLoggedIn } from '~/utils/validations/profile';
import { parseSearch } from '~/utils/location';

const resolve = async ({ dispatch, getState, location }) => {
  const profile = commonSelectors.profile(getState());

  isLoggedIn(profile);

  const masterOrganization = profile.organization;
  const query = parseSearch(location.search);

  await dispatch(
    clientApplicationsActions.loadApplications(masterOrganization.id, query)
  );
};

export default resolve;
