export const FETCH_CLIENT_APPLICATION_LIST_REQUEST =
  'Fetch client application list request';
export const FETCH_CLIENT_APPLICATION_LIST_SUCCESS =
  'Fetch client application list success';
export const FETCH_CLIENT_APPLICATION_LIST_FAILURE =
  'Fetch client application list failure';
export const FETCH_CLIENT_APPLICATION_REQUEST =
  'Fetch client application request';
export const FETCH_CLIENT_APPLICATION_SUCCESS =
  'Fetch client application success';
export const FETCH_CLIENT_APPLICATION_FAILURE =
  'Fetch client application failure';
export const CREATE_CLIENT_APPLICATION_REQUEST =
  'Create client application request';
export const CREATE_CLIENT_APPLICATION_SUCCESS =
  'Create client application success';
export const CREATE_CLIENT_APPLICATION_FAILURE =
  'Create client application failure';
export const RESET_CLIENT_SECRET_REQUEST = 'Reset client secret request';
export const RESET_CLIENT_SECRET_SUCCESS = 'Reset client secret success';
export const RESET_CLIENT_SECRET_FAILURE = 'Reset client secret failure';
export const EDIT_CLIENT_APPLICATION_REQUEST =
  'Edit client application request';
export const EDIT_CLIENT_APPLICATION_SUCCESS =
  'Edit client application success';
export const EDIT_CLIENT_APPLICATION_FAILURE =
  'Edit client application failure';
export const DELETE_CLIENT_APPLICATION_REQUEST =
  'Delete client application request';
export const DELETE_CLIENT_APPLICATION_SUCCESS =
  'Delete client application success';
export const DELETE_CLIENT_APPLICATION_FAILURE =
  'Delete client application failure';
export const LOAD_CLIENT_APPLICATION_SUCCESS =
  'Load client application success';
