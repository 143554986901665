import { push } from 'connected-react-router';
import { getPortalPath } from '~/utils/routes';
import { SET_PUBLIC_PORTAL_STATUS } from '../common/actionTypes';
import { actions as commonActions } from '~/domains/common';
import { actions as analyticsActions } from '~/domains/analytics';
import * as customizationService from '~/services/customizationService';
import * as commonSelectors from '~/domains/common/selectors';
import * as customizationSelectors from '~/domains/customization/selectors';
import { getArray, getHashMap } from '~/utils/arrays';
import { EVENT_TYPES } from '~/analytics/events';
import actionify from '~/utils/actionify';
import { actions as modalActions } from '~/domains/modal';
import { handleReorderingError } from '~/utils/errors';
import * as actionTypes from './actionTypes';

const DEFAULT_STATUS = 'enabled';
const DISABLED_STATUS = 'disabled';

export function fetchCustomizationData() {
  return (dispatch, getState) => {
    const state = getState();
    const customizationData = customizationSelectors.customizationData(state);

    if (customizationData) {
      return Promise.resolve(customizationData);
    }

    const profileOrganizationDomain =
      commonSelectors.profileOrganizationDomain(state);
    const organizationDomain =
      customizationSelectors.organizationDomain(state) ||
      profileOrganizationDomain;

    if (!organizationDomain) {
      return Promise.resolve();
    }

    const context = commonSelectors.context(state);

    return customizationService
      .getCustomization({ organizationDomain, context })
      .then((json) => {
        dispatch(fetchCustomizationDataSuccess(json, organizationDomain));

        if (profileOrganizationDomain === organizationDomain) {
          dispatch(setProfilePublicPortalStatus(json));
        }
      });
  };
}

export function fetchCustomizationStatus() {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = commonSelectors.profileOrganizationDomain(state);

    if (!organizationDomain) {
      return Promise.resolve();
    }

    const context = commonSelectors.context(state);

    return customizationService
      .getCustomizationStatus({ organizationDomain, context })
      .then((status) => {
        dispatch(setProfilePublicPortalStatus({ status }));
      })
      .catch((error) => {
        const status = error?.status === 404 ? DEFAULT_STATUS : DISABLED_STATUS;

        dispatch(setProfilePublicPortalStatus({ status }));
      });
  };
}

export function setProfilePublicPortalStatus({ status }) {
  return {
    type: SET_PUBLIC_PORTAL_STATUS,
    publicPortalStatus: status ?? DEFAULT_STATUS
  };
}

export function fetchCustomizationDataSuccess(
  { customization, pages, cookieConsentId, status },
  organizationDomain
) {
  return {
    type: actionTypes.FETCH_CUSTOMIZATION_DATA_SUCCESS,
    organizationDomain,
    data: {
      cookieConsentId: cookieConsentId || null,
      welcomeSection: customization.home || {},
      navbar: customization.navbar || {},
      status: status ?? DEFAULT_STATUS,
      customPages: pages ? getHashMap(pages, 'path') : {},
      customPagesOrder: pages ? pages.map((page) => page.path) : []
    }
  };
}

export function fetchCustomizationDraft() {
  return (dispatch, getState) => {
    const state = getState();
    const customizationDraft = customizationSelectors.customizationDraft(state);

    if (customizationDraft && customizationDraft.welcomeSection) {
      return Promise.resolve(customizationDraft);
    }

    dispatch(enableCustomizationLoading());

    const organizationDomain = customizationSelectors.organizationDomain(state);
    const context = commonSelectors.context(state);

    return customizationService
      .getCustomizationDraft({ organizationDomain, context })
      .then((json) => {
        dispatch(disableCustomizationLoading());
        dispatch(fetchCustomizationDraftSuccess(json, organizationDomain));
      })
      .catch((err) => {
        dispatch(disableCustomizationLoading());

        throw err;
      });
  };
}

export function fetchCustomizationDraftSuccess(
  { customization, pages, cookieConsentId, status, draft, pagesOrderRevision },
  organizationDomain
) {
  return {
    type: actionTypes.FETCH_CUSTOMIZATION_DRAFT_SUCCESS,
    organizationDomain,
    data: {
      draft,
      cookieConsentId: cookieConsentId ?? null,
      welcomeSection: customization.home || {},
      navbar: customization.navbar || {},
      status: status ?? DEFAULT_STATUS,
      customPages: pages ? getHashMap(pages, 'path') : {},
      customPagesOrder: pages ? pages.map((page) => page.path) : [],
      pagesOrderRevision
    }
  };
}

export function updateCustomizationTitle(value) {
  return (dispatch, getState) => {
    const organizationDomain =
      customizationSelectors.organizationDomain(getState());

    return dispatch({
      type: actionTypes.UPDATE_CUSTOMIZATION_TITLE,
      value,
      organizationDomain
    });
  };
}

export function uploadCustomizationResource({ file, organizationDomain }) {
  return async (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    return customizationService.uploadResource({
      context,
      file,
      organizationDomain
    });
  };
}

export function updateCustomizationText(value) {
  return (dispatch, getState) => {
    const organizationDomain =
      customizationSelectors.organizationDomain(getState());

    return dispatch({
      type: actionTypes.UPDATE_CUSTOMIZATION_TEXT,
      value,
      organizationDomain
    });
  };
}

export function updateCustomizationColor(value, colorType, destination) {
  return (dispatch, getState) => {
    const organizationDomain =
      customizationSelectors.organizationDomain(getState());

    return dispatch({
      type: actionTypes.UPDATE_CUSTOMIZATION_COLOR,
      value,
      colorType,
      destination,
      organizationDomain
    });
  };
}

export function updateImage({ url, file, imageKind }) {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const prevURL =
      typeof customizationSelectors[imageKind] === 'function' &&
      customizationSelectors[imageKind](state);

    if (prevURL && prevURL.startsWith('blob:')) {
      global.URL.revokeObjectURL(prevURL);
    }

    return dispatch({
      type: actionTypes.UPDATE_CUSTOMIZATION_IMAGE,
      url,
      file,
      imageKind,
      organizationDomain
    });
  };
}

export function renameCustomPage(name, page) {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const organization = { domain: organizationDomain };
    const context = commonSelectors.context(state);
    const contextPath = commonSelectors.contextPath(state);
    const getPath = getPortalPath({ contextPath });

    return customizationService
      .renameDraftPage({
        pagePath: name,
        oldPagePath: page.path,
        organizationDomain,
        context
      })
      .then((renamedPage) => {
        dispatch({
          type: actionTypes.RENAME_CUSTOMIZATION_CUSTOM_PAGE,
          organizationDomain,
          renamedPage: {
            ...renamedPage,
            markdown: page.markdown,
            html: page.html
          },
          oldPagePath: page.path
        });

        return dispatch(
          push(
            getPath(
              'customPage',
              {
                organization,
                pagePath: renamedPage.path
              },
              { isEditMode: true }
            )
          )
        );
      });
  };
}

export function updateCustomPageMarkdown({ content, pagePath }) {
  return (dispatch, getState) => {
    const organizationDomain =
      customizationSelectors.organizationDomain(getState());

    return dispatch({
      type: actionTypes.UPDATE_CUSTOMIZATION_CUSTOM_PAGE_MARKDOWN,
      organizationDomain,
      content,
      pagePath
    });
  };
}

export function setCustomizationHeader({ isDraft = false } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const portalPath = customizationSelectors.portalAbsolutePath(state, {
      isDraft
    });
    const header = {
      title: 'Anypoint Exchange',
      base: portalPath
    };

    return dispatch(commonActions.setHeader(header));
  };
}

export function updateCustomPageHTML({ content, pagePath }) {
  return (dispatch, getState) => {
    const organizationDomain =
      customizationSelectors.organizationDomain(getState());

    return dispatch({
      type: actionTypes.UPDATE_CUSTOMIZATION_CUSTOM_PAGE_HTML,
      organizationDomain,
      content,
      pagePath
    });
  };
}

export function handleClientCustomPageDraftError(err, pagePath) {
  return (dispatch, getState) => {
    if (err.status === 404) {
      const state = getState();
      const publishedPages = customizationSelectors.publishedCustomPages(state);
      const getPortalPathFn = commonSelectors.getPortalPathFn(state);

      const isPublishedPage = publishedPages.some(
        (publishedPage) => publishedPage.path === pagePath
      );

      if (isPublishedPage) {
        return dispatch(
          push(getPortalPathFn('home', {}, { isEditMode: true }))
        );
      }
    }

    return Promise.reject(err);
  };
}

export function fetchCustomPageMarkdown(pagePath) {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const context = commonSelectors.context(state);
    const page = customizationSelectors.customPageToEdit(state, pagePath);

    if (page && page.markdown !== undefined) {
      return Promise.resolve(page);
    }

    dispatch(enableContentLoading());

    return customizationService
      .getDraftPageMarkdown({ pagePath, organizationDomain, context })
      .then((content = '') => {
        dispatch(
          updateCustomPageMarkdown({
            content,
            pagePath
          })
        );

        return dispatch(disableContentLoading());
      })
      .catch((err) => {
        dispatch(disableContentLoading());

        throw err;
      });
  };
}

export function fetchCustomPageHTML(pagePath) {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const context = commonSelectors.context(state);
    const page = customizationSelectors.customPageToView(state, pagePath);

    if (page && page.html) {
      return Promise.resolve(page);
    }

    dispatch(enableContentLoading());

    return customizationService
      .getPageHTML({
        pagePath,
        organizationDomain,
        context
      })
      .then((content) => {
        if (content) {
          dispatch(
            updateCustomPageHTML({
              content,
              pagePath
            })
          );
        }

        return dispatch(disableContentLoading());
      })
      .catch((err) => {
        dispatch(disableContentLoading());

        throw err;
      });
  };
}

export function createCustomPage(name) {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const organization = { domain: organizationDomain };
    const context = commonSelectors.context(state);
    const contextPath = commonSelectors.contextPath(state);
    const getPath = getPortalPath({ contextPath });

    return customizationService
      .createDraftPage({
        pagePath: name,
        organizationDomain,
        context
      })
      .then((page) => {
        dispatch({
          type: actionTypes.CREATE_CUSTOMIZATION_CUSTOM_PAGE,
          organizationDomain,
          name: page.name,
          path: page.path
        });

        return dispatch(
          push(
            getPath(
              'customPage',
              {
                organization,
                pagePath: name
              },
              { isEditMode: true }
            )
          )
        );
      });
  };
}

export function deleteCustomPage({ path }, pages = []) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const organization = { domain: organizationDomain };
    const contextPath = commonSelectors.contextPath(state);
    const getPath = getPortalPath({ contextPath });

    return customizationService
      .deleteDraftPage({
        pagePath: path,
        organizationDomain,
        context
      })
      .then(() => {
        dispatch({
          type: actionTypes.DELETE_CUSTOMIZATION_CUSTOM_PAGE,
          organizationDomain,
          pagePath: path
        });

        const pageToRedirect = pages.find((page) => page.path !== path);

        if (pageToRedirect) {
          return dispatch(
            push(
              getPath(
                'customPage',
                {
                  organization,
                  pagePath: pageToRedirect.path
                },
                { isEditMode: true }
              )
            )
          );
        }

        return dispatch(
          push(getPath('home', { organization }, { isEditMode: true }))
        );
      });
  };
}

function updateCustomizationDraft(state) {
  const draft = customizationSelectors.customizationDraft(state);

  if (!draft || !Object.keys(draft).length) {
    return Promise.reject(new Error('The customization draft does not exist'));
  }

  const { welcomeSection, navbar, customPages } = draft;
  const organizationDomain = customizationSelectors.organizationDomain(state);
  const context = commonSelectors.context(state);

  return customizationService
    .updateCustomizationDraft({
      data: {
        customization: {
          home: welcomeSection,
          navbar
        },
        pages: customPages ? getArray(customPages) : undefined
      },
      organizationDomain,
      context
    })
    .then(({ pages }) =>
      updateDraftPagesMarkdown(pages, organizationDomain, context)
    );
}

function uploadImages(context, organizationId, organizationDomain) {
  return async (dispatch, getState) => {
    const state = getState();
    const { images = {} } = customizationSelectors.customizationDraft(state);

    for (const imageKind of Object.keys(images)) {
      const image = images[imageKind];

      const { url } = await customizationService.uploadResource({
        context,
        file: image,
        organizationDomain
      });

      dispatch(updateImage({ url, file: image, imageKind }));
    }
  };
}

function updateDraftPagesMarkdown(pages = [], organizationDomain, context) {
  return Promise.all(
    pages.map((page) => {
      if (!page.markdown) {
        return Promise.resolve(page);
      }

      return customizationService.updateDraftPageMarkdown({
        page,
        organizationDomain,
        context
      });
    })
  );
}

export function saveCustomizationDraft() {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const organization = { domain: organizationDomain };
    const organizationId = commonSelectors.organizationId(state);
    const contextPath = commonSelectors.contextPath(state);
    const getPath = getPortalPath({ contextPath });

    dispatch(saveCustomizationDraftRequest());

    return dispatch(uploadImages(context, organizationId, organizationDomain))
      .then(() => updateCustomizationDraft(getState()))
      .then(() => dispatch(saveCustomizationDraftSuccess(organizationDomain)))
      .then(() => {
        dispatch(
          analyticsActions.trackEvent(EVENT_TYPES.CUSTOMIZATION_DRAFT_SAVED)
        );
        dispatch(modalActions.closeModal());
        dispatch(push(getPath('home', { organization })));
      })
      .catch((err) => {
        dispatch(saveCustomizationDraftFailure());
        dispatch(modalActions.closeModal());

        throw err;
      });
  };
}

export function saveCustomizationDraftRequest() {
  return {
    type: actionTypes.SAVE_CUSTOMIZATION_DRAFT_REQUEST
  };
}

export function saveCustomizationDraftSuccess(organizationDomain) {
  return {
    type: actionTypes.SAVE_CUSTOMIZATION_DRAFT_SUCCESS,
    organizationDomain
  };
}

export function saveCustomizationDraftFailure() {
  return {
    type: actionTypes.SAVE_CUSTOMIZATION_DRAFT_FAILURE
  };
}

export function saveAndPublishDraft() {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const context = commonSelectors.context(state);
    const organization = { domain: organizationDomain };
    const organizationId = commonSelectors.organizationId(state);
    const getPath = commonSelectors.getPortalPathFn(state);

    dispatch(publishCustomizationDraftRequest());

    return dispatch(uploadImages(context, organizationId, organizationDomain))
      .then(() => updateCustomizationDraft(getState()))
      .then(() =>
        customizationService.publishCustomizationDraft({
          organizationDomain,
          context
        })
      )
      .then(() =>
        dispatch(publishCustomizationDraftSuccess(organizationDomain))
      )
      .then(() => {
        dispatch(modalActions.closeModal());
        dispatch(
          analyticsActions.trackEvent(EVENT_TYPES.CUSTOMIZATION_DRAFT_PUBLISHED)
        );

        dispatch(push(getPath('home', { organization })));
      })
      .catch((err) => {
        dispatch(publishCustomizationDraftFailure());
        dispatch(modalActions.closeModal());

        throw err;
      });
  };
}

export function publishCustomizationDraftRequest() {
  return {
    type: actionTypes.PUBLISH_CUSTOMIZATION_DRAFT_REQUEST
  };
}

export function publishCustomizationDraftSuccess(organizationDomain) {
  return {
    type: actionTypes.PUBLISH_CUSTOMIZATION_DRAFT_SUCCESS,
    organizationDomain
  };
}

export function publishCustomizationDraftFailure() {
  return {
    type: actionTypes.PUBLISH_CUSTOMIZATION_DRAFT_FAILURE
  };
}

export function discardCustomizationDraft() {
  return (dispatch, getState) => {
    const state = getState();
    const organizationDomain = customizationSelectors.organizationDomain(state);
    const context = commonSelectors.context(state);

    dispatch(discardCustomizationDraftRequest());

    return customizationService
      .deleteCustomizationDraft({ organizationDomain, context })
      .then(() => {
        dispatch(discardCustomizationDraftSuccess(organizationDomain));
        dispatch(modalActions.closeModal());
      })
      .catch((err) => {
        dispatch(discardCustomizationDraftFailure());
        dispatch(modalActions.closeModal());

        throw err;
      });
  };
}

export function discardCustomizationDraftRequest() {
  return {
    type: actionTypes.DISCARD_CUSTOMIZATION_DRAFT_REQUEST
  };
}

export function discardCustomizationDraftSuccess(organizationDomain) {
  return {
    type: actionTypes.DISCARD_CUSTOMIZATION_DRAFT_SUCCESS,
    organizationDomain
  };
}

export function discardCustomizationDraftFailure() {
  return {
    type: actionTypes.DISCARD_CUSTOMIZATION_DRAFT_FAILURE
  };
}

export function enableContentLoading() {
  return {
    type: actionTypes.ENABLE_CONTENT_LOADING
  };
}

export function disableContentLoading() {
  return {
    type: actionTypes.DISABLE_CONTENT_LOADING
  };
}

export function enableCustomizationLoading() {
  return {
    type: actionTypes.ENABLE_CUSTOMIZATION_LOADING
  };
}

export function disableCustomizationLoading() {
  return {
    type: actionTypes.DISABLE_CUSTOMIZATION_LOADING
  };
}

export function openMobileMenu() {
  return {
    type: actionTypes.OPEN_CUSTOMIZATION_MOBILE_MENU
  };
}

export function closeMobileMenu() {
  return {
    type: actionTypes.CLOSE_CUSTOMIZATION_MOBILE_MENU
  };
}

export function saveCustomPagesOrder({ organizationDomain, pages }) {
  return (dispatch, getState) => {
    const state = getState();
    const customizationDraft = customizationSelectors.customizationDraft(state);
    const currentPagesOrderRevision = customizationDraft.pagesOrderRevision;
    const currentCustomPagesOrder = customizationDraft.customPagesOrder;
    const pageRequest = {
      organizationDomain,
      pages,
      pagesOrderRevision: currentPagesOrderRevision,
      currentCustomPagesOrder
    };

    return actionify(
      customizationService.savePagesOrder,
      actionTypes.SAVE_CUSTOM_PAGES_ORDER
    )(pageRequest)(dispatch, getState).catch(handleReorderingError(dispatch));
  };
}
