import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withAssetTypesConfiguration } from '@mulesoft/exchange-react-hooks';
import withCustomizationLeaveHandling from '~/components/Customization/LeaveHookProvider';
import withFocusRestore from '~/components/WithFocusRestore';
import RootStoreContext from '~/contexts/RootStoreContext';
import withLayoutContext from '~/components/LayoutContextProvider';
import { actions as modalActions } from '~/domains/modal';
import { selectors as assetsSelectors } from '~/domains/assets';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as searchesSelectors } from '~/domains/searches';
import { selectors as searchSelectors } from '~/domains/search';
import { getActiveOrganizationId } from '~/utils/organization';
import { parseSearch } from '~/utils/location';
import ResultsInfo from './ResultsInfo';

const getOrganizationId = (state) => {
  const profile = commonSelectors.profile(state);

  return getActiveOrganizationId(profile);
};

const mapStateToProps = (state, { location }) => {
  const profile = commonSelectors.profile(state);
  const query = parseSearch(location.search);
  const assets = assetsSelectors.assets(state);
  const isANGSearchEnabled = searchSelectors.isANGSearchEnabled(state);

  return {
    profile,
    isANGSearchEnabled,
    totalResults: assets.length,
    savedSearches:
      searchesSelectors.savedSearches(state, getOrganizationId(state)) || [],
    userSavedSearches: searchesSelectors.userSavedSearches(state) || [],
    query
  };
};

const mapDispatchToProps = (dispatch) => ({
  openModal: ({ modalType, modalProps }) =>
    dispatch(modalActions.openModal({ modalType, modalProps }))
});

const mapContextToProps = ({ isPublicPortal }) => ({
  isSavedSearchEnabled: !isPublicPortal,
  isPublicPortal
});

export default compose(
  withLayoutContext(mapContextToProps),
  withRouter,
  withAssetTypesConfiguration,
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  }),
  withCustomizationLeaveHandling,
  withFocusRestore
)(ResultsInfo);
