import { format } from 'url';
import config from 'config';
import memoize from 'memoize-one';
import pathToRegexp from 'path-to-regexp';
import { matchRoutes } from 'react-router-config';
import * as urlUtils from '~/utils/url';

const externalURI = format(config.externalURI);

function getVersion({
  version,
  isVersionNavigation,
  minorVersion,
  versionGroup
}) {
  if (version && isVersionNavigation) {
    return `${version}/`;
  }

  if (minorVersion) {
    return `minor/${minorVersion}/`;
  }

  if (versionGroup) {
    return `version/${versionGroup}/`;
  }

  if (version) {
    return `${version}/`;
  }

  return '';
}

const encodePagePath = (pagePath) => {
  const paths = pagePath.split('/');

  return paths.map((path) => encodeURIComponent(path)).join('/');
};

const stringifyStatus = (status) => `?status=${status}`;

const routesMap = {
  home: () => '/',
  createAsset: () => '/create/',
  newVersion: ({ groupId, assetId }) =>
    `/${groupId}/${assetId}/versions/create/`,
  settings: () => '/settings/',
  settingsAdd: () => '/settings/add/',
  settingsEdit: ({ categoryId }) => `/settings/${categoryId}/edit/`,
  applications: () => '/applications/',
  applicationDetail: ({ applicationId }) => `/applications/${applicationId}/`,
  assetHome: ({ groupId, assetId }) => `/${groupId}/${assetId}/`,
  metrics: () => '/metrics/',
  minor: ({ groupId, assetId, minorVersion, status }) =>
    `/${groupId}/${assetId}/minor/${minorVersion}/${
      status ? stringifyStatus(status) : ''
    }`,
  assetDetailBase: ({
    groupId,
    assetId,
    version,
    minorVersion,
    versionGroup,
    isVersionNavigation,
    isDraft
  }) =>
    `/${groupId}/${assetId}/${getVersion({
      isVersionNavigation,
      minorVersion,
      versionGroup,
      version
    })}${isDraft ? 'draft/' : ''}`,
  page: ({ pagePath, ...params }) =>
    `${routesMap.assetDetailBase(params)}${
      pagePath ? `pages/${encodePagePath(pagePath)}/` : ''
    }`,
  pageDraft: ({ pagePath = config.defaultPageName, ...params }) =>
    routesMap.page({ ...params, pagePath, isDraft: true }),
  pageEdit: ({ pagePath = config.defaultPageName, ...params }) =>
    `${routesMap.assetDetailBase({
      ...params,
      isDraft: true
    })}edit/${encodePagePath(pagePath)}/`,
  console: ({ consolePath, ...params }) =>
    `${routesMap.assetDetailBase(params)}console${consolePath}/`,
  consoleSummary: (params) =>
    routesMap.console({ ...params, consolePath: '/summary' }),
  apiSettings: (params) => `${routesMap.assetDetailBase(params)}settings/`,
  apiGroup: (params) => `${routesMap.assetDetailBase(params)}detail/`,
  publicPortal: ({ organization: { domain } }) =>
    `/portals/${domain}/login/?no_public_login=true`,
  logout: () => '/logout',
  login: () => '/login',
  file: ({
    organizationId,
    groupId,
    assetId,
    version,
    fileClassifier,
    packaging
  }) =>
    `/organizations/${organizationId}/assets/${groupId}/${assetId}/${version}/files/${
      fileClassifier ? `${fileClassifier}/` : ''
    }${packaging}`
};

const portalRoutesMap = {
  home: routesMap.home,
  assetHome: routesMap.assetHome,
  assetDetailBase: routesMap.assetDetailBase,
  page: routesMap.page,
  console: routesMap.console,
  consoleSummary: routesMap.consoleSummary,
  applications: routesMap.applications,
  applicationDetail: routesMap.applicationDetail,
  apiSettings: routesMap.apiSettings,
  apiGroup: routesMap.apiGroup,
  logout: routesMap.logout,
  login: routesMap.login,
  customPage: ({ pagePath }) => `/pages/${encodeURIComponent(pagePath)}/`,
  file: routesMap.file
};

const allowedRoutesToAddQueryParams = [
  'assetDetailBase',
  'page',
  'pageDraft',
  'pageEdit',
  'console',
  'consoleSummary',
  'apiSettings',
  'customPage'
];

export function getPathWithQuery(location) {
  return (name, params, { excludeContextPath, excludeQueryParams } = {}) => {
    const contextPath = excludeContextPath ? '' : config.contextPath;

    if (!routesMap[name]) {
      return null;
    }
    let path = `${contextPath}${routesMap[name](params)}`;

    if (
      location?.search &&
      allowedRoutesToAddQueryParams.includes(name) &&
      !excludeQueryParams
    ) {
      path += location.search;
    }

    return path;
  };
}

/*
 * Gets the path for a route by name using params
 */
export function getPath(name, params, { excludeContextPath } = {}) {
  const contextPath = excludeContextPath ? '' : config.contextPath;

  if (!routesMap[name]) {
    return null;
  }

  return `${contextPath}${routesMap[name](params)}`;
}

export function getPortalPath({
  routingContext = { path: config.contextPath },
  organizationDomain
} = {}) {
  return (
    name,
    routeParams = {},
    { excludeContextPath = false, isEditMode } = {}
  ) => {
    if (!portalRoutesMap[name]) {
      return null;
    }

    const contextPath = routingContext.path || config.contextPath;
    const contextPathOrEmpty = excludeContextPath
      ? ''
      : urlUtils.removeTrailingSlashes(contextPath);

    const portalDomain =
      (routeParams.organization && routeParams.organization.domain) ||
      organizationDomain;

    const basePath = `${contextPathOrEmpty}${
      routingContext.isForwardedPath ? '' : `/portals/${portalDomain}`
    }`;

    const customize = isEditMode ? '/customize' : '';

    return `${basePath}${customize}${portalRoutesMap[name](routeParams)}`;
  };
}

export function getIconURL({
  files = [],
  iconMetadata,
  icon,
  organization: { id: organizationId },
  groupId,
  assetId,
  isPublicPortal
}) {
  const iconURL = `${externalURI}/organizations/${organizationId}/assets/${groupId}/${assetId}/icon`;
  const iconFile = files.find((file) => file.classifier === 'icon');

  if (iconFile) {
    return `${iconURL}/${iconFile.packaging}/?sha=${iconFile.sha1}${
      isPublicPortal ? '&public' : ''
    }`;
  }

  if (iconMetadata) {
    return `${iconURL}/${iconMetadata.packaging}/?sha=${iconMetadata.sha1}${
      isPublicPortal ? '&public' : ''
    }`;
  }

  return icon;
}

export function isHomePage(pathname) {
  return pathname === getPath('home');
}

export function isPortalHomePage(pathname, organization, getPathFn) {
  return pathname === getPathFn('home', { organization });
}

export function isPublicPortalRoute(routes) {
  return routes.reduce(
    (acum, current) => acum || !!current.isPublicPortal,
    false
  );
}

export function isDraftRoute(routes) {
  return routes.reduce((acum, current) => acum || !!current.isDraft, false);
}

export const decodeParam = (param) =>
  param &&
  param.replace(
    /%2C|%2F|%3F|%3A|%40|%26|%3D|%2B|%24|%23|%3B/g,
    decodeURIComponent
  );

export const decodeParams = memoize((params = {}) => {
  return Object.keys(params).reduce(
    (accum, key) => ({
      ...accum,
      [key]: decodeParam(params[key])
    }),
    {}
  );
});

export const getCurrentRoute = memoize((route, location) => {
  let routes;

  if (location && route.routes) {
    routes = matchRoutes(route.routes, location.pathname);
  } else if (location) {
    routes = matchRoutes([route], location.pathname);
  }

  if (routes && routes.length) {
    const lastRoute = routes[routes.length - 1];

    return {
      ...lastRoute,
      params: decodeParams(lastRoute.match.params)
    };
  }

  return { route, match: {} };
});

const mergeParams = memoize((params, status) => ({
  ...params,
  status
}));

export function getAssetParams(match, location) {
  const routeParams = decodeParams(match.params);
  const status = urlUtils.getQueryParam(location, 'status')[0];
  const params = mergeParams(routeParams, status);

  return params;
}

/*
 * This method is used only for public portals, it returns true when the customization sidebar is visible
 */
export function isEditCustomizationRoute(route, location) {
  const matchingRoute = getCurrentRoute(route, location);
  const {
    route: { id: routeId }
  } = matchingRoute;

  return ['homeEdit', 'applicationsEdit', 'customPageEdit'].includes(routeId);
}

const assetResourceRoutes = [
  '/:groupId/:assetId/:version/resources/:resourceName',
  '/:groupId/:assetId/:version/draft/resources/:resourceName',
  '/:groupId/:assetId/:version/external-resources/:resourceHash/:resourceId',
  '/organizations/:organizationId/assets/:groupId/:assetId/icon/:packaging/',
  '/organizations/:organizationId/assets/:groupId/:assetId/icon/:packaging/\\?sha=(.*)'
];

const resourcePathRegExps = assetResourceRoutes.map((resourcePath) =>
  pathToRegexp(resourcePath)
);

/**
 * Returns true if the given route corresponds to an asset resource.
 *
 * @param {String} route
 * @returns {Boolean}
 */
export function isAssetResourceRoute(route) {
  return resourcePathRegExps.some((resourcePathRegExp) =>
    resourcePathRegExp.test(route)
  );
}
