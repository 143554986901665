import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Validations.css';

const getErrors = ({ value, validate }) =>
  Object.values(validate).reduce((acc, curr) => {
    const isValid = curr.validate({ value });

    if (!isValid) {
      return [...acc, curr.message];
    }

    return acc;
  }, []);

const Validations = ({
  value,
  validate,
  errorClassName,
  children,
  errorLabelId,
  isDirty
}) => {
  const errors = isDirty ? getErrors({ value, validate }) : [];
  const isValid = errors.length === 0;

  return (
    <React.Fragment>
      {children({
        isDirty,
        isValid,
        ariaDescribedBy: isValid ? null : errorLabelId,
        ariaInvalid: !isValid
      })}
      {!isValid && (
        <p
          id={errorLabelId}
          className={classNames(styles.errorMessage, errorClassName)}
          role="alert"
        >
          {errors[0]}
        </p>
      )}
    </React.Fragment>
  );
};

Validations.propTypes = {
  validate: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      validate: PropTypes.func.isRequired
    })
  ).isRequired,
  children: PropTypes.func.isRequired,
  errorLabelId: PropTypes.string.isRequired,
  isDirty: PropTypes.bool,
  value: PropTypes.string,
  errorClassName: PropTypes.string
};

Validations.defaultProps = {
  errorClassName: ''
};

export default Validations;
