import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FileUploader from '@mulesoft/anypoint-components/lib/FileUploader';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import InfoIcon from '@mulesoft/anypoint-icons/lib/svg/info-small.svg';
import { RefType } from '@mulesoft/exchange-react-shapes';
import styles from './ImageUploader.css';

export class ImageUploader extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    onUpload: PropTypes.func,
    // max file size in kilobytes
    maxSize: PropTypes.number,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    placeholder: PropTypes.string,
    fileType: PropTypes.string,
    'aria-label': PropTypes.string,
    innerRef: RefType
  };

  static defaultProps = {
    placeholder: 'Upload an image',
    fileType: 'image/*',
    maxSize: 2048,
    onUpload: () => {}
  };

  state = { value: undefined, error: false };

  onChange = ({ value: file }) => {
    if (!file) return null;

    const { maxSize, maxWidth, maxHeight, onUpload } = this.props;

    if (!file.type.includes('image')) {
      return this.setState({ error: 'Invalid file type' });
    }

    if (maxSize && file.size / 1000 > maxSize) {
      return this.setState({
        error: `The file is too large. The maximum allowed file size is ${Math.round(
          maxSize / 1000
        )}MB (uploaded file is ${(file.size / 1000000).toFixed(2)}MB)`
      });
    }

    return this.getImageObject(file).then(
      ({ image: { width, height }, url }) => {
        if (maxWidth && maxHeight && (width > maxWidth || height > maxHeight)) {
          return this.setState({
            error: `The image is more than ${maxWidth}px x ${maxHeight}px`
          });
        }

        if (maxWidth && width > maxWidth) {
          return this.setState({
            error: `The image is more than ${maxWidth}px width`
          });
        }

        if (maxHeight && height > maxHeight) {
          return this.setState({
            error: `The image is more than ${maxHeight}px height`
          });
        }

        this.setState({ value: file, error: false });

        return onUpload({ url, file });
      }
    );
  };

  getImageObject = (file) => {
    const image = new global.Image();
    const url = global.URL.createObjectURL(file);

    return new Promise((resolve) => {
      image.addEventListener('load', () => resolve({ image, url }));
      image.src = url;
    });
  };

  render() {
    const {
      id,
      placeholder,
      fileType,
      innerRef,
      'aria-label': ariaLabel
    } = this.props;
    const { value } = this.state;

    return (
      <div ref={innerRef} tabIndex="-1" className={styles.container}>
        <FileUploader
          id={id}
          placeholder={placeholder}
          theme={styles}
          isValid={!this.state.error}
          fileType={fileType}
          onChange={this.onChange}
          value={value}
          aria-label={ariaLabel}
        />
        <p
          className={styles.errorMsg}
          data-test-id="error-message"
          display-if={this.state.error}
        >
          <Icon size="xs">
            <InfoIcon />
          </Icon>
          {this.state.error}
        </p>
      </div>
    );
  }
}

const ImageUploaderWithRef = (props, ref) => {
  return <ImageUploader innerRef={ref} {...props} />;
};

export default React.forwardRef(ImageUploaderWithRef);
