import { format } from 'url';
import { push } from 'connected-react-router';
import * as clientApplicationService from '~/services/clientApplicationService';
import { selectors as commonSelectors } from '~/domains/common';
import { closeModal } from '~/domains/modal/actions';
import * as actions from './actionTypes';
import { parseSearch } from '~/utils/location';

export function loadClientApplicationSuccess(applications) {
  return {
    type: actions.LOAD_CLIENT_APPLICATION_SUCCESS,
    applications
  };
}

export function fetchClientApplicationListRequest() {
  return {
    type: actions.FETCH_CLIENT_APPLICATION_LIST_REQUEST
  };
}

export function fetchClientApplicationListSuccess(applications) {
  return {
    type: actions.FETCH_CLIENT_APPLICATION_LIST_SUCCESS,
    applications
  };
}

export function fetchClientApplicationListFailure(error) {
  return {
    type: actions.FETCH_CLIENT_APPLICATION_LIST_FAILURE,
    error
  };
}

export function fetchClientApplicationRequest() {
  return {
    type: actions.FETCH_CLIENT_APPLICATION_REQUEST
  };
}

export function fetchClientApplicationSuccess(application) {
  return {
    type: actions.FETCH_CLIENT_APPLICATION_SUCCESS,
    application
  };
}

export function fetchClientApplicationFailure() {
  return {
    type: actions.FETCH_CLIENT_APPLICATION_FAILURE
  };
}

export function resetClientSecretRequest() {
  return {
    type: actions.RESET_CLIENT_SECRET_REQUEST
  };
}

export function resetClientSecretSuccess({ application, clientSecret }) {
  return {
    type: actions.RESET_CLIENT_SECRET_SUCCESS,
    application,
    clientSecret
  };
}

export function resetClientSecretFailure() {
  return {
    type: actions.RESET_CLIENT_SECRET_FAILURE
  };
}

export function editClientApplicationRequest() {
  return {
    type: actions.EDIT_CLIENT_APPLICATION_REQUEST
  };
}

export function editClientApplicationSuccess(application) {
  return {
    type: actions.EDIT_CLIENT_APPLICATION_SUCCESS,
    application
  };
}

export function editClientApplicationFailure(error) {
  return {
    type: actions.EDIT_CLIENT_APPLICATION_FAILURE,
    error
  };
}

export function deleteClientApplicationRequest() {
  return {
    type: actions.DELETE_CLIENT_APPLICATION_REQUEST
  };
}

export function deleteClientApplicationSuccess(application) {
  return {
    type: actions.DELETE_CLIENT_APPLICATION_SUCCESS,
    application
  };
}

export function deleteClientApplicationFailure() {
  return {
    type: actions.DELETE_CLIENT_APPLICATION_FAILURE
  };
}

export function searchApplications(payload, location) {
  return (dispatch) => {
    const query = parseSearch(location.search);
    const queryParams = { ...query, search: payload.search };

    dispatch(push(format({ pathname: location.pathname, query: queryParams })));
  };
}

export function loadApplications(masterOrganizationId, query) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    dispatch(fetchClientApplicationListRequest());

    return clientApplicationService
      .list({ masterOrganizationId, context, ...query })
      .then((applications) =>
        dispatch(fetchClientApplicationListSuccess(applications))
      )
      .catch((error) => {
        dispatch(fetchClientApplicationListFailure(error));

        throw error;
      });
  };
}

export function loadMoreApplications(masterOrganizationId, query) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    return clientApplicationService
      .list({ masterOrganizationId, context, ...query })
      .then((applications) =>
        dispatch(loadClientApplicationSuccess(applications))
      );
  };
}

export function getClientApplication({ masterOrganizationId, applicationId }) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    dispatch(fetchClientApplicationRequest());

    return Promise.all([
      clientApplicationService.get({
        masterOrganizationId,
        applicationId,
        context
      })
    ])
      .then(([application]) =>
        dispatch(fetchClientApplicationSuccess({ ...application }))
      )
      .catch((error) => {
        dispatch(fetchClientApplicationFailure());

        throw error;
      });
  };
}

export function resetClientSecret(application) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const { masterOrganizationId, id: applicationId } = application;

    dispatch(resetClientSecretRequest());

    return clientApplicationService
      .resetClientSecret({ masterOrganizationId, applicationId, context })
      .then(({ clientSecret }) => {
        dispatch(resetClientSecretSuccess({ application, clientSecret }));
        dispatch(
          closeModal({
            trackPayloadProps: {
              closeReason: 'submit',
              applicationId: application.id
            }
          })
        );
      })
      .catch((error) => {
        dispatch(resetClientSecretFailure());

        throw error;
      });
  };
}

export function editClientApplication(application) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    dispatch(editClientApplicationRequest());

    return clientApplicationService
      .editClientApplication({ application, context })
      .then(($application) => {
        dispatch(editClientApplicationSuccess($application));
        dispatch(
          closeModal({
            trackPayloadProps: {
              closeReason: 'submit',
              isEditingApplication: true,
              applicationId: application.id
            }
          })
        );
      })
      .catch((error) => {
        dispatch(editClientApplicationFailure(error));

        throw error;
      });
  };
}

export function deleteClientApplication(application, applicationListPath) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const { masterOrganizationId, id: applicationId } = application;

    dispatch(deleteClientApplicationRequest());

    return clientApplicationService
      .deleteClientApplication({ masterOrganizationId, applicationId, context })
      .then(() => {
        dispatch(deleteClientApplicationSuccess(application));
        dispatch(
          closeModal({
            trackPayloadProps: {
              closeReason: 'submit',
              applicationId: application.id
            }
          })
        );
        dispatch(push(applicationListPath));
      })
      .catch((error) => {
        dispatch(deleteClientApplicationFailure());

        throw error;
      });
  };
}
