import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { isClient } from '~/utils/window';

const isAssetAdmin = (asset) => asset?.permissions?.indexOf('admin') >= 0;

const resolve = async ({ getState, params }) => {
  if (isClient()) {
    return;
  }

  const state = getState();
  const isReuseMetricsEnabled = sessionSelectors.isMetricsEnabled(state);
  const asset = assetsSelectors.asset(state, params);

  if (!isReuseMetricsEnabled || !isAssetAdmin(asset)) {
    const error = new Error('Page not enabled');

    error.status = 404;

    throw error;
  }
};

export default resolve;
