import { compose } from 'redux';
import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import * as commonSelectors from '~/domains/common/selectors';
import * as actions from '~/domains/customization/actions';
import * as selectors from '~/domains/customization/selectors';
import { getImageName } from '~/utils/images';
import CustomizeSidebar from './CustomizeSidebar';

const mapStateToProps = (state) => {
  const isDraftExist = selectors.isDraftExist(state);
  const isEditMode = true;
  const heroImageName = getImageName(
    selectors.heroImage(state, { isEditMode })
  );
  const logoImageName = getImageName(
    selectors.logoImage(state, { isEditMode })
  );
  const faviconName = getImageName(selectors.favicon(state, { isEditMode }));
  const pages = selectors.draftCustomPagesWithURL(state);
  const textColor = selectors.textColor(state, { isEditMode });
  const { pathname } = commonSelectors.location(state);
  const domain = selectors.organizationDomain(state);
  const organization = { domain };
  const isCustomizationLoading = selectors.isCustomizationLoading(state);
  const getPath = commonSelectors.getPortalPathFn(state);
  const welcomeTitle = selectors.welcomeTitle(state, { isEditMode });
  const welcomeText = selectors.welcomeText(state, { isEditMode });
  const isHome = pathname === getPath('home', { organization }, { isEditMode });
  const navbar = {
    backgroundColor: selectors.navbarBackgroundColor(state, { isEditMode }),
    textColor: selectors.navbarTextColor(state, { isEditMode }),
    textColorActive: selectors.navbarTextColorActive(state, { isEditMode })
  };

  return {
    isHome,
    isEditMode,
    isDraftExist,
    pages,
    getPath,
    organization,
    textColor,
    navbar,
    heroImageName,
    logoImageName,
    faviconName,
    isCustomizationLoading,
    welcomeTitle,
    welcomeText
  };
};

const mapDispatchToProps = (dispatch) => ({
  onColorChange: (value, colorType, destination) =>
    dispatch(actions.updateCustomizationColor(value, colorType, destination)),
  onImageChange: ({ url, file, imageKind }) =>
    dispatch(actions.updateImage({ url, file, imageKind })),
  onCreatePage: (pageName) => dispatch(actions.createCustomPage(pageName)),
  onOrderPages: (payload) => dispatch(actions.saveCustomPagesOrder(payload)),
  onTitleChange: ({ value }) =>
    dispatch(actions.updateCustomizationTitle(value)),
  onTextChange: ({ value }) => dispatch(actions.updateCustomizationText(value))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(CustomizeSidebar);
