import React from 'react';
import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as searchSelectors } from '~/domains/search';
import { selectors as commonSelectors } from '~/domains/common';
import ItemWithRef from './Item';

const mapStateToProps = (state) => ({
  isANGSearchEnabled: searchSelectors.isANGSearchEnabled(state),
  rootOrganizations: searchSelectors.rootOrganizations(state),
  profile: commonSelectors.profile(state)
});

// for some unknown reason, internet explorer does not work when using React.forwardRef together with react-redux connect
// Adding this as a probed workaround for such mysteriously thing
export default connect(mapStateToProps, null, null, {
  context: RootStoreContext
})((props) => <ItemWithRef {...props} />);
