import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import OpenIcon from '@mulesoft/anypoint-icons/lib/svg/open.svg';
import { AssetType, Rating, Table } from '@mulesoft/exchange-ui-components';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import withLayoutContext from '~/components/LayoutContextProvider';
import AssetCreatedBy from '~/components/Asset/CreatedBy';
import FormattedDate from '~/components/Layout/FormattedDate';
import InfiniteScroll from '~/components/Layout/InfiniteScroll';
import styles from './AssetList.css';

const publicHeaders = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'type',
    label: 'Type'
  },
  {
    key: 'modifiedAt',
    label: 'Date Modified'
  },
  {
    key: 'rating',
    label: 'Rating'
  }
];
const headers = publicHeaders.concat([
  {
    key: 'createdBy',
    label: 'Created By'
  }
]);

class AssetList extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    assets: PropTypes.arrayOf(AssetPropType),
    query: PropTypes.object,
    onAssetClick: PropTypes.func,
    onLoadAssets: PropTypes.func.isRequired,
    hasMoreAssets: PropTypes.bool,
    onReachEnd: PropTypes.func,
    isPublicPortal: PropTypes.bool,
    getPath: PropTypes.func
  };

  loadMoreAssets = () => {
    const query = {
      offset: this.props.assets.length,
      ...this.props.query
    };

    this.props.onReachEnd();

    return this.props.onLoadAssets(query);
  };

  render() {
    const {
      id = '',
      assets,
      hasMoreAssets,
      isPublicPortal,
      getPath,
      onAssetClick
    } = this.props;

    return (
      <InfiniteScroll
        hasMore={hasMoreAssets}
        onLoadMore={this.loadMoreAssets}
        loadingMoreMessage="Loading more assets ..."
        loadMoreMessage="Load more assets"
        testId="assets"
      >
        <div id={id} className={styles.assetList} data-test-id="asset-list">
          <Table
            caption="Asset results"
            testId="assets"
            className={styles.table}
            headers={isPublicPortal ? publicHeaders : headers}
            rows={mapAssets(assets, isPublicPortal, getPath, onAssetClick)}
            emptyMessage="We couldn't find results that match your criteria."
          />
        </div>
      </InfiniteScroll>
    );
  }
}

function mapAssets(assets, isPublicPortal, getPath, onClick) {
  return assets.map((asset) => {
    const assetRow = {
      name: <AssetName asset={asset} getPath={getPath} onClick={onClick} />,
      type: <AssetType type={asset.type} size="xs" />,
      modifiedAt: (
        <FormattedDate testId="asset-modified-date" date={asset.modifiedAt} />
      ),
      rating: (
        <Rating
          testId="asset-rating"
          rating={asset.rating}
          className={styles.rating}
          disabled
        />
      ),
      key: asset.id
    };

    if (!isPublicPortal) {
      assetRow.createdBy = <AssetCreatedBy user={asset.createdBy} />;
    }

    return assetRow;
  });
}

class AssetName extends React.Component {
  static propTypes = {
    asset: AssetPropType,
    getPath: PropTypes.func,
    onClick: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {}
  };

  onClick = () => {
    const { onClick, asset } = this.props;

    onClick(asset);
  };

  render() {
    const { asset, getPath } = this.props;

    return (
      <div className={styles.name}>
        <Link to={getPath('assetHome', asset)} onClick={this.onClick}>
          <span className="visually-hidden">Go to asset</span>
          {asset.name}
        </Link>
        <Icon className={styles.openIcon} size="xxs">
          <OpenIcon />
        </Icon>
      </div>
    );
  }
}

export { AssetList, AssetName };

export default withLayoutContext()(AssetList);
