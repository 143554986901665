import debug from 'debug';
import config from 'config';
import { createStore, applyMiddleware } from 'redux';
import { reducers } from './domains/reducers';
import * as sessionActions from './domains/session/actions';
import { isClient } from '~/utils/window';
import createReducerManager from './reducerManager';

const log = debug('exchange:store');
const logError = debug('exchange:store:error');

export const initialize = (session) => {
  return (dispatch) => {
    dispatch(sessionActions.setSession(session));
    dispatch(sessionActions.setFeatureFlags(session.featureFlags));
  };
};

export const thunk =
  ({ errorHandler, dispatchHandler = true } = {}) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action, options = { skipErrorHandler: () => false }) => {
    if (typeof action === 'function') {
      let promise = action(dispatch, getState, { config });

      if (promise && promise.catch) {
        promise = promise.catch((error) => {
          if (!options.skipErrorHandler(error)) {
            logError(error);

            const actionFn = () => dispatch(action);

            if (errorHandler) {
              if (dispatchHandler) {
                dispatch(errorHandler(error, actionFn));
              } else {
                errorHandler(error, actionFn);
              }
            }
          }

          return Promise.reject(error);
        });
      }

      return promise;
    }

    if (action) {
      log(`Dispatching action ${action.type}`, ...(isClient() ? [action] : []));

      return next(action);
    }

    return null;
  };

export default function configureStore(
  errorHandler,
  customMiddlewares,
  initialState
) {
  const reducerManager = createReducerManager(reducers);
  const middlewares = [
    thunk({ errorHandler, dispatchHandler: false }),
    ...customMiddlewares
  ];

  if (config.isLocalEnvironment && config.showReduxLogger) {
    // eslint-disable-next-line global-require
    const { logger } = require(`redux-logger`);

    middlewares.push(logger);
  }
  const middleware = applyMiddleware(...middlewares);
  const store = createStore(reducerManager.reduce, initialState, middleware);

  store.reducerManager = reducerManager;

  return store;
}
