export function getActiveOrganizationId(profile) {
  if (profile) {
    return profile.properties && profile.properties.cs_auth
      ? profile.properties.cs_auth.activeOrganizationId
      : profile.organization.id;
  }

  return null;
}

export function getActiveOrganization(profile) {
  if (profile && profile.contributorOfOrganizations) {
    const activeOrganizationId = getActiveOrganizationId(profile);

    return profile.contributorOfOrganizations.find(
      (organization) => organization.id === activeOrganizationId
    );
  }

  return null;
}

export function getMasterOrganizationId(organization) {
  if (organization.isMaster) {
    return organization.id;
  }

  if (
    organization.parentOrganizationIds &&
    organization.parentOrganizationIds[0]
  ) {
    return organization.parentOrganizationIds[0];
  }

  // fallback for when everything above fails
  return organization.id;
}

export function getBelongingOrganizationIds(profile) {
  return profile?.memberOfOrganizations?.map(({ id }) => id) || [];
}
