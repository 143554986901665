import React from 'react';
import PropTypes from 'prop-types';
import { useConfig } from '@mulesoft/exchange-react-hooks';
import { VersionsTable as GenericAssetPortalVersionsTable } from '@mulesoft/exchange-ui-portals-common';

const AssetPortalVersionsTable = ({ portalConfiguration, ...props }) => {
  const config = useConfig();
  const headers = () =>
    portalConfiguration.metadata({
      ...props,
      config,
      section: 'versions-table'
    });

  return (
    <GenericAssetPortalVersionsTable {...props} additionalHeaders={headers} />
  );
};

AssetPortalVersionsTable.propTypes = {
  portalConfiguration: PropTypes.object
};

export default AssetPortalVersionsTable;
