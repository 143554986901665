import { compose } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import setModalType from '~/components/Modals/setModalType';
import { registerModal } from '~/components/Modals/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { closeAllModals, closeModal } from '~/domains/modal/actions';
import NotFoundModal from './NotFoundModal';

const mapDispatchToProps = (dispatch) => ({
  onGoBack: () => {
    dispatch(closeAllModals());
    dispatch(goBack());
  },
  onClose: () => {
    dispatch(closeModal());
  }
});

export default compose(
  registerModal,
  setModalType('NotFoundModal'),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(NotFoundModal);
