import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Resizable } from 're-resizable';
import { Layout } from '@mulesoft/exchange-ui-components/lib/components/Layout';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { AssetPortalsLayout } from '@mulesoft/exchange-ui-portals-common';
import AssetDetailSidebar from '~/layouts/common/Sidebar';
import styles from './AssetDetailWrapper.css';

const AssetDetailWrapper = ({ route, routes, asset, isPageComponent }) => {
  const isMobile = global.innerWidth <= 991;
  const isPortalReArchitectureEnabled = useSelector(
    sessionSelectors.isPIRAEnabled
  );

  const renderSidebar = () => (
    <AssetDetailSidebar route={route} asset={asset} />
  );

  const renderRoutesFn = () => renderRoutes(route.routes, { route, routes });

  return isPortalReArchitectureEnabled ? (
    <AssetPortalsLayout
      renderRoutes={renderRoutesFn}
      renderSidebar={renderSidebar}
      asset={asset}
      isPageComponent={isPageComponent}
    />
  ) : (
    <Layout>
      <Resizable
        defaultSize={{ width: 250 }}
        minWidth={200}
        maxWidth={400}
        className={styles.sidebar}
        enable={isMobile ? false : { right: true }}
      >
        <div id="sidebar" data-test-id="sidebar">
          <AssetDetailSidebar route={route} asset={asset} />
        </div>
      </Resizable>
      {renderRoutes(route.routes, { route, routes })}
    </Layout>
  );
};

AssetDetailWrapper.propTypes = {
  route: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object),
  asset: PropTypes.object,
  isPageComponent: PropTypes.bool
};
export default AssetDetailWrapper;
