import { format } from 'url';
import config from 'config';
import { isClient } from '~/utils/window';

export function getExternalHost(context) {
  if (isClient()) {
    return global.location.host;
  }

  if (config.parallelTest) {
    return context.host;
  }

  return config.externalURI.host;
}

export const getAPIPort = (host) => {
  const port = host.split(':')[1];
  const { apiPort } = config.uiPortToAPIPort.find(
    ({ uiPort }) => uiPort === parseInt(port, 10)
  );

  return apiPort;
};

export const getAPIProxy = (req) =>
  format({
    ...config.api.uri,
    port: getAPIPort(req.headers.host)
  });

export const getAPIUrl = ({ context, pathname, query }) => {
  const apiPathname = `${config.api.uri.pathname || ''}${pathname}`;

  if (config.parallelTest && !isClient()) {
    return format({
      ...config.api.uri,
      port: getAPIPort(context.host),
      pathname: apiPathname,
      query
    });
  }

  return format({
    ...config.api.uri,
    pathname: apiPathname,
    query
  });
};
