export default {
  CATEGORY_SETTING_FORCED_DELETION_CANCELED:
    'Category setting forced deletion canceled',
  CATEGORY_SETTING_DELETED: 'Category setting deleted',
  CATEGORY_SETTING_FORCE_DELETED: 'Category setting deleted (forced)',
  CATEGORY_SETTING_FORCED_EDITION_CANCELED:
    'Category setting forced edition canceled',
  CATEGORY_SETTING_FORCE_EDITED: 'Category setting edited (forced)',
  CATEGORY_SETTING_CREATED: 'Category setting created',
  CATEGORY_SETTING_UPDATED: 'Category setting updated',
  CATEGORIES_UPDATED: 'Categories updated'
};
