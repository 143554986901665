import { connect } from 'react-redux';
import { compose } from 'redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as localStorageSelectors } from '~/domains/localStorage';
import Announcements from './Announcements';

const mapStateToProps = (state) => ({
  isLocalStorageLoaded: localStorageSelectors.localStorageIsLoaded(state)
});

export default compose(
  connect(mapStateToProps, null, null, { context: RootStoreContext })
)(Announcements);
