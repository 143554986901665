import config from 'config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withAnalytics, withModal } from '@mulesoft/exchange-react-hooks';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as pagesSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import DiscardDraftModal from '@mulesoft/exchange-ui-portals-common/lib/components/DraftHeader/DiscardDraftModal';
import { withAssetFromRootStore } from '~/contexts/RootStoreContext';
import withLayoutContext from '~/components/LayoutContextProvider';
import withFocusRestore from '~/components/WithFocusRestore';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import * as routesUtils from '~/utils/routes';
import { EVENT_TYPES } from '~/analytics/events';
import AssetDetail from './AssetDetail';

const mapStateToProps = (
  state,
  { assetFromRootStore, match, location, routes, route, hideRating }
) => {
  const params = routesUtils.getAssetParams(match, location);
  const { id: routeId, isDraft } = route;
  const asset = assetsSelectors.asset(state, params) || assetFromRootStore;
  const isEdit = routeId === 'pageEdit';
  const isPageComponent = routeId === 'page' || isEdit;
  const isTypeLoaded =
    !!asset?.type && reducerManagerSelectors.isTypeLoaded(state, asset.type);

  return {
    asset,
    hideRating,
    isDraft,
    isEdit,
    isTypeLoaded,
    isHomePage:
      isPageComponent &&
      (!params.pagePath || params.pagePath === config.defaultPageName),
    canEdit: assetsSelectors.canShowEdition(state, params),
    hasUnsavedChanges: pagesSelectors.pageUI(state).hasUnsavedChanges,
    portal: portalsSelectors.portal(state, { ...params, isDraft }),
    profile: sessionSelectors.profile(state),
    isPortalReArchitectureEnabled: sessionSelectors.isPIRAEnabled(state),
    isMinorDeprecated: assetsSelectors.isMinorDeprecated(state, asset),
    routes
  };
};

const mapDispatchToProps = (dispatch, { openModal, trackEvent }) => ({
  openModal: (modalType, modalProps) => openModal(modalType, modalProps),
  onRatingChange: (rating, asset) =>
    trackEvent(EVENT_TYPES.RATING_CLICKED, {
      organizationId: asset.organization.id,
      groupId: asset.groupId,
      assetId: asset.assetId,
      version: asset.version,
      name: asset.name,
      type: asset.type,
      rating
    }),
  onDiscardDraft: (asset) => openModal(DiscardDraftModal, { asset })
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onDiscardDraft: () => dispatchProps.onDiscardDraft(stateProps.asset)
});

const mapContextToProps = ({ isPublicPortal, getPath }, { hideRating }) => {
  return {
    isPublicPortal,
    showHeaderActions: isPublicPortal && !hideRating,
    getPath
  };
};

export default compose(
  withAnalytics,
  withAssetFromRootStore,
  withModal,
  withRouter,
  withLayoutContext(mapContextToProps),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withFocusRestore
)(AssetDetail);
