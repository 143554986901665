import PropTypes from 'prop-types';
import React from 'react';
import DeprecateIcon from '@mulesoft/anypoint-icons/lib/svg/warning-small.svg';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import styles from './EditCategoryConfigurationModal.css';

class EditCategoryConfigurationModal extends React.Component {
  static propTypes = {
    isApplyingChanges: PropTypes.bool,
    onClose: PropTypes.func,
    onForceEdit: PropTypes.func,
    clearPortalCategories: PropTypes.func
  };

  handleForceDelete = async () => {
    const { onForceEdit, clearPortalCategories } = this.props;

    await onForceEdit();
    clearPortalCategories();
  };

  bodyContent() {
    return (
      <div>
        <p>
          Already existing categories that are no longer valid will be removed
          from their related assets.{' '}
          <strong>Do you want to apply changes?</strong>
        </p>
      </div>
    );
  }

  footerContent() {
    const { onClose, isApplyingChanges } = this.props;

    return (
      <div>
        <Button
          testId="cancel-button"
          kind="tertiary"
          onClick={onClose}
          disabled={isApplyingChanges}
          noFill
        >
          Cancel
        </Button>
        <Button
          testId="apply-changes-button"
          kind="danger"
          isLoading={isApplyingChanges}
          onClick={this.handleForceDelete}
          noFill
        >
          Apply
        </Button>
      </div>
    );
  }

  render() {
    const { onClose } = this.props;
    const labelId = 'edit-category-modal-title';

    return (
      <Modal
        renderInBody={false}
        onEsc={onClose}
        onClickOverlay={onClose}
        className={styles.modal}
        ariaLabelledBy={labelId}
        role="alertdialog"
      >
        <ModalHeader
          testId="edit-category-modal-header"
          className={styles.header}
          isDanger
        >
          <h2 className={styles.title}>
            <Icon className={styles.icon} size="s">
              <DeprecateIcon />
            </Icon>
            <span
              id={labelId}
              className={styles.text}
              data-test-id="edit-category-configuration-modal-title"
            >
              Conflicting changes
            </span>
          </h2>
        </ModalHeader>
        <ModalBody testId="edit-category-modal-body" className={styles.body}>
          {this.bodyContent()}
        </ModalBody>
        <ModalFooter testId="edit-category-modal-footer">
          {this.footerContent()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditCategoryConfigurationModal;
