import React from 'react';
import searchDefinitions from '@mulesoft/exchange-assets-definitions/lib/search';
import { AssetCard } from '@mulesoft/exchange-ui-portals-common';

const componentResolver = (props) => {
  const TypeCard = searchDefinitions[props.type]?.AssetCard;

  if (TypeCard) {
    return React.createElement(TypeCard, props);
  }

  return React.createElement(AssetCard, props);
};

export default componentResolver;
