import debug from 'debug';
import { actions as categoriesActions } from '~/domains/categories';
import { selectors as commonSelectors } from '~/domains/common';
import { isMasterOrgAdmin } from '~/utils/validations/profile';

const log = debug('exchange:settings:resolver');

const resolver = async ({ dispatch, getState }) => {
  const state = getState();
  const profile = commonSelectors.profile(state);

  isMasterOrgAdmin(profile);

  const organizationId = profile.organization.id;

  log(`Dispatching categories list for org id ${organizationId}`);

  await dispatch(
    categoriesActions.listCategories({
      organizationId
    })
  );
};

export default resolver;
