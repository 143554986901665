import React from 'react';
import { AssetPortalContext } from '@mulesoft/exchange-react-hooks';

const VersionsTable = (props) => {
  const {
    components: { VersionsTable: PortalVersionsTable }
  } = React.useContext(AssetPortalContext);

  return React.createElement(PortalVersionsTable, props);
};

export default VersionsTable;
