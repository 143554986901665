import React from 'react';
import PropTypes from 'prop-types';
import Detail from './Detail';
import Main from './Main';
import { useCustomRoute } from './hooks';

const CustomLayout = ({
  match,
  asset,
  isContentLoading,
  isVersionNavigation,
  route
}) => {
  const resolvedRoute = useCustomRoute({ match, asset, route });

  return (
    <React.Fragment>
      <Main
        asset={asset}
        isVersionNavigation={isVersionNavigation}
        resolvedRoute={resolvedRoute}
        route={route}
      />
      <Detail
        asset={asset}
        route={route}
        resolvedRoute={resolvedRoute}
        isContentLoading={isContentLoading}
        isVersionNavigation={isVersionNavigation}
      />
    </React.Fragment>
  );
};

CustomLayout.propTypes = {
  asset: PropTypes.object,
  isContentLoading: PropTypes.bool,
  isVersionNavigation: PropTypes.bool,
  match: PropTypes.object,
  route: PropTypes.object
};

export default CustomLayout;
