import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import Button from '@mulesoft/anypoint-components/lib/Button';
import styles from '../Modal.css';

class ActionModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.node,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func
  };

  static defaultProps = {
    buttonText: 'Ok'
  };

  render() {
    const { title, body, buttonText, onButtonClick } = this.props;
    const labelId = 'action-modal-title';

    return (
      <Modal
        renderInBody={false}
        className={styles.modal}
        ariaLabelledBy={labelId}
      >
        <ModalHeader testId="action-modal-header" className={styles.header}>
          <h2 id={labelId} className={styles.title}>
            {title}
          </h2>
        </ModalHeader>
        <ModalBody testId="action-modal-body" className={styles.body}>
          {body}
        </ModalBody>
        <ModalFooter testId="action-modal-footer" className={styles.footer}>
          <Button
            testId="action-modal-button"
            kind="primary"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ActionModal;
