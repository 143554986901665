const modalRegistry = {};

export function registerModal(modalComponent) {
  modalRegistry[modalComponent.modalType] = modalComponent;

  return modalComponent;
}

export function getModalComponent(modalType) {
  return modalRegistry[modalType];
}

export function cleanRegistry() {
  Object.keys(modalRegistry).forEach((key) => delete modalRegistry[key]);
}
