import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as modalSelectors } from '~/domains/modal';
import ModalRoot from './ModalRoot';

const mapStateToProps = (state) => ({
  hasVisibleModal: modalSelectors.modalStack(state).length > 0,
  targetElementAfterClose: modalSelectors.targetElementAfterClose(state)
});

export default connect(mapStateToProps, null, null, {
  context: RootStoreContext
})(ModalRoot);
