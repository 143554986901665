import * as qs from 'querystring';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import GridIcon from '@mulesoft/anypoint-icons/lib/svg/grid.svg';
import ListIcon from '@mulesoft/anypoint-icons/lib/svg/list.svg';
import styles from './ViewSwitcher.css';

class ViewSwitcher extends React.Component {
  static propTypes = {
    query: PropTypes.object,
    className: PropTypes.string,
    pathname: PropTypes.string,
    onSwitch: PropTypes.func
  };

  onSwitch(view) {
    return () => {
      const { onSwitch } = this.props;

      onSwitch(view);
    };
  }

  render() {
    const { query = {}, pathname = '/', className } = this.props;
    const isListView = query.view === 'list';

    return (
      <div
        role="radiogroup"
        className={classNames(styles.viewSwitcher, className)}
      >
        <div
          role="radio"
          aria-checked={!isListView}
          className={styles.iconWrapper}
          data-test-id="grid-switch-wrapper"
        >
          <Link
            aria-label="View grid"
            title="View grid"
            className={isListView ? '' : styles.active}
            to={`${pathname}?${qs.stringify({ ...query, view: 'grid' })}`}
            onClick={this.onSwitch('grid')}
          >
            <Icon size="xs">
              <GridIcon />
            </Icon>
          </Link>
        </div>
        <div
          role="radio"
          aria-checked={isListView}
          className={styles.iconWrapper}
          data-test-id="list-switch-wrapper"
        >
          <Link
            aria-label="View list"
            title="View list"
            className={isListView ? styles.active : ''}
            to={`${pathname}?${qs.stringify({ ...query, view: 'list' })}`}
            onClick={this.onSwitch('list')}
          >
            <Icon size="xs">
              <ListIcon />
            </Icon>
          </Link>
        </div>
      </div>
    );
  }
}

export default ViewSwitcher;
