import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { InfoModal } from '~/components/Modals';

class AssetLimitModal extends PureComponent {
  static propTypes = {
    assetLimit: PropTypes.number,
    onClose: PropTypes.func,
    buttonText: PropTypes.string
  };

  static defaultProps = {
    buttonText: 'Close'
  };

  renderBody() {
    return (
      <p>
        Your organization has reached its limit of{' '}
        <strong data-test-id="asset-limit">{this.props.assetLimit}</strong>{' '}
        asset versions in Exchange. Please contact your administration to
        increase the limit.
      </p>
    );
  }

  render() {
    return (
      <InfoModal
        title="Exchange asset limit reached"
        body={this.renderBody()}
        docsLinkText="Learn more"
        docsLink="https://docs.mulesoft.com/exchange/to-create-an-asset#asset-limits"
        buttonText={this.props.buttonText}
        onClose={this.props.onClose}
      />
    );
  }
}

export default AssetLimitModal;
