import React from 'react';
import { createStoreHook } from 'react-redux';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { RootStoreContext } from '@mulesoft/exchange-react-hooks';
import { selectors as assetsSelectors } from '~/domains/assets';

const useStore = createStoreHook(RootStoreContext);

export function withAssetFromRootStore(Component) {
  return withRouter(function AssetFromRootStoreComponent(props) {
    const params = useParams();
    const store = useStore();
    const asset = assetsSelectors.asset(store.getState(), params);

    return <Component {...props} assetFromRootStore={asset} />;
  });
}

export default RootStoreContext;
