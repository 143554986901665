import PropTypes from 'prop-types';
import React from 'react';
import { getAnnouncements } from './registry';

const Announcements = ({ isLocalStorageLoaded }) => {
  if (!isLocalStorageLoaded) {
    return null;
  }

  return getAnnouncements().map((Component, index) => (
    <Component key={index} />
  ));
};

Announcements.propTypes = {
  isLocalStorageLoaded: PropTypes.bool
};

export default Announcements;
