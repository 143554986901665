import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import Toggle from '@mulesoft/anypoint-components/lib/Toggle';
import TooltipLabel from '@mulesoft/exchange-ui-components/lib/components/TooltipLabel';
import styles from './ApiGroupVisibilityToggle.css';

class ApiGroupVisibilityToggle extends React.PureComponent {
  static propTypes = {
    asset: PropTypes.object.isRequired,
    apiGroupInstance: PropTypes.object.isRequired,
    onVisibilityChange: PropTypes.func.isRequired,
    isUpdatingVisibility: PropTypes.bool.isRequired,
    testId: PropTypes.string
  };

  static defaultProps = {
    testId: 'api-group-visibility-toggle'
  };

  handleVisibilityChange = ({ value }) => {
    const { apiGroupInstance, onVisibilityChange, asset } = this.props;
    const { id, environmentId, isPublic } = apiGroupInstance;

    if (value !== isPublic) {
      onVisibilityChange({
        ...asset,
        environmentId,
        apiGroupInstanceId: id,
        isPublic: value
      });
    }
  };

  renderTooltip() {
    const { testId } = this.props;
    const tooltipContent = (
      <div className={styles.tooltip}>
        <p>
          This action enables the public visibility of the group instance when
          the asset version is public.
        </p>
        <p>
          Making a group instance public will not change the visibility of the
          underlying API instances. You can manage their visibility on the
          &quot;API instances&quot; page of each API.
        </p>
        <a
          href={config.docsLinks.publicPortalSharing}
          className={styles.linkAnchor}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </div>
    );

    return (
      <span
        role="none"
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
      >
        <TooltipLabel
          position="bottom"
          offset={[-75, 10]}
          tooltipContent={tooltipContent}
          id={`${testId}-tooltip`}
        />
      </span>
    );
  }

  render() {
    const { testId, apiGroupInstance, isUpdatingVisibility } = this.props;
    const { isPublic } = apiGroupInstance;

    return (
      <span className={styles.toggleContainer} data-test-id={testId}>
        Public
        {this.renderTooltip()}
        <Toggle
          className={styles.toggle}
          testId={`${testId}-button`}
          checked={isPublic}
          disabled={isUpdatingVisibility}
          onChange={this.handleVisibilityChange}
        />
      </span>
    );
  }
}

export default ApiGroupVisibilityToggle;
