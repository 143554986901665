import memoize from 'memoize-one';
import { getExchangeTypeConfiguration } from '~/portals/exchangeTypesConfiguration';

export function getGAKey(action) {
  const { params } = action;

  if (params && params.groupId && params.assetId) {
    return `${params.groupId}/${params.assetId}`;
  }

  return `${action.groupId}/${action.assetId}`;
}

export function getGAVKey(action) {
  const { params } = action;

  // when using actionify, GAV may come into params property
  if (params && params.version) {
    return `${getGAKey(action)}/${params.version}`;
  }

  return `${getGAKey(action)}/${action.version}`;
}

export function getGAPKey(action) {
  const { params } = action;

  if (params && params.versionGroup) {
    return `${getGAKey(action)}/${params.versionGroup}`;
  }

  return `${getGAKey(action)}/${action.versionGroup}`;
}

export function getGAMKey(action) {
  const { params } = action;

  if (params && params.minorVersion) {
    return `${getGAKey(action)}/${params.minorVersion}`;
  }

  return `${getGAKey(action)}/${action.minorVersion}`;
}

export function getPortalKey({ groupId, assetId, version, isDraft }) {
  return `${groupId}/${assetId}/${version}${isDraft ? '/draft' : ''}`;
}

export function isAPIGroup(asset) {
  return asset.type === 'api-group';
}

export function getDefault(assetTypesConfiguration, type) {
  let typeObj = assetTypesConfiguration[type];

  if (!typeObj) {
    typeObj = assetTypesConfiguration.custom;
  }

  return typeObj;
}

export function getName(assetTypesConfiguration, type) {
  return getDefault(assetTypesConfiguration, type)?.name ?? 'Unknown';
}

export const getDefaultTypes = memoize(
  (assetTypesConfiguration, featureFlags, isPublicSearch) => {
    return Object.keys(assetTypesConfiguration).reduce((accum, current) => {
      const type = assetTypesConfiguration[current];

      if (current === 'default') {
        return accum;
      }

      if (type.headless) {
        return accum;
      }

      if (type.featureFlag && !featureFlags[type.featureFlag]) {
        return accum;
      }

      if (isPublicSearch && type.visibility !== 'public') {
        return accum;
      }

      return [...accum, current];
    }, []);
  }
);

export const getOptions = memoize((assetTypesConfiguration, featureFlags) => {
  return getDefaultTypes(assetTypesConfiguration, featureFlags)
    .reduce((accum, typeKey) => {
      const typeConfiguration = assetTypesConfiguration[typeKey];

      if (typeConfiguration.headless) {
        return accum;
      }

      const existingType = accum.find(
        (type) => type.value === typeConfiguration.filter
      );

      if (existingType) {
        existingType.keys.push(typeKey);
        existingType.key += `-${typeKey}`;

        return accum;
      }

      return [
        ...accum,
        {
          key: typeKey,
          keys: [typeKey],
          text: typeConfiguration.filter,
          value: typeConfiguration.filter,
          isNew: getExchangeTypeConfiguration(typeKey, 'isNew')
        }
      ];
    }, [])
    .sort(
      (typeA, typeB) =>
        getExchangeTypeConfiguration(typeA.keys[0], 'order') -
        getExchangeTypeConfiguration(typeB.keys[0], 'order')
    );
});

export const getPortalOptions = memoize(
  (assetTypesConfiguration, featureFlags) => {
    const filterOptions = getOptions(assetTypesConfiguration, featureFlags);
    const portalFilters = filterOptions.filter((filter) =>
      filter.keys.some(
        (key) => assetTypesConfiguration[key].visibility === 'public'
      )
    );

    return portalFilters;
  }
);

export function getListTypes(type) {
  return type === 'connector' ? ['connector', 'extension'] : type;
}
