import config from 'config';
import memoize from 'memoize-one';
import * as objectsUtils from '../utils/objects';

const emptyFilesConfig = { files: [] };
interface AssetFile {
  classifier: string;
  packaging: string;
}

const isMatchingFile = (
  file: AssetFile,
  fileConfiguration: AssetFile
): boolean =>
  ((!fileConfiguration.classifier && file.classifier !== 'docs') ||
    file.classifier === fileConfiguration.classifier) &&
  (fileConfiguration.packaging === '**' ||
    file.packaging === fileConfiguration.packaging);

const buildDownloadableFile = (fileConfiguration, typeConfiguration, asset) => {
  let fileId;

  if (typeof fileConfiguration === 'string') {
    fileId = fileConfiguration;
  } else if (fileConfiguration.fileId) {
    // eslint-disable-next-line prefer-destructuring
    fileId = fileConfiguration.fileId;
  } else {
    return fileConfiguration;
  }

  const file = typeConfiguration.publication.files.find(
    (publicationFile) => publicationFile.id === fileId
  );
  const matchingFile =
    file && asset.files.find((assetFile) => isMatchingFile(assetFile, file));

  if (!matchingFile) {
    return null;
  }

  return {
    ...objectsUtils.omit(file, 'id'),
    ...(typeof fileConfiguration === 'string'
      ? {}
      : objectsUtils.omit(fileConfiguration, 'fileId')),
    classifier: matchingFile.classifier,
    packaging: matchingFile.packaging,
    sha1: matchingFile.sha1,
    organizationId: asset.organization.id,
    groupId: asset.groupId,
    assetId: asset.assetId,
    version: asset.version
  };
};

export const getFilesConfiguration = memoize(
  (assetTypesConfiguration, assetType, portalConfiguration) => {
    const typeConfiguration = assetTypesConfiguration[assetType];
    const filesConfiguration =
      portalConfiguration?.default?.files || typeConfiguration?.files;

    if (typeof filesConfiguration === 'function') {
      return memoize((asset, studioMetadata, section, portalConfig) => {
        const result = filesConfiguration(
          asset,
          studioMetadata,
          section,
          portalConfig
        );
        const files = Array.isArray(result) ? result : result.files;
        const action = Array.isArray(result) ? undefined : result.action;

        return {
          action,
          files: files.reduce((fileAccum, fileConfiguration) => {
            const file = buildDownloadableFile(
              fileConfiguration,
              typeConfiguration,
              asset
            );

            if (file) {
              return [...fileAccum, file];
            }

            return fileAccum;
          }, [])
        };
      });
    }

    if (Array.isArray(filesConfiguration)) {
      return memoize((asset, studioMetadata) => {
        if (studioMetadata) {
          return { files: [] };
        }

        return {
          files: filesConfiguration
            .map((fileConfiguration) =>
              buildDownloadableFile(fileConfiguration, typeConfiguration, asset)
            )
            .reduce((fileAccum, fileConfiguration) => {
              if (!fileConfiguration) {
                return fileAccum;
              }

              const file = asset.files.find((assetFile) =>
                isMatchingFile(assetFile, fileConfiguration)
              );

              if (file) {
                return [...fileAccum, { ...fileConfiguration, ...file }];
              }

              return fileAccum;
            }, [])
        };
      });
    }

    return () => emptyFilesConfig;
  }
);

export function getTypeFiles(
  portalConfiguration,
  asset,
  studioMetadata,
  section
) {
  const isStudio = !!(studioMetadata && studioMetadata.version);

  if (portalConfiguration?.files) {
    return portalConfiguration.files(
      asset,
      isStudio ? studioMetadata : null,
      section,
      config
    );
  }

  return emptyFilesConfig;
}
