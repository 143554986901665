import { replace } from 'connected-react-router';
import * as commonSelectors from '~/domains/common/selectors';
import * as scriptUtils from '~/utils/scripts';
import { isClient } from '~/utils/window';
import * as authenticationService from '~/services/authenticationService';
import * as actions from './actionTypes';

export function setRoute(route, location) {
  return {
    type: actions.SET_ROUTE,
    route,
    location
  };
}

export function setNavbarVisibility(visibility) {
  return {
    type: actions.SET_NAVBAR_VISIBILITY,
    visibility
  };
}

export function setIsMarketingSite(isMarketingSite) {
  return {
    type: actions.SET_IS_MARKETING_SITE,
    isMarketingSite
  };
}

export function setHomeQuery(query) {
  return {
    type: actions.SET_HOME_QUERY,
    query
  };
}

export function setHeader({
  title = '',
  description = '',
  canonical,
  meta = {},
  base = ''
}) {
  return {
    type: actions.SET_HEADER,
    header: {
      title,
      description,
      canonical,
      meta,
      base
    }
  };
}

export function setError(error) {
  return {
    type: actions.SET_ERROR,
    error
  };
}

export function setAlert(alert) {
  return {
    type: actions.SET_ALERT,
    alert
  };
}

export function setPublicPortalDomain(domain) {
  return {
    type: actions.SET_PUBLIC_PORTAL_DOMAIN,
    domain
  };
}

export function setRoutingContext({
  contextPath,
  contextHost,
  isForwardedHost,
  isForwardedPath,
  isPortalsSession,
  publicPortalDomain
}) {
  return {
    type: actions.SET_ROUTING_CONTEXT,
    contextPath,
    contextHost,
    isForwardedHost,
    isForwardedPath,
    isPortalsSession,
    publicPortalDomain
  };
}

export function clearAlert() {
  return {
    type: actions.CLEAR_ALERT,
    alert: null
  };
}

export function closeAlert() {
  return (dispatch) => dispatch(clearAlert());
}

export function loadScript(script) {
  return (dispatch, getState) => {
    const state = getState();
    const isScriptLoading = commonSelectors.isScriptLoading(state, script.key);
    const isScriptLoaded = commonSelectors.isScriptLoaded(state, script.key);

    if (!isClient() || isScriptLoading || isScriptLoaded) {
      return Promise.resolve();
    }

    dispatch({
      type: actions.SCRIPT_LOAD_START,
      key: script.key
    });

    return scriptUtils
      .loadScript(script.url)
      .then(() => dispatch(scriptLoaded(script.key)));
  };
}

export function scriptLoaded(key) {
  return {
    type: actions.SCRIPT_LOAD_FINISH,
    key
  };
}

export function setServerRedirectLocation(pathname, status = 302) {
  return {
    type: actions.SET_SERVER_REDIRECT_LOCATION,
    pathname,
    status
  };
}

export function logout() {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const getPathFn = commonSelectors.getPortalPathFn(state);

    return authenticationService
      .logout({ context, getPathFn })
      .then(({ logoutURL }) => {
        global.location.href = logoutURL;
      });
  };
}

export function getOrganizationMetadata(domain) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);

    return authenticationService
      .getOrganizationMetadata({ domain, context })
      .then((organizationMetadata) =>
        dispatch(setOrganizationMetadata(organizationMetadata))
      );
  };
}

export function setOrganizationMetadata(organizationMetadata) {
  return {
    type: actions.SET_ORGANIZATION_METADATA,
    organizationMetadata
  };
}

export function isomorphicPush(path, status) {
  return (dispatch) => {
    if (isClient()) {
      return dispatch(replace(path));
    }

    return dispatch(setServerRedirectLocation(path, status));
  };
}
