import React from 'react';
import PropTypes from 'prop-types';
import AssetDetail from '~/components/AssetDetail';
import AssetDetailPanel from '~/components/AssetDetail/Detail';
import Main from '~/layouts/common/Main';
import DetailWrapper from '../../DetailWrapper';

class AssetBaseLayout extends React.Component {
  static propTypes = {
    route: PropTypes.object
  };

  renderComponent() {
    return null;
  }

  renderMain() {
    const { hideRating } = this;
    const { route } = this.props;

    return (
      <AssetDetail hideRating={hideRating} route={route}>
        {this.renderComponent()}
      </AssetDetail>
    );
  }

  renderDetail() {
    const { route } = this.props;

    return <AssetDetailPanel route={route} />;
  }

  render() {
    const main = this.renderMain();
    const detail = this.renderDetail();

    return (
      <React.Fragment>
        <Main>{main}</Main>
        <DetailWrapper detail={detail} />
      </React.Fragment>
    );
  }
}

export default AssetBaseLayout;
