import * as qs from 'querystring';
import config from 'config';
import { EventTypes } from 'redux-segment';
import { omit, deepMerge } from '~/utils/objects';
import { report } from './reporter';

const pageProperties = ({ params = {} }) => {
  // Clean search string from sensible data
  const unsafeQueryParameters = ['access_token', 'token_type', 'state'];
  const queryStr = global.location
    ? global.location.search.replace('?', '')
    : '';
  const safeQueryParameters = omit(
    qs.parse(queryStr),
    ...unsafeQueryParameters
  );
  const safeSearchStr = qs.stringify(safeQueryParameters);

  return {
    search: safeSearchStr ? `?${safeSearchStr}` : '',
    query: safeQueryParameters,
    groupId: params?.groupId ?? '',
    assetId: params?.assetId ?? '',
    params
  };
};

// redux-segment payload
const analyticsPayload = (eventType, eventPayload, options = {}) => {
  const allOptions = deepMerge(config.analytics.options || {}, options);

  return {
    meta: {
      analytics: {
        eventType,
        eventPayload: {
          ...eventPayload,
          options: deepMerge(allOptions, {
            context: { page: pageProperties({}) }
          })
        }
      }
    }
  };
};

// events may be a string or an object specifying name and required properties
const getEventName = (event) =>
  typeof event === 'object' ? event.name : event;

// adds a configured prefix to the event name (e.g EXC clicked some button)
const addEventPrefix = (eventName) =>
  `${config.analytics.eventPrefix} ${eventName}`;

export const sanitizePayload = (payload) =>
  omit(
    payload,
    'access_token',
    'token_type',
    'embedded',
    'is_marketing',
    'no_cookie',
    'no_navbar'
  );

// payload for the identify event
export const identifyPayload = ({ profile, searchExperiment }) =>
  analyticsPayload(EventTypes.identify, {
    userId: profile.id,
    traits: {
      company: profile.organization.name,
      searchExperiment
    }
  });

// payload for the reset event
export const resetPayload = () => analyticsPayload(EventTypes.reset);

// payload for the track page event
export const pagePayload = ({ name, routeParams, properties }) =>
  analyticsPayload(EventTypes.page, {
    name: addEventPrefix(name),
    properties: { ...pageProperties({ params: routeParams }), ...properties }
  });

// validates event existence and required properties and executes payload strategy
export const buildTrackPayload =
  (payloadStrategy) =>
  ({ eventSchema, properties, userId, options, context }) => {
    if (!eventSchema) {
      report('Event schema does not exist');

      return {};
    }

    const name = getEventName(eventSchema);
    const { required = [] } = eventSchema;

    if (!required.every((propName) => properties[propName] !== undefined)) {
      report(
        `Event "${name}" should have the following properties: ${required.join(
          ', '
        )}`
      );
    }

    return payloadStrategy({ name, properties, userId, context, options });
  };

// payload for events tracked in the server
export const serverTrackPayload = buildTrackPayload(
  ({ name, properties, context, userId }) => ({
    event: addEventPrefix(name),
    properties: context ? { ...properties, context } : properties,
    ...(userId ? { userId } : { anonymousId: 'anonymous' })
  })
);

// payload for events tracked in the browser
export const clientTrackPayload = buildTrackPayload(
  ({ name, properties, context, options }) =>
    analyticsPayload(
      EventTypes.track,
      {
        event: addEventPrefix(name),
        properties: context
          ? {
              ...properties,
              context
            }
          : properties
      },
      options
    )
);
