import PropTypes from 'prop-types';
import React from 'react';
import ClientApplicationList from '~/components/ClientApplication/ClientApplicationList';
import BaseLayout from '~/layouts/BaseLayout';

class ClientApplicationListLayout extends BaseLayout {
  static propTypes = {
    dispatch: PropTypes.func
  };

  renderMain() {
    const { route } = this.props;

    return <ClientApplicationList route={route} />;
  }
}

export default ClientApplicationListLayout;
