import React from 'react';
import debug from 'debug';
import { useLocation } from 'react-router';

const log = debug('exchange:core');

export default (Component) => {
  const WithFocusRestore = (props) => {
    const location = useLocation();

    React.useEffect(() => {
      const skipLinksElement = global.document.getElementById('skip-links');

      if (document.activeElement !== skipLinksElement) {
        log('focusing skip links');
        skipLinksElement.focus();
      }

      setTimeout(() => {
        if (document.activeElement !== skipLinksElement) {
          log('forcing skip links focus again');
          skipLinksElement.focus();
        }
      }, 100);
    }, [location?.pathname]);

    return <Component {...props} />;
  };

  const name = Component.displayName || Component.name;

  WithFocusRestore.displayName = name
    ? `WithFocusRestore(${name})`
    : 'WithFocusRestore';

  return WithFocusRestore;
};
