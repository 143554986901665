import { connect } from 'react-redux';
import { selectors as categoriesSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/categories';
import { actions as assetsActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { getMasterOrganizationId } from '~/utils/organization';
import Categories from './Categories';

const mapStateToProps = (state, { asset, canEdit }) => ({
  asset,
  isEditable: !!canEdit,
  organizationCategories:
    categoriesSelectors.categories(state, {
      organizationId: getMasterOrganizationId(asset.organization)
    }) || []
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAssetCategory: (payload) =>
    dispatch(assetsActions.updateAssetCategory(payload)),
  onRemoveAssetCategory: (payload) =>
    dispatch(assetsActions.removeAssetCategory(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
