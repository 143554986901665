import PropTypes from 'prop-types';
import React from 'react';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import Button from '@mulesoft/anypoint-components/lib/Button';
import styles from './ConfirmDeletePageModal.css';

const ConfirmDeletePageModal = ({ page, onCloseDeleteModal, onDeletePage }) => {
  const labelId = 'confirm-delete-page-modal-title';

  return (
    <Modal
      renderInBody={false}
      onEsc={onCloseDeleteModal}
      onClickOverlay={onCloseDeleteModal}
      className={styles.modal}
      ariaLabelledBy={labelId}
      role="alertdialog"
    >
      <ModalHeader testId="modal-header" className={styles.header} isDanger>
        <h2 id={labelId} className={styles.title}>
          Delete page
        </h2>
      </ModalHeader>
      <ModalBody testId="modal-body" className={styles.body}>
        <p>
          Are you sure you want to delete <strong>{page.name}</strong>?
        </p>
        <p>This action cannot be reversed.</p>
      </ModalBody>
      <ModalFooter testId="modal-footer" className={styles.footer}>
        <Button kind="tertiary" onClick={onCloseDeleteModal} noFill>
          Cancel
        </Button>
        <Button kind="danger" onClick={onDeletePage} noFill>
          Delete page
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmDeletePageModal.propTypes = {
  page: PropTypes.object,
  onCloseDeleteModal: PropTypes.func,
  onDeletePage: PropTypes.func
};

export default ConfirmDeletePageModal;
