import { pick } from '~/utils/objects';
import { getMasterOrganizationId } from '~/utils/organization';

const VERSION_AGNOSTIC_PROPERTIES = [
  'type',
  'name',
  'description',
  'icon',
  'groupId',
  'assetId',
  'organization'
];

export function extractAssetAgnosticProperties(asset) {
  return pick(asset, ...VERSION_AGNOSTIC_PROPERTIES);
}

export function getUniqueAssets({ assets, uniqueKeys, joinString = ':' }) {
  const uniques = {};

  assets.forEach((asset) => {
    const joinedKey = uniqueKeys.map((key) => asset[key]).join(joinString);

    uniques[joinedKey || joinString] = asset;
  });

  return Object.values(uniques);
}

export function isExternalSharedAsset({ asset, profile }) {
  if (!profile || !profile.organization) {
    return false;
  }

  const assetMasterOrganizationId = getMasterOrganizationId(asset.organization);
  const profileMasterOrganizationId = getMasterOrganizationId(
    profile.organization
  );

  return assetMasterOrganizationId !== profileMasterOrganizationId;
}
