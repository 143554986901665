import { apiManagerURI } from '~/utils/APIInstances';

export function apiManagerApiGroupUri({
  organizationDomain,
  organizationId,
  environmentId,
  apiGroupInstanceId
}) {
  const baseUri = apiManagerURI(organizationDomain);

  return `${baseUri}/#/organizations/${organizationId}/environments/${environmentId}/groupInstances/${apiGroupInstanceId}`;
}

export function isAssetPublicPortalContradictory({ asset, apiGroupInstances }) {
  return (
    asset.isPublic &&
    apiGroupInstances.length > 0 &&
    apiGroupInstances.every((apiGroupInstance) => !apiGroupInstance.isPublic)
  );
}

export function isInstancePublicPortalContradictory(apiGroupInstance) {
  const { isPublic, apiInstances } = apiGroupInstance;

  return (
    isPublic &&
    apiInstances.length > 0 &&
    apiInstances.every((apiInstance) => !apiInstance.isPublic)
  );
}
