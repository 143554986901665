/*
Returns the name of an image uploaded to file-upload-service
This is an exmaple of url https://exchange2-file-upload-service-kqa.s3.amazonaws.com/0c0f06c0-nzlogo.png
*/

export function getImageName(imageURL) {
  if (!imageURL || !imageURL.length) return null;
  const betweenSlashes = imageURL.split('/');

  if (!betweenSlashes.length) return null;
  const fullImageName = betweenSlashes[betweenSlashes.length - 1];

  return fullImageName.substring(fullImageName.indexOf('-') + 1);
}
