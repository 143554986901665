import { format } from 'url';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import TrashIcon from '@mulesoft/anypoint-icons/lib/svg/trash-small.svg';
import Select from '@mulesoft/anypoint-components/lib/Select';
import AddPillButton from '~/components/AddPillButton';
import PillLink from '~/components/PillLink';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import * as refUtils from '~/utils/refs';
import { ALL_ASSETS_LINK_ID } from '~/utils/navigation/constants';
import styles from './Category.css';

class Category extends React.Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onUpdateAssetCategory: PropTypes.func.isRequired,
    onRemoveAssetCategory: PropTypes.func.isRequired,
    acceptedValues: PropTypes.array.isRequired,
    getPath: PropTypes.func.isRequired
  };

  state = {
    isCreating: false
  };

  componentDidMount() {
    if (this.props.isEditable && !this.props.category.value.length) {
      refUtils.focus(this.addValueRef);
    }
  }

  componentDidUpdate(prevProps) {
    const previousUnselectedValues = this.getUnselectedValues(
      prevProps.category,
      prevProps.acceptedValues
    );
    const currentUnselectedValues = this.getUnselectedValues(
      this.props.category,
      this.props.acceptedValues
    );

    if (previousUnselectedValues.length !== currentUnselectedValues.length) {
      refUtils.focus(this.addValueRef);
    }
  }

  getUnselectedValues = (category, acceptedValues) =>
    acceptedValues.filter((val) => !category.value.includes(val));

  getCategoryURL = (categoryValue) => {
    const categoryName = this.props.category.key;
    const pathname = this.props.getPath('home');
    const query = {
      search: `category:"${categoryName}" = "${categoryValue}"`,
      show: ALL_ASSETS_LINK_ID
    };

    return format({ pathname, query });
  };

  addValueRef = React.createRef();

  handleRemoveValue(removedValue) {
    const { category, onUpdateAssetCategory, onRemoveAssetCategory } =
      this.props;
    const newValue = category.value.filter((value) => value !== removedValue);

    if (newValue.length === 0) {
      onRemoveAssetCategory(category);
    } else {
      onUpdateAssetCategory({
        key: category.key,
        displayName: category.displayName,
        value: newValue
      });
    }
  }

  handleCategoryValueSelected = ({ itemsSelected }) => {
    const { category, onUpdateAssetCategory } = this.props;

    this.leaveCreatingState();
    onUpdateAssetCategory({
      key: category.key,
      displayName: category.displayName,
      value: category.value.concat([itemsSelected.value])
    });
  };

  enterCreatingState = () => {
    this.setState({ isCreating: true });
  };

  leaveCreatingState = () => {
    this.setState({ isCreating: false });
  };

  render() {
    const { isCreating } = this.state;
    const { isEditable, category, acceptedValues, onRemoveAssetCategory } =
      this.props;
    const unselectedValues = this.getUnselectedValues(category, acceptedValues);

    return (
      <div data-test-id="asset-category" className={styles.category}>
        <div data-test-id="asset-category-name" className={styles.name}>
          <span>{category.displayName}</span>
          <span
            role="button"
            aria-label={`Delete ${category.displayName} category`}
            data-test-id="asset-remove-category"
            display-if={isEditable}
            className={styles.trashIcon}
            tabIndex="0"
            onClick={() => onRemoveAssetCategory(category)}
            onKeyDown={eventHandlerUtils.onEnterKey(() =>
              onRemoveAssetCategory(category)
            )}
          >
            <Icon size="xs">
              <TrashIcon />
            </Icon>
          </span>
        </div>
        <div className={styles.values} aria-live="polite">
          {(category.value || []).map((value, index) => (
            <PillLink
              key={`categoryValue-${index}`}
              to={this.getCategoryURL(value)}
              disabled={!isEditable}
              title={value}
              aria-label={`Go to category ${value}`}
              onRemove={() => this.handleRemoveValue(value)}
            >
              {value}
            </PillLink>
          ))}
          <Select
            display-if={isCreating && isEditable}
            className={styles.valueSelect}
            testId="category-value"
            name="category-value"
            placeholder="Select a value"
            noResultsText="No results"
            options={unselectedValues.map((value) => ({
              label: value,
              value
            }))}
            onChange={this.handleCategoryValueSelected}
            onBlur={this.leaveCreatingState}
            openAfterFocus
            autofocus
            autosize={false}
          />
          {unselectedValues.length ? (
            <AddPillButton
              ref={this.addValueRef}
              data-test-id="add-value"
              display-if={isEditable && !isCreating}
              className={styles.action}
              tabIndex="0"
              onClick={this.enterCreatingState}
              onKeyDown={eventHandlerUtils.onEnterKey(this.enterCreatingState)}
            >
              Add value
            </AddPillButton>
          ) : (
            <span
              ref={this.addValueRef}
              tabIndex="-1"
              className="visually-hidden"
            >
              No values left to add
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default Category;
