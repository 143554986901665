import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import OpenIcon from '@mulesoft/anypoint-icons/lib/svg/open.svg';
import Table from '@mulesoft/exchange-ui-components/lib/components/Table';
import { DeleteAssetModal as AssetDetailDeleteAssetModal } from '@mulesoft/exchange-ui-portals-common';
import * as apiGroupUtils from '~/portals/api-group/utils';
import styles from './DeleteAssetModal.css';

class DeleteAssetModal extends React.Component {
  static propTypes = {
    apiGroupInstances: PropTypes.arrayOf(PropTypes.object).isRequired,
    organizationDomain: PropTypes.string.isRequired
  };

  static headers = [
    {
      key: 'name',
      label: 'Group instance'
    },
    {
      key: 'version',
      label: 'Version'
    },
    {
      key: 'environmentType',
      label: 'Environment Type'
    }
  ];

  getApiGroupInstanceLink = (apiGroupInstance) => {
    const { organizationDomain } = this.props;
    const { environmentId, organizationId, id } = apiGroupInstance;

    return apiGroupUtils.apiManagerApiGroupUri({
      organizationDomain,
      environmentId,
      organizationId,
      apiGroupInstanceId: id
    });
  };

  mapApiGroupInstanceToRow = (apiGroupInstance) => {
    const { name, environmentType, productVersion } = apiGroupInstance;

    return {
      environmentType: environmentType || '-',
      version: productVersion,
      name: (
        <a
          href={this.getApiGroupInstanceLink(apiGroupInstance)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {name}
          <Icon className={styles.openIcon} size="xxs">
            <OpenIcon />
          </Icon>
        </a>
      )
    };
  };

  renderErrorDescription = () => {
    const { apiGroupInstances } = this.props;
    const rows = apiGroupInstances.map(this.mapApiGroupInstanceToRow);

    return (
      <div className={styles.container}>
        <p className={styles.emphasis}>
          This asset version cannot be deleted because group instances in API
          Manager depend on it.
        </p>
        <p>
          Delete the group instances in API Manager, then delete this asset
          version.
        </p>
        <h3>Dependent group instances</h3>
        <Table
          caption="Dependent instances"
          headers={DeleteAssetModal.headers}
          rows={rows}
          testId="delete-modal-instances"
        />
      </div>
    );
  };

  render() {
    const { apiGroupInstances } = this.props;

    return (
      <AssetDetailDeleteAssetModal
        displayVersionAsMinor
        isDeleteBlocked={apiGroupInstances.length > 0}
        renderErrorDescription={this.renderErrorDescription}
        {...this.props}
      />
    );
  }
}

export default DeleteAssetModal;
