import * as portalsStoreUtils from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/reducers/utils';
import * as portalsActionTypes from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/actionTypes';
import { getGAKey } from '@mulesoft/exchange-ui-portals-store/lib/utils/assets';
import * as searchActions from '~/domains/search/actionTypes';
import * as assetsActions from '~/domains/assets/actionTypes';
import * as utils from './utils';
import * as actions from '../actionTypes';

const initialState = {
  data: {}
};

export default function reducer(state = initialState, action) {
  const assetPath = getGAKey(action);

  switch (action.type) {
    case assetsActions.FETCH_ASSET_SUCCESS: {
      return utils.mergeIncompleteVersion(state, action.asset);
    }

    case searchActions.CHANGE_RESULTS: {
      if (action.currentBatch) {
        return action.currentBatch.reduce(
          (accum, [asset]) => utils.mergeIncompleteVersion(accum, asset),
          state
        );
      }

      return state;
    }

    case actions.LOAD_ASSETS_SUCCESS:
    case actions.FETCH_ASSETS_SUCCESS: {
      return action.result.reduce(utils.mergeIncompleteVersion, state);
    }

    case actions.DELETE_ASSET_DETAIL: {
      return {
        ...utils.mergeVersion(state, {
          ...action.asset,
          isDeleted: true
        })
      };
    }

    case portalsActionTypes.UPDATE_ICON_ROOT: {
      if (!(assetPath in state.data)) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [assetPath]: portalsStoreUtils.updateIcon(
            state.data[assetPath],
            action.params.icon.externalLink
          )
        }
      };
    }

    case portalsActionTypes.DELETE_ICON_ROOT: {
      if (!(assetPath in state.data)) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [assetPath]: portalsStoreUtils.deleteIcon(state.data[assetPath])
        }
      };
    }

    default:
      return state;
  }
}
