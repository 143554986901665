import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RootStoreContext from '~/contexts/RootStoreContext';
import * as selectors from '~/domains/customization/selectors';
import * as commonSelectors from '~/domains/common/selectors';
import * as routesUtils from '~/utils/routes';
import CustomPage from './CustomPage';

const mapStateToProps = (state, { match }) => {
  const params = routesUtils.decodeParams(match.params);
  const page = selectors.customPageToView(state, params.pagePath);
  const domain = selectors.organizationDomain(state);
  const organization = { domain };
  const canEditPortalPage = selectors.canEditPortalPage(state);
  const getPath = commonSelectors.getPortalPathFn(state);

  return {
    page,
    canEdit: canEditPortalPage,
    pathToEdit: getPath(
      'customPage',
      { organization, pagePath: page.path },
      { isEditMode: true }
    ),
    isContentLoading: selectors.isContentLoading(state)
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null, null, { context: RootStoreContext })
)(CustomPage);
