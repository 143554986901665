import PropTypes from 'prop-types';
import React from 'react';
import TopRankMetrics from '~/components/TopRankMetrics';
import NavigationSidebar from '~/components/NavigationSidebar';
import BaseLayout from '~/layouts/BaseLayout';

class TopRankMetricsLayout extends BaseLayout {
  static propTypes = {
    dispatch: PropTypes.func
  };

  renderSidebar() {
    return <NavigationSidebar />;
  }

  renderMain() {
    const { route } = this.props;

    return <TopRankMetrics route={route} />;
  }
}

export default TopRankMetricsLayout;
