import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store';
import * as routesUtils from '~/utils/routes';
import AssetMetricsComponent from './AssetMetrics';

const mapStateToProps = (state, { match, location }) => {
  const params = routesUtils.getAssetParams(match, location);
  const asset = assetsSelectors.selectAsset(state, params);

  return { asset };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(AssetMetricsComponent);
