import debug from 'debug';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import { withAssetTypesConfiguration } from '@mulesoft/exchange-react-hooks';
import { actions as portalCategoriesActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/categories';
import { EVENT_TYPES } from '~/analytics/events';
import withFocusRestore from '~/components/WithFocusRestore';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import {
  actions as categoriesActions,
  selectors as categoriesSelectors
} from '~/domains/categories';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { actions as modalActions } from '~/domains/modal';
import { getComparatorByPropertyName } from '~/utils/arrays';
import { getOptions } from '~/utils/types';
import Loading from './Loading';

const Settings = loadable(
  () => import(/* webpackChunkName: "settings" */ './Settings'),
  { fallback: <Loading /> }
);

Settings.displayName = 'Settings';

const log = debug('exchange:settings:container');

const mapPortalStateToProps = (state) => {
  const profile = commonSelectors.profile(state);
  const organizationId = profile.organization.id;

  return {
    organizationId
  };
};

const mapStateToProps = (state, { assetTypesConfiguration }) => {
  const featureFlags = featureFlagsSelectors.featureFlags(state);
  const assetTypes = getOptions(assetTypesConfiguration, featureFlags);
  const profile = commonSelectors.profile(state);
  const organizationId = profile.organization.id;
  const categories = categoriesSelectors
    .categories(state, { organizationId })
    .sort(getComparatorByPropertyName('displayName'));

  log(`Returning ${categories.length} categories for org id ${organizationId}`);

  return {
    assetTypes,
    categories,
    isLoading: categoriesSelectors.isCategoriesLoading(state, {
      organizationId
    }),
    organizationId
  };
};
const mapPortalDispatchToProps = (dispatch) => ({
  clearPortalCategories: ({ organizationId }) => {
    return dispatch(
      portalCategoriesActions.clearCategories({ organizationId })
    );
  }
});

const mapDispatchToProps = (dispatch) => ({
  onAdd: (payload) => {
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.CATEGORY_SETTING_CREATED, payload)
    );

    return dispatch(categoriesActions.createCategory(payload));
  },
  onSave: (payload) => {
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.CATEGORY_SETTING_UPDATED, payload)
    );

    return dispatch(categoriesActions.updateCategory(payload));
  },
  openModal: ({ modalType, modalProps }) => {
    dispatch(modalActions.openModal({ modalType, modalProps }));
  }
});

const mergePortalProps = (stateProps, dispatchProps, ownProps) => {
  const { organizationId } = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    clearPortalCategories: (payload) =>
      dispatchProps.clearPortalCategories({
        ...payload,
        organizationId
      })
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { organizationId } = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onAdd: (payload) =>
      dispatchProps.onAdd({
        ...payload,
        organizationId
      }),
    openModal: ({ modalType, modalProps }) =>
      dispatchProps.openModal({
        modalType,
        modalProps: {
          ...modalProps,
          organizationId
        }
      }),
    onSave: (payload) =>
      dispatchProps.onSave({
        ...payload,
        organizationId
      })
  };
};

export default compose(
  withRouter,
  withAssetTypesConfiguration,
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  }),
  connect(mapPortalStateToProps, mapPortalDispatchToProps, mergePortalProps),
  withFocusRestore
)(Settings);
