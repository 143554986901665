import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './ApplicationName.css';

class ApplicationName extends React.PureComponent {
  static propTypes = {
    application: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    getPath: PropTypes.func.isRequired,
    testId: PropTypes.string
  };

  static defaultProps = {
    testId: 'application-name'
  };

  render() {
    const { application, getPath, organization, testId, onClick } = this.props;

    return (
      <div data-test-id={testId} className={styles.name}>
        <Link
          onClick={onClick}
          to={getPath('applicationDetail', {
            organization,
            applicationId: application.id
          })}
        >
          <span className="visually-hidden">Go to application </span>
          {application.name}
        </Link>
      </div>
    );
  }
}

export default ApplicationName;
