import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import DeprecateIcon from '@mulesoft/anypoint-icons/lib/svg/warning-small.svg';
import { isAPIGroup } from '~/utils/types';
import styles from './DeprecationHeader.css';

const DeprecationHeader = ({ asset, isMinorDeprecated }) => {
  const isAPIGroupAsset = isAPIGroup(asset);
  const isFullVersionDeprecation = isAPIGroupAsset;
  const deprecationText = `This ${
    isFullVersionDeprecation ? '' : 'minor '
  }version has been deprecated`;

  return (
    <div
      className={styles.deprecationHeader}
      display-if={isMinorDeprecated}
      data-test-id="deprecation-header"
      role="status"
    >
      <Icon title="Deprecated Version" size="s">
        <DeprecateIcon />
      </Icon>
      <span
        data-test-id="deprecation-label"
        className={styles.text}
        title={deprecationText}
      >
        {deprecationText}
      </span>
    </div>
  );
};

DeprecationHeader.propTypes = {
  asset: PropTypes.object,
  isMinorDeprecated: PropTypes.bool
};

export default DeprecationHeader;
