import React from 'react';
import PropTypes from 'prop-types';
import styles from './Main.css';

const Main = ({ children }) => {
  return (
    <section
      aria-label="Main content"
      className={styles.content}
      data-test-id="layout-middle-content"
    >
      {children}
    </section>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
