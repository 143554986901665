import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import withLayoutContext from '~/components/LayoutContextProvider';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import TermsPage from './TermsPage';

const mapStateToProps = (state, { match: { params } }) => {
  const isAPIGroupLoaded = reducerManagerSelectors.isTypeLoaded(
    state,
    'api-group'
  );

  if (!isAPIGroupLoaded) {
    return { isContributor: false, isPortalLoading: true };
  }

  return {
    isContributor: assetsSelectors.canEdit(state, { ...params }),
    isPortalLoading:
      portalsSelectors.isPortalLoading(state) ||
      apiGroupSelectors.isLoadingUnderlyingTerms(state)
  };
};

export default compose(
  withRouter,
  withLayoutContext(),
  connect(mapStateToProps)
)(TermsPage);
