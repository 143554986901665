import { format } from 'url';
import config from 'config';

export default {
  groupIdPattern: '[A-Za-z0-9_\\-.]+',
  versionPattern:
    '((0|[1-9]\\d*)\\.){2}(0|[1-9]\\d*)(-([A-Za-z0-9\\.]*[A-Za-z0-9]))?',
  apiVersionPattern: '\\S+',
  mulesoftCertifiedTag: 'mulesoft-certified',
  oauthCallbackUrl: format(config.externalURI).concat('/oauth.html'),
  navbarActiveProduct: {
    permissions: 'exchange',
    group: 0,
    name: 'Exchange',
    id: 'exchange',
    hoverIcon: 'xchange-color',
    icon: 'xchange-greyscale',
    href: `${config.contextPath}/`
  },
  cacheControlMaxAge: 31536000 // an year in seconds
};
