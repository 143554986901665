import React from 'react';
import loadable from '@loadable/component';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import styles from './TopRankMetrics.css';

const Loading = () => {
  return (
    <div className={styles.loading} data-test-id="metrics-spinner">
      <Spinner size="l" />
    </div>
  );
};

const TopRankMetrics = loadable(
  () =>
    import(
      /* webpackChunkName: "top-rank-metrics" */ '@mulesoft/exchange-reuse-metrics-components/lib/components/TopRankMetrics'
    ),
  { fallback: <Loading /> }
);

TopRankMetrics.displayName = 'TopRankMetrics';

const Metrics = () => {
  return (
    <React.Fragment>
      <h1>Usage and engagement metrics</h1>
      <TopRankMetrics />
    </React.Fragment>
  );
};

export default Metrics;
