import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { actions as portalCategoriesActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/categories';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import {
  actions as categoriesActions,
  selectors as categoriesSelectors
} from '~/domains/categories';
import { actions as modalActions } from '~/domains/modal';
import { getPath } from '~/utils/routes';
import { EVENT_TYPES } from '~/analytics/events';
import { setModalType, registerModal } from '~/components/Modals';
import EditCategoryConfigurationModal from './EditCategoryConfigurationModal';

const mapStateToProps = (state, { category }) => ({
  isApplyingChanges: categoriesSelectors.isCategoriesUpdating(state, {
    organizationId: category.organizationId
  })
});

const mapPortalDispatchToProps = (dispatch, { category }) => ({
  clearPortalCategories: () => {
    return dispatch(portalCategoriesActions.updateCategory({ category }));
  }
});

const mapDispatchToProps = (dispatch, { category }) => ({
  onClose: () => {
    dispatch(
      analyticsActions.trackEvent(
        EVENT_TYPES.CATEGORY_SETTING_FORCED_EDITION_CANCELED,
        category
      )
    );

    dispatch(modalActions.closeModal());
    dispatch(push(getPath('settings')));
  },
  onForceEdit: () => {
    dispatch(
      analyticsActions.trackEvent(
        EVENT_TYPES.CATEGORY_SETTING_FORCE_EDITED,
        category
      )
    );

    return dispatch(
      categoriesActions.updateCategory(category, { forceUpdate: true })
    );
  }
});

export default compose(
  registerModal,
  setModalType('EditCategoryConfigurationModal'),
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  }),
  connect(null, mapPortalDispatchToProps)
)(EditCategoryConfigurationModal);
