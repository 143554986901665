import memoize from 'memoize-one';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import * as assetSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/selectors';
import withLayoutContext from '~/components/LayoutContextProvider';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import * as urlUtils from '~/utils/url';
import Detail from './Detail';

const mergeParams = memoize((params, status) => ({
  ...params,
  status
}));

const mapStateToProps = (
  state,
  { location, match: { params: routeParams }, isPublicPortal }
) => {
  // someday, when we finally move out this code from exchange-ui to exchange-assets-definitions,
  // we will be able to remove this selector
  const isAPIGroupLoaded = reducerManagerSelectors.isTypeLoaded(
    state,
    'api-group'
  );

  if (!isAPIGroupLoaded) {
    return {
      asset: null,
      apiGroupInstances: [],
      isAdminMode: false
    };
  }

  const status = urlUtils.getQueryParam(location, 'status')[0];
  const params = mergeParams(routeParams, status);
  const asset = assetSelectors.asset(state, params);
  const canAdmin = assetSelectors.canAdmin(state, params);
  const apiGroupInstances = apiGroupSelectors.apiGroupInstances(state, asset);

  return {
    asset,
    apiGroupInstances,
    isAdminMode: !isPublicPortal && canAdmin
  };
};

const mapContextToProps = ({ isPublicPortal }) => ({
  isPublicPortal
});

export default compose(
  withRouter,
  withLayoutContext(mapContextToProps),
  connect(mapStateToProps)
)(Detail);
