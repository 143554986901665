import React from 'react';
import {
  Layout,
  Sidebar,
  Main
} from '@mulesoft/exchange-ui-components/lib/components/Layout';
import DetailWrapper from './DetailWrapper';
import styles from './BaseLayout.css';

class BaseLayout extends React.Component {
  renderSidebar() {
    return null;
  }

  renderMain() {
    return null;
  }

  renderDetail() {
    return null;
  }

  render() {
    const sidebar = this.renderSidebar();
    const main = this.renderMain();
    const detail = this.renderDetail();

    return (
      <Layout className={styles.layout}>
        {sidebar ? <Sidebar>{sidebar}</Sidebar> : null}
        <Main>{main}</Main>
        <DetailWrapper detail={detail} />
      </Layout>
    );
  }
}

export default BaseLayout;
