import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withAssetTypesConfiguration } from '@mulesoft/exchange-react-hooks';
import * as sessionSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/session/selectors';
import withLayoutContext from '~/components/LayoutContextProvider';
import withCustomizationLeaveHandling from '~/components/Customization/LeaveHookProvider';
import withFocusRestore from '~/components/WithFocusRestore';
import RootStoreContext from '~/contexts/RootStoreContext';
import { isEditCustomizationRoute } from '~/utils/routes';
import { selectors as assetsSelectors } from '~/domains/assets';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as customizationSelectors } from '~/domains/customization';
import { selectors as searchSelectors } from '~/domains/search';
import { parseSearch } from '~/utils/location';
import { getPresetLinkToEnabledMap } from '~/utils/navigation';
import Home from './Home';

const portalHomeState = (state, { location, route }) => {
  const query = parseSearch(location.search);

  return {
    isEditMode: isEditCustomizationRoute(route),
    hasUnsavedChanges: customizationSelectors.hasUnsavedChanges(state),
    profile: commonSelectors.profile(state),
    query
  };
};

export const homeState = (state, { location }) => {
  const query = parseSearch(location.search);
  const profile = commonSelectors.profile(state);
  const isSearchTriggered = assetsSelectors.isSearchTriggered(state);
  const isANGSearchEnabled = searchSelectors.isANGSearchEnabled(state);
  const featureFlags = featureFlagsSelectors.featureFlags(state);
  const presetLinkToEnabledMap = getPresetLinkToEnabledMap(featureFlags);

  return {
    profile,
    isANGSearchEnabled,
    isMarketingSite: sessionSelectors.isMarketingSite(state),
    presetLinkToEnabledMap,
    showExtendedCards: isANGSearchEnabled && isSearchTriggered,
    query
  };
};

const mapStateToProps = (state, ownProps) => {
  const mapStateToPropsStrategy = ownProps.isPublicPortal
    ? portalHomeState
    : homeState;

  return mapStateToPropsStrategy(state, ownProps);
};

const mapContextToProps = ({ isPublicPortal }) => ({
  isSidebarEnabled: !isPublicPortal,
  isWelcomeEnabled: isPublicPortal,
  isCreateAssetEnabled: !isPublicPortal,
  isPublicPortal
});

export default compose(
  withLayoutContext(mapContextToProps),
  withRouter,
  withAssetTypesConfiguration,
  connect(mapStateToProps, null, null, {
    context: RootStoreContext
  }),
  withCustomizationLeaveHandling,
  withFocusRestore
)(Home);
