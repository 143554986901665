/**
 * Creates a equals function to compare objects with a given
 * comparison function between elements
 * @param equalsFn
 */
export function equalsFactory(equalsFn) {
  return (...objects) => {
    if (!objects.length || objects.length === 1) {
      return true;
    }

    const firstObject = objects[0];
    const otherObjects = objects.slice(1);

    if (otherObjects.every((obj) => firstObject === obj)) {
      return true;
    }

    if (!firstObject) {
      return false;
    }

    return otherObjects.every((obj) => {
      if (firstObject === obj) {
        return true;
      }

      if (typeof firstObject !== 'object' || typeof obj !== 'object') {
        return false;
      }

      const objKeys = obj && Object.keys(obj);

      return (
        obj &&
        objKeys.length === Object.keys(firstObject).length &&
        objKeys.every((key) => equalsFn(obj[key], firstObject[key]))
      );
    });
  };
}

/**
 * Compare objects (using === as equality function between elements)
 * @param ...objects
 */
export const shallowEquals = equalsFactory((a, b) => a === b);

/**
 * Omit keys from an object.
 * @param object
 * @param ...keysToOmit
 */
export function omit(object, ...keysToOmit) {
  if (!isObject(object)) {
    throw new Error('`object` param must be an object.');
  }

  const result = { ...object };

  keysToOmit.forEach((key) => delete result[key]);

  return result;
}

/**
 * Get keys from an object.
 * @param object
 * @param ...keysToGet
 */
export function pick(object, ...keysToGet) {
  if (!isObject(object)) {
    throw new Error('`object` param must be an object.');
  }

  const result = {};

  keysToGet.forEach((key) => {
    if (typeof object[key] !== 'undefined') {
      result[key] = object[key];
    }
  });

  return result;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge objects.
 * @param target
 * @param ...sources
 */
export function deepMerge(target, ...sources) {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }

        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return deepMerge(target, ...sources);
}

/**
 * Get a property from an object given a nested path
 * @param object
 * @param path
 */
export function getProp(object, propPath) {
  return propPath
    .split('.')
    .reduce((current, path) => current && current[path], object);
}

/**
 * Returns true if object is empty
 * @param object
 */
export function isEmpty(object) {
  if (isObject(object)) {
    return Object.keys(object).length === 0;
  }

  if (Array.isArray(object)) {
    return object.length === 0;
  }

  return false;
}
