import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BasicCallout from '@mulesoft/exchange-ui-components/lib/components/BasicCallout';
import CollapsibleCard from '~/components/CollapsibleCard';
import * as apiGroupUtils from '~/portals/api-group/utils';
import ApiGroupCardTitle from '../ApiGroupCardTitle';
import ApiGroupInstance from '../ApiGroupInstance';
import ApiGroupEmptyInstance from '../ApiGroupEmptyInstance';
import styles from './ApiGroupCard.css';

class ApiGroupCard extends React.PureComponent {
  static propTypes = {
    asset: PropTypes.object,
    isExpanded: PropTypes.bool,
    isAdminMode: PropTypes.bool.isRequired,
    apiGroupInstance: PropTypes.object.isRequired,
    testId: PropTypes.string
  };

  static defaultProps = {
    isExpanded: false,
    testId: 'api-group-card'
  };

  renderTitle() {
    const { asset, apiGroupInstance, isAdminMode, testId } = this.props;

    return (
      <ApiGroupCardTitle
        asset={asset}
        apiGroupInstance={apiGroupInstance}
        isAdminMode={isAdminMode}
        testId={`${testId}-title`}
      />
    );
  }

  render() {
    const { testId, isAdminMode, isExpanded, apiGroupInstance } = this.props;
    const { apiInstances } = apiGroupInstance;
    const showCallout =
      isAdminMode &&
      apiGroupUtils.isInstancePublicPortalContradictory(apiGroupInstance);

    return (
      <CollapsibleCard
        className={classNames({ [styles.card]: isAdminMode })}
        isExpanded={showCallout || isExpanded}
        title={this.renderTitle()}
        testId={testId}
      >
        {apiInstances.length > 0 ? (
          <React.Fragment>
            <BasicCallout
              className={styles.callout}
              testId={`${testId}-private-apis-callout`}
              type="warning"
              title="All underlying API instances are private and will not be displayed on the public portal."
              subtitle="You can make it public on that API's page."
              display-if={showCallout}
            />
            <ApiGroupInstance
              isAdminMode={isAdminMode}
              apiGroupInstance={apiGroupInstance}
              testId={`${testId}-instance`}
            />
          </React.Fragment>
        ) : (
          <ApiGroupEmptyInstance testId={`${testId}-empty-instance`} />
        )}
      </CollapsibleCard>
    );
  }
}

export default ApiGroupCard;
