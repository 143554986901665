import React from 'react';
import PropTypes from 'prop-types';
import Home from '~/components/Home';
import NavigationSidebar from '~/components/NavigationSidebar';
import BaseLayout from '~/layouts/BaseLayout';

class HomeLayout extends BaseLayout {
  componentDidMount() {
    const { isMasterOrgAdmin, onMasterOrgPageLoaded, onPageLoaded } =
      this.props;

    onPageLoaded();

    if (isMasterOrgAdmin) {
      onMasterOrgPageLoaded();
    }
  }

  renderSidebar() {
    return <NavigationSidebar />;
  }

  renderMain() {
    return <Home />;
  }
}

HomeLayout.propTypes = {
  isMasterOrgAdmin: PropTypes.bool,
  onMasterOrgPageLoaded: PropTypes.func.isRequired,
  onPageLoaded: PropTypes.func.isRequired
};

export default HomeLayout;
