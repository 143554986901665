import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import Label from '@mulesoft/anypoint-components/lib/Label';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import EditIcon from '@mulesoft/anypoint-icons/lib/svg/edit-small.svg';
import CheckIcon from '@mulesoft/anypoint-icons/lib/svg/check.svg';
import CloseIcon from '@mulesoft/anypoint-icons/lib/svg/close-small.svg';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import styles from './Title.css';

class AssetTitle extends React.Component {
  static propTypes = {
    asset: AssetPropType,
    canEdit: PropTypes.bool,
    onSaveName: PropTypes.func
  };

  static maxLength = 256;

  state = {
    assetName: this.props.asset.name,
    isEditing: false,
    isLoading: false,
    unsavedName: ''
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.asset.name !== this.props.asset.name) {
      this.setState({ assetName: nextProps.asset.name });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isEditing && !this.state.isEditing) {
      this.editNameRef.current.focus();
    }
  }

  editNameRef = React.createRef();

  handleNameChange = (name) => {
    this.setState({ assetName: name });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.leaveEditState();
    }
    eventHandlerUtils.onEnterKey(this.saveName)(event);
  };

  isNameValid = (name) => !!name;

  saveName = () => {
    const { onSaveName, asset } = this.props;
    const assetName = this.state.assetName.trim();

    if (this.isNameValid(assetName) && assetName !== asset.name) {
      this.setState(
        {
          isLoading: true,
          unsavedName: assetName
        },
        async () => {
          await onSaveName(asset, assetName);
          this.setState({
            isLoading: false,
            unsavedName: ''
          });
        }
      );
    }

    this.leaveEditState();
  };

  leaveEditState = () => {
    this.setState({
      isEditing: false
    });
  };

  editName = () => {
    this.setState({
      isEditing: true
    });
  };

  renderEdit = () => {
    const { asset, canEdit } = this.props;
    const hasError = this.isNameValid(this.state.assetName.trim());
    const errorClass = hasError ? '' : styles.error;

    return (
      <React.Fragment>
        <Label className="visually-hidden" htmlFor="asset-name">
          Asset name
        </Label>
        <TextField
          id="asset-name"
          display-if={canEdit}
          testId="edit-asset-name"
          aria-invalid={!hasError}
          name="asset-name"
          type="text"
          className={classNames(styles.textField, errorClass)}
          onChange={({ value }) => this.handleNameChange(value)}
          onKeyDown={this.handleKeyDown}
          value={this.state.assetName}
          placeholder={asset.name}
          maxLength={AssetTitle.maxLength}
          autoFocus
        />
        <Icon
          data-test-id="name-save-action"
          role="button"
          aria-label="Save name"
          tabIndex="0"
          className={classNames(styles.icon, styles.action)}
          size={20}
          onClick={this.saveName}
          onKeyDown={eventHandlerUtils.onEnterKey(this.saveName)}
        >
          <CheckIcon />
        </Icon>
        <Icon
          data-test-id="name-cancel-action"
          role="button"
          aria-label="Cancel edit name"
          tabIndex="0"
          className={classNames(styles.icon, styles.action)}
          size={20}
          onClick={this.leaveEditState}
          onKeyDown={eventHandlerUtils.onEnterKey(this.leaveEditState)}
        >
          <CloseIcon />
        </Icon>
      </React.Fragment>
    );
  };

  renderName = () => {
    const { asset, canEdit } = this.props;

    return (
      <div
        ref={this.editNameRef}
        role="button"
        aria-label="Edit asset name"
        data-test-id="asset-name"
        tabIndex="0"
        className={classNames(
          styles.name,
          {
            [styles.disabled]: this.state.isLoading
          },
          { [styles.editable]: canEdit && !this.state.isLoading }
        )}
        onClick={canEdit && !this.state.isLoading ? this.editName : null}
        onKeyDown={
          canEdit && !this.state.isLoading
            ? eventHandlerUtils.onEnterKey(this.editName)
            : null
        }
      >
        <h1 title={this.state.isLoading ? this.state.unsavedName : asset.name}>
          {this.state.isLoading ? this.state.unsavedName : asset.name}
        </h1>
        <Icon
          display-if={canEdit && !this.state.isLoading}
          data-test-id="edit-asset-name-icon" // this value should not be changed because it is used in AppCue
          className={styles.editIcon}
          size="s"
        >
          <EditIcon />
        </Icon>
      </div>
    );
  };

  render() {
    const { isEditing } = this.state;

    return (
      <div className={styles.title}>
        {isEditing ? this.renderEdit() : this.renderName()}
      </div>
    );
  }
}

export default AssetTitle;
