import { actions as modalActions } from '~/domains/modal';

const getModalInfo = (modalIdentifier) => {
  const modalComponent =
    typeof modalIdentifier === 'string' ? null : modalIdentifier;
  const modalType =
    typeof modalIdentifier === 'string' ? modalIdentifier : null;

  return { modalComponent, modalType };
};

const getModalContext = ({ store }) => {
  const { dispatch } = store;
  const openModal = (modalIdentifier, modalProps, trackPayloadProps) => {
    const { modalComponent, modalType } = getModalInfo(modalIdentifier);

    dispatch(
      modalActions.openModal({
        modalComponent,
        modalType,
        modalProps,
        trackPayloadProps
      })
    );
  };
  const pushModal = (modalIdentifier, modalProps) => {
    const { modalComponent, modalType } = getModalInfo(modalIdentifier);

    dispatch(modalActions.pushModal({ modalComponent, modalType, modalProps }));
  };
  const closeModal = (trackPayloadProps) =>
    dispatch(modalActions.closeModal({ trackPayloadProps }));
  const addModalProps = (modalIdentifier, modalProps) => {
    const { modalComponent, modalType } = getModalInfo(modalIdentifier);

    dispatch(
      modalActions.addModalProps({
        modalComponent,
        modalType,
        modalProps
      })
    );
  };

  return {
    addModalProps,
    openModal,
    pushModal,
    closeModal
  };
};

export default getModalContext;
