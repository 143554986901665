const isClientProcess = typeof window === 'object';

/**
 * Scroll to the top of the page
 */
export function scrollToTop() {
  global.scrollTo(0, 0);
}

/**
 * Check if the process is in the client
 */
export function isClient() {
  return isClientProcess;
}
