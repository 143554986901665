import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as customizationSelectors } from '~/domains/customization';
import { actions as modalActions } from '~/domains/modal';
import { isPortalHomePage } from '~/utils/routes';
import ConfirmModal from '../ConfirmModal';
import DiscardModal from '../DiscardModal';
import SidebarActions from './SidebarActions';

const mapStateToProps = (state) => {
  const isDraftExist = customizationSelectors.isDraftExist(state);
  const hasUnsavedChanges = customizationSelectors.hasUnsavedChanges(state);
  const location = commonSelectors.location(state);
  const domain = customizationSelectors.organizationDomain(state);
  const getPath = commonSelectors.getPortalPathFn(state);
  const organization = { domain };

  return {
    isDraftExist,
    hasUnsavedChanges,
    organization,
    getPath,
    location
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    isDraftExist,
    hasUnsavedChanges,
    location: { pathname },
    organization,
    getPath
  } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    ...ownProps,
    ...stateProps,
    onDiscardButtonClick: () =>
      dispatch(modalActions.openModal({ modalType: DiscardModal.modalType })),
    onDoneButtonClick: () => {
      if (isDraftExist || hasUnsavedChanges) {
        dispatch(modalActions.openModal({ modalType: ConfirmModal.modalType }));
      } else if (!isPortalHomePage(pathname, organization, getPath)) {
        dispatch(push(getPath('home', { organization })));
      }
    }
  };
};

export default connect(mapStateToProps, null, mergeProps, {
  context: RootStoreContext
})(SidebarActions);
