import React from 'react';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import Container from './SettingsContainer';
import styles from './Settings.css';

const Loading = () => {
  return (
    <Container>
      <div className={styles.spinner}>
        <Spinner size="l" />
      </div>
    </Container>
  );
};

export default Loading;
