export function getModalTarget({ modalComponent, modalType }) {
  return {
    ...(modalType ? { modalType } : {}),
    ...(modalComponent ? { modalComponent } : {})
  };
}

export function getModalDescriptiveName({ modalType, modalComponent }) {
  const modalName =
    modalType ||
    modalComponent.modalName ||
    modalComponent.displayName ||
    modalComponent.name ||
    'ModalComponent';

  if (modalName === 'ModalComponent' || modalName.length < 2) {
    // eslint-disable-next-line no-console
    console.warn(
      'WARNING: this modal does not have a modalName defined',
      modalComponent
    );
  }

  return modalName;
}
