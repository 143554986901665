import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { goBack, push } from 'connected-react-router';
import { withPageTracking } from '~/components/Analytics';
import RootStoreContext from '~/contexts/RootStoreContext';
import {
  actions as commonActions,
  selectors as commonSelectors
} from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { isEditCustomizationRoute } from '~/utils/routes';
import PublicPortalApp from './PublicPortalApp';

const mapStateToProps = (state, { route, location }) => ({
  isEditMode: isEditCustomizationRoute(route, location),
  alert: commonSelectors.alert(state),
  header: commonSelectors.header(state),
  getPath: commonSelectors.getPortalPathFn(state),
  showNavbar: commonSelectors.showNavbar(state),
  previousRoutesSection: commonSelectors.previousRoutesSection(state),
  featureFlags: featureFlagsSelectors.featureFlags(state),
  routingContext: commonSelectors.routingContext(state)
});

const mapDispatchToProps = (dispatch) => ({
  onCloseAlert: () => dispatch(commonActions.closeAlert()),
  goBack: () => dispatch(goBack()),
  push: (path) => dispatch(push(path)),
  setDocumentMeta: (header) => dispatch(commonActions.setHeader(header))
});

export default compose(
  withRouter,
  withPageTracking,
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(PublicPortalApp);
