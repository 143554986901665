import memoize from 'memoize-one';
import * as actionTypes from './actionTypes';

const categoriesActionTypes = [
  actionTypes.UPDATE_CATEGORY,
  actionTypes.REMOVE_CATEGORY
];

const getCategories = memoize((categories, requests, organizationId) =>
  (categories || []).map((category) => ({
    ...category,
    isLoading: requests.some(
      (request) =>
        categoriesActionTypes.includes(request.identifier) &&
        request.params.organizationId === organizationId &&
        request.params.tagKey === category.tagKey &&
        request.status === 'STARTED'
    )
  }))
);

const hasCategoriesRequest = (state, { organizationId }, actionType) =>
  state.requests.some(
    (request) =>
      request.identifier === actionType &&
      request.params.organizationId === organizationId &&
      request.status === 'STARTED'
  );

export const categories = (state, { organizationId }) =>
  getCategories(
    state.categories.data[organizationId],
    state.requests,
    organizationId
  );

export const isCategoriesLoading = (state, { organizationId }) =>
  hasCategoriesRequest(state, { organizationId }, actionTypes.LIST_CATEGORIES);

export const isCategoriesUpdating = (state, { organizationId }) =>
  hasCategoriesRequest(state, { organizationId }, actionTypes.UPDATE_CATEGORY);

export const isDeletingCategory = (state) =>
  state.categories.ui.isDeletingCategory;

export const hasDeleteCategoryConflict = (state) =>
  state.categories.ui.hasDeleteCategoryConflict;
