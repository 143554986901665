import React from 'react';
import PropTypes from 'prop-types';

const PublishErrorHint = ({ type }) => {
  return (
    <span data-test-id="error-message-hints">
      To look for the errors, upload the same {type} in{' '}
      <a href="/designcenter">Design Center</a>.
    </span>
  );
};

PublishErrorHint.propTypes = {
  type: PropTypes.string.isRequired
};

export default React.memo(PublishErrorHint);
