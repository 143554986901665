import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import TrashIcon from '@mulesoft/anypoint-icons/lib/svg/trash.svg';
import CheckIcon from '@mulesoft/anypoint-icons/lib/svg/check.svg';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import styles from './SidebarActions.css';

const propTypes = {
  isDraftExist: PropTypes.bool,
  hasUnsavedChanges: PropTypes.bool,
  onDiscardButtonClick: PropTypes.func,
  onDoneButtonClick: PropTypes.func
};

const SidebarActions = ({
  isDraftExist,
  hasUnsavedChanges,
  onDiscardButtonClick,
  onDoneButtonClick
}) => {
  return (
    <div className={styles.actions} data-test-id="sidebar-actions">
      <button
        type="button"
        className={styles.action}
        data-test-id="discard-new-changes-button"
        aria-describedby="discard-new-changes-button-description"
        onClick={onDiscardButtonClick}
        onKeyDown={eventHandlerUtils.onEnterKey(onDiscardButtonClick)}
        disabled={!isDraftExist && !hasUnsavedChanges}
      >
        <Icon>
          <TrashIcon />
        </Icon>
        <span className={styles.label}>Discard changes</span>
      </button>
      <span
        id="discard-new-changes-button-description"
        className="visually-hidden"
      >
        Opens discard changes modal
      </span>
      <button
        type="button"
        className={styles.action}
        onClick={onDoneButtonClick}
        onKeyDown={eventHandlerUtils.onEnterKey(onDoneButtonClick)}
        data-test-id="done-editing-portal-button"
      >
        <Icon>
          <CheckIcon />
        </Icon>
        <span className={styles.label}>Done editing</span>
      </button>
    </div>
  );
};

SidebarActions.defaultProps = {
  onDiscardButtonClick: () => {},
  onDoneButtonClick: () => {}
};

SidebarActions.propTypes = propTypes;

export default SidebarActions;
