import { compose } from 'redux';
import { connect } from 'react-redux';
import { setModalType, registerModal } from '~/components/Modals';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import {
  actions as customizationActions,
  selectors as customizationSelectors
} from '~/domains/customization';
import { actions as modalActions } from '~/domains/modal';
import ConfirmModal from './ConfirmModal';

const mapStateToProps = (state) => {
  const domain = customizationSelectors.organizationDomain(state);
  const organization = { domain };

  return {
    organization,
    location: commonSelectors.location(state),
    isSaving: customizationSelectors.isSaving(state),
    isPublishing: customizationSelectors.isPublishing(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(modalActions.closeModal()),
  onSaveForLater: () => dispatch(customizationActions.saveCustomizationDraft()),
  onPublish: () => dispatch(customizationActions.saveAndPublishDraft())
});

export default compose(
  registerModal,
  setModalType('CustomizationConfirmModal'),
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(ConfirmModal);
