import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import styles from './LoadingElement.css';

const LoadingElement = ({ message, size = 'medium' }) => {
  return (
    <div className={classNames(styles[size], styles.loading)}>
      <Spinner testId="loading-element" size="s" className={styles.spinner} />
      <span>{message || 'Loading more'}</span>
    </div>
  );
};

LoadingElement.propTypes = {
  message: PropTypes.string,
  size: PropTypes.string
};

export default LoadingElement;
