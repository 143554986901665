import * as commonSelectors from '~/domains/common/selectors';
import * as requestsSelectors from '~/domains/requests/selectors';
import * as actions from '~/domains/requests/actionTypes';
import { shallowEquals } from '~/utils/objects';

function defaultRequestCompare({ oldParams, params }) {
  return shallowEquals(oldParams, params);
}

export const actionifyFn =
  (
    serviceFn,
    identifier,
    { once = false, requestCompare = defaultRequestCompare } = {}
  ) =>
  (params) =>
  (dispatch, getState) => {
    if (!serviceFn) {
      throw new Error('Missing mandatory service function');
    }

    if (!identifier) {
      throw new Error('Missing mandatory identifier');
    }

    const state = getState();
    const context = commonSelectors.context(state);

    if (once) {
      const requests = requestsSelectors.requests(state);
      const alreadyCalled = requests.some(
        (request) =>
          request.identifier === identifier &&
          requestCompare({ state, params, oldParams: request.params })
      );

      if (alreadyCalled) {
        return Promise.resolve();
      }
    }

    dispatch({ type: `${identifier} Request`, params });
    dispatch({ type: actions.REQUEST_STARTED, identifier, params });

    return serviceFn({ ...params, context })
      .then((result) => {
        dispatch({ type: `${identifier} Success`, params, result });
        dispatch({ type: actions.REQUEST_SUCCESS, identifier, params });

        return result;
      })
      .catch((error) => {
        dispatch({
          type: `${identifier} Failure`,
          params,
          error: {
            message: error.message,
            requestId: error.requestId
          }
        });
        dispatch({ type: actions.REQUEST_FAILURE, identifier, params });

        throw error;
      });
  };

export default actionifyFn;
