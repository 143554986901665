import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as clientApplicationsActions } from '~/domains/clientApplications';
import Actions from './Actions';

const mapDispatchToProps = (dispatch) => ({
  onEditApplication: (application) =>
    dispatch(
      clientApplicationsActions.editClientApplicationSuccess(application)
    )
});

export default connect(null, mapDispatchToProps, null, {
  context: RootStoreContext
})(Actions);
