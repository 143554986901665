import config from 'config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import {
  withConfig,
  withRouter as withRouterActions
} from '@mulesoft/exchange-react-hooks';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import RootStoreContext, {
  withAssetFromRootStore
} from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import Sidebar from './Sidebar';

const mapStateToProps = (state, { location, route, match, asset }) => {
  const { isDraft = false, isEdit = false } = route;
  const getPath = commonSelectors.getPortalPathFn(state);
  const isContentLoading = portalsSelectors.isPortalLoading(state);
  const isHomePage =
    getPath('assetHome', asset) === location.pathname ||
    getPath(`page${isEdit ? 'Edit' : ''}`, {
      assetId: asset.assetId,
      groupId: asset.groupId,
      isDraft,
      minorVersion: asset.minorVersion,
      pagePath: config.defaultPageName
    }) === location.pathname ||
    getPath('page', {
      assetId: asset.assetId,
      groupId: asset.groupId,
      minorVersion: asset.minorVersion
    }) === location.pathname;
  const isAdmin = assetsSelectors.canAdmin(state, asset);
  const isContributor = assetsSelectors.canEdit(state, asset);
  const isVersionNavigation = !!match.params.version;

  return {
    asset,
    isAdmin,
    isContributor,
    isContentLoading,
    isDraft,
    isEdit,
    isHomePage,
    isVersionNavigation
  };
};

const mapRootStateToProps = (state) => ({
  homeQuery: commonSelectors.homeQuery(state)
});

export default compose(
  withConfig,
  withRouterActions,
  withAssetFromRootStore,
  withRouter,
  connect(mapStateToProps),
  connect(mapRootStateToProps, null, null, {
    context: RootStoreContext
  })
)(Sidebar);
