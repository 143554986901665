import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from '@mulesoft/anypoint-components/lib/Form';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import Tooltip from '@mulesoft/anypoint-components/lib/Tooltip';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import Button from '@mulesoft/anypoint-components/lib/Button';
import styles from '../Modal.css';

class FormModal extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.node,
    cancelButtonText: PropTypes.string,
    submitButtonText: PropTypes.string,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    disableText: PropTypes.string,
    disableSubmitButton: PropTypes.bool,
    form: PropTypes.object,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    isDanger: PropTypes.bool,
    className: PropTypes.string,
    testId: PropTypes.string
  };

  static defaultProps = {
    cancelButtonText: 'Cancel',
    disableSubmitButton: false,
    testId: 'modal',
    submitButtonText: 'Submit'
  };

  render() {
    const {
      title,
      body,
      cancelButtonText,
      submitButtonText,
      disableSubmitButton,
      disableText,
      isDanger,
      isLoading,
      onCancel,
      onChange,
      form,
      onSubmit,
      className,
      testId
    } = this.props;
    const labelId = 'form-modal-title';

    return (
      <Modal
        renderInBody={false}
        onEsc={onCancel}
        onClickOverlay={onCancel}
        className={classNames(className, styles.modal)}
        ariaLabelledBy={labelId}
        role={isDanger ? 'alertdialog' : 'dialog'}
      >
        <Form
          onSubmit={onSubmit}
          onChange={onChange}
          value={form}
          testId={`${testId}-form`}
        >
          <ModalHeader
            onClose={this.handleClose}
            testId="form-modal-header"
            className={styles.header}
            isDanger={isDanger}
          >
            <h2 id={labelId} className={styles.title}>
              {title}
            </h2>
          </ModalHeader>
          <ModalBody className={styles.body} testId="form-modal-body">
            {body}
          </ModalBody>
          <ModalFooter testId="form-modal-footer" className={styles.footer}>
            <Button
              testId="form-modal-cancel-button"
              kind="tertiary"
              onClick={onCancel}
              disabled={isLoading}
              noFill
            >
              {cancelButtonText}
            </Button>
            <Button
              testId="form-modal-submit-button"
              kind={isDanger ? 'danger' : 'primary'}
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || disableSubmitButton}
            >
              {disableSubmitButton ? (
                <Tooltip content={disableText}>{submitButtonText}</Tooltip>
              ) : (
                submitButtonText
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default FormModal;
