export const LIST_CATEGORIES = 'List Categories';
export const LIST_CATEGORIES_REQUEST = 'List Categories Request';
export const LIST_CATEGORIES_SUCCESS = 'List Categories Success';
export const LIST_CATEGORIES_FAILURE = 'List Categories Failure';
export const CREATE_CATEGORY = 'Create Category';
export const CREATE_CATEGORY_REQUEST = 'Create Category Request';
export const CREATE_CATEGORY_SUCCESS = 'Create Category Success';
export const CREATE_CATEGORY_FAILURE = 'Create Category Failure';
export const UPDATE_CATEGORY = 'Update Category';
export const UPDATE_CATEGORY_REQUEST = 'Update Category Request';
export const UPDATE_CATEGORY_SUCCESS = 'Update Category Success';
export const UPDATE_CATEGORY_FAILURE = 'Update Category Failure';
export const REMOVE_CATEGORY = 'Remove Category';
export const REMOVE_CATEGORY_REQUEST = 'Remove Category Request';
export const REMOVE_CATEGORY_SUCCESS = 'Remove Category Success';
export const REMOVE_CATEGORY_FAILURE = 'Remove Category Failure';
export const REMOVE_CATEGORY_CONFLICT = 'Remove Category Conflict';
export const CANCEL_REMOVE_CATEGORY = 'Cancel Remove Category';
