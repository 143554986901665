import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  withAnalytics,
  withModal,
  withRouter as withRouterActions
} from '@mulesoft/exchange-react-hooks';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import {
  actions as pagesActions,
  selectors as pagesSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import StatelessPageEdit from './StatelessPageEdit';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import { selectors as customizationSelectors } from '~/domains/customization';
import { selectors as commonSelectors } from '~/domains/common';
import { notebooksRanMode } from '~/analytics/events/notebooks';
import ConfirmDeletePageModal from '~/components/DeletePage/ConfirmDeletePageModal';
import { EVENT_TYPES } from '~/analytics/events';
import * as assetPortalService from '~/services/assetPortalService';
import * as notebooksUtils from '~/portals/utils/notebooks';
import * as typesUtils from '~/utils/types';
import * as routesUtils from '~/utils/routes';
import {
  isPredefinedPage,
  isDefaultPage,
  replacePredefinedPageName
} from '~/utils/page';

const mapStateToProps = (state, { location, match }) => {
  const params = routesUtils.getAssetParams(match, location);
  const page = replacePredefinedPageName(
    pagesSelectors.draftOrPublishedPage(state, params)
  );
  const context = commonSelectors.context(state);
  const isAdmin = assetsSelectors.canAdmin(state, params);
  const isContributor = assetsSelectors.canEdit(state, params);
  const asset = assetsSelectors.asset(state, params);
  const isAPIGroup = typesUtils.isAPIGroup(asset);
  const pageEditorState = pagesSelectors.pageEditorState(state, params);

  return {
    asset,
    context,
    pageEditorState,
    page,
    pages: pagesSelectors.pages(state, { ...params, isDraft: true }),
    isContentLoading:
      pagesSelectors.isLoading(state) ||
      customizationSelectors.isContentLoading(state) ||
      portalsSelectors.isPortalLoading(state),
    hasTitle: page && !isDefaultPage(page),
    canRenamePage: page && !isPredefinedPage(page),
    canDeletePage: page && !isDefaultPage(page),
    isAdmin,
    isContributor,
    hasUnderlyingTerms:
      isAPIGroup && reducerManagerSelectors.loadedType(state, asset.type)
        ? apiGroupSelectors.hasUnderlyingTerms(state, asset)
        : false
  };
};

const mapDispatchToProps = (
  dispatch,
  { closeModal, openModal, trackEvent, router: { getPath, push } }
) => ({
  onDeletePage: (payload) => {
    dispatch(pagesActions.deletePage({ ...payload, pushFn: push, getPath }));
    closeModal();
  },
  onOpenDeleteModal: (page, onDeletePage) =>
    openModal(ConfirmDeletePageModal, {
      page,
      onDeletePage
    }),
  onFetchNotebookClient: (payload) =>
    dispatch(notebooksUtils.fetchNotebookClient(payload)),
  onFileUpload: (file, { context, groupId, assetId, version }) =>
    assetPortalService.uploadResource({
      context,
      file,
      groupId,
      assetId,
      version
    }),
  onAddNotebook: () => trackEvent(EVENT_TYPES.NOTEBOOK_CREATED),
  onPlayNotebook: (stateProps) =>
    trackEvent(EVENT_TYPES.NOTEBOOK_RAN, {
      mode: notebooksRanMode.EDIT,
      isAdmin: stateProps.isAdmin,
      isContributor: stateProps.isContributor
    }),
  onPlayNotebookSnippet: (stateProps) =>
    trackEvent(EVENT_TYPES.NOTEBOOK_SNIPPET_RAN, {
      mode: notebooksRanMode.EDIT,
      isAdmin: stateProps.isAdmin,
      isContributor: stateProps.isContributor
    }),
  onPageContentChange: async (pageEditorState, pageContent, asset) => {
    await dispatch(
      pagesActions.updatePageEditorState({
        ...pageEditorState,
        ...asset,
        pageContent
      })
    );
  },
  onPageNameChange: async (pageEditorState, pageName, asset) => {
    await dispatch(
      pagesActions.updatePageEditorState({
        ...pageEditorState,
        ...asset,
        pageName
      })
    );
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onFileUpload: (file) =>
    dispatchProps.onFileUpload(file, {
      context: stateProps.context,
      groupId: stateProps.asset.groupId,
      assetId: stateProps.asset.assetId,
      version: stateProps.asset.version
    }),
  onOpenDeleteModal: () => {
    const { asset, page } = stateProps;
    const onDeletePage = () =>
      dispatchProps.onDeletePage({
        type: asset.type,
        organizationId: asset.organization.id,
        groupId: asset.groupId,
        assetId: asset.assetId,
        version: asset.version,
        minorVersion: asset.minorVersion,
        versionGroup: asset.versionGroup,
        pagePath: page.path
      });

    dispatchProps.onOpenDeleteModal(stateProps.page, onDeletePage);
  },
  onPlayNotebook: () => dispatchProps.onPlayNotebook(stateProps),
  onPlayNotebookSnippet: () => dispatchProps.onPlayNotebookSnippet(stateProps),
  onPageContentChange: (pageContent) =>
    dispatchProps.onPageContentChange(stateProps.pageEditorState, pageContent, {
      groupId: stateProps.asset.groupId,
      assetId: stateProps.asset.assetId,
      version: stateProps.asset.version
    }),
  onPageNameChange: (pageName) =>
    dispatchProps.onPageNameChange(stateProps.pageEditorState, pageName, {
      groupId: stateProps.asset.groupId,
      assetId: stateProps.asset.assetId,
      version: stateProps.asset.version
    })
});

export default compose(
  withAnalytics,
  withModal,
  withRouter,
  withRouterActions,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(StatelessPageEdit);
