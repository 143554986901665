import { format } from 'url';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { withAssetTypesConfiguration } from '@mulesoft/exchange-react-hooks';
import { EVENT_TYPES } from '~/analytics/events';
import withLayoutContext from '~/components/LayoutContextProvider';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import { actions as assetsActions } from '~/domains/assets';
import { selectors as commonSelectors } from '~/domains/common';
import { parseSearch } from '~/utils/location';
import { getOptions, getPortalOptions } from '~/utils/types';
import Search from './Search';

const mapStateToProps = (state) => {
  const location = commonSelectors.location(state);

  return { studioType: commonSelectors.studioType(state), location };
};

const portalSearchActions = (dispatch, { location }) => {
  const query = parseSearch(location.search);

  return {
    onGetAssets: (payload) =>
      dispatch(assetsActions.searchPortalAssets(payload, location)),
    onClearFilters: (payload) =>
      dispatch(assetsActions.clearPortalFilters(payload, location)),
    onChangeSelectedType: (selectedType) =>
      dispatch(
        push(
          format({
            pathname: location.pathname,
            query: { ...query, type: selectedType && selectedType.keys }
          })
        )
      ),
    onAssetClick: (
      { organization: { id: organizationId }, groupId, assetId, type },
      view
    ) =>
      dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.ASSETS_LIST_RESULT_CLICKED, {
          organizationId,
          groupId,
          assetId,
          type,
          view
        })
      ),
    onSwitchView: (view) =>
      dispatch(
        analyticsActions.trackEvent(
          EVENT_TYPES[`PORTAL_${view.toUpperCase()}_VIEW_CLICKED`]
        )
      )
  };
};

const searchActions = (dispatch, { location }) => {
  const query = parseSearch(location.search);

  return {
    onGetAssets: (payload) => dispatch(assetsActions.searchAssets(payload)),
    onClearFilters: () => dispatch(assetsActions.clearFilters(location, query)),
    onChangeSelectedType: (selectedType) =>
      dispatch(
        push(
          format({
            pathname: location.pathname,
            query: { ...query, type: selectedType && selectedType.keys }
          })
        )
      ),
    onAssetClick: (
      { organization: { id: organizationId }, groupId, assetId, type },
      view
    ) =>
      dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.ASSETS_LIST_RESULT_CLICKED, {
          organizationId,
          groupId,
          assetId,
          type,
          view
        })
      ),
    onSwitchView: (view) =>
      dispatch(
        analyticsActions.trackEvent(
          EVENT_TYPES[`${view.toUpperCase()}_VIEW_CLICKED`]
        )
      )
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const mapDispatchToPropsStrategy = ownProps.isPublicPortal
    ? portalSearchActions
    : searchActions;

  return mapDispatchToPropsStrategy(dispatch, ownProps);
};

const mapContextToProps = (
  { isPublicPortal, getPath, featureFlags },
  { assetTypesConfiguration }
) => {
  const filterOptions = isPublicPortal
    ? getPortalOptions(assetTypesConfiguration, featureFlags)
    : getOptions(assetTypesConfiguration, featureFlags);

  return {
    filterOptions,
    getPath,
    isPublicPortal
  };
};

export default compose(
  withAssetTypesConfiguration,
  withLayoutContext(mapContextToProps),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(Search);
