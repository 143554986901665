import React from 'react';
import BackIcon from '@mulesoft/anypoint-icons/lib/svg/back-small.svg';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import RootStoreContext from '~/contexts/RootStoreContext';
import Links from '~/components/Layout/Sidebar/Links';
import { getPath } from '~/utils/routes';

class Sidebar extends React.PureComponent {
  static propTypes = {
    asset: AssetPropType
  };

  static contextType = RootStoreContext;

  render() {
    const { asset } = this.props;
    const { store } = this.context;
    const state = store?.getState();
    const { homeQuery } = state?.common || {};
    const items = [
      {
        name: asset ? asset.name : 'Assets list',
        icon: <BackIcon />,
        to: asset ? getPath('assetHome', asset) : getPath('home'),
        query: asset ? {} : homeQuery
      }
    ];

    return (
      <nav>
        <Links items={items} testId="create" />
      </nav>
    );
  }
}

export default Sidebar;
