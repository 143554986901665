import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useAsset } from '@mulesoft/exchange-react-hooks';
import { Detail } from '@mulesoft/exchange-ui-components/lib/components/Layout';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';

const DetailWrapper = ({ detail }) => {
  const isPortalReArchitectureEnabled = useSelector(
    sessionSelectors.isPIRAEnabled
  );
  const asset = useAsset();
  const showDetail = !isPortalReArchitectureEnabled || !asset;

  if (showDetail && detail) {
    return <Detail>{detail}</Detail>;
  }

  return null;
};

DetailWrapper.propTypes = {
  detail: PropTypes.node
};
export default DetailWrapper;
