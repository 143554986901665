import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';

const announcements = new Map();

const AnnouncementWrapper = (Component) => {
  return function ({
    // eslint-disable-next-line react/prop-types
    isAnnouncementVisible,
    ...props
  }) {
    if (isAnnouncementVisible) {
      return <Component {...props} />;
    }

    return null;
  };
};

AnnouncementWrapper.propTypes = {
  isAnnouncementVisible: PropTypes.bool
};

export const setAnnouncement = (condition) => {
  const announcement = (Component) => {
    const mapStateToProps = (state) => ({
      isAnnouncementVisible: condition(state)
    });
    const Announcement = connect(mapStateToProps, null, null, {
      context: RootStoreContext
    })(AnnouncementWrapper(Component));

    announcements.set(Component.displayName || Component.name, Announcement);

    return Announcement;
  };

  return announcement;
};

export const getAnnouncements = () => [...announcements.values()];

export const clearAnnouncements = () => announcements.clear();
