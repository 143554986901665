import React from 'react';
import { useSelector } from 'react-redux';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import StatefulPageEdit from './StatefulPageEdit';
import StatelessPageEdit from './StatelessPageEdit';

const PageEdit = (props) => {
  const isPIRAEnabled = useSelector(sessionSelectors.isPIRAEnabled);

  if (isPIRAEnabled) {
    return <StatelessPageEdit {...props} />;
  }

  return <StatefulPageEdit {...props} />;
};

export default PageEdit;
