import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import MuleIcon from '@mulesoft/anypoint-icons/lib/svg/mule.svg';
import EditIcon from '@mulesoft/anypoint-icons/lib/svg/edit-small.svg';
import Button from '@mulesoft/anypoint-components/lib/Button';
import { Link } from 'react-router-dom';
import styles from './EmptyPage.css';

const buttonStyles = Button.themes.DefaultTheme;

const EmptyPage = ({
  pathToEdit,
  canEdit,
  hasUnsavedChanges,
  actionName = 'Edit'
}) => {
  return (
    <div className={styles.emptyAsset} data-test-id="assets-detail-empty-page">
      <Icon className={styles.icon} size="xl">
        <MuleIcon />
      </Icon>
      <p data-test-id="assets-empty-page-reason" className={styles.message}>
        This page is missing content.
      </p>
      <p
        data-test-id="assets-empty-page-edit-reason"
        display-if={canEdit}
        className={styles.message}
      >
        {hasUnsavedChanges
          ? `Click ${actionName} to continue working on the draft.`
          : `Click ${actionName} to add text, images, videos, code blocks, etc...`}
      </p>
      <Link
        data-test-id="page-action-edit"
        display-if={canEdit}
        className={classNames(
          styles.actionEdit,
          buttonStyles.button,
          buttonStyles.noFill,
          buttonStyles.md,
          buttonStyles.primary
        )}
        to={pathToEdit}
      >
        <Icon size="s" aria-hidden>
          <EditIcon />
        </Icon>
        <span>{actionName}</span>
      </Link>
    </div>
  );
};

EmptyPage.propTypes = {
  pathToEdit: PropTypes.string,
  canEdit: PropTypes.bool,
  hasUnsavedChanges: PropTypes.bool,
  actionName: PropTypes.string
};

export default EmptyPage;
