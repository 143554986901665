import { createSelector } from 'reselect';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { selectors as localStorageSelectors } from '~/domains/localStorage';
import {
  ANG_SEARCH_ENABLED_FLAG,
  GLOBAL_ANG_SEARCH_ENABLED,
  ANG_DEFAULT_ENABLED
} from '~/domains/featureFlags/keys';
import * as objectsUtils from '~/utils/objects';

const daysSinceLastShownThreshold = 1;
const oneDayToMiliseconds = 1000 * 60 * 60 * 24;

export const facets = (state) => state.search.facets;
export const facetsErrorDate = (state) => state.search?.fetchingFacetsErrorDate;
export const isANGAvailable = (state) => !state.search?.fetchingFacetsErrorDate;
export const facetsHasBeenRefreshed = (state) =>
  state.search.facetsHasBeenRefreshed;

const isANGSearchFlagEnabled = (state) =>
  !commonSelectors.isStudio(state) &&
  featureFlagsSelectors.featureFlag(state, ANG_SEARCH_ENABLED_FLAG);

const isGlobalANGSearchFlagEnabled = (state) =>
  featureFlagsSelectors.featureFlag(state, GLOBAL_ANG_SEARCH_ENABLED);

const isANGDefaultFlagEnabled = (state) =>
  featureFlagsSelectors.featureFlag(state, ANG_DEFAULT_ENABLED);

const isANGSearchOptInEnabled = createSelector(
  commonSelectors.profile,
  ($profile) =>
    !!$profile &&
    objectsUtils.getProp($profile, 'properties.exchange.isANGSearchEnabled') ===
      'true'
);

const isANGSearchOptOutEnabled = createSelector(
  commonSelectors.profile,
  ($profile) =>
    !!$profile &&
    objectsUtils.getProp(
      $profile,
      'properties.exchange.isANGSearchDisabled'
    ) === 'true'
);

export const isANGSearchOptedIn = createSelector(
  isANGDefaultFlagEnabled,
  isANGSearchOptInEnabled,
  isANGSearchOptOutEnabled,
  ($isANGDefaultFlagEnabled, $isOptInEnabled, $isOptOutEnabled) =>
    $isANGDefaultFlagEnabled ? !$isOptOutEnabled : $isOptInEnabled
);

export const isANGSearchEnabled = createSelector(
  isANGAvailable,
  isANGSearchFlagEnabled,
  isGlobalANGSearchFlagEnabled,
  isANGSearchOptedIn,
  commonSelectors.profile,
  (
    $isANGAvailable,
    $isEnabled,
    $isGlobalEnabled,
    $isANGSearchOptedIn,
    $profile
  ) =>
    $isANGAvailable &&
    ($profile ? $isEnabled && $isANGSearchOptedIn : $isGlobalEnabled)
);

export const isANGSearchOptInVisible = createSelector(
  isANGAvailable,
  isANGSearchFlagEnabled,
  isANGSearchOptedIn,
  commonSelectors.profile,
  ($isANGAvailable, $isEnabled, $isANGSearchOptedIn, $profile) =>
    $isANGAvailable && $isEnabled && !$isANGSearchOptedIn && !!$profile
);

const OPT_IN_BANNER_DISABLED_KEY = 'isANGSearchOptInBannerDisabled';

export const isANGSearchOptInBannerVisible = createSelector(
  isANGSearchOptInVisible,
  isANGDefaultFlagEnabled,
  (state) =>
    localStorageSelectors.localStorageItem(state, OPT_IN_BANNER_DISABLED_KEY),
  sessionSelectors.isPublicPortal,
  (
    $isANGSearchOptInVisible,
    $isANGDefaultFlagEnabled,
    $isDisabled,
    $isPublicPortal
  ) =>
    $isANGSearchOptInVisible &&
    !$isANGDefaultFlagEnabled &&
    !$isDisabled &&
    !$isPublicPortal
);

export const isANGSearchOptOutVisible = createSelector(
  isANGAvailable,
  isANGSearchFlagEnabled,
  isANGSearchOptedIn,
  ($isANGAvailable, $isEnabled, $isANGSearchOptedIn) =>
    $isANGAvailable && $isEnabled && $isANGSearchOptedIn
);

const OPT_OUT_BANNER_DISABLED_KEY = 'isANGSearchOptOutBannerDisabled';

export const isANGSearchOptOutBannerVisible = createSelector(
  isANGSearchOptOutVisible,
  isANGDefaultFlagEnabled,
  (state) =>
    localStorageSelectors.localStorageItem(state, OPT_OUT_BANNER_DISABLED_KEY),
  sessionSelectors.isPublicPortal,
  (
    $isANGSearchOptOutVisible,
    $isANGDefaultFlagEnabled,
    $isDisabled,
    $isPublicPortal
  ) =>
    $isANGSearchOptOutVisible &&
    !$isANGDefaultFlagEnabled &&
    !$isDisabled &&
    !$isPublicPortal
);

const TEMP_DISABLED_BANNER_LAST_TIME_SHOWN_KEY =
  'lastTimeANGTemporarilyDisabledShown';

export const isANGTemporarilyDisabledBannerVisible = createSelector(
  facetsErrorDate,
  isANGSearchFlagEnabled,
  isANGSearchOptInEnabled,
  (state) =>
    localStorageSelectors.localStorageItem(
      state,
      TEMP_DISABLED_BANNER_LAST_TIME_SHOWN_KEY
    ),
  commonSelectors.isStudio,
  sessionSelectors.isPublicPortal,
  (
    $facetsErrorDate,
    $isANGSearchFlagEnabled,
    $isANGSearchOptInEnabled,
    $lastTimeShown,
    $isStudio,
    $isPublicPortal
  ) => {
    const $isANGAvailable = !$facetsErrorDate;
    const isRecentFacetsError =
      !!$facetsErrorDate &&
      !!$lastTimeShown &&
      new Date($lastTimeShown) - new Date($facetsErrorDate) > 0;
    const hasBeenRecentlyShown =
      // Diff between today's date and last time shown converted to days
      Math.floor(
        (new Date() - new Date($lastTimeShown || null)) / oneDayToMiliseconds
      ) < daysSinceLastShownThreshold;

    if ($isStudio || $isPublicPortal || !$isANGSearchOptInEnabled) {
      return false;
    }

    if ($isANGSearchFlagEnabled && !$isANGAvailable && !isRecentFacetsError) {
      return true;
    }

    return !$isANGSearchFlagEnabled && !hasBeenRecentlyShown;
  }
);

export const rootOrganizations = (state) => state.search?.rootOrganizations;
