export const FETCH_ASSETS = 'Fetch Assets';
export const FETCH_ASSETS_REQUEST = 'Fetch Assets Request';
export const FETCH_ASSETS_SUCCESS = 'Fetch Assets Success';
export const FETCH_ASSETS_FAILURE = 'Fetch Assets Failure';
export const FETCH_ASSET_SUCCESS = 'Fetch Asset Success';
export const LOAD_ASSETS = 'Load Assets';
export const LOAD_ASSETS_SUCCESS = 'Load Assets Success';
export const SELECT_SEARCH_RESULT = 'Navigate Asset';
export const DELETE_ASSET_DETAIL = 'Delete Asset Detail';
export const SHOW_MORE_ASSETS = 'Show More Assets';
