import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withCustomizationLeaveHandling from '~/components/Customization/LeaveHookProvider';
import ConfirmDeletePageModal from '~/components/DeletePage/ConfirmDeletePageModal';
import withLayoutContext from '~/components/LayoutContextProvider';
import RootStoreContext from '~/contexts/RootStoreContext';
import * as actions from '~/domains/customization/actions';
import * as modalActions from '~/domains/modal/actions';
import * as customizationSelectors from '~/domains/customization/selectors';
import * as commonSelectors from '~/domains/common/selectors';
import * as routesUtils from '~/utils/routes';
import EditCustomPage from './EditCustomPage';

const mapStateToProps = (state, { match }) => {
  const params = routesUtils.decodeParams(match.params);

  return {
    authorization: commonSelectors.context(state).authorization,
    page: customizationSelectors.customPageToEdit(state, params.pagePath),
    pages: customizationSelectors.draftCustomPages(state),
    profile: commonSelectors.profile(state),
    isContentLoading: customizationSelectors.isContentLoading(state),
    hasUnsavedChanges: customizationSelectors.hasUnsavedChanges(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onNameChange: (value, page) =>
    dispatch(actions.renameCustomPage(value, page)),
  onContentChange: (content, page) =>
    dispatch(
      actions.updateCustomPageMarkdown({
        content,
        pagePath: page.path
      })
    ),
  onFileUpload: (file, { organizationId, organizationDomain }) =>
    dispatch(
      actions.uploadCustomizationResource({
        file,
        organizationId,
        organizationDomain
      })
    ),
  onOpenDeleteModal: (page, onDeletePage) =>
    dispatch(
      modalActions.openModal({
        modalType: ConfirmDeletePageModal.modalType,
        modalProps: {
          page,
          onDeletePage
        }
      })
    ),
  onDeletePage: (page, pages = []) => {
    dispatch(actions.deleteCustomPage(page, pages));
    dispatch(modalActions.closeModal());
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onFileUpload: (file) =>
    dispatchProps.onFileUpload(file, {
      organizationId: stateProps.profile.organizationId,
      organizationDomain: stateProps.profile.organization.domain
    }),
  onOpenDeleteModal: () => {
    const onDeletePage = () =>
      dispatchProps.onDeletePage(stateProps.page, stateProps.pages);

    dispatchProps.onOpenDeleteModal(stateProps.page, onDeletePage);
  }
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  }),
  withLayoutContext(),
  withCustomizationLeaveHandling
)(EditCustomPage);
