import React from 'react';
import styles from './MulesoftFooter.css';

const MulesoftFooter = () => {
  return (
    <div
      data-test-id="footer-container"
      role="banner"
      className={styles.footer}
    >
      <a
        href="https://www.salesforce.com/form/other/privacy-request/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Go to privacy policy site"
      >
        Your Privacy Choices
      </a>
    </div>
  );
};

export default MulesoftFooter;
