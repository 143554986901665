import config from 'config';
import * as actions from './actionTypes';

const initialState = {
  data: {},
  list: [],
  ui: {
    isFetchingList: false,
    isFetchingApplication: false,
    isEditingApplication: false,
    isResetingClientSecret: false,
    isDeletingApplication: false
  }
};

const normalizeApplicationList = (applications) => {
  const normalizedData = {};

  applications.forEach((application) => {
    normalizedData[application.id] = application;
  });

  return normalizedData;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_CLIENT_APPLICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...normalizeApplicationList(action.applications)
        },
        list: state.list.concat(action.applications),
        hasMoreApplications:
          action.applications.length === config.applicationsLimit
      };

    case actions.FETCH_CLIENT_APPLICATION_LIST_REQUEST:
      return {
        ...state,
        list: [],
        ui: {
          ...state.ui,
          isFetchingList: true
        }
      };

    case actions.FETCH_CLIENT_APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...normalizeApplicationList(action.applications)
        },
        list: action.applications,
        hasMoreApplications:
          action.applications.length === config.applicationsLimit,
        ui: {
          ...state.ui,
          isFetchingList: false
        }
      };

    case actions.FETCH_CLIENT_APPLICATION_LIST_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetchingList: false
        }
      };

    case actions.FETCH_CLIENT_APPLICATION_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetchingApplication: true
        }
      };

    case actions.FETCH_CLIENT_APPLICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.application.id]: action.application
        },
        ui: {
          ...state.ui,
          isFetchingApplication: false
        }
      };

    case actions.FETCH_CLIENT_APPLICATION_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetchingApplication: false
        }
      };

    case actions.RESET_CLIENT_SECRET_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isResetingClientSecret: true
        }
      };

    case actions.RESET_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.application.id]: {
            ...state.data[action.application.id],
            clientSecret: action.clientSecret
          }
        },
        ui: {
          ...state.ui,
          isResetingClientSecret: false
        }
      };

    case actions.RESET_CLIENT_SECRET_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isResetingClientSecret: false
        }
      };

    case actions.EDIT_CLIENT_APPLICATION_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isEditingApplication: true
        }
      };

    case actions.EDIT_CLIENT_APPLICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.application.id]: {
            ...state.data[action.application.id],
            ...action.application
          }
        },
        ui: {
          ...state.ui,
          isEditingApplication: false
        }
      };

    case actions.EDIT_CLIENT_APPLICATION_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          error: action.error,
          isEditingApplication: false
        }
      };

    case actions.DELETE_CLIENT_APPLICATION_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingApplication: true
        }
      };

    case actions.DELETE_CLIENT_APPLICATION_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (application) => application.id !== action.application.id
        ),
        ui: {
          ...state.ui,
          isDeletingApplication: false
        }
      };

    case actions.DELETE_CLIENT_APPLICATION_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingApplication: false
        }
      };

    case actions.CREATE_CLIENT_APPLICATION_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.application]
      };

    default:
      return state;
  }
}
