import { connect } from 'react-redux';
import { compose } from 'redux';
import { EVENT_TYPES } from '~/analytics/events';
import RootStoreContext from '~/contexts/RootStoreContext';
import * as analyticsActions from '~/domains/analytics/actions';
import Announcement from './Announcement';

const mapDispatchToProps = (dispatch) => ({
  trackOnClose: (announcementName) =>
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.ANNOUNCEMENT_CLOSED, {
        announcementName
      })
    )
});

export default compose(
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(Announcement);
