import PropTypes from 'prop-types';
import React from 'react';
import ErrorPage from '~/components/ErrorPage';
import { getDefaultCards } from '../utils';

const ForbiddenPage = ({ description, getPath }) => {
  return (
    <ErrorPage
      title="403 Forbidden"
      description={description || 'Insufficient permissions to see this page'}
      cards={getDefaultCards(getPath)}
    />
  );
};

ForbiddenPage.propTypes = {
  getPath: PropTypes.func.isRequired,
  description: PropTypes.string
};

export default ForbiddenPage;
