import debug from 'debug';
import { ReactReduxContext } from 'react-redux';
import { asyncConnect } from 'redux-connect';
import * as routesUtils from '~/utils/routes';
import { isClient } from '~/utils/window';

const log = debug('exchange:utils:async');

/**
 * @func createPagesMerger
 *
 * Returns a function that performs async calls,
 * merges them into one promise that resolves with the array of records
 *
 * @arg {Object}   opts - Options object
 * @arg {Number}   [opts.offset=0] - Items offset
 * @arg {Number}   opts.limit - Page size
 * @arg {Function} opts.callback - Function that returns a promise, it's expected to resolve with an array
 *
 * @returns {@async Function} Returns a function that returns a promise
 *
 */
export const createPagesMerger =
  ({ limit, callback, offset = 0 }) =>
  async () => {
    const result = [];
    let currentOffset = offset;
    let page = null;

    do {
      page = await callback({ offset: currentOffset, limit });
      result.push(...page);
      currentOffset += page.length;
    } while (page.length === limit);

    return result;
  };

const defaultPromise = () => Promise.resolve();

const async = (
  resolve = defaultPromise,
  { context = ReactReduxContext } = {}
) =>
  asyncConnect(
    [
      {
        promise: async (options) => {
          const storeContext = options.store.context || ReactReduxContext;

          if (storeContext !== context) {
            return;
          }

          log('resolving async for', options.match);

          const handleError = options.helpers?.errorHandler || console.error;
          const { dispatch, getState, reducerManager } = options.store;
          const params = routesUtils.decodeParams(options.match.params);
          const payload = {
            ...options,
            params,
            getState,
            dispatch,
            reducerManager
          };

          log('decoded params', params);

          const state = options.store.getState();

          // don't resolve if error
          if (state.session?.error) {
            log('a previous error exists', state.session.error);

            throw state.session.error;
          }

          let result;

          try {
            result = resolve(payload);
          } catch (error) {
            log('a sync error has occurred', error);
            handleError(error);

            throw error;
          }

          if (!result) {
            const error = new Error('Container does not return promise');

            log('container does not return a promise');
            handleError(error);

            throw error;
          }

          const promise = result.catch((error) => {
            log('promise failed', error);

            handleError(error);
          });

          if (!isClient()) {
            await promise;
          }
        }
      }
    ],
    null,
    null,
    null,
    { context }
  );

export default async;
