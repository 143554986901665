import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { NavLink } from 'react-router-dom';
import linkStyles from '~/components/Layout/Sidebar/Links/Links.css';
import styles from './DraggableTree.css';

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

class DraggableTree extends Component {
  static propTypes = {
    shouldDisableDrag: PropTypes.bool,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        level: PropTypes.number,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired,
    onOrderPages: PropTypes.func.isRequired
  };

  static defaultProps = {
    shouldDisableDrag: false
  };

  onDragEnd = (result) => {
    const isSamePosition =
      result.destination && result.source.index === result.destination.index;

    if (!result.destination || isSamePosition) {
      return;
    }

    const pages = reorder(
      this.props.pages,
      result.source.index,
      result.destination.index
    );

    this.props.onOrderPages(pages);
  };

  render() {
    const { pages, shouldDisableDrag } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={classNames(styles.draggableTree, linkStyles.links)}
            >
              {pages.map((page, index) => (
                <Draggable
                  isDragDisabled={shouldDisableDrag}
                  key={page.name}
                  draggableId={page.name}
                  index={index}
                >
                  {($provided, $snapshot) => (
                    <div
                      ref={$provided.innerRef}
                      {...$provided.draggableProps}
                      {...$provided.dragHandleProps}
                      className={
                        $snapshot.isDragging
                          ? classNames(
                              styles.treeElement,
                              styles.draggedElement
                            )
                          : styles.treeElement
                      }
                      style={$provided.draggableProps.style}
                    >
                      <NavLink
                        data-test-id="page-tree-link"
                        key={`page-tree-link-${index}`}
                        to={page.url}
                        className={classNames(styles.page, linkStyles.item)}
                        exact
                        activeClassName={linkStyles.active}
                        draggable={!shouldDisableDrag}
                      >
                        <div
                          className={linkStyles.content}
                          style={{ marginLeft: 10 * (page.level || 0) }}
                        >
                          <div
                            data-test-id="page-move-dots"
                            className={classNames(styles.pageMove, {
                              [styles.hidden]: shouldDisableDrag
                            })}
                          />
                          <span
                            data-test-id="page-label"
                            className={classNames(
                              linkStyles.label,
                              styles.label
                            )}
                          >
                            <span className="visually-hidden">Go to page </span>
                            {page.name}
                          </span>
                        </div>
                      </NavLink>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DraggableTree;
