import memoize from 'memoize-one';
import pathToRegexp from 'path-to-regexp';
import isEqual from 'deep-equal';

export const resolveRouteURL = (match, location) => {
  const resolvedURL = match.url || '';
  const { pathname } = location;

  return pathname.substring(resolvedURL.length);
};

const memoizedRoute = memoize(
  (route) => route,
  ([routeA], [routeB]) =>
    routeA?.path === routeB?.path && isEqual(routeA?.params, routeB?.params)
);

export const resolveRouteParams = memoize((match, location, routes) => {
  const routeURL = resolveRouteURL(match, location);

  return routes.reduce((accum, route) => {
    if (accum) {
      return accum;
    }

    const matchResult = route.match(routeURL);

    if (!matchResult) {
      return false;
    }

    return memoizedRoute({
      ...route,
      params: matchResult.params
    });
  }, false);
});

export const getRoutesConfiguration = memoize((portalConfiguration) => {
  const routes = (portalConfiguration?.default || portalConfiguration)?.routes;

  return (routes ?? []).map((route) => ({
    ...route,
    match: pathToRegexp.match(route.path)
  }));
});
