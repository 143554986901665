import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import CloseIcon from '@mulesoft/anypoint-icons/lib/svg/close-small.svg';
import Callout from '@mulesoft/exchange-ui-components/lib/components/Callout';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import styles from './Announcement.css';

const Announcement = ({
  children,
  icon,
  onClose,
  name,
  trackOnClose,
  type,
  theme
}) => {
  const [isVisible, setVisibility] = React.useState(true);
  const close = () => {
    setVisibility(false);
    trackOnClose(name);
    onClose();
  };

  return (
    <div
      className={classNames(styles.wrapper, theme && styles[`${theme}Theme`])}
    >
      <Callout
        display-if={isVisible}
        className={styles.callout}
        icon={icon}
        iconSize="s"
        type={type}
      >
        <div className={styles.children}>
          {children}
          <Icon
            data-test-id="close-announcement"
            role="button"
            aria-label="Close announcement"
            tabIndex="0"
            className={styles.icon}
            size="xs"
            onClick={close}
            onKeyDown={eventHandlerUtils.onEnterKey(close)}
          >
            <CloseIcon />
          </Icon>
        </div>
      </Callout>
    </div>
  );
};

Announcement.defaultProps = {
  name: 'Unknown',
  trackOnClose: () => {}
};

Announcement.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  trackOnClose: PropTypes.func,
  name: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['info', 'warning']),
  theme: PropTypes.string
};

export default Announcement;
