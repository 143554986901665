/* eslint-disable react/jsx-no-constructed-context-values */
import { format } from 'url';
import config from 'config';
import { ConnectedRouter, push } from 'connected-react-router';
import debug from 'debug';
import memoize from 'memoize-one';
import React from 'react';
import { Provider, useSelector, useStore } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { renderRoutes } from 'react-router-config';
import AssetsDefinitionsProvider from '@mulesoft/exchange-assets-definitions/provider';
import {
  AlertsContext,
  AnalyticsContext,
  AssetTypesConfigurationContext,
  ConfigContext,
  ErrorHandlingContext,
  UserContext,
  ModalContext,
  ThemeContext,
  LocalStorageContext
} from '@mulesoft/exchange-react-hooks';
import { ConfigContext as DeprecatedConfigContext } from '@mulesoft/exchange-publish-form-deprecated/lib/contexts/withConfig'; // remove when removing publish asset form v1
import RootStoreContext from '~/contexts/RootStoreContext';
import getRoutes from '~/routes';
import { selectors as assetsSelectors } from '~/domains/assets';
import * as analyticsActions from '~/domains/analytics/actions';
import {
  actions as localStorageActions,
  selectors as localStorageSelectors
} from '~/domains/localStorage';

import {
  actions as commonActions,
  selectors as commonSelectors
} from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { LUME_THEME_ENABLED } from '~/domains/featureFlags/keys';
import { parseSearch } from '~/utils/location';
import { omit } from '~/utils/objects';
import ReduxAsyncConnect from '~/utils/connect';
import { getPath, getPortalPath } from '~/utils/routes';
import * as windowUtils from '~/utils/window';
import getModalContext from './modals';

const log = debug('exchange:client');

let prevLocation = global.location;

/* eslint-disable react/prop-types */
const asyncRender = (routes, store, props) => {
  const { location } = props;
  const pathChanged = prevLocation.pathname !== location.pathname;

  prevLocation = location;

  log('Changing to location', location);

  if (pathChanged) {
    windowUtils.scrollToTop();
  }

  if (global.parent) {
    const query = parseSearch(location.search);
    const message = {
      type: 'url',
      target: format({
        pathname: location.pathname,
        // avoid propagating internal parameters
        query: omit(
          query,
          'no_navbar',
          'no_cookie',
          'embedded',
          'is_marketing'
        ),
        hash: location.hash
      })
    };

    global.parent.postMessage(message, '*');
  }

  return renderRoutes(routes);
};

const HotRouter = hot(ConnectedRouter);

const getUserContext = memoize((profile, studioMetadata, isStudio, store) => {
  const getContext = () => commonSelectors.context(store.getState());

  return {
    profile,
    studioMetadata,
    isStudio,
    context: getContext(),
    getContext
  };
});

const UserContextWrapper = ({ children }) => {
  const profile = useSelector(commonSelectors.profile);
  const studioMetadata = useSelector(commonSelectors.studioMetadata);
  const isStudio = useSelector(commonSelectors.isStudio);
  const store = useStore();

  return (
    <UserContext.Provider
      value={getUserContext(profile, studioMetadata, isStudio, store)}
    >
      {children}
    </UserContext.Provider>
  );
};

export const RenderProvider = ({
  store,
  portalsStore,
  errorHandler,
  history
}) => {
  const { dispatch } = store;
  const state = store.getState();
  const featureFlags = featureFlagsSelectors.featureFlags(state);
  const routingContext = commonSelectors.routingContext(state);
  const trackEvent = (...props) =>
    dispatch(analyticsActions.trackEvent(...props));
  const routes = getRoutes(routingContext);
  const modalContext = getModalContext({ store });
  const getPathFn = routingContext.publicPortalDomain
    ? getPortalPath({
        routingContext,
        organizationDomain: routingContext.publicPortalDomain
      })
    : getPath;
  const getAssetFromRootStore = (params) =>
    assetsSelectors.asset(store.getState(), params);
  const getRoutingContext = () => routingContext;
  const pushFn = (path) => dispatch(push(path));
  const setHeader = (header) => dispatch(commonActions.setHeader(header));
  const setAlert = (alert) => dispatch(commonActions.setAlert(alert));
  const isLumeThemeEnabled = featureFlags?.[LUME_THEME_ENABLED];

  const localStorageWrapper = {
    setItem: (key, value) => dispatch(localStorageActions.setItem(key, value)),
    removeItem: (key) => dispatch(localStorageActions.removeItem(key)),
    getItem: (key) => {
      const $state = store.getState();

      return localStorageSelectors.localStorageItem($state, key);
    }
  };

  return (
    <LocalStorageContext.Provider value={localStorageWrapper}>
      <AnalyticsContext.Provider value={{ trackEvent }}>
        <ConfigContext.Provider value={{ ...config, featureFlags }}>
          <ErrorHandlingContext.Provider value={errorHandler}>
            <ModalContext.Provider value={modalContext}>
              <AssetsDefinitionsProvider>
                <DeprecatedConfigContext.Provider
                  value={{ ...config, featureFlags }}
                >
                  <AlertsContext.Provider value={{ setAlert }}>
                    <ThemeContext.Provider
                      value={isLumeThemeEnabled ? 'lume' : undefined}
                    >
                      <Provider store={store} context={RootStoreContext}>
                        <Provider store={portalsStore}>
                          <UserContextWrapper>
                            <HotRouter
                              history={history}
                              context={RootStoreContext}
                            >
                              <AssetTypesConfigurationContext.Consumer>
                                {(assetTypesConfiguration) => (
                                  <ReduxAsyncConnect
                                    context={RootStoreContext}
                                    helpers={{
                                      assetTypesConfiguration,
                                      getPath: getPathFn,
                                      trackEvent,
                                      errorHandler,
                                      push: pushFn,
                                      setHeader,
                                      getAssetFromRootStore,
                                      getRoutingContext
                                    }}
                                    routes={routes}
                                    stores={[portalsStore]}
                                    render={(props) =>
                                      asyncRender(routes, store, props)
                                    }
                                  />
                                )}
                              </AssetTypesConfigurationContext.Consumer>
                            </HotRouter>
                          </UserContextWrapper>
                        </Provider>
                      </Provider>
                    </ThemeContext.Provider>
                  </AlertsContext.Provider>
                </DeprecatedConfigContext.Provider>
              </AssetsDefinitionsProvider>
            </ModalContext.Provider>
          </ErrorHandlingContext.Provider>
        </ConfigContext.Provider>
      </AnalyticsContext.Provider>
    </LocalStorageContext.Provider>
  );
};
/* eslint-enable react/prop-types */
