import config from 'config';
import isKeyCombo from 'is-key-combo';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Label from '@mulesoft/anypoint-components/lib/Label';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import TextArea from '@mulesoft/anypoint-components/lib/TextArea';
import { RefType } from '@mulesoft/exchange-react-shapes';
import Validations from '~/components/common/Validations';
import RatingSelector from '~/components/RatingSelector';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import * as refUtils from '~/utils/refs';
import styles from './ReviewEdition.css';

const EMPTY_REVIEW = { rating: 0, text: '', title: '' };

export class ReviewEdition extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    rating: PropTypes.number,
    text: PropTypes.string,
    title: PropTypes.string,
    asset: PropTypes.object,
    review: PropTypes.object,
    collapsed: PropTypes.bool,
    isFirstReview: PropTypes.bool,
    innerRef: RefType,
    acceptButtonName: PropTypes.string,
    onAcceptOperation: PropTypes.func,
    onCancelOperation: PropTypes.func,
    onRatingChange: PropTypes.func
  };

  static defaultProps = {
    ...EMPTY_REVIEW,
    collapsed: true,
    onCancelOperation: () => {},
    onRatingChange: () => {}
  };

  state = {
    rating: this.props.rating,
    text: this.props.text,
    title: this.props.title,
    collapsed: this.props.collapsed
  };

  starReviewRef = React.createRef();

  isTitleFilled = () => !!this.state.title.trim();

  isDescriptionFilled = () => !!this.state.text.trim();

  isRatingSelected = () => this.state.rating > 0;

  getNotEmptyValidation = ({ validate, message }) => ({
    isNotEmpty: {
      validate,
      message
    }
  });

  focusStarReview = () => {
    refUtils.focus(this.starReviewRef);
  };

  open = () => {
    this.setState({ collapsed: false });
  };

  close = () => {
    this.setState({ ...EMPTY_REVIEW, collapsed: true });
  };

  handleOpenHeader = () => {
    const { collapsed } = this.state;

    if (collapsed) {
      this.open();
      this.focusStarReview();
    }
  };

  handleRatingChange = (rating) => {
    const { onRatingChange, asset } = this.props;
    const { collapsed } = this.state;

    if (rating > 0 && collapsed) {
      this.open();
    }

    this.setState({ rating, isDirty: false }, () =>
      onRatingChange(rating, asset)
    );
  };

  handleTextChange = (event) => {
    this.setState({ text: event.value, isDirty: false });
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.value, isDirty: false });
  };

  handleFormKeyDown = (event) => {
    if (isKeyCombo(event, 'cmd+enter')) {
      this.submitReview();
    }
  };

  handleCancelClick = () => {
    this.props.onCancelOperation();
    this.setState({ isDirty: false });
    this.close();
  };

  isValidReview = () =>
    this.isTitleFilled() &&
    this.isDescriptionFilled() &&
    this.isRatingSelected();

  submitReview = () => {
    const isValidReview = this.isValidReview();

    this.setState({ isDirty: !isValidReview });

    if (!isValidReview) {
      return;
    }

    const { review, onAcceptOperation } = this.props;
    const { rating, title, text } = this.state;

    onAcceptOperation({
      ...review,
      rating,
      title: title.trim(),
      text: text.trim()
    });

    this.close();
  };

  render() {
    const { id, asset, isFirstReview, acceptButtonName, review, innerRef } =
      this.props;

    const { rating, title, text, collapsed, isDirty } = this.state;
    const rateMessage = isFirstReview
      ? `Be the first to review ${asset.name}`
      : `Review ${asset.name}`;
    const editExpandableId = 'edition-review-expandable-header';
    const editReviewRatingId = `edit-review-rating-${
      review ? review.id : 'new'
    }`;
    const editReviewTitleId = `edit-review-title-${review ? review.id : 'new'}`;
    const editReviewDescriptionId = `edit-review-description-${
      review ? review.id : 'new'
    }`;

    return (
      <div ref={innerRef} id={id} className={styles.box} tabIndex="-1">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          data-test-id="edition-review-form"
          onKeyDown={this.handleFormKeyDown}
          onSubmit={eventHandlerUtils.preventDefault}
        >
          <div
            id={editExpandableId}
            className={styles.expandable}
            aria-expanded={!collapsed}
          >
            <div
              display-if={!collapsed}
              data-test-id="edition-review-rate-label"
              className={styles.rateLabel}
            >
              <Label className={styles.label} htmlFor={editReviewRatingId}>
                Rating
              </Label>
            </div>
            <div className={styles.header}>
              <Validations
                validate={this.getNotEmptyValidation({
                  validate: this.isRatingSelected,
                  message: 'Review rating should be selected'
                })}
                isDirty={isDirty}
                errorLabelId="review-rating-error"
              >
                {({
                  isDirty: $isDirty,
                  isValid,
                  ariaDescribedBy,
                  ariaInvalid
                }) => (
                  <RatingSelector
                    isDirty={$isDirty}
                    isValid={isValid}
                    aria-describedby={ariaDescribedBy}
                    aria-invalid={ariaInvalid}
                    id={editReviewRatingId}
                    ref={this.starReviewRef}
                    testId="edition-review-rating"
                    className={styles.rating}
                    rating={rating}
                    onChange={this.handleRatingChange}
                  />
                )}
              </Validations>
              <div
                display-if={collapsed}
                data-test-id="edition-review-header"
                className={styles.headerButton}
                role="button"
                tabIndex="0"
                onClick={this.handleOpenHeader}
                onKeyDown={eventHandlerUtils.onEnterKey(this.handleOpenHeader)}
                aria-controls={editExpandableId}
              >
                <span
                  data-test-id="edition-review-rating-message"
                  className={styles.rateMessage}
                >
                  {rateMessage}
                  {` ${
                    asset.minorVersion
                      ? `${asset.minorVersion}.x`
                      : asset.version
                  }`}
                </span>
              </div>
            </div>
          </div>
          <div display-if={!collapsed}>
            <Label className={styles.label} htmlFor={editReviewTitleId}>
              Title
            </Label>
            <Validations
              validate={this.getNotEmptyValidation({
                validate: this.isTitleFilled,
                message: 'Review title should not be empty'
              })}
              isDirty={isDirty}
              errorLabelId="review-title-error"
            >
              {({
                isDirty: $isDirty,
                isValid,
                ariaDescribedBy,
                ariaInvalid
              }) => (
                <TextField
                  isDirty={$isDirty}
                  isValid={isValid}
                  aria-describedby={ariaDescribedBy}
                  aria-invalid={ariaInvalid}
                  id={editReviewTitleId}
                  testId="edition-review-title"
                  name="title"
                  type="text"
                  placeholder="Write the title"
                  tabIndex="0"
                  value={title}
                  onChange={this.handleTitleChange}
                  required
                  maxLength={config.maxReviewTitleLength}
                />
              )}
            </Validations>
            <Label className={styles.label} htmlFor={editReviewDescriptionId}>
              Description
            </Label>
            <Validations
              validate={this.getNotEmptyValidation({
                validate: this.isDescriptionFilled,
                message: 'Review description should not be empty'
              })}
              isDirty={isDirty}
              errorLabelId="review-description-error"
            >
              {({
                isDirty: $isDirty,
                isValid,
                ariaDescribedBy,
                ariaInvalid
              }) => (
                <TextArea
                  isDirty={$isDirty}
                  isValid={isValid}
                  aria-describedby={ariaDescribedBy}
                  aria-invalid={ariaInvalid}
                  id={editReviewDescriptionId}
                  testId="edition-review-text"
                  placeholder="Write a review..."
                  tabIndex="0"
                  value={text}
                  maxLength={config.maxReviewTextLength}
                  required
                  onChange={this.handleTextChange}
                />
              )}
            </Validations>
            <div className={styles.actions}>
              <Button
                testId="cancel-button"
                kind="tertiary"
                type="button"
                onClick={this.handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                testId="submit-button"
                kind="primary"
                type="submit"
                formNoValidate
                onClick={this.submitReview}
              >
                {acceptButtonName}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const ReviewEditionWithRef = (props, ref) => {
  return <ReviewEdition innerRef={ref} {...props} />;
};

export default React.forwardRef(ReviewEditionWithRef);
