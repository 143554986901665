import PropTypes from 'prop-types';
import { CreatedByType } from '@mulesoft/exchange-react-shapes';

const ReviewPropTypes = PropTypes.shape({
  rating: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  createdBy: CreatedByType,
  createdDate: PropTypes.string.isRequired
});

export { ReviewPropTypes };
