import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  withAnalytics,
  withAssetPortal,
  withRouter as withRouterActions
} from '@mulesoft/exchange-react-hooks';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { actions as portalsActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import * as reviewsActions from '@mulesoft/exchange-ui-portals-store/lib/domains/reviews/actions';
import * as reviewsSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/reviews/selectors';
import * as sessionActions from '@mulesoft/exchange-ui-portals-store/lib/domains/session/actions';
import * as sessionSelectors from '@mulesoft/exchange-ui-portals-store/lib/domains/session/selectors';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import {
  PORTAL_RE_ARCHITECTURE_ENABLED,
  PUBLIC_PORTAL_PIRA_ENABLED
} from '~/domains/featureFlags/keys';
import * as assetUtils from '~/portals/utils/asset';
import RootStoreContext from '~/contexts/RootStoreContext';
import withLayoutContext from '~/components/LayoutContextProvider';
import { EVENT_TYPES } from '~/analytics/events';
import { assetDownloadProperties } from '~/analytics/events/assets';
import Header from '~/components/AssetDetail/Header';
import { isEmpty } from '~/utils/objects';
import { resolveRouteParams, resolveRouteURL } from '~/portals/utils/config';

const hasRouteParams = (match, location, routes) => {
  const routeParams = resolveRouteParams(match, location, routes);

  return !isEmpty(routeParams.params);
};

const mapStateToProps = (state, { asset, versionsNavigation }) => {
  const showVersionDropdown = versionsNavigation === 'version-group';

  return {
    asset,
    showVersionDropdown,
    isPublishing: assetsSelectors.assetUI(state).isPublishing,
    isStudio: sessionSelectors.isStudio(state),
    isPortalReArchitectureEnabled: featureFlagsSelectors.featureFlag(
      state,
      PORTAL_RE_ARCHITECTURE_ENABLED
    ),
    isPublicPortalPIRAEnabled: featureFlagsSelectors.featureFlag(
      state,
      PUBLIC_PORTAL_PIRA_ENABLED
    ),
    isMarketingSite: sessionSelectors.isMarketingSite(state),
    assetMinorVersions: assetsSelectors.assetMinorVersions(state, asset) || [],
    assetVersionGroups: assetsSelectors.assetVersionGroups(state, asset) || [],
    profile: sessionSelectors.profile(state),
    reviews: reviewsSelectors.reviews(state, asset)
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    router: { push },
    trackEvent,
    updateIcon: updateIconRoot,
    deleteIcon: deleteIconRoot
  }
) => ({
  onBackToPrevious: () => {
    dispatch(
      sessionActions.setUserProperty({
        namespace: 'exchange',
        key: 'isPreviousUIEnabled',
        value: 'false'
      })
    );
    trackEvent(EVENT_TYPES.BACK_TO_NEW_UI);
  },
  onDownload: (asset, isInstall, file) => {
    dispatch(assetUtils.trackDownloadForMarketo(asset));
    trackEvent(
      EVENT_TYPES.ASSET_DOWNLOADED,
      assetDownloadProperties({ asset, file, isInstall })
    );
  },
  onDeleteIcon: async (asset) => {
    await dispatch(assetsActions.deleteIcon({ asset }));
    deleteIconRoot(asset);
  },
  onSaveIcon: async (asset, icon) => {
    const { externalLink } = await dispatch(
      assetsActions.updateIcon({ asset, icon })
    );

    if (externalLink) {
      updateIconRoot(asset, externalLink);
    }
  },
  onSaveName: (asset, name) =>
    dispatch(assetsActions.updateName({ asset, name })),
  onAPIVersionChange: (asset, { value: pathname }) => {
    trackEvent(EVENT_TYPES.VERSION_DROPDOWN_CLICKED, {
      organizationId: asset.organizationId,
      groupId: asset.groupId,
      assetId: asset.assetId,
      type: asset.type,
      originalFormat: asset.originalFormat,
      originalFormatVersion: asset.originalFormatVersion
    });
    push(pathname);
  },
  onMinorVersionChange: (asset, assetMinorVersions, selectedMinor, path) => {
    trackEvent(EVENT_TYPES.VERSION_DROPDOWN_CLICKED, {
      organizationId: asset.organizationId,
      groupId: asset.groupId,
      assetId: asset.assetId,
      type: asset.type,
      minorVersion: asset.minorVersion,
      minorVersionPosition: assetMinorVersions
        .map((minor) => minor.minorVersion)
        .indexOf(selectedMinor),
      minorVersionCount: assetMinorVersions.length,
      originalFormat: asset.originalFormat,
      originalFormatVersion: asset.originalFormatVersion
    });
    dispatch(portalsActions.pageLoading());
    push(path);
  },
  onLoadReview: (asset) => {
    const navigationParams = {
      groupId: asset.groupId,
      assetId: asset.assetId,
      minorVersion: asset.minorVersion,
      organizationId: asset.organization.id,
      version: asset.version
    };

    return dispatch(reviewsActions.getRating(navigationParams));
  }
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSaveIcon: (icon) => dispatchProps.onSaveIcon(stateProps.asset, icon),
  onDeleteIcon: () => dispatchProps.onDeleteIcon(stateProps.asset)
});

export const mapContextToProps = (
  { isPublicPortal, getPath },
  { match, location, routes }
) => ({
  getVersionPath: (params) => {
    const versionPath = getPath('page', params);

    if (hasRouteParams(match, location, routes)) {
      return versionPath;
    }

    const routeURL = resolveRouteURL(match, location);

    return `${versionPath}${
      routeURL.startsWith('/') ? routeURL.substring(1) : routeURL
    }`;
  },
  isPublicPortal,
  showVisibilityLabel: !isPublicPortal
});

const mapRootDispatchToProps = (dispatch) => ({
  updateIcon: (asset, icon) =>
    dispatch(assetsActions.updateIconRoot(asset, icon)),
  deleteIcon: (asset) => dispatch(assetsActions.deleteIconRoot(asset))
});

export default compose(
  withAnalytics,
  withAssetPortal,
  withRouter,
  withRouterActions,
  withLayoutContext(mapContextToProps),
  connect(null, mapRootDispatchToProps, null, { context: RootStoreContext }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Header);
