const STICKY_HEADER_HEIGHT = 65;

export function calculateModalTopOffset(pageInfo, modalHeight) {
  const { clientHeight, scrollTop, offsetTop, iframeHeight } = pageInfo;

  const offsetBottom = clientHeight + scrollTop - (iframeHeight + offsetTop);
  const innerOffset = scrollTop - offsetTop - Math.max(offsetBottom / 2, 0);
  const windowOffset = Math.max(
    STICKY_HEADER_HEIGHT,
    (clientHeight - modalHeight + STICKY_HEADER_HEIGHT) / 2
  );

  return Math.max(windowOffset + innerOffset, 10);
}
