import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAssetTypesConfiguration } from '@mulesoft/exchange-react-hooks';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import { actions as analyticsActions } from '~/domains/analytics';
import async from '~/utils/async';
import { EVENT_TYPES } from '~/analytics/events';
import { getActiveOrganization } from '~/utils/organization';
import * as objectsUtils from '~/utils/objects';
import { isMasterOrganizationExchangeAdmin } from '~/utils/validations/profile';
import HomeLayout from './HomeLayout';
import resolver from './HomeLayout.resolver';

const mapStateToProps = (state) => {
  const profile = commonSelectors.profile(state);

  return {
    isMasterOrgAdmin: isMasterOrganizationExchangeAdmin(profile),
    profile
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPageLoaded: (properties) =>
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.HOME_PAGE_LOADED, properties)
    ),
  onMasterOrgPageLoaded: () =>
    dispatch(
      analyticsActions.trackEvent(
        EVENT_TYPES.HOME_PAGE_LOADED_BY_MASTER_ORG_ADMIN
      )
    )
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPageLoaded: () => {
    let sessionProperties = {};

    if (stateProps.profile) {
      const activeOrganization = getActiveOrganization(stateProps.profile);

      if (activeOrganization) {
        sessionProperties = objectsUtils.pick(
          activeOrganization,
          'isExchangeAdmin',
          'isExchangeContributor'
        );
      }
    }

    dispatchProps.onPageLoaded(sessionProperties);
  }
});

export default compose(
  async(resolver, { context: RootStoreContext }),
  withAssetTypesConfiguration,
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  })
)(HomeLayout);
