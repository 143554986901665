const required = [
  'organizationId',
  'groupId',
  'assetId',
  'version',
  'name',
  'type',
  'rating'
];

export default {
  REVIEW_CREATED: 'Review created',
  REVIEW_DELETED: 'Review deleted',
  REVIEW_EDITED: 'Review edited',
  REVIEW_FORM_OPENED: 'Review form opened',
  REVIEW_FORM_CLOSED: 'Review form closed',
  RATING_CLICKED: {
    name: 'Rating clicked',
    required
  }
};
