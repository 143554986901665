import config from 'config';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as assetsActions from '~/domains/assets/actionTypes';
import * as actions from './actionTypes';
import { getSectionNavigationLog } from './utils';

const initialState = {
  studioMetadata: {},
  location: {},
  context: {},
  routingContext: {},
  scripts: {
    loading: [],
    loaded: []
  },
  sectionNavigationLog: [],
  history: [],
  serverRedirectLocation: {},
  hasUserClickedSearchResult: false,
  header: {
    title: 'Anypoint Exchange',
    meta: {
      property: {
        'og:site_name': config.title
      }
    }
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (state.location?.pathname !== action.payload.location.pathname) {
        return {
          ...state,
          location: action.payload.location,
          history: [...state.history, action.payload.location],
          error: null,
          alert: null
        };
      }

      return {
        ...state,
        history: [...state.history, action.payload.location]
      };
    }

    case actions.SET_ROUTE: {
      const { route, location } = action;
      const sectionId = route.sectionId ?? route.id;
      const lastLocation = state.history[state.history.length - 1];
      const sectionNavigationLog = getSectionNavigationLog({
        previousNavigationLog: state.sectionNavigationLog,
        location: location ?? lastLocation,
        sectionId
      });

      return {
        ...state,
        sectionNavigationLog,
        route: {
          id: route.id,
          params: route.params
        }
      };
    }

    case actions.SET_ORGANIZATION_METADATA:
      return {
        ...state,
        organizationMetadata: action.organizationMetadata
      };

    case actions.SET_IS_MARKETING_SITE:
      return {
        ...state,
        isMarketingSite: action.isMarketingSite
      };

    case actions.SET_NAVBAR_VISIBILITY:
      return {
        ...state,
        showNavbar: action.visibility
      };

    case actions.SET_HOME_QUERY:
      return {
        ...state,
        homeQuery: action.query
      };

    case actions.SET_HEADER:
      return {
        ...state,
        header: action.header
      };

    case actions.SET_ERROR:
      return {
        ...state,
        error: action.error
      };

    case actions.SET_ALERT:
      return {
        ...state,
        alert: action.alert
      };

    case actions.SET_PUBLIC_PORTAL_DOMAIN: {
      if (action.domain) {
        return {
          ...state,
          publicPortalDomain: action.domain
        };
      }

      return state;
    }

    case actions.SET_PUBLIC_PORTAL_STATUS:
      return {
        ...state,
        routingContext: {
          ...state.routingContext,
          publicPortalStatus: action.publicPortalStatus
        }
      };

    case actions.SET_ROUTING_CONTEXT: {
      return {
        ...state,
        routingContext: {
          ...state.routingContext,
          isForwardedHost: action.isForwardedHost,
          isForwardedPath: action.isForwardedPath,
          isPortalsSession: action.isPortalsSession,
          path: action.contextPath,
          host: action.contextHost,
          publicPortalStatus: 'enabled',
          publicPortalDomain: action.publicPortalDomain
        }
      };
    }

    case actions.CLEAR_ALERT:
      return {
        ...state,
        alert: null
      };

    case actions.SCRIPT_LOAD_START:
      return {
        ...state,
        scripts: {
          ...state.scripts,
          loading: state.scripts.loading.concat(action.key)
        }
      };

    case actions.SCRIPT_LOAD_FINISH:
      return {
        ...state,
        scripts: {
          ...state.scripts,
          loading: state.scripts.loading.filter(
            (scriptKey) => scriptKey !== action.key
          ),
          loaded: state.scripts.loaded.concat(action.key)
        }
      };

    case actions.SET_SERVER_REDIRECT_LOCATION:
      return {
        ...state,
        serverRedirectLocation: {
          ...state.serverRedirectLocation,
          pathname: action.pathname,
          status: action.status
        }
      };

    case assetsActions.SELECT_SEARCH_RESULT:
      return {
        ...state,
        hasUserClickedSearchResult: true
      };

    case assetsActions.FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        hasUserClickedSearchResult: false
      };

    default:
      return state;
  }
}
