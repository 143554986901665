import config from 'config';
import { actions as categoriesActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/categories';
import {
  actions as assetsActions,
  selectors as assetsSelectors
} from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { actions as pagesActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { actions as portalsActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { EVENT_TYPES } from '~/analytics/events';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as metadataSelectors } from '~/portals/store/domains/metadata';
import * as assetUtils from '~/portals/utils/asset';
import * as pagesUtils from '~/portals/utils/pages';
import { getMasterOrganizationId } from '~/utils/organization';
import { getCurrentRoute } from '~/utils/routes';
import { isAssetContributor } from '~/utils/validations/profile';
import { isClient } from '~/utils/window';
import { isAPIGroup } from '~/utils/types';
import { isTermsPageName } from '~/utils/page';
import * as urlUtils from '~/utils/url';

const resolvePortal = async ({
  getState,
  dispatch,
  route,
  location,
  helpers: {
    getPath,
    push,
    setHeader,
    getAssetFromRootStore,
    getRoutingContext,
    trackEvent
  }
}) => {
  const routingContext = getRoutingContext();

  const state = getState();
  const { route: matchingRoute, params: routeParams } = getCurrentRoute(
    route,
    location
  );
  const status = urlUtils.getQueryParam(location, 'status')[0];

  const { id: routeId, isDraft } = matchingRoute;
  const domain = sessionSelectors.publicPortalDomain(state);
  const params = { ...routeParams, domain, isDraft, status };
  const queryParams = location.search;
  const isAssetHome = routeId === 'page' && !params.pagePath;
  const isVersionGroupNavigation = (assetType) =>
    commonSelectors.definitions(state)[assetType]?.versionsNavigation ===
    'version-group';
  const forwardedHost = routingContext?.isForwardedHost
    ? routingContext?.host
    : null;
  const forwardedPath = routingContext?.isForwardedPath
    ? routingContext?.path
    : null;

  if (isClient()) {
    return clientStrategy();
  }

  return serverStrategy();

  function shouldRequestOrganizationCategories(asset = {}) {
    return asset.permissions && asset.permissions.indexOf('edit') !== -1;
  }

  async function redirectToMinorVersionPage({ asset }) {
    if (params.version) {
      const [major, minor] = params.version.split('.');
      const minorVersion = `${major}.${minor}`;
      const pathToRedirect = location.pathname.replace(
        params.version,
        queryParams
          ? `minor/${minorVersion}/${queryParams}`
          : `minor/${minorVersion}`
      );

      trackEvent(EVENT_TYPES.PATCH_REDIRECT);
      push(pathToRedirect, 301);
    }

    if (params.versionGroup) {
      await dispatch(
        assetUtils.redirectVersionGroupToMinorVersionPage({
          domain,
          groupId: asset.groupId,
          assetId: asset.assetId,
          currentPath: location.pathname,
          versionGroup: params.versionGroup,
          pushFn: push,
          trackEvent
        })
      );
    }
  }

  function redirectToConsoleIfDefaultPageEmpty(assetParams) {
    if (isAssetHome) {
      const consolePath = getPath('consoleSummary', assetParams);

      dispatch(
        pagesUtils.redirectIfDefaultPageEmpty({
          targetPath: consolePath,
          pushFn: push,
          ...assetParams
        })
      );
    }
  }

  function fetchPortal(assetParams) {
    const isHomePage =
      !assetParams.pagePath || assetParams.pagePath === config.defaultPageName;

    const getPageHTML = async () => {
      const isAPIGroupTermsPage =
        isAPIGroup(assetParams) && isTermsPageName(assetParams.pagePath);

      try {
        await dispatch(
          pagesActions.getPageHTML({
            ...assetParams,
            pushFn: push,
            getPath,
            domain,
            forwardedHost,
            forwardedPath
          }),
          {
            skipErrorHandler: (error) =>
              (error.status === 404 && isAPIGroupTermsPage) ||
              // 422 error is thrown when the server cannot process the markdown and convert it to HTML
              // in those cases, show an error instead
              error.status === 422
          }
        );
      } catch (error) {
        if (error.status === 422) {
          dispatch(portalsActions.pageLoaded());

          return;
        }

        throw error;
      }
    };

    return Promise.all([
      dispatch(portalsActions.getPortal(assetParams)),
      params.isConsole ? null : getPageHTML(),
      isClient() || (!isClient() && isHomePage)
        ? null
        : dispatch(
            pagesActions.getPageHTML({
              ...assetParams,
              pushFn: push,
              getPath,
              pagePath: config.defaultPageName,
              domain,
              forwardedHost,
              forwardedPath
            })
          )
    ]);
  }

  async function clientStrategy() {
    const assetFromState =
      assetsSelectors.asset(state, params) ?? getAssetFromRootStore(params);

    if (
      assetFromState &&
      !assetFromState.isCompleted &&
      !assetFromState.isMinorVersionTransition
    ) {
      const portalHeader = metadataSelectors.getPortalHeader(getState(), {
        ...params,
        ...assetFromState,
        getPath,
        routingContext,
        asset: assetFromState
      });
      const getVersionGroups = async () => {
        if (isVersionGroupNavigation(assetFromState.type)) {
          await dispatch(
            assetsActions.getVersionGroups({
              ...assetFromState,
              domain
            })
          );
        }
      };

      setHeader(portalHeader);

      const [asset] = await Promise.all([
        dispatch(assetsActions.getAsset(params)).then(($asset) => {
          if (shouldRequestOrganizationCategories($asset)) {
            dispatch(
              categoriesActions.listCategories({
                masterOrganizationId: getMasterOrganizationId(
                  $asset.organization
                ),
                organizationId: $asset.organization.id
              })
            );
          }

          return $asset;
        }),
        fetchPortal({
          ...params,
          organizationId: assetFromState.organization.id,
          version: assetFromState.version
        }),
        dispatch(
          assetsActions.getMinorVersions({
            ...assetFromState,
            domain
          })
        ),
        getVersionGroups()
      ]);

      redirectToConsoleIfDefaultPageEmpty({ ...params, ...asset });

      return;
    }

    const asset = await dispatch(assetsActions.getAsset(params));
    const portalHeader = metadataSelectors.getPortalHeader(getState(), {
      ...params,
      ...asset,
      getPath,
      routingContext
    });

    setHeader(portalHeader);

    if (isVersionGroupNavigation(asset.type)) {
      dispatch(assetsActions.getVersionGroups({ ...asset, domain }));
    }

    if (asset) {
      dispatch(assetsActions.getMinorVersions({ ...asset, domain }));
    }

    if (shouldRequestOrganizationCategories(asset)) {
      dispatch(
        categoriesActions.listCategories({
          masterOrganizationId: getMasterOrganizationId(asset.organization),
          organizationId: asset.organization.id
        })
      );
    }

    await fetchPortal({
      ...params,
      ...asset,
      organizationId: asset.organization.id,
      version: asset.version
    });

    redirectToConsoleIfDefaultPageEmpty({ ...params, ...asset });
  }

  async function serverStrategy() {
    const asset = await dispatch(assetsActions.getAsset(params));

    if (asset.assetId !== params.assetId || asset.groupId !== params.groupId) {
      const error = new Error('There is no match for the given route');

      error.status = 404;
      throw error;
    }
    const isConsole = /\/console\/[a-zA-Z]+\//.test(location.pathname); // TODO: special case for the console, hope some day we remove this
    const isMinorRedirect =
      (params.version && !isConsole) || params.versionGroup;

    if (isMinorRedirect) {
      await redirectToMinorVersionPage({ asset });

      return;
    }

    if (params.isDraft) {
      isAssetContributor(asset);
    }

    const promises = [
      fetchPortal({
        ...params,
        ...asset,
        organizationId: asset.organization.id,
        version: asset.version
      })
    ];

    if (asset) {
      promises.push(
        dispatch(
          assetsActions.getMinorVersions({
            ...asset,
            domain
          })
        )
      );
    }

    if (isVersionGroupNavigation(asset.type)) {
      promises.push(
        dispatch(
          assetsActions.getVersionGroups({
            ...asset,
            domain
          })
        )
      );
    }

    if (shouldRequestOrganizationCategories(asset)) {
      promises.push(
        dispatch(
          categoriesActions.listCategories({
            masterOrganizationId: getMasterOrganizationId(asset.organization),
            organizationId: asset.organization.id
          })
        )
      );
    }

    await Promise.all(promises);

    redirectToConsoleIfDefaultPageEmpty({ ...params, ...asset });

    const portalHeader = metadataSelectors.getPortalHeader(getState(), {
      ...params,
      ...asset,
      getPath,
      routingContext
    });

    setHeader(portalHeader);
  }
};

export default resolvePortal;
