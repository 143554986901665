import { format } from 'url';
import { push } from 'connected-react-router';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import * as assetService from '~/services/assetService';
import { actions as analyticsActions } from '~/domains/analytics';
import {
  actions as commonActions,
  selectors as commonSelectors
} from '~/domains/common';
import { selectors as customizationSelectors } from '~/domains/customization';
import actionify from '~/utils/actionify';
import { EVENT_TYPES } from '~/analytics/events';
import { queryToFilters } from './utils';
import * as actions from './actionTypes';

export function deleteAsset({ groupId, assetId, version }) {
  return {
    type: actions.DELETE_ASSET_DETAIL,
    asset: {
      groupId,
      assetId,
      version
    }
  };
}

export function selectSearchResult() {
  return {
    type: actions.SELECT_SEARCH_RESULT
  };
}

export function searchAssets(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const location = commonSelectors.location(state);
    const queryParams = { ...location.query };

    queryParams.search = payload.search;

    dispatch(commonActions.setHomeQuery(null));
    dispatch(push(format({ pathname: location.pathname, query: queryParams })));
  };
}

export function searchPortalAssets(payload) {
  return searchAssets(payload);
}

export function clearFilters(location, query) {
  return (dispatch) => {
    const queryParams = { ...query };

    delete queryParams.type;

    if (!location) {
      return;
    }

    dispatch(analyticsActions.trackEvent(EVENT_TYPES.FILTERS_CLEARED));
    dispatch(commonActions.setHomeQuery(queryParams));
    dispatch(push(format({ pathname: location.pathname, query: queryParams })));
  };
}

export function clearPortalFilters(payload, location) {
  return clearFilters(payload, location);
}

export function getAssets({
  publicOnly = false,
  query,
  assetTypesConfiguration
} = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const studioType = commonSelectors.studioType(state);
    const domain = customizationSelectors.organizationDomain(state);
    const profile = commonSelectors.profile(state);
    const status = sessionSelectors.validAssetStatuses(state);

    const listFilters = queryToFilters({
      query: { ...query },
      profile,
      studioType,
      domain,
      publicOnly
    });

    const listAssetsFn = publicOnly
      ? assetService.publicList
      : assetService.list;

    return dispatch(
      actionify(
        listAssetsFn,
        actions.FETCH_ASSETS
      )({ ...listFilters, status, context, assetTypesConfiguration })
    );
  };
}

export function getPortalAssets({ query = {}, assetTypesConfiguration }) {
  return getAssets({
    query: {
      ...query,
      type: query.type
    },
    assetTypesConfiguration,
    publicOnly: true
  });
}

export function loadAssets({
  publicOnly = false,
  query,
  assetTypesConfiguration
} = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const context = commonSelectors.context(state);
    const studioType = commonSelectors.studioType(state);
    const domain = customizationSelectors.organizationDomain(state);
    const profile = commonSelectors.profile(state);
    const status = sessionSelectors.validAssetStatuses(state);

    const listFilters = queryToFilters({
      query,
      profile,
      studioType,
      domain,
      publicOnly
    });

    const listAssetsFn = publicOnly
      ? assetService.publicList
      : assetService.list;

    return dispatch(
      actionify(
        listAssetsFn,
        actions.LOAD_ASSETS
      )({ ...listFilters, status, context, assetTypesConfiguration })
    );
  };
}

export function loadPortalAssets({ query = {}, assetTypesConfiguration }) {
  return loadAssets({
    query: {
      ...query,
      type: query.type
    },
    publicOnly: true,
    assetTypesConfiguration
  });
}

export function showMoreAssets() {
  return {
    type: actions.SHOW_MORE_ASSETS
  };
}
