import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EVENT_TYPES } from '~/analytics/events';
import withLayoutContext from '~/components/LayoutContextProvider';
import withCustomizationLeaveHandling from '~/components/Customization/LeaveHookProvider';
import withFocusRestore from '~/components/WithFocusRestore';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import {
  actions as clientApplicationsActions,
  selectors as clientApplicationsSelectors
} from '~/domains/clientApplications';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as customizationSelectors } from '~/domains/customization';
import { parseSearch } from '~/utils/location';
import { isEditCustomizationRoute } from '~/utils/routes';
import ClientApplicationList from './ClientApplicationList';

const mapStateToProps = (state, { location, route }) => {
  const query = parseSearch(location.search);

  return {
    query,
    isEditMode: isEditCustomizationRoute(route),
    hasMoreApplications: clientApplicationsSelectors.hasMoreApplications(state),
    hasUnsavedChanges: customizationSelectors.hasUnsavedChanges(state),
    organization: commonSelectors.profile(state).organization,
    applications: clientApplicationsSelectors.applications(state),
    isFetchingList: clientApplicationsSelectors.isFetchingApplicationList(state)
  };
};

const mapDispatchToProps = (dispatch, { location }) => ({
  onListLoaded: (analyticsPayload) => {
    return dispatch(
      analyticsActions.trackEvent(
        EVENT_TYPES.APPLICATION_LIST_LOADED,
        analyticsPayload
      )
    );
  },
  onSearchApplications: (payload) => {
    return dispatch(
      clientApplicationsActions.searchApplications(payload, location)
    );
  },
  onApplicationClicked: (analyticsPayload) => {
    return dispatch(
      analyticsActions.trackEvent(
        EVENT_TYPES.APPLICATION_CLICKED,
        analyticsPayload
      )
    );
  },
  onLoadMoreApplications: (organizationId, payload) => {
    return dispatch(
      clientApplicationsActions.loadMoreApplications(organizationId, payload)
    );
  },
  onReachEnd: (analyticsPayload) => {
    return dispatch(
      analyticsActions.trackEvent(
        EVENT_TYPES.APPLICATION_LIST_END_REACHED,
        analyticsPayload
      )
    );
  }
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  }),
  withLayoutContext(),
  withCustomizationLeaveHandling,
  withFocusRestore
)(ClientApplicationList);
