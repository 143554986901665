import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import loadable from '@loadable/component';
import { AssetTypesConfigurationContext } from '@mulesoft/exchange-react-hooks';
import { SkipLinks } from '@mulesoft/exchange-ui-components';
import ANGFilters from '@mulesoft/exchange-search-common/lib/ANGFilters';
import Announcements from '~/components/Announcements';
import WelcomeSection from '~/components/Customization/WelcomeSection';
import { getHomeLink } from '~/utils/navigation';
import Search from './Search';
import Sorting from './Sorting';
import styles from './Home.css';
import ResultsInfo from './ResultsInfo';
import AssetView from './AssetView';

const ANGSearch = loadable(
  () =>
    import(
      /* webpackChunkName: "ang-search" */
      /* webpackPrefetch: true */
      './ANGSearch'
    )
);

ANGSearch.displayName = 'ANGSearch';

class Home extends React.PureComponent {
  static contextType = AssetTypesConfigurationContext;

  static propTypes = {
    isANGSearchEnabled: PropTypes.bool,
    isWelcomeEnabled: PropTypes.bool,
    isPublicPortal: PropTypes.bool,
    isMarketingSite: PropTypes.bool,
    presetLinkToEnabledMap: PropTypes.object,
    profile: PropTypes.object,
    showExtendedCards: PropTypes.bool,
    location: PropTypes.object,
    query: PropTypes.object,
    isEditMode: PropTypes.bool
  };

  static defaultProps = {
    isANGSearchEnabled: false,
    isPublicPortal: false,
    isMarketingSite: false,
    isWelcomeEnabled: false,
    presetLinkToEnabledMap: {},
    showExtendedCards: false,
    isEditMode: false,
    query: {},
    location: {}
  };

  searchRef = React.createRef();

  getResultsViewType = () => {
    const {
      query: { view, search },
      showExtendedCards
    } = this.props;

    if (showExtendedCards && search) {
      return 'card';
    }

    return view === 'list' ? 'list' : 'grid';
  };

  isListView = () => {
    const {
      query: { view }
    } = this.props;

    return view === 'list';
  };

  renderDomainHeader = () => {
    const { isANGSearchEnabled } = this.props;

    return isANGSearchEnabled ? (
      <div data-test-id="domain-header" className={styles.domainHeader}>
        {this.renderTitle()}
        <Sorting />
      </div>
    ) : (
      this.renderTitle()
    );
  };

  renderTitle = () => {
    const {
      isANGSearchEnabled,
      presetLinkToEnabledMap,
      isPublicPortal,
      location,
      profile
    } = this.props;
    const homeLink = getHomeLink(
      profile,
      location,
      isPublicPortal,
      { isANGSearchEnabled },
      presetLinkToEnabledMap
    );

    return (
      <h1 data-test-id="domain-title" className={styles.domainTitle}>
        {homeLink.title}
        {homeLink.annotation ? (
          <span className={styles.annotation}> {homeLink.annotation}</span>
        ) : null}
      </h1>
    );
  };

  renderMainContent = () => {
    const {
      isANGSearchEnabled,
      isEditMode,
      isWelcomeEnabled,
      isMarketingSite,
      query
    } = this.props;

    const isCardViewEnabled = this.getResultsViewType() === 'card';

    return (
      <div
        className={classNames(styles.home, {
          [styles.whiteTheme]: this.isListView(),
          [styles.greyTheme]: !this.isListView(),
          [styles.editMode]: isEditMode,
          [styles.isWelcomeEnabled]: isWelcomeEnabled,
          [styles.marketingCardView]: isCardViewEnabled && isMarketingSite
        })}
        aria-disabled={isEditMode}
        data-test-id="home-view"
        // React doesn’t accept custom attributes that dont start with "aria-" and "data-"
        {...(isEditMode ? { inert: 'true' } : {})}
      >
        <SkipLinks
          links={[
            { id: 'asset-results', text: 'Jump to asset results' },
            { id: 'asset-search', text: 'Jump to asset search' },
            { id: 'create-asset', text: 'Jump to create an asset' },
            { id: 'asset-filters', text: 'Jump to asset filters' },
            {
              id: 'organization-saved-searches',
              text: 'Jump to organization saved searches'
            },
            { id: 'user-saved-searches', text: 'Jump to user saved searches' },
            { id: 'other-links', text: 'Jump to other links' },
            {
              id: 'customize-sidebar',
              text: 'Jump to public portal customization'
            }
          ]}
        />
        <Announcements />
        <div
          data-test-id="container-title"
          display-if={!isANGSearchEnabled}
          className={styles.titleContainer}
        >
          {this.renderDomainHeader()}
        </div>
        <div tabIndex="-1" ref={this.searchRef} aria-label="Search">
          {isANGSearchEnabled ? <ANGSearch /> : <Search />}
        </div>
        <ResultsInfo />
        {isANGSearchEnabled && !new ANGFilters(query).hasQuery()
          ? this.renderDomainHeader()
          : null}
        <AssetView />
      </div>
    );
  };

  render() {
    const { isEditMode, isWelcomeEnabled } = this.props;

    return (
      <React.Fragment>
        <WelcomeSection isEditMode={isEditMode} display-if={isWelcomeEnabled} />
        {this.renderMainContent()}
      </React.Fragment>
    );
  }
}

export default Home;
