import { compose } from 'redux';
import { connect } from 'react-redux';
import { withModal } from '@mulesoft/exchange-react-hooks';
import { selectors as commonSelectors } from '~/domains/common';
import { setModalType, registerModal } from '~/components/Modals';
import { apiManagerInstanceUrl } from '~/utils/APIInstances';
import DeleteAPIWithInstancesModal from './DeleteAPIWithInstancesModal';

const mapStateToProps = (state, { instances }) => {
  const masterOrganization = commonSelectors.organization(state);
  const instancesWithUrl = instances.map((instance) => ({
    ...instance,
    url: apiManagerInstanceUrl(instance, masterOrganization)
  }));

  return {
    instances: instancesWithUrl
  };
};

const mapDispatchToProps = (dispatch, { closeModal }) => ({
  onClose: () => closeModal()
});

export default compose(
  registerModal,
  setModalType('DeleteAPIWithInstancesModal'),
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteAPIWithInstancesModal);
