import React from 'react';
import Settings from '~/components/Settings';
import NavigationSidebar from '~/components/NavigationSidebar';
import BaseLayout from '~/layouts/BaseLayout';

class SettingsLayout extends BaseLayout {
  renderSidebar() {
    return <NavigationSidebar />;
  }

  renderMain() {
    const { route } = this.props;

    return <Settings route={route} />;
  }
}

export default SettingsLayout;
