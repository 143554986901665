import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@mulesoft/anypoint-icons/lib/svg/info-small.svg';
import Announcement from '~/components/Announcements/Announcement';
import styles from './ANGTemporarilyDisabled.css';

const componentName = 'ANGTemporarilyDisabled';

class ANGTemporarilyDisabled extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func
  };

  static displayName = componentName;

  render() {
    const { onClose } = this.props;
    const icon = <InfoIcon />;

    return (
      <Announcement onClose={onClose} icon={icon} name={componentName}>
        <span className={styles.announcement}>
          The new search features have been disabled temporarily and will be
          back soon.
        </span>
      </Announcement>
    );
  }
}

export default ANGTemporarilyDisabled;
