import { createSelector } from 'reselect';
import memoize from 'memoize-one';
import semver from 'semver';
import { extractAssetAgnosticProperties } from '~/utils/assets';
import { omit } from '~/utils/objects';
import { getGAKey } from '~/utils/types';
import * as actionTypes from './actionTypes';

const hasHomeQuery = (state) =>
  !!state.common.homeQuery &&
  (!!state.common.homeQuery.search || !!state.common.homeQuery.type);

export const isSearchTriggered = (state) =>
  state.assets.list.isSearchTriggered || hasHomeQuery(state);

export const hasMoreAssets = (state) => state.assets.list.hasMoreAssets;

export const limit = (state) => state.assets.list.limit;

export const isFetchingAssets = (state) =>
  state.assets.list.isLoading ||
  state.requests.some(
    (request) =>
      request.identifier === actionTypes.FETCH_ASSETS &&
      request.status === 'STARTED'
  );

const assetParent = (state, { groupId, assetId }) =>
  state.assets.detail.data[getGAKey({ groupId, assetId })];

const assetVersionAgnosticProps = createSelector(
  assetParent,
  ($assetParent) => $assetParent && omit($assetParent, 'versions')
);

const memoizedAssetVersionsParams = memoize(
  (versionGroup, minorVersion, includeDeleted) => ({
    versionGroup,
    minorVersion,
    includeDeleted
  })
);

export const assetVersions = createSelector(
  assetParent,
  (state, params) =>
    memoizedAssetVersionsParams(
      params.versionGroup,
      params.minorVersion,
      params.includeDeleted
    ),
  ($assetParent, { minorVersion, includeDeleted }) => {
    if (!$assetParent || !$assetParent.versions) {
      return [];
    }

    const $assetVersions = Object.keys($assetParent.versions)
      .filter(
        (version) => includeDeleted || !$assetParent.versions[version].isDeleted
      )
      .map((version) => {
        return {
          version,
          ...$assetParent.versions[version]
        };
      })
      .sort((v1, v2) => semver.rcompare(v1.version, v2.version));

    if (minorVersion) {
      return $assetVersions.filter(
        (version) => version.minorVersion === minorVersion
      );
    }

    return $assetVersions;
  }
);

export const asset = createSelector(
  (state, params) => assetVersions(state, { includeDeleted: true, ...params }),
  assetParent,
  assetVersionAgnosticProps,
  (state, params) => params.minorVersion,
  (state, params) => params.version,
  (
    $assetVersions,
    $assetParent,
    $versionAgnosticProps,
    minorVersion,
    version
  ) => {
    if (!$assetParent) {
      return null;
    }

    let $asset;

    if (version) {
      $asset = $assetParent.versions[version];
    } else {
      const nonDeletedVersions = $assetVersions.filter(
        (assetVersion) => !assetVersion.isDeleted
      );

      $asset = nonDeletedVersions[0] || $assetVersions[0];
    }

    if ($asset) {
      return {
        ...$asset,
        ...$versionAgnosticProps
      };
    }

    // this state represents a transition, where only the asset agnostic props are used to render the portal
    return {
      minorVersion,
      isMinorVersionTransition: true,
      ...extractAssetAgnosticProperties($versionAgnosticProps)
    };
  }
);

export const assetOrganizationDomain = createSelector(
  assetParent,
  ($asset) => $asset && $asset.organization.domain
);

export const listAssets = (state) => state.assets.list.data;

export const assets = createSelector(
  listAssets,
  (state) => state.assets.detail.data,
  (assetKeys, $assets) =>
    assetKeys.reduce(
      (accum, { groupId, assetId, version, asset: listAsset, highlight }) => {
        const $assetParent =
          $assets[
            `${groupId ?? listAsset?.groupId}/${assetId ?? listAsset?.assetId}`
          ];

        if (!$assetParent) {
          return accum;
        }

        const versionAgnosticProps = omit($assetParent, 'versions');
        const $asset =
          $assetParent.versions[
            (version ?? listAsset?.version) || $assetParent.lastVersion
          ];

        if (!$asset) {
          return accum;
        }

        return [
          ...accum,
          {
            ...$asset,
            ...versionAgnosticProps,
            highlight
          }
        ];
      },
      []
    )
);
