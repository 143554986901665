import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '~/components/ErrorPage';
import { getDefaultCards } from '../utils';

const NotFoundPage = ({ getPath }) => {
  return (
    <ErrorPage
      title="404 Not Found"
      description="The page you are looking for could not be found."
      cards={getDefaultCards(getPath)}
    />
  );
};

NotFoundPage.propTypes = {
  getPath: PropTypes.func.isRequired
};

export default NotFoundPage;
