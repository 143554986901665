import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import OpenIcon from '@mulesoft/anypoint-icons/lib/svg/open.svg';
import WarningIcon from '@mulesoft/anypoint-icons/lib/svg/warning-small.svg';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import { isAPIGroup } from '~/utils/types';
import { getPath } from '~/utils/routes';
import styles from './RevertDeprecateAssetModal.css';

const VersionName = ({ asset }) => {
  return (
    <a href={getPath('page', asset)}>
      {asset.name}
      <Icon className={styles.openIcon} size="xxs">
        <OpenIcon />
      </Icon>
    </a>
  );
};

class RevertDeprecateAssetModal extends React.Component {
  static propTypes = {
    asset: PropTypes.object,
    onClose: PropTypes.func,
    isMinorDeprecation: PropTypes.bool,
    onPublish: PropTypes.func,
    onPublishMinor: PropTypes.func,
    onError: PropTypes.bool,
    isPortalReArchitectureEnabled: PropTypes.bool
  };

  static defaultProps = { isMinorDeprecation: false };

  handleDeprecate = () => {
    const { asset, onPublish, onPublishMinor, isMinorDeprecation } = this.props;

    if (isMinorDeprecation) {
      return onPublishMinor(asset);
    }

    return onPublish(asset);
  };

  bodyContent = () => {
    const { asset, onError, isMinorDeprecation } = this.props;
    const versionMessage = (
      <div>
        <p>
          Are you sure you want to revert the deprecated status of{' '}
          <strong>
            {isMinorDeprecation
              ? `all versions of ${asset.name} ${asset.minorVersion}.x`
              : `${asset.name} ${
                  isAPIGroup(asset)
                    ? asset.minorVersion.concat('.x')
                    : asset.version
                }`}
          </strong>
          ?
        </p>
      </div>
    );

    if (onError) {
      return this.errorDescription();
    }

    return versionMessage;
  };

  footerContent = () => {
    const { onError, onClose, isMinorDeprecation } = this.props;

    return (
      <div>
        <Button kind="tertiary" onClick={onClose} display-if={!onError} noFill>
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={this.handleDeprecate}
          display-if={!onError}
          testId="revert-deprecate-button"
        >
          Revert deprecated {isMinorDeprecation ? 'minor' : 'version'}
        </Button>
        <Button
          testId="cancel-button"
          kind="tertiary"
          onClick={onClose}
          display-if={onError}
          noFill
        >
          Close
        </Button>
      </div>
    );
  };

  render() {
    const {
      onClose,
      onError,
      isMinorDeprecation,
      isPortalReArchitectureEnabled
    } = this.props;
    const labelId = 'revert-deprecate-asset-modal-title';

    return (
      <Modal
        renderInBody={false}
        onEsc={onClose}
        onClickOverlay={onClose}
        className={classNames(styles.modal, {
          [styles.modalLg]: isPortalReArchitectureEnabled
        })}
        labelId={labelId}
      >
        <ModalHeader testId="modal-header" className={styles.header}>
          <h2 className={styles.title}>
            <Icon display-if={onError} size="s" className={styles.icon}>
              <WarningIcon />
            </Icon>
            <span
              className={styles.text}
              data-test-id="revert-deprecate-asset-title"
              id={labelId}
            >
              {onError
                ? 'Unable to revert'
                : `Revert deprecated asset ${
                    isMinorDeprecation ? 'minor' : 'version'
                  }`}
            </span>
          </h2>
        </ModalHeader>
        <ModalBody testId="modal-body" className={styles.body}>
          {this.bodyContent()}
        </ModalBody>
        <ModalFooter testId="modal-footer">{this.footerContent()}</ModalFooter>
      </Modal>
    );
  }
}

VersionName.propTypes = {
  asset: PropTypes.object.isRequired
};

export default RevertDeprecateAssetModal;
