import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import Sidebar from './Sidebar';

const mapStateToProps = (state, { match: { params }, route }) => {
  const isNewVersion = route && route.id === 'newVersion';
  const asset = isNewVersion ? assetsSelectors.asset(state, params) : undefined;

  return {
    asset
  };
};

export default compose(withRouter, connect(mapStateToProps))(Sidebar);
