import { createTracker } from 'redux-segment';
import { LOCATION_CHANGE } from 'connected-react-router';

// Avoid redux-segment "out-of-the-box" tracking of location changes
const customMapper = {
  mapper: {
    [LOCATION_CHANGE]: () => {}
  }
};

export default () => createTracker(customMapper);
