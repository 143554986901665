import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AssetImage,
  AssetType,
  Rating
} from '@mulesoft/exchange-ui-components';
import { AssetTypesConfigurationContext } from '@mulesoft/exchange-react-hooks';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import * as arrayUtils from '~/utils/arrays';
import constants from '~/utils/constants';
import { getIconURL } from '~/utils/routes';
import { getName } from '~/utils/types';
import styles from './Card.css';

class Card extends React.PureComponent {
  static propTypes = {
    asset: AssetPropType.isRequired,
    getPath: PropTypes.func,
    isPublicPortal: PropTypes.bool,
    onClick: PropTypes.func,
    homeQuery: PropTypes.object
  };

  static defaultProps = {
    onClick: () => {}
  };

  static contextType = AssetTypesConfigurationContext;

  getCreatedBy = () => {
    const { isPublicPortal, asset } = this.props;

    if (isPublicPortal) {
      return null;
    }

    if (!asset.createdBy) {
      return null;
    }

    return `${asset.createdBy.firstName} ${asset.createdBy.lastName}`;
  };

  buildCardLabel = () => {
    const { asset } = this.props;
    const createdBy = this.getCreatedBy();
    let label = `${asset.name}. ${getName(this.context, asset.type)}.`;

    if (asset.rating === 0) {
      label = `${label} No rating.`;
    } else {
      label = `${label} ${asset.rating} Stars.`;
    }

    if (createdBy) {
      label = `${label} Created By ${createdBy}`;
    }

    return label;
  };

  handleClick = () => {
    const { onClick, asset } = this.props;

    onClick(asset);
  };

  buildAssetPath(getPath) {
    const { asset, homeQuery, isPublicPortal } = this.props;
    const { groupId, assetId, minorVersion } = asset;

    const statuses = arrayUtils.convertToArray(homeQuery?.status);
    const highlightedStatus = statuses?.length === 1 ? statuses[0] : null;

    const path = getPath('minor', {
      groupId,
      assetId,
      minorVersion
    });

    if (isPublicPortal || !path) {
      return getPath('assetHome', asset);
    }

    if (highlightedStatus) {
      return `${path}?status=${highlightedStatus}`;
    }

    return path;
  }

  render() {
    const { asset, getPath, isPublicPortal } = this.props;
    const createdBy = this.getCreatedBy();

    return (
      <article aria-label={this.buildCardLabel()}>
        <Link
          data-test-id="asset-card"
          to={this.buildAssetPath(getPath)}
          onClick={this.handleClick}
          className={classNames(styles.assetCard, styles[asset.type], {
            [styles.publicPortal]: isPublicPortal
          })}
          aria-label={`Go to ${asset.name} portal`}
        >
          <div
            role="listitem"
            className={styles.header}
            data-test-id="card-header"
          >
            <AssetType
              type={asset.type}
              size="xs"
              testId="asset-type"
              ariaHidden
            />
            <Rating
              testId="asset-rating"
              className={styles.rating}
              rating={asset.rating}
              ariaHidden
              disabled
            />
          </div>
          <div className={styles.content} data-test-id="card-content">
            <div className={styles.assetImage}>
              <AssetImage
                type={asset.type}
                icon={getIconURL({ ...asset, isPublicPortal })}
                alt={asset.name}
                isCertified={asset.labels.some(
                  (label) => label === constants.mulesoftCertifiedTag
                )}
                ariaHidden
              />
            </div>
            <div
              data-test-id="asset-name"
              className={styles.name}
              title={asset.name}
              aria-hidden
            >
              {asset.name}
            </div>
            <div
              display-if={createdBy}
              className={classNames(styles.createdBy, 'fs-block')}
              data-test-id="asset-created-by"
              title={createdBy}
              aria-hidden
            >
              {createdBy}
            </div>
          </div>
        </Link>
      </article>
    );
  }
}

export default Card;
