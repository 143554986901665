import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import OpenIcon from '@mulesoft/anypoint-icons/lib/svg/open.svg';
import Table from '@mulesoft/exchange-ui-components/lib/components/Table';
import { InfoModal } from '~/components/Modals';
import styles from './DeleteAPIWithInstancesModal.css';

const headers = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'environmentName',
    label: 'Environment'
  }
];

const InstanceLink = ({ instance }) => {
  return (
    <a href={instance.url} target="_blank" rel="noopener noreferrer">
      {instance.name}
      <Icon className={styles.openIcon} size="xxs">
        <OpenIcon />
      </Icon>
    </a>
  );
};

InstanceLink.propTypes = {
  instance: PropTypes.object
};

const mapInstance = (instance) => ({
  name: <InstanceLink instance={instance} />,
  environmentName: instance.environmentName
});

class DeleteAPIWithInstancesModal extends PureComponent {
  static propTypes = {
    instances: PropTypes.arrayOf(PropTypes.object)
  };

  renderBody() {
    const { instances } = this.props;

    return (
      <div className={styles.container}>
        <p>
          This version cannot be deleted because it is used as API specification
          for one or more managed instances.
        </p>
        <h3 className={styles.subtitle}>Dependent instances:</h3>
        <Table
          headers={headers}
          rows={instances.map(mapInstance)}
          testId="api-instances"
        />
      </div>
    );
  }

  render() {
    return (
      <InfoModal
        isDanger
        title="Version has dependent instances"
        body={this.renderBody()}
        buttonText="Close"
        {...this.props}
      />
    );
  }
}

export default DeleteAPIWithInstancesModal;
