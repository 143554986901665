import React from 'react';
import PropTypes from 'prop-types';
import LoadingElement from '~/components/Layout/LoadingElement';
import AssetCardList from '../AssetCardList';
import AssetCardGrid from '../AssetCardGrid';
import AssetList from '../AssetList';
import styles from './AssetView.css';

const AssetView = ({
  assets = [],
  getPath,
  isFetchingAssets = false,
  onAssetClick,
  onLoadAssets = () => {},
  onReachResultsEnd = () => {},
  isANGSearchEnabled = false,
  isPublicPortal = false,
  hasMoreAssets = false,
  hasUserClickedSearchResult,
  showExtendedCards = false,
  query = {}
}) => {
  let AssetsView;

  const getResultsViewType = () => {
    const { search, view } = query;

    if (showExtendedCards && search) {
      return 'card';
    }

    return view === 'list' ? 'list' : 'grid';
  };

  const resultsViewType = getResultsViewType();

  if (resultsViewType === 'list') {
    AssetsView = AssetList;
  } else if (resultsViewType === 'card') {
    AssetsView = AssetCardList;
  } else {
    AssetsView = AssetCardGrid;
  }

  const getANGSearchProperties = (asset) => {
    if (!isANGSearchEnabled || !asset.highlight) {
      return null;
    }

    const { scores } = asset.highlight;
    const latestMatchedMinorScore = scores.latestVersionScore;

    return {
      matchedMinorCount: scores.count,
      maxMatchedMinorScore: asset.score,
      latestMatchedMinorScore,
      isLatestMatchedMinorHighestScore: asset.score === latestMatchedMinorScore
    };
  };

  const handleAssetClick = (asset) => {
    const { search, sort, view, ...filters } = query;

    const assetIndex = assets.findIndex(
      ($asset) =>
        asset.groupId === $asset.groupId && asset.assetId === $asset.assetId
    );
    const angProps = getANGSearchProperties(assets[assetIndex]);
    const resultCount = assets.length;
    const resultPosition = assetIndex + 1;
    const relativeClickRank = 1 - (resultPosition - 1) / resultCount;
    const weightedRelativeClickRank =
      resultCount === 1
        ? 1
        : (resultCount ** relativeClickRank - 1) / (resultCount - 1);

    onAssetClick(asset, getResultsViewType(), {
      ...angProps,
      isPublicPortal,
      resultCount,
      resultPosition,
      reciprocalRank: 1 / resultPosition,
      relativeClickRank,
      weightedRelativeClickRank,
      isFirstTimeClicked: !hasUserClickedSearchResult,
      query: { search, sort, view, filters, filterKeys: Object.keys(filters) }
    });
  };

  return (
    <section
      aria-live="assertive"
      aria-label="Search results"
      className={styles.searchResults}
    >
      {isFetchingAssets ? (
        <LoadingElement message="Searching for assets" />
      ) : (
        <AssetsView
          id="asset-results"
          assets={assets}
          query={query}
          getPath={getPath}
          isFetchingAssets={isFetchingAssets}
          onAssetClick={handleAssetClick}
          onLoadAssets={onLoadAssets}
          onReachEnd={onReachResultsEnd}
          hasMoreAssets={hasMoreAssets}
          searchTerm={query.search}
        />
      )}
    </section>
  );
};

AssetView.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object),
  hasUserClickedSearchResult: PropTypes.bool,
  isANGSearchEnabled: PropTypes.bool,
  isFetchingAssets: PropTypes.bool,
  isPublicPortal: PropTypes.bool,
  onLoadAssets: PropTypes.func,
  getPath: PropTypes.func,
  showExtendedCards: PropTypes.bool,
  hasMoreAssets: PropTypes.bool,
  query: PropTypes.object,
  onAssetClick: PropTypes.func,
  onReachResultsEnd: PropTypes.func
};

export default AssetView;
