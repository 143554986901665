import config from 'config';
import { push } from 'connected-react-router';
import { actions as analyticsActions } from '~/domains/analytics';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { ANG_DEFAULT_ENABLED } from '~/domains/featureFlags/keys';
import { actions as modalActions } from '~/domains/modal';
import { actions as sessionActions } from '~/portals/store/domains/session';
import * as searchService from '~/services/searchService';
import { buildFromLocation } from '~/utils/ang/ANGFilters';
import { shouldGetFacetsWithOrganizationId } from '~/utils/ang/query';
import { getBelongingOrganizationIds } from '~/utils/organization';
import { EVENT_TYPES } from '~/analytics/events';
import ANGOptOutModal from '~/components/Modals/ANGOptOutModal';
import ANGOnboardingModal from '~/components/Modals/ANGOnboardingModal';
import { parseSearch } from '~/utils/location';
import * as actions from './actionTypes';
import * as selectors from './selectors';

export function changeResults(results) {
  return {
    type: actions.CHANGE_RESULTS,
    ...results
  };
}

export function setFacets(facets) {
  return {
    type: actions.SET_FACETS,
    facets
  };
}

export function getRootOrganizationsFromFacets(facetsOrganizations, profile) {
  const organizationIds = facetsOrganizations?.map(({ id }) => id) ?? [];
  const belongingOrganizationIds = getBelongingOrganizationIds(profile);
  const rootOrganizations = organizationIds.filter((id) =>
    belongingOrganizationIds.includes(id)
  );

  return rootOrganizations;
}

export function setRootOrganizations(rootOrganizations) {
  return {
    type: actions.SET_ROOT_ORGANIZATIONS,
    rootOrganizations
  };
}

export function handleFacetsError(error) {
  return {
    type: actions.FETCHING_FACETS_ERROR,
    error
  };
}

export function updateFacets(facets) {
  return {
    type: actions.UPDATE_FACETS,
    facets
  };
}

export function getFacets(
  location,
  assetTypesConfiguration,
  rootOrganizations
) {
  return async (dispatch, getState) => {
    const state = getState();
    const query = parseSearch(location.search);
    const context = commonSelectors.context(state);
    const profile = commonSelectors.profile(state);
    const organizationId = profile?.organization?.id;
    const filters = buildFromLocation(
      location,
      assetTypesConfiguration,
      rootOrganizations,
      profile
    );
    const parsedQuery = filters.getQuery();
    const shouldIncludeOrganizationId =
      shouldGetFacetsWithOrganizationId(query);
    const start = Date.now();

    try {
      const facets = await searchService.getFacets({
        context,
        query: parsedQuery,
        assetTypesConfiguration,
        ...(shouldIncludeOrganizationId ? { organizationId } : {})
      });
      const responseTime = Date.now() - start;

      dispatch(setFacets({ ...facets, responseTime }));
    } catch (error) {
      if (error.status === 401) {
        throw error;
      }

      dispatch(handleFacetsError(error));
    }
  };
}

export function getRootOrganizations() {
  return async (dispatch, getState) => {
    const state = getState();
    const profile = commonSelectors.profile(state);
    const context = commonSelectors.context(state);

    if (!profile) {
      return null;
    }

    let facets;
    const rootOrganizations = selectors.rootOrganizations(state);

    if (rootOrganizations) {
      return rootOrganizations;
    }

    try {
      facets = await searchService.getFacets({
        context,
        query: {}
      });
    } catch (error) {
      if (error.status === 401) {
        throw error;
      }

      dispatch(handleFacetsError(error));

      return null;
    }

    const rootOrganizationsFromFacets = getRootOrganizationsFromFacets(
      facets.organizations,
      profile
    );

    dispatch(setRootOrganizations(rootOrganizationsFromFacets));

    return rootOrganizationsFromFacets;
  };
}

export function enableANGSearch() {
  return async (dispatch, getState) => {
    const state = getState();
    const isANGDefaultFlagEnabled = featureFlagsSelectors.featureFlag(
      state,
      ANG_DEFAULT_ENABLED
    );

    if (isANGDefaultFlagEnabled) {
      await dispatch(
        sessionActions.setUserProperty({
          namespace: 'exchange',
          key: 'isANGSearchDisabled',
          value: 'false'
        })
      );
    } else {
      await dispatch(
        sessionActions.setUserProperty({
          namespace: 'exchange',
          key: 'isANGSearchEnabled',
          value: 'true'
        })
      );
    }

    const homeURL = `${config.externalURI.pathname}/?new_search=`;

    dispatch(push(homeURL)); // hack trick to re-launch home resolver so facets are being loaded
  };
}

export function disableANGSearch() {
  return async (dispatch, getState) => {
    const state = getState();
    const isANGDefaultFlagEnabled = featureFlagsSelectors.featureFlag(
      state,
      ANG_DEFAULT_ENABLED
    );

    if (isANGDefaultFlagEnabled) {
      await dispatch(
        sessionActions.setUserProperty({
          namespace: 'exchange',
          key: 'isANGSearchDisabled',
          value: 'true'
        })
      );
    } else {
      await dispatch(
        sessionActions.setUserProperty({
          namespace: 'exchange',
          key: 'isANGSearchEnabled',
          value: 'false'
        })
      );
    }

    const homeURL = `${config.externalURI.pathname}/`;

    dispatch(push(homeURL));
  };
}

export function optOutOfANGSearch() {
  return async (dispatch, getState) => {
    const state = getState();
    const isANGDefaultFlagEnabled = featureFlagsSelectors.featureFlag(
      state,
      ANG_DEFAULT_ENABLED
    );

    if (isANGDefaultFlagEnabled) {
      await dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.ANG_SEARCH_OPT_OUT)
      );

      await dispatch(disableANGSearch());
    } else {
      dispatch(modalActions.openModal({ modalType: ANGOptOutModal.modalType }));
    }
  };
}

export function optInToANGSearch() {
  return async (dispatch, getState) => {
    const state = getState();
    const isANGDefaultFlagEnabled = featureFlagsSelectors.featureFlag(
      state,
      ANG_DEFAULT_ENABLED
    );

    if (isANGDefaultFlagEnabled) {
      await dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.ANG_SEARCH_OPT_IN)
      );

      await dispatch(enableANGSearch());
    } else {
      dispatch(
        modalActions.openModal({ modalType: ANGOnboardingModal.modalType })
      );
    }
  };
}
