const urlProtocolRegExp = new RegExp('^https?://');

export const getFileURL = (file, getPath) => {
  if (file.url) {
    if (!urlProtocolRegExp.test(file.url)) {
      return `http://${file.url}`;
    }

    return file.url;
  }

  if (file.onClick) {
    return null;
  }
  const filePath = getPath('file', {
    organizationId: file.organizationId,
    groupId: file.groupId,
    assetId: file.assetId,
    version: file.version,
    fileClassifier: file.classifier,
    packaging: file.packaging
  });
  const sha = file.sha1 ? file.sha1 : Date.now();

  return `${filePath}/?sha=${sha}`;
};

export const download = (file, isStudio, getPath) => {
  if (file.onClick) {
    file.onClick();

    return;
  }

  const fileURL = getFileURL(file, getPath);

  if (isStudio) {
    // We need to ensure that analytics calls are made before a redirection
    // and as segment does not provide a trustworthy callback, we use a timeout
    setTimeout(() => {
      global.location.href = fileURL;
    }, 1000);
  } else {
    global.open(fileURL, '_blank');
  }
};
