import { push } from 'connected-react-router';
import * as categoriesService from '~/services/categoriesService';
import EditCategoryConfigurationModal from '~/components/Settings/Actions/EditCategoryConfigurationModal/EditCategoryConfigurationModal.container';
import actionify from '~/utils/actionify';
import { getPath } from '~/utils/routes';
import { actions as modalActions } from '~/domains/modal';
import * as actionTypes from './actionTypes';

export const listCategories = actionify(
  categoriesService.list,
  actionTypes.LIST_CATEGORIES,
  {
    once: true,
    requestCompare: ({ params, oldParams }) =>
      params.organizationId === oldParams.organizationId
  }
);

const goToSettings = () => push(getPath('settings'));

function deleteCategoryConflict() {
  return {
    type: actionTypes.REMOVE_CATEGORY_CONFLICT
  };
}

export function cancelRemoveCategory() {
  return {
    type: actionTypes.CANCEL_REMOVE_CATEGORY
  };
}

export const createCategory = (payload) => (dispatch) =>
  dispatch(
    actionify(categoriesService.create, actionTypes.CREATE_CATEGORY)(payload)
  ).then(() => dispatch(goToSettings()));

export const updateCategory =
  (category, { forceUpdate = false } = {}) =>
  (dispatch, getState) => {
    if (forceUpdate) {
      return updateAction({ category, options: { forceUpdate } })(
        dispatch,
        getState
      )
        .then(() => {
          dispatch(modalActions.closeModal());
          dispatch(goToSettings());
        })
        .catch((err) => {
          dispatch(modalActions.closeModal());
          dispatch(goToSettings());

          throw err;
        });
    }

    return updateAction({
      category
    })(dispatch, getState)
      .then(() => {
        dispatch(goToSettings());
      })
      .catch((err) => {
        if (err.status === 409) {
          return dispatch(
            modalActions.openModal({
              modalType: EditCategoryConfigurationModal.modalType,
              modalProps: {
                category
              }
            })
          );
        }

        dispatch(modalActions.closeModal());
        dispatch(goToSettings());

        throw err;
      });
  };

function updateAction({ category, options }) {
  return (dispatch, getState) =>
    actionify(
      categoriesService.update,
      actionTypes.UPDATE_CATEGORY
    )({
      ...category,
      options
    })(dispatch, getState);
}

export const deleteCategory = (payload) => (dispatch, getState) =>
  actionify(
    categoriesService.remove,
    actionTypes.REMOVE_CATEGORY
  )(payload)(dispatch, getState)
    .then(() => {
      dispatch(modalActions.closeModal());
    })
    .catch((err) => {
      if (err.status !== 409 || payload.isForceDelete) {
        dispatch(modalActions.closeModal());
        throw err;
      }

      return dispatch(deleteCategoryConflict());
    });
