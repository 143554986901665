export const notebooksRanMode = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  DRAFT: 'DRAFT'
};

const nootebookRanRequiredProperties = ['mode', 'isAdmin', 'isContributor'];

export default {
  NOTEBOOK_CREATED: 'Notebook created',
  NOTEBOOK_RAN: {
    name: 'Notebook ran',
    required: nootebookRanRequiredProperties
  },
  NOTEBOOK_SNIPPET_RAN: {
    name: 'Notebook snippet ran',
    required: nootebookRanRequiredProperties
  }
};
