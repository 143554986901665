import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import ExternalRequestAccessTooltip from './ExternalRequestAccessTooltip';

const mapStateToProps = (state, { match: { params } }) => ({
  asset: assetsSelectors.asset(state, params)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ExternalRequestAccessTooltip);
