import React from 'react';
import Page from '~/components/Page';
import AssetBaseLayout from '../AssetBaseLayout';

class PageLayout extends AssetBaseLayout {
  renderComponent() {
    const { route } = this.props;

    return <Page route={route} />;
  }
}

export default PageLayout;
