import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectors as apiInstancesSelectors } from '@mulesoft/exchange-assets-definitions/lib/common/store/apiInstances';
import { DeleteAssetModal } from '@mulesoft/exchange-ui-portals-common';
import * as APIInstancesUtils from '~/utils/APIInstances';
import { setModalName } from '~/components/Modals';
import DeleteAPIWithInstancesModal from '../DeleteAPIWithInstancesModal';

const mapStateToProps = (state, { asset }) => {
  return {
    instances: apiInstancesSelectors.apiInstances(state, asset)
  };
};

const ComponentResolver = (props) => {
  const { instances, asset } = props;
  const managedInstances = instances.filter(
    (instance) =>
      instance.version === asset.version &&
      APIInstancesUtils.isManaged(instance)
  );

  if (managedInstances.length > 0) {
    return (
      <DeleteAPIWithInstancesModal {...props} instances={managedInstances} />
    );
  }

  return <DeleteAssetModal {...props} />;
};

ComponentResolver.propTypes = {
  asset: PropTypes.object,
  instances: PropTypes.arrayOf(PropTypes.object)
};

export default compose(
  setModalName('DeleteAssetModal'),
  connect(mapStateToProps)
)(ComponentResolver);
