import React from 'react';
import ServerErrorPage from '~/components/ErrorPage/ServerErrorPage';
import NotFoundPage from '~/components/ErrorPage/NotFoundPage';
import ForbiddenPage from '~/components/ErrorPage/ForbiddenPage';
import TooManyRequestPage from '~/components/ErrorPage/TooManyRequestPage';
import * as commonActions from '~/domains/common/actions';

export function getErrorPage(error, getPathFn) {
  if (error.status === 400 || error.status === 404) {
    return <NotFoundPage getPath={getPathFn} />;
  }

  if (error.status === 403) {
    return (
      <ForbiddenPage getPath={getPathFn} description={error.description} />
    );
  }

  if (error.status === 429) {
    return <TooManyRequestPage getPath={getPathFn} />;
  }

  return <ServerErrorPage getPath={getPathFn} />;
}

export const handleReorderingError = (dispatch) => {
  const reorderingConflictAlert = {
    type: 'error',
    message: `There was an error while saving the order of the pages. The file have been modified by another user. Try <a onclick="window.location.reload()">refreshing the page</a>.`,
    isHTML: true
  };

  return (error) => {
    if (error.status === 409) {
      dispatch(commonActions.setAlert(reorderingConflictAlert));
    } else {
      throw error;
    }
  };
};

export const handleUploadResourceError = (err, { url }) => {
  if (err.status === 422) {
    const message = `${err.message}. Learn how to manage portal images in <a href="${url}" target="_blank">our documentation</a>.`;

    const htmlError = new Error(message);

    htmlError.isHTML = true;

    throw htmlError;
  }

  throw err;
};
