import { format } from 'url';
import config from 'config';
import debug from 'debug';
import { fetch } from '~/utils/fetch';
import { getAPIUrl } from '~/utils/api';

const log = debug('exchange:user:service');

export async function getProfile({ context } = {}) {
  if (context && context.authorization) {
    const url = getAPIUrl({ context, pathname: '/api/v1/profile' });
    const response = await fetch(url, { context });

    log('got profile');

    return response.json();
  }

  log('authorization not provided, returning an error');

  const unauthorizedError = new Error('Unauthorized');

  unauthorizedError.status = 401;

  throw unauthorizedError;
}

export async function setUserProperty({
  context,
  organizationId,
  userId,
  namespace,
  key,
  value
}) {
  const url = format({
    ...config.services.csExternal.uri,
    pathname: `/api/organizations/${organizationId}/users/${userId}/properties`
  });
  const body = JSON.stringify({
    [namespace]: {
      [key]: value
    }
  });

  await fetch(url, { method: 'PUT', context, body });

  log(
    'changed user %s property. namespace: %s, key: %s, value: %s',
    userId,
    namespace,
    key,
    value
  );
}
