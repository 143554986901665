import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import AlertIcon from '@mulesoft/anypoint-icons/lib/svg/alert.svg';
import CloseIcon from '@mulesoft/anypoint-icons/lib/svg/close-small.svg';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import {
  isAssetsLimitWarningVisible,
  changeAssetsLimitWarningVisibility,
  getWarningMessage,
  isAssetsLimitOverWarning
} from '~/utils/assetsLimitWarning';
import styles from './AssetsLimitWarning.css';

export default class AssetsLimitWarning extends React.Component {
  static propTypes = {
    profile: PropTypes.object
  };

  state = { isWarningVisible: false };

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ isWarningVisible: this.shouldDisplayAssetsLimitWarning() });
  }

  shouldDisplayAssetsLimitWarning = () => {
    const { profile } = this.props;

    if (!profile) {
      return false;
    }

    const { assetsLimit, isExchangeContributor, isExchangeCreator } =
      profile.organization;

    return (
      (isExchangeContributor || isExchangeCreator) &&
      isAssetsLimitWarningVisible(profile) &&
      isAssetsLimitOverWarning(assetsLimit)
    );
  };

  handleClose = () => {
    const { profile } = this.props;

    changeAssetsLimitWarningVisibility(profile, false);
    this.setState({ isWarningVisible: false });
  };

  render() {
    const { profile } = this.props;
    const warningMessage = getWarningMessage(profile);

    return (
      <div
        className={styles.assetsLimitWarning}
        data-test-id="assets-limit-warning"
        display-if={this.state.isWarningVisible}
        role="banner"
      >
        <Icon size="s">
          <AlertIcon />
        </Icon>
        <span className={styles.text} data-test-id="assets-limit-label">
          {warningMessage}
        </span>
        <span
          className={styles.closeWarning}
          data-test-id="close-warning"
          onClick={this.handleClose}
          role="button"
          tabIndex="0"
          aria-label="Close assets limit banner"
          onKeyDown={eventHandlerUtils.onEnterKey(this.handleClose)}
        >
          <Icon size="xs">
            <CloseIcon />
          </Icon>
        </span>
      </div>
    );
  }
}
