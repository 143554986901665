import config from 'config';
import memoize from 'memoize-one';
import { MULESOFT_ORGANIZATION_LINK_ID } from '~/utils/navigation/constants';

const FEATURED_SORT = 'featured';

const shouldHaveFeaturedSort = ({ isAuthenticated, show }) => {
  const showFeaturedOnPrivateExchange = show === MULESOFT_ORGANIZATION_LINK_ID;
  const showFeaturedOnGlobalExchange =
    !show ||
    config.presetLinks.find(({ id: presetShow }) => presetShow === show);

  return isAuthenticated
    ? showFeaturedOnPrivateExchange
    : showFeaturedOnGlobalExchange;
};

export const getSortingOptions = memoize((isAuthenticated, show, search) => {
  const hasFeaturedSort = shouldHaveFeaturedSort({
    isAuthenticated,
    show
  });
  const hasRelevanceSort = !!search;
  const sortingOptions = {
    ...(hasRelevanceSort && {
      score: 'Relevance'
    }),
    ...(hasFeaturedSort && {
      featured: 'Featured'
    }),
    name: 'Name',
    last: 'Last modified',
    rating: 'Rating'
  };

  return sortingOptions;
});

export const getDefaultSort = ({ isAuthenticated, show }) => {
  if (
    shouldHaveFeaturedSort({
      isAuthenticated,
      show
    })
  ) {
    return FEATURED_SORT;
  }

  return 'last';
};
