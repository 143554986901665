import config from 'config';
import { fetch } from '~/utils/fetch';
import { getAPIUrl } from '~/utils/api';

export function list({
  masterOrganizationId,
  apiInstanceId = null,
  apiGroupInstanceId = null,
  context,
  search = '',
  offset = 0,
  limit = config.applicationsLimit
}) {
  const query = { query: search, offset, limit };

  if (apiInstanceId) {
    query.apiInstanceId = apiInstanceId;
  }

  if (apiGroupInstanceId) {
    query.groupInstanceId = apiGroupInstanceId;
  }

  const url = getAPIUrl({
    context,
    query,
    pathname: `/api/v1/organizations/${masterOrganizationId}/applications`
  });

  return fetch(url, { context }).then((response) => response.json());
}

export function get({ masterOrganizationId, applicationId, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${masterOrganizationId}/applications/${applicationId}`
  });

  return fetch(url, { context }).then((response) => response.json());
}

export function resetClientSecret({
  masterOrganizationId,
  applicationId,
  context
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${masterOrganizationId}/applications/${applicationId}/secret/reset`
  });

  return fetch(url, { context, method: 'POST' }).then((response) =>
    response.json()
  );
}

export function deleteClientApplication({
  masterOrganizationId,
  applicationId,
  context
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${masterOrganizationId}/applications/${applicationId}`
  });

  return fetch(url, { context, method: 'DELETE' });
}

export function editClientApplication({ context, application }) {
  const editURL = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${application.masterOrganizationId}/applications/${application.id}`
  });

  const { name, description, url, redirectURI, grantTypes, apiEndpoints } =
    application;

  const applicationBody = {
    name,
    description,
    url,
    redirectUri: redirectURI,
    grantTypes,
    apiEndpoints
  };

  return fetch(editURL, {
    context,
    method: 'PATCH',
    body: JSON.stringify(applicationBody)
  }).then((response) => response.json());
}

export function create({
  context,
  masterOrganizationId,
  name,
  description,
  url,
  redirectURI = [],
  grantTypes,
  apiEndpoints,
  apiInstanceId,
  apiGroupInstanceId
}) {
  const query = {};

  if (apiInstanceId) {
    query.apiInstanceId = apiInstanceId;
  }

  if (apiGroupInstanceId) {
    query.apiGroupInstanceId = apiGroupInstanceId;
  }

  const createURL = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${masterOrganizationId}/applications`,
    query
  });

  const applicationBody = {
    name,
    description,
    url,
    redirectUri: redirectURI,
    grantTypes,
    apiEndpoints
  };

  return fetch(createURL, {
    context,
    method: 'POST',
    body: JSON.stringify(applicationBody)
  }).then((response) => response.json());
}
