import React from 'react';
import PropTypes from 'prop-types';
import semver from 'semver';
import InstanceOverview from '../InstanceOverview';
import styles from './ApiGroupInstance.css';

class ApiGroupInstance extends React.Component {
  static propTypes = {
    isAdminMode: PropTypes.bool.isRequired,
    isPublicPortal: PropTypes.bool.isRequired,
    apiGroupInstance: PropTypes.object.isRequired,
    dependencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    testId: PropTypes.string
  };

  static defaultProps = {
    testId: 'api-group-instance'
  };

  hasViewerPermission({ groupId, assetId }) {
    const { isPublicPortal, dependencies } = this.props;

    return (
      isPublicPortal ||
      dependencies.some(
        (dependency) =>
          dependency.groupId === groupId &&
          dependency.assetId === assetId &&
          dependency.permissions &&
          dependency.permissions.length
      )
    );
  }

  getSortedInstances() {
    const { apiGroupInstance } = this.props;
    const { apiInstances } = apiGroupInstance;
    const sorted = Array.from(apiInstances);

    return sorted.sort((asset, otherAsset) => {
      if (asset.assetName !== otherAsset.assetName) {
        return asset.assetName < otherAsset.assetName ? -1 : 1;
      }

      return semver.compare(otherAsset.assetVersion, asset.assetVersion);
    });
  }

  render() {
    const { isAdminMode, testId } = this.props;
    const apiInstances = this.getSortedInstances();

    return (
      <table className={styles.table}>
        <caption className="visually-hidden">
          List of API instances for this API Group instance
        </caption>
        <thead>
          <tr>
            <th scope="col">API</th>
            <th scope="col">Instance name</th>
            <th scope="col">Environment</th>
            <th scope="col">Instance URL</th>
            <th display-if={isAdminMode} scope="col">
              Visibility
            </th>
          </tr>
        </thead>
        <tbody>
          {apiInstances.map((apiInstance, i) => (
            <InstanceOverview
              apiInstance={apiInstance}
              isAdminMode={isAdminMode}
              isDisabled={!this.hasViewerPermission(apiInstance)}
              testId={`${testId}-api-${i}`}
              key={`api-instance-${i}`}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

export default ApiGroupInstance;
