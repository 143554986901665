import React from 'react';
import { AssetPortalContext } from '@mulesoft/exchange-react-hooks';

const Sidebar = (props) => {
  const {
    components: { Sidebar: PortalSidebar }
  } = React.useContext(AssetPortalContext);

  return React.createElement(PortalSidebar, props);
};

export default Sidebar;
