import classNames from 'classnames';
import config from 'config';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import EditIcon from '@mulesoft/anypoint-icons/lib/svg/edit-small.svg';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import { getPath } from '~/utils/routes';
import styles from './DraftHeader.css';

const buttonStyles = Button.themes.DefaultTheme;

const DraftHeader = ({
  asset,
  isDraft,
  isEdit,
  hasUnpublishedChanges,
  onDiscardDraft
}) => {
  const params = {
    ...asset,
    pagePath: config.defaultPageName
  };
  const assetHomeURL = getPath('page', params);
  const pageDraftURL = getPath('pageDraft', params);
  const portalText =
    'This asset contains a draft that has not been published yet.';
  const draftText = 'This is a draft that has not been published yet.';
  const inputRef = useRef(null);
  const mustBeFocused = isDraft && !isEdit && hasUnpublishedChanges;

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [mustBeFocused]);

  return (
    <div
      className={styles.draftHeader}
      role="status"
      data-test-id="draft-header"
      display-if={!isEdit && hasUnpublishedChanges}
    >
      <Icon size="s">
        <EditIcon />
      </Icon>
      <span
        data-test-id="draft-label"
        display-if={isDraft}
        className={styles.text}
        title={draftText}
        ref={inputRef}
        tabIndex={-1}
      >
        {draftText}
      </span>
      <span
        data-test-id="published-label"
        display-if={!isDraft}
        className={styles.text}
        title={portalText}
      >
        {portalText}
      </span>
      <span className={styles.actions}>
        <span
          role="button"
          display-if={isDraft}
          data-test-id="discard-draft-action"
          className={classNames(
            styles.button,
            buttonStyles.button,
            buttonStyles.noFill,
            buttonStyles.secondary
          )}
          tabIndex="0"
          onClick={onDiscardDraft}
          onKeyDown={eventHandlerUtils.onEnterKey(onDiscardDraft)}
        >
          Discard draft
        </span>
        <Link
          data-test-id="exit-draft-action"
          className={classNames(
            styles.button,
            buttonStyles.button,
            buttonStyles.noFill,
            buttonStyles.secondary
          )}
          to={assetHomeURL}
          display-if={isDraft}
        >
          Exit draft
        </Link>
        <Link
          data-test-id="go-to-draft-action"
          className={classNames(
            styles.button,
            buttonStyles.button,
            buttonStyles.noFill,
            buttonStyles.secondary
          )}
          to={pageDraftURL}
          display-if={!isDraft}
        >
          Go to draft
        </Link>
      </span>
    </div>
  );
};

DraftHeader.propTypes = {
  asset: AssetPropType,
  isDraft: PropTypes.bool,
  isEdit: PropTypes.bool,
  hasUnpublishedChanges: PropTypes.bool,
  onDiscardDraft: PropTypes.func
};

export default DraftHeader;
