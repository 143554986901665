import { format, parse } from 'url';
import { parse as parseQuery } from 'querystring';
import config from 'config';

/**
* Receives an urlObject-like and fills all missed props to generate a real urlObject
* https://nodejs.org/api/url.html#url_url_strings_and_url_objects
*
* Example:

  compose({ port: 80, hostname: '0.0.0.0', protocol: 'http' }) =>
  Url {
    protocol: 'http:',
    slashes: true,
    host: '0.0.0.0:80',
    port: '80',
    hostname: '0.0.0.0',
    pathname: '/',
    path: '/',
    href: 'http://0.0.0.0:80/'
  }

*
* @param {Object} urlObject
*/
export function compose(urlObject = {}) {
  const formatted = format(urlObject);
  const parsed = parse(formatted);

  return parsed;
}

/**
 * Retrieves the redirect URL from the state
 * or the externalURI as default
 *
 */
export function getRedirectURL(state = {}, domain) {
  return (
    state.redirectURL ||
    format({
      ...config.externalURI,
      pathname: domain
        ? `${config.externalURI.pathname}/portals/${domain}/`
        : `${config.externalURI.pathname}/`
    })
  );
}

export function concatPathToURI(baseUri, path) {
  return [baseUri.replace(/[/]$/, ''), path.replace(/^[/]/, '')].join('/');
}

export function removeTrailingSlashes(path) {
  return path.replace(/[/]+$/, '');
}

const absoluteRegExp = /^(blob:)?https?:\/\//i;

export function isAbsolutePath(path = '') {
  return absoluteRegExp.test(path);
}

export function getQueryParam(location, param) {
  const queryStr = location?.search?.replace('?', '');

  if (!queryStr) {
    return [];
  }

  const query = parseQuery(queryStr);

  if (!query[param]) {
    return [];
  }

  if (Array.isArray(query[param])) {
    return query[param];
  }

  return [query[param]];
}

export function getHashProperties(uri) {
  if (!uri) {
    return uri;
  }
  const { hash } = parse(uri);

  if (!hash) {
    return {};
  }
  const hashString = hash.replace(/^#/, '');
  const hashProperties = parseQuery(hashString);

  Object.keys(hashProperties).forEach((key) => {
    const value = hashProperties[key];

    if (!value || value === '') {
      delete hashProperties[key];
    }
  });

  return hashProperties;
}
