import React from 'react';
import CustomPage from '~/components/Customization/CustomPage';
import BaseLayout from '~/layouts/BaseLayout';

class CustomPageLayout extends BaseLayout {
  renderMain() {
    return <CustomPage />;
  }
}

export default CustomPageLayout;
