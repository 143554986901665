import React from 'react';
import CustomizeSidebar from '~/components/Customization/CustomizeSidebar';
import BaseLayout from '~/layouts/BaseLayout';

class BaseCustomizationLayout extends BaseLayout {
  renderDetail() {
    return <CustomizeSidebar />;
  }
}

export default BaseCustomizationLayout;
