import config from 'config';
import debug from 'debug';
import { fetch } from '~/utils/fetch';
import { getAPIUrl } from '~/utils/api';
import { getDefaultTypes } from '~/utils/types';

const log = debug('exchange:asset:service');

export async function list(params) {
  const pathname = '/api/v2/assets';

  return baseList({ ...params, pathname });
}

export async function publicList(params) {
  const pathname = `/api/v2/portals/${params.domain}/assets`;

  return baseList({ ...params, pathname });
}

export function updateAssetMetadata({ groupId, assetId, metadata, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/assets/${groupId}/${assetId}`
  });
  const body = JSON.stringify(metadata);

  log('Updating asset metadata', {
    groupId,
    assetId,
    metadata
  });

  return fetch(url, { context, method: 'PATCH', body });
}

export async function updateIcon({ groupId, assetId, iconFile, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/assets/${groupId}/${assetId}/icon`
  });
  const response = await fetch(url, {
    context,
    method: 'PUT',
    headers: { 'content-type': iconFile.type || 'image/png' },
    body: iconFile
  });

  return response.json();
}

export async function deleteIcon({ groupId, assetId, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/assets/${groupId}/${assetId}/icon`
  });

  return fetch(url, {
    context,
    method: 'DELETE'
  });
}

async function baseList({
  search = '',
  organizationId = [],
  rootOrganizationId = '',
  domain = '',
  type = [],
  status = ['development', 'published', 'deprecated'],
  offset = 0,
  limit = config.assetsLimit,
  context,
  assetTypesConfiguration,
  sharedWithMe,
  pathname
} = {}) {
  const isPublicSearch = !!domain;
  const url = getAPIUrl({
    context,
    query: {
      search,
      types: type.length
        ? type
        : getDefaultTypes(
            assetTypesConfiguration,
            context.featureFlags,
            isPublicSearch
          ),
      status,
      domain,
      organizationId,
      rootOrganizationId,
      offset,
      limit,
      sharedWithMe
    },
    pathname
  });

  log('Getting assets list', {
    search,
    organizationId,
    rootOrganizationId,
    domain,
    type,
    offset,
    limit,
    context,
    pathname
  });

  const response = await fetch(url, { context });

  return response.json();
}

function getPublicPathname({
  domain,
  groupId,
  assetId,
  version,
  minorVersion
}) {
  if (minorVersion) {
    return `/api/v2/portals/${domain}/assets/${groupId}/${assetId}/minorVersions/${minorVersion}`;
  }

  if (version) {
    return `/api/v2/portals/${domain}/assets/${groupId}/${assetId}/${version}`;
  }

  return `/api/v2/portals/${domain}/assets/${groupId}/${assetId}`;
}

function getPathname({ groupId, assetId, version, minorVersion }) {
  if (minorVersion) {
    return `/api/v2/assets/${groupId}/${assetId}/minorVersions/${minorVersion}`;
  }

  if (version) {
    return `/api/v2/assets/${groupId}/${assetId}/${version}`;
  }

  return `/api/v2/assets/${groupId}/${assetId}`;
}

export async function get({
  domain,
  groupId,
  assetId,
  version,
  minorVersion,
  versionGroup,
  context
}) {
  let url;

  if (domain) {
    url = getAPIUrl({
      context,
      pathname: getPublicPathname({
        domain,
        groupId,
        assetId,
        version,
        minorVersion
      })
    });
  } else {
    url = getAPIUrl({
      context,
      pathname: getPathname({
        groupId,
        assetId,
        version,
        minorVersion
      })
    });
  }

  log('Getting asset', {
    groupId,
    assetId,
    minorVersion,
    version,
    versionGroup
  });

  const response = await fetch(url, { context });

  return response.json();
}

export async function getDomain({ context, groupId, assetId }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/assets/${groupId}/${assetId}/domain`
  });

  log('Getting asset domain', {
    groupId,
    assetId
  });

  const response = await fetch(url, {
    context,
    headers: {
      accept: 'text/plain'
    }
  });

  return response.text();
}

export async function getFiles({ groupId, assetId, context }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/assets/${groupId}/${assetId}/files`
  });

  log('Getting asset files', {
    groupId,
    assetId
  });

  const response = await fetch(url, { context });

  return response.json();
}

export function updateLabels({
  organizationId,
  groupId,
  assetId,
  version,
  labels,
  context
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/assets/${groupId}/${assetId}/${version}/tags`
  });

  const body = JSON.stringify(labels.map((label) => ({ value: label })));

  log('Updating asset labels', {
    groupId,
    assetId,
    version,
    labels
  });

  return fetch(url, { context, method: 'PUT', body });
}

export async function getMinorVersions({ context, domain, groupId, assetId }) {
  const pathname = domain
    ? `/api/v2/portals/${domain}/assets/${groupId}/${assetId}/minorVersions`
    : `/api/v2/assets/${groupId}/${assetId}/minorVersions`;

  const url = getAPIUrl({ context, pathname });

  log('Getting asset minorVersions', {
    groupId,
    assetId,
    domain
  });

  const response = await fetch(url, { context });

  return response.json();
}
