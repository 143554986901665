export const applications = (state) => state.applications.list;
export const application = (state, applicationId) =>
  state.applications.data[applicationId];
export const isFetchingApplicationList = (state) =>
  state.applications.ui.isFetchingList;
export const isFetchingApplication = (state) =>
  state.applications.ui.isFetchingApplication;
export const isEditingApplication = (state) =>
  state.applications.ui.isEditingApplication;
export const isResetingClientSecret = (state) =>
  state.applications.ui.isResetingClientSecret;
export const isDeletingApplication = (state) =>
  state.applications.ui.isDeletingApplication;
export const hasMoreApplications = (state) =>
  state.applications.hasMoreApplications;
export const query = (state) => state.applications.query || {};
