import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import Button from '@mulesoft/anypoint-components/lib/Button';
import styles from '../Modal.css';
import infoModalStyles from './InfoModal.css';

class InfoModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.node,
    buttonText: PropTypes.string,
    docsLinkText: PropTypes.string,
    isDanger: PropTypes.bool,
    onClose: PropTypes.func,
    docsLink: PropTypes.string,
    footer: PropTypes.node
  };

  static defaultProps = {
    buttonText: 'Ok',
    docsLinkText: 'Link to docs'
  };

  render() {
    const {
      title,
      body,
      buttonText,
      docsLink,
      docsLinkText,
      isDanger,
      onClose,
      footer
    } = this.props;
    const labelId = 'info-modal-title';

    const defaultFooter = () => (
      <React.Fragment>
        <a
          href={docsLink}
          display-if={docsLink}
          data-test-id="info-modal-docs"
          rel="noopener noreferrer"
          target="_blank"
        >
          {docsLinkText}
        </a>
        <Button
          testId="info-modal-button"
          kind="tertiary"
          onClick={onClose}
          noFill
        >
          {buttonText}
        </Button>
      </React.Fragment>
    );

    return (
      <Modal
        renderInBody={false}
        onEsc={onClose}
        className={styles.modal}
        ariaLabelledBy={labelId}
        role={isDanger ? 'alertdialog' : 'dialog'}
      >
        <ModalHeader
          testId="info-modal-header"
          className={styles.header}
          isDanger={isDanger}
        >
          <h2 id={labelId} className={styles.title}>
            {title}
          </h2>
        </ModalHeader>
        <ModalBody testId="info-modal-body" className={styles.body}>
          {body}
        </ModalBody>
        <ModalFooter
          testId="info-modal-footer"
          className={classNames(styles.footer, infoModalStyles.footer)}
        >
          {footer ?? defaultFooter()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default InfoModal;
