import PropTypes from 'prop-types';
import React from 'react';
import DocumentMeta from 'react-document-meta';
import { renderRoutes } from 'react-router-config';
import classNames from 'classnames';
import memoize from 'memoize-one';
import { RouterContext } from '@mulesoft/exchange-react-hooks';
import { ErrorBoundary } from '@mulesoft/exchange-ui-components';
import UnhandledClientError from '~/components/ErrorPage/UnhandledClientError';
import CustomizeNavbar from '~/components/Customization/CustomizeNavbar';
import AlertMessage from '~/components/Layout/AlertMessage';
import { ModalRoot } from '~/components/Modals';
import styles from './PublicPortalApp.css';

const getPreviousRoutesSectionFn = memoize(
  (previousRoutesSection) => () => previousRoutesSection
);

class PublicPortalApp extends React.PureComponent {
  static propTypes = {
    header: PropTypes.object,
    isEditMode: PropTypes.bool,
    featureFlags: PropTypes.object,
    route: PropTypes.object,
    alert: PropTypes.object,
    location: PropTypes.object,
    getPath: PropTypes.func,
    goBack: PropTypes.func,
    previousRoutesSection: PropTypes.object,
    setDocumentMeta: PropTypes.func,
    push: PropTypes.func,
    onCloseAlert: PropTypes.func.isRequired,
    routingContext: PropTypes.object,
    showNavbar: PropTypes.bool
  };

  static childContextTypes = {
    isPublicPortal: PropTypes.bool,
    getPath: PropTypes.func,
    featureFlags: PropTypes.object
  };

  getChildContext() {
    const { getPath } = this.props;

    return {
      isPublicPortal: true,
      getPath,
      featureFlags: this.props.featureFlags
    };
  }

  render() {
    const {
      alert,
      route,
      header,
      getPath,
      goBack,
      location,
      push,
      previousRoutesSection,
      setDocumentMeta,
      isEditMode,
      onCloseAlert,
      routingContext,
      showNavbar
    } = this.props;

    return (
      <RouterContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          location,
          context: routingContext,
          getPreviousRoutesSection: getPreviousRoutesSectionFn(
            previousRoutesSection
          ),
          getPath,
          push,
          goBack,
          setDocumentMeta
        }}
      >
        <div
          className={classNames(styles.publicPortal, {
            [styles.noScroll]: isEditMode
          })}
          data-test-id="customize-layout-wrapper"
        >
          <DocumentMeta {...header} />
          <CustomizeNavbar display-if={showNavbar} isEditMode={isEditMode} />
          <ErrorBoundary errorFallbackComponent={UnhandledClientError}>
            {renderRoutes(route.routes)}
          </ErrorBoundary>
          <AlertMessage
            {...alert}
            display-if={alert}
            onCloseAlert={onCloseAlert}
          />
          <ModalRoot />
        </div>
      </RouterContext.Provider>
    );
  }
}

export default PublicPortalApp;
