import { format } from 'url';
import config from 'config';
import { createSelector } from 'reselect';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as pagesSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { getPath, getPortalPath } from '~/utils/routes';
import { isClient } from '~/utils/window';

function getDescription(html) {
  let description = '';
  const firstParagraph = /(?:<p>)([\s\S]+?)(?:<\/p>)/gi.exec(html);

  if (firstParagraph) {
    description = firstParagraph[1]
      .replace(/<br\s*\/?>/gi, '\n') // parse new lines
      .replace(/<[\s\S]+?\/?>/g, '') // remove tags and leave inner text only
      .split('\n')[0]; // take only the first paragraph
  }

  return description;
}

export const assetCanonicalURL = createSelector(
  assetsSelectors.asset,
  ($asset) => {
    if (!$asset) {
      return null;
    }

    if ($asset.organization.id === config.mulesoft.id) {
      return format({
        ...config.mulesoft.site,
        pathname: getPath('assetHome', $asset)
      });
    }

    if ($asset.isPublic) {
      return format({
        ...config.externalURI,
        pathname: getPortalPath()('assetHome', $asset)
      });
    }

    return format({
      ...config.externalURI,
      pathname: getPath('page', $asset)
    });
  }
);

export const getPortalHeader = createSelector(
  (state, params) =>
    params.asset ? params.asset : assetsSelectors.asset(state, params),
  pagesSelectors.page,
  (state, params) =>
    pagesSelectors.pageHTML(state, {
      ...params,
      pagePath: config.defaultPageName
    }),
  assetCanonicalURL,
  (asset, page, homeHTML, canonical) => {
    const pageTitle =
      page && page.path && page.path !== config.defaultPageName
        ? ` - ${page.name}`
        : '';
    const title = `${asset.name}${pageTitle}`;

    let description = asset.description || '';
    let openGraphProps;

    if (!isClient()) {
      description = getDescription(homeHTML) || description;
      openGraphProps = {
        'og:site_name': config.title,
        'og:image': asset.icon
      };
    }

    const header = {
      title,
      description,
      canonical,
      meta: {
        name: {
          author: asset.createdBy
            ? `${asset.createdBy.firstName} ${asset.createdBy.lastName}`
            : null,
          keywords: [...config.siteKeywords, asset.type]
            .concat(asset.labels)
            .join(',')
        }
      }
    };

    if (openGraphProps) {
      header.meta.property = openGraphProps;
    }

    return header;
  }
);
