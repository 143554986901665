import PropTypes from 'prop-types';
import React from 'react';
import config from 'config';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import { isValidPageName, isPredefinedPageName } from '~/utils/page';
import styles from './StatelessPageName.css';

const pageDoesAlreadyExist = (pageName, pages) =>
  pages.some((page) => page.name === pageName) ||
  isPredefinedPageName(pageName);

const StatelessPageName = ({
  pageName,
  page,
  pages,
  onRenamePage,
  canRenamePage
}) => {
  const trimmedPageName = pageName.trim();
  const invalid =
    trimmedPageName !== page.name &&
    (!isValidPageName(trimmedPageName) ||
      pageDoesAlreadyExist(trimmedPageName, pages));

  return (
    <div className={styles.pageName} data-test-id="page-name">
      <TextField
        aria-invalid={invalid}
        isDirty={invalid}
        isValid={!invalid}
        aria-label="Page title"
        data-test-id="page-name-input"
        name="page-name-input"
        type="text"
        className={styles.pageNameInput}
        onChange={({ value }) => onRenamePage(value)}
        value={pageName}
        maxLength={config.maxPageNameLength}
        disabled={!canRenamePage}
      />
    </div>
  );
};

StatelessPageName.propTypes = {
  canRenamePage: PropTypes.bool,
  onRenamePage: PropTypes.func,
  page: PropTypes.object,
  pageName: PropTypes.object,
  pages: PropTypes.arrayOf(PropTypes.object)
};

export default StatelessPageName;
