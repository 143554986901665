import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import DeprecateIcon from '@mulesoft/anypoint-icons/lib/svg/warning-small.svg';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import Modal from '@mulesoft/anypoint-components/lib/Modal';
import ModalHeader from '@mulesoft/anypoint-components/lib/ModalHeader';
import ModalBody from '@mulesoft/anypoint-components/lib/ModalBody';
import ModalFooter from '@mulesoft/anypoint-components/lib/ModalFooter';
import { isAPIGroup } from '~/utils/types';
import styles from './DeprecateAssetModal.css';

class DeprecateAssetModal extends React.Component {
  static propTypes = {
    asset: PropTypes.object,
    isMinorDeprecation: PropTypes.bool,
    onClose: PropTypes.func,
    onDeprecate: PropTypes.func,
    onDeprecateByMinor: PropTypes.func,
    onDeprecateError: PropTypes.bool,
    isPortalReArchitectureEnabled: PropTypes.bool
  };

  static defaultProps = { isMinorDeprecation: false };

  state = {};

  handleDeprecate = () => {
    const { asset, onDeprecate, onDeprecateByMinor, isMinorDeprecation } =
      this.props;

    if (isMinorDeprecation) {
      return onDeprecateByMinor(asset);
    }

    return onDeprecate(asset);
  };

  bodyContent = () => {
    const { asset, onDeprecateError, isMinorDeprecation } = this.props;
    const isAPIGroupAsset = isAPIGroup(asset);

    const versionMessage = (
      <div>
        <p>
          Are you sure you want to deprecate{' '}
          <strong>
            {isMinorDeprecation && !isAPIGroupAsset
              ? `all versions of ${asset.name} ${asset.minorVersion}.x`
              : `${asset.name} ${
                  isAPIGroupAsset
                    ? asset.minorVersion.concat('.x')
                    : asset.version
                }`}
          </strong>
          ?
        </p>
      </div>
    );

    if (onDeprecateError) {
      return this.errorDescription();
    }

    return versionMessage;
  };

  footerContent = () => {
    const { onDeprecateError, onClose, isMinorDeprecation } = this.props;

    return (
      <div>
        <Button
          testId="cancel-button"
          kind="tertiary"
          onClick={onClose}
          display-if={!onDeprecateError}
          noFill
        >
          Cancel
        </Button>
        <Button
          testId="deprecate-asset-button"
          kind="danger"
          onClick={this.handleDeprecate}
          display-if={!onDeprecateError}
          noFill
        >
          Deprecate {isMinorDeprecation ? 'minor' : 'version'}
        </Button>
        <Button
          testId="close-button"
          kind="tertiary"
          onClick={onClose}
          display-if={onDeprecateError}
          noFill
        >
          Close
        </Button>
      </div>
    );
  };

  render() {
    const {
      onClose,
      onDeprecateError,
      isMinorDeprecation,
      isPortalReArchitectureEnabled
    } = this.props;
    const labelId = 'deprecate-asset-modal-title';

    return (
      <Modal
        renderInBody={false}
        onEsc={onClose}
        onClickOverlay={onClose}
        className={classNames(styles.modal, {
          [styles.modalLg]: isPortalReArchitectureEnabled
        })}
        ariaLabelledBy={labelId}
        role="alertdialog"
      >
        <ModalHeader testId="modal-header" className={styles.header} isDanger>
          <h2 className={styles.title}>
            <Icon className={styles.icon} size="s">
              <DeprecateIcon />
            </Icon>
            <span
              className={styles.text}
              data-test-id="deprecate-asset-title"
              id={labelId}
            >
              {onDeprecateError
                ? 'Unable to deprecate'
                : `Deprecate asset ${isMinorDeprecation ? 'minor' : 'version'}`}
            </span>
          </h2>
        </ModalHeader>
        <ModalBody testId="modal-body" className={styles.body}>
          {this.bodyContent()}
        </ModalBody>
        <ModalFooter testId="modal-footer" className={styles.footer}>
          {this.footerContent()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeprecateAssetModal;
