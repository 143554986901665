import React from 'react';
import PropTypes from 'prop-types';
import { useConfig, useGetPortalPath } from '@mulesoft/exchange-react-hooks';
import { SidebarItem, SidebarTitle } from '@mulesoft/exchange-ui-components';
import { Sidebar as GenericAssetPortalSidebar } from '@mulesoft/exchange-ui-portals-common';

const AssetPortalSidebar = ({ portalConfiguration, ...props }) => {
  const getPortalPath = useGetPortalPath();
  const config = useConfig();
  const items = portalConfiguration
    .sections({
      ...props,
      config
    })
    .reduce((accum, current, index) => {
      if (React.isValidElement(current)) {
        return [
          ...accum,
          <React.Fragment key={index}>{current}</React.Fragment>
        ];
      }

      if (typeof current === 'object' && 'to' in current) {
        return [
          ...accum,
          <SidebarItem to={getPortalPath(current.to)} key={index}>
            {current.label}
          </SidebarItem>
        ];
      }

      return accum;
    }, []);

  const renderNavigation = () => (
    <React.Fragment>
      <SidebarTitle display-if={items.length}>Other details</SidebarTitle>
      {items}
    </React.Fragment>
  );

  return (
    <GenericAssetPortalSidebar {...props} renderNavigation={renderNavigation} />
  );
};

AssetPortalSidebar.propTypes = {
  portalConfiguration: PropTypes.object
};

export default AssetPortalSidebar;
