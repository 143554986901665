import PropTypes from 'prop-types';
import React from 'react';
import LoadingElement from '~/components/Layout/LoadingElement';
import * as refUtils from '~/utils/refs';
import SavedSearchItem from './Item';
import styles from './SavedSearches.css';

export default class SavedSearches extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    canEdit: PropTypes.bool,
    defaultItemCount: PropTypes.number,
    hasMore: PropTypes.bool,
    isFetching: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    type: PropTypes.string,
    view: PropTypes.string,
    organizationId: PropTypes.string,
    onGetSavedSearches: PropTypes.func,
    onEditSavedSearch: PropTypes.func,
    onDeleteSavedSearch: PropTypes.func,
    onClickShowMore: PropTypes.func,
    onClickShowLess: PropTypes.func
  };

  state = {
    showMore: true
  };

  componentDidMount() {
    const { defaultItemCount, organizationId, onGetSavedSearches } = this.props;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isMounted: true });

    onGetSavedSearches({ organizationId, limit: defaultItemCount });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultItemCount, organizationId, onGetSavedSearches, type } =
      this.props;

    if (
      type === 'organization' &&
      nextProps.organizationId !== organizationId &&
      this.state.isMounted
    ) {
      onGetSavedSearches({
        organizationId: nextProps.organizationId,
        limit: defaultItemCount
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const itemsAdded = this.props.items.length > prevProps.items.length;
    const isShowMoreToggled = prevState.showMore && !this.state.showMore;

    if (itemsAdded || isShowMoreToggled) {
      refUtils.focus(this.firstOfLoadedRef);
    }
  }

  firstOfLoadedRef = React.createRef();

  shouldDisplayExpandable = () => {
    const { hasMore, items, defaultItemCount } = this.props;

    return items.length > defaultItemCount || hasMore;
  };

  toggleCollapseList = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  fetchSavedSearches = () => {
    const { showMore } = this.state;
    const {
      hasMore,
      organizationId,
      onGetSavedSearches,
      onClickShowMore,
      onClickShowLess
    } = this.props;

    this.toggleCollapseList();

    if (hasMore && showMore) {
      onGetSavedSearches({ organizationId });
    }

    if (showMore) {
      onClickShowMore();
    } else {
      onClickShowLess();
    }
  };

  checkSavedSearchNameAvailability = (savedSearchName) => {
    const { items } = this.props;

    return !items.some((savedSearch) => savedSearch.name === savedSearchName);
  };

  renderSavedSearch = (search, index) => {
    const {
      organizationId,
      view,
      items,
      canEdit,
      defaultItemCount,
      onDeleteSavedSearch,
      onEditSavedSearch
    } = this.props;
    const query = { ...search, view };

    // The first search after loading more searches
    const isFirstOfLoadedRef = index === defaultItemCount;

    return (
      <SavedSearchItem
        testId={`saved-search-item-${search.name}`}
        organizationId={organizationId}
        checkNameAvailability={this.checkSavedSearchNameAvailability}
        currentSavedSearches={items}
        onDelete={onDeleteSavedSearch}
        onEdit={onEditSavedSearch}
        canEdit={canEdit}
        query={query}
        key={index}
        ref={isFirstOfLoadedRef ? this.firstOfLoadedRef : undefined}
      />
    );
  };

  render() {
    const { showMore } = this.state;
    const { id, title, items, defaultItemCount, isFetching } = this.props;
    const availableItems = showMore ? items.slice(0, defaultItemCount) : items;
    const shouldDisplayExpandable = this.shouldDisplayExpandable() || !showMore;

    return (
      <div
        display-if={availableItems.length}
        id={id}
        className={styles.savedSearch}
      >
        <h6 data-test-id="saved-search-header" className={styles.header}>
          {title}
        </h6>
        <div data-test-id="saved-search-content" className={styles.content}>
          {availableItems.map(this.renderSavedSearch)}
        </div>
        {isFetching ? (
          <LoadingElement message="Loading saved searches" size="small" />
        ) : (
          <button
            type="button"
            display-if={shouldDisplayExpandable}
            data-test-id="saved-search-expandable"
            className={styles.showMore}
            onClick={this.fetchSavedSearches}
          >
            {showMore ? 'Show more' : 'Show less'}
          </button>
        )}
      </div>
    );
  }
}
