import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxAsyncReducer } from 'redux-connect';
import { reducer as assetsReducer } from './assets';
import { reducer as categoriesReducer } from './categories';
import { reducer as clientApplicationReducer } from './clientApplications';
import { reducer as commonReducer } from './common';
import { reducer as customizationReducer } from './customization';
import { reducer as localStorageReducer } from './localStorage';
import { reducer as modalReducer } from './modal';
import { reducer as reloginReducer } from './relogin';
import { reducer as requestsReducer } from './requests';
import { reducer as searchReducer } from './search';
import { reducer as searchesReducer } from './searches';
import { reducer as sessionReducer } from '~/portals/store/domains/session';

export default (history) =>
  combineReducers({
    assets: assetsReducer,
    categories: categoriesReducer,
    common: commonReducer,
    customization: customizationReducer,
    reduxAsyncConnect: reduxAsyncReducer,
    router: connectRouter(history),
    requests: requestsReducer,
    applications: clientApplicationReducer,
    localStorage: localStorageReducer,
    modal: modalReducer,
    search: searchReducer,
    searches: searchesReducer,
    session: sessionReducer,
    relogin: reloginReducer
  });
