import { format } from 'url';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Label from '@mulesoft/anypoint-components/lib/Label';
import TextField from '@mulesoft/anypoint-components/lib/TextField';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import AddPillButton from '~/components/AddPillButton';
import PillLink from '~/components/PillLink';
import * as refUtils from '~/utils/refs';
import { ALL_ASSETS_LINK_ID } from '~/utils/navigation/constants';
import styles from './LabelsList.css';

class LabelsList extends React.Component {
  static propTypes = {
    isEditable: PropTypes.bool,
    asset: AssetPropType,
    onUpdateLabels: PropTypes.func,
    onNewLabelClick: PropTypes.func,
    onLeaveCreatingState: PropTypes.func,
    getPath: PropTypes.func
  };

  static maxLabelLength = 512;

  state = {
    isCreating: false,
    isValid: true,
    value: '',
    errorMessage: ''
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isCreating && !this.state.isCreating) {
      refUtils.focus(this.addTagRef);
    }
  }

  getTagUrl = (tagName) => {
    const pathname = this.props.getPath('home');
    const query = {
      search: `tag:"${tagName}"`,
      show: ALL_ASSETS_LINK_ID
    };

    return format({ pathname, query });
  };

  addTagRef = React.createRef();

  handleTextFieldEnter = ({ value }) => {
    const labelName = value.trim();

    if (labelName) {
      const {
        organization: { id: organizationId },
        groupId,
        assetId,
        version,
        labels
      } = this.props.asset;
      const newLabel = labelName.toLowerCase();

      if (!labels.some((label) => label.toLowerCase() === newLabel)) {
        this.props.onUpdateLabels({
          organizationId,
          groupId,
          assetId,
          version,
          labels: labels.concat([newLabel])
        });
        this.setState({ value: '', errorMessage: '', isValid: true });
      } else {
        this.setState({ isValid: false, errorMessage: 'Duplicate tag name' });
      }
    }
  };

  handleTextFieldBlur = () => {
    this.leaveCreatingState();
  };

  handleTextFieldKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.leaveCreatingState();
    }
  };

  handleNewLabelClick = () => {
    this.setState({ isCreating: true, isValid: true });
    this.props.onNewLabelClick();
  };

  handlePillRemove = (removedLabel) => {
    const {
      organization: { id: organizationId },
      groupId,
      assetId,
      version,
      labels
    } = this.props.asset;

    this.props.onUpdateLabels({
      organizationId,
      groupId,
      assetId,
      version,
      labels: labels.filter((label) => label !== removedLabel)
    });

    this.leaveCreatingState();
  };

  leaveCreatingState = () => {
    this.setState({ isCreating: false, value: '', errorMessage: '' });
    this.props.onLeaveCreatingState();
  };

  handleTextFieldChange = ({ value }) => this.setState({ value });

  render() {
    const { isCreating, isValid, value, errorMessage } = this.state;
    const { isEditable, asset } = this.props;

    return (
      <section data-test-id="asset-labels-list" aria-labelledby="tags-heading">
        <h4 id="tags-heading">Tags</h4>
        <div className={styles.labelsContainer} aria-live="polite">
          {(asset.labels || []).map((label, index) => (
            <PillLink
              key={`tag-${index}`}
              to={this.getTagUrl(label)}
              aria-label={`Go to tag ${label}`}
              disabled={!isEditable}
              onRemove={() => this.handlePillRemove(label)}
              title={label}
            >
              {label}
            </PillLink>
          ))}
          <Label
            className="visually-hidden"
            htmlFor="add-new-label"
            display-if={isCreating}
          >
            Tag
          </Label>
          <TextField
            id="add-new-label"
            testId="add-new-label"
            name="label"
            type="text"
            required
            display-if={isCreating && isEditable}
            onEnter={this.handleTextFieldEnter}
            onKeyDown={this.handleTextFieldKeyDown}
            onBlur={this.handleTextFieldBlur}
            placeholder="Add a tag..."
            className={classNames(styles.textfield, {
              [styles.error]: !isValid
            })}
            isValid={isValid}
            autoFocus
            onChange={this.handleTextFieldChange}
            value={value}
            maxLength={LabelsList.maxLabelLength}
          />
          <div
            role="alert"
            data-test-id="label-error-message"
            display-if={!isValid && isCreating && isEditable}
            className={classNames(styles.errorMessage)}
          >
            {errorMessage}
          </div>
          <AddPillButton
            display-if={!isCreating && isEditable}
            ref={this.addTagRef}
            data-test-id="add-label"
            className={styles.action}
            onClick={this.handleNewLabelClick}
          >
            Add a tag
          </AddPillButton>
        </div>
      </section>
    );
  }
}

export default LabelsList;
