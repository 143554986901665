import { format } from 'url';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { EVENT_TYPES } from '~/analytics/events';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import { selectors as searchSelectors } from '~/domains/search';
import { selectors as commonSelectors } from '~/domains/common';
import { parseSearch } from '~/utils/location';
import {
  ACTIVE_ORGANIZATION_LINK_ID,
  ALL_ORGANIZATION_LINK_ID,
  ALL_ASSETS_LINK_ID
} from '~/utils/navigation/constants';
import Sorting from './Sorting';

const mapStateToProps = (state, { location }) => {
  const query = parseSearch(location.search);
  const profile = commonSelectors.profile(state);
  const isAuthenticated = !!profile;

  const defaultSort = 'last';

  return {
    sort: query.sort || defaultSort,
    show: query.show,
    search: query.search,
    defaultSort,
    isANGSearchEnabled: searchSelectors.isANGSearchEnabled(state),
    isAuthenticated,
    profile
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  const query = parseSearch(location.search);

  return {
    onChange: (sort, profile) => {
      const newQuery = { ...query, sort };
      const isLandingPage = Object.keys(query).length === 0;
      const masterOrganization = profile?.organization;
      const onlyOneBG = !masterOrganization?.subOrganizationIds?.length;

      if (!newQuery.show && profile) {
        if (isLandingPage) {
          if (onlyOneBG) {
            newQuery.show = ACTIVE_ORGANIZATION_LINK_ID;
          } else {
            newQuery.show = ALL_ORGANIZATION_LINK_ID;
          }
        } else {
          newQuery.show = ALL_ASSETS_LINK_ID;
        }
      }

      const newURL = format({
        pathname: location.pathname,
        query: newQuery
      });

      dispatch(
        analyticsActions.trackEvent(EVENT_TYPES.SORTING_CHANGE, { sort })
      );
      dispatch(push(newURL));
    }
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onChange: (sort) => dispatchProps.onChange(sort, stateProps.profile)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  })
)(Sorting);
