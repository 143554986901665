import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mulesoft/anypoint-components/lib/Button';
import LaunchIcon from '@mulesoft/anypoint-icons/lib/svg/deploy-small.svg';
import Announcement from '~/components/Announcements/Announcement';
import styles from './ANGSearchOptOut.css';

const componentName = 'ANGSearchOptOut';

const ANGSearchOptOut = ({ onDeactivateANGSearch, onClose }) => {
  const icon = <LaunchIcon />;

  return (
    <Announcement
      onClose={onClose}
      icon={icon}
      name={componentName}
      theme="grey"
    >
      Welcome to the new search experience! You can switch back at any time.
      <Button
        className={styles.button}
        testId="announcement-activate-ang-button"
        onClick={onDeactivateANGSearch}
        kind="secondary"
        noFill
      >
        Back to legacy search
      </Button>
    </Announcement>
  );
};

ANGSearchOptOut.displayName = componentName;
ANGSearchOptOut.propTypes = {
  onDeactivateANGSearch: PropTypes.func,
  onClose: PropTypes.func
};

export default ANGSearchOptOut;
