import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mulesoft/exchange-ui-components/lib/components/Table';
import tableStyles from '../ApiGroupInstance/ApiGroupInstance.css';
import styles from './ApiGroupEmptyInstance.css';

const ApiGroupEmptyInstance = ({ testId }) => {
  return (
    <React.Fragment>
      <Table
        className={tableStyles.table}
        headers={[
          { label: 'API' },
          { label: 'Instance name' },
          { label: 'Environment' },
          { label: 'Instance URL' }
        ]}
        rows={[]}
        testId={`${testId}-table`}
      />
      <span className={styles.zeroApiInstances}>
        There are no public instances to show
      </span>
    </React.Fragment>
  );
};

ApiGroupEmptyInstance.propTypes = {
  testId: PropTypes.string
};

ApiGroupEmptyInstance.defaultProps = {
  testId: 'api-group-empty-instance'
};

export default ApiGroupEmptyInstance;
