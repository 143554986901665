/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  AnalyticsContext,
  AssetPortalContext,
  AssetTypesConfigurationContext,
  ModalContext,
  useAnalyticsTracking
} from '@mulesoft/exchange-react-hooks';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import { selectors } from '@mulesoft/exchange-ui-portals-store';
import { EVENT_TYPES } from '~/analytics/events';
import { buildAssetContext } from '~/portals';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import AssetDetailWrapper from '../AssetDetailWrapper';
import { usePortalsConfiguration } from './hooks';

const getAssetProps = (asset) => ({
  organizationId: asset.organization.id,
  groupId: asset.groupId,
  assetId: asset.assetId,
  version: asset.version,
  versionGroup: asset.versionGroup,
  name: asset.name,
  type: asset.type,
  status: asset.status,
  isContributor: asset.permissions?.includes('edit'),
  isAdmin: asset.permissions?.includes('admin'),
  originalFormat: asset.originalFormat,
  originalFormatVersion: asset.originalFormatVersion
});

const getTrackEvent = memoize((analyticsContext, asset, isPIRAEnabled) => {
  const trackEvent = (event, properties) =>
    analyticsContext.trackEvent(event, {
      isPIRAEnabled,
      ...getAssetProps(asset),
      ...properties
    });

  return { ...analyticsContext, trackEvent };
});

const AnalyticsWrapper = ({ asset, isPIRAEnabled, children }) => {
  return (
    <AnalyticsContext.Consumer>
      {(analyticsContext) => (
        <AnalyticsContext.Provider
          value={getTrackEvent(analyticsContext, asset, isPIRAEnabled)}
        >
          {children}
        </AnalyticsContext.Provider>
      )}
    </AnalyticsContext.Consumer>
  );
};

AnalyticsWrapper.propTypes = {
  asset: AssetPropType,
  isPIRAEnabled: PropTypes.bool,
  children: PropTypes.node
};

const AssetDetailLayout = ({
  route,
  routes,
  asset,
  assetContext,
  minorVersions,
  isPageComponent,
  isTypeLoaded,
  status
}) => {
  const assetTypesConfiguration = React.useContext(
    AssetTypesConfigurationContext
  );
  const trackEvent = useAnalyticsTracking();
  const isPIRAEnabled = useSelector(selectors.selectIsPIRAEnabled);
  const isPublicPortal = useSelector(selectors.selectIsPublicPortal);
  const isStudio = useSelector(selectors.selectIsStudio);
  const profile = useSelector(selectors.selectProfile);
  const isMarketingSite = useSelector(sessionSelectors.isMarketingSite);
  const { portalConfiguration } = usePortalsConfiguration({
    asset,
    route
  });
  const [trackedGAV, setTrackedGAV] = React.useState();

  React.useEffect(() => {
    if (!asset) {
      return;
    }

    const gav = `${asset.groupId}:${asset.assetId}:${asset.version}`;

    if (asset.isCompleted && minorVersions !== null && trackedGAV !== gav) {
      const minorProperties = {
        minorVersion: asset.minorVersion,
        minorVersionCount: minorVersions.length,
        minorVersionPosition: minorVersions
          .map((minor) => minor.minorVersion)
          .indexOf(asset.minorVersion)
      };

      setTrackedGAV(gav);

      trackEvent(EVENT_TYPES.ASSET_DETAIL_LOADED, {
        context: assetContext,
        isPIRAEnabled,
        isStudio,
        isPublicPortal,
        isMarketingSite,
        hasProfile: !!profile,
        ...getAssetProps(asset),
        ...minorProperties
      });
    }
  }, [
    asset,
    assetContext,
    isPIRAEnabled,
    isStudio,
    isPublicPortal,
    isMarketingSite,
    profile,
    minorVersions,
    trackedGAV,
    trackEvent
  ]);

  if (!asset) {
    return null;
  }

  const { isDraft = false, isEdit = false } = route;

  const assetPortalContext = buildAssetContext(
    asset,
    isDraft,
    isEdit,
    isTypeLoaded ? portalConfiguration : null,
    assetTypesConfiguration
  );
  const modifiedOpenModalFn =
    (openModalFn) => (modalIdentifier, modalProps, trackPayloadProps) => {
      if (typeof modalIdentifier === 'string') {
        openModalFn(modalIdentifier, modalProps, trackPayloadProps);

        return;
      }

      const WrappedModal = (props) => {
        const isModalTypeLoaded = useSelector((state) =>
          reducerManagerSelectors.isTypeLoaded(state, asset?.type)
        );
        const modalAsset =
          useSelector((state) =>
            assetsSelectors.asset(state, {
              groupId: asset.groupId,
              assetId: asset.assetId,
              minorVersion: asset.minorVersion,
              status
            })
          ) ?? asset;
        const { portalConfiguration: modalPortalConfiguration } =
          usePortalsConfiguration({
            asset: modalAsset,
            route,
            disableEvents: true
          });
        const modalAssetPortalContext = buildAssetContext(
          modalAsset,
          isDraft,
          isEdit,
          isModalTypeLoaded ? modalPortalConfiguration : null,
          assetTypesConfiguration
        );

        return (
          <AssetPortalContext.Provider value={modalAssetPortalContext}>
            <AnalyticsWrapper asset={modalAsset} isPIRAEnabled={isPIRAEnabled}>
              <ModalContext.Consumer>
                {(modalContext) => (
                  <ModalContext.Provider
                    value={{
                      ...modalContext,
                      openModal: modifiedOpenModalFn(modalContext.openModal),
                      pushModal: modifiedOpenModalFn(modalContext.pushModal)
                    }}
                  >
                    {React.createElement(modalIdentifier, props)}
                  </ModalContext.Provider>
                )}
              </ModalContext.Consumer>
            </AnalyticsWrapper>
          </AssetPortalContext.Provider>
        );
      };

      WrappedModal.modalName = modalIdentifier.modalName;
      openModalFn(WrappedModal, modalProps, trackPayloadProps);
    };

  return (
    <AssetPortalContext.Provider value={assetPortalContext}>
      <ModalContext.Consumer>
        {(modalContext) => (
          <ModalContext.Provider
            value={{
              ...modalContext,
              openModal: modifiedOpenModalFn(modalContext.openModal)
            }}
          >
            <AnalyticsWrapper asset={asset} isPIRAEnabled={isPIRAEnabled}>
              <AssetDetailWrapper
                route={route}
                routes={routes}
                asset={asset}
                isPageComponent={isPageComponent}
              />
            </AnalyticsWrapper>
          </ModalContext.Provider>
        )}
      </ModalContext.Consumer>
    </AssetPortalContext.Provider>
  );
};

AssetDetailLayout.propTypes = {
  route: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object),
  asset: PropTypes.object,
  assetContext: PropTypes.string,
  minorVersions: PropTypes.arrayOf(PropTypes.object),
  isTypeLoaded: PropTypes.bool,
  isPageComponent: PropTypes.bool,
  status: PropTypes.string
};

export default AssetDetailLayout;
