import debug from 'debug';
import { fetch } from '~/utils/fetch';
import { getGAVKey } from '~/utils/types';
import { getAPIUrl } from '~/utils/api';

const log = debug('exchange:categories:service');

export async function list({ organizationId, context } = {}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/categories`
  });

  log(`Listing categories for org id ${organizationId}`, {
    context
  });

  const response = await fetch(url, { context });

  return response.json();
}

export async function create({
  organizationId,
  displayName,
  acceptedValues,
  assetTypeRestrictions,
  context
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/categories`
  });
  const body = JSON.stringify({
    displayName,
    acceptedValues,
    assetTypeRestrictions
  });

  log(`Creating category for org id ${organizationId}`, {
    context,
    displayName,
    acceptedValues,
    assetTypeRestrictions
  });

  const response = await fetch(url, { body, context, method: 'POST' });

  return response.json();
}

export function update({
  organizationId,
  tagKey,
  displayName,
  acceptedValues,
  assetTypeRestrictions,
  context,
  options: { forceUpdate = false } = {}
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/categories/${encodeURIComponent(
      tagKey
    )}`
  });
  const body = JSON.stringify({
    displayName,
    acceptedValues,
    assetTypeRestrictions
  });
  const headers = forceUpdate ? { 'x-force-update': true } : {};

  log(`Updating category with id ${tagKey} for org id ${organizationId}`, {
    context,
    displayName,
    acceptedValues,
    assetTypeRestrictions
  });

  return fetch(url, { body, context, headers, method: 'PATCH' });
}

export function remove({ organizationId, tagKey, context, isForceDelete }) {
  const headers = isForceDelete ? { 'x-force-delete': true } : {};
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/categories/${encodeURIComponent(
      tagKey
    )}`
  });

  log(`Removing category ${tagKey} for org id ${organizationId}`, {
    context
  });

  return fetch(url, { context, method: 'DELETE', headers });
}

export function removeAssetCategory({
  organizationId,
  groupId,
  assetId,
  version,
  category,
  context
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/assets/${groupId}/${assetId}/${version}/tags/categories/${encodeURIComponent(
      category.key
    )}`
  });

  log(
    `Removing asset category ${category.key} from asset ${getGAVKey({
      groupId,
      assetId,
      version
    })}`,
    {
      context
    }
  );

  return fetch(url, { context, method: 'DELETE' });
}

export async function updateAssetCategory({
  organizationId,
  groupId,
  assetId,
  version,
  category,
  context
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationId}/assets/${groupId}/${assetId}/${version}/tags/categories/${encodeURIComponent(
      category.key
    )}`
  });

  const body = JSON.stringify({
    tagValue: category.value
  });

  log(
    `Updating category ${category.key} for org id ${getGAVKey({
      groupId,
      assetId,
      version
    })}`,
    {
      context,
      tagValues: category.values
    }
  );

  const response = await fetch(url, { body, context, method: 'PUT' });

  return response.json();
}
