import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Pill from '@mulesoft/anypoint-components/lib/Pill';
import styles from './PillLink.css';

const PillLink = React.memo(({ to, children, ...props }) => (
  <Link className={styles.pill} to={to}>
    <Pill {...props} className={styles.pillTag}>
      <span className={styles.pillSpan}>{children}</span>
    </Pill>
  </Link>
));

PillLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
};

export default PillLink;
