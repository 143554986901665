import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { goBack, push } from 'connected-react-router';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { withPageTracking, withTimeTracking } from '~/components/Analytics';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import {
  actions as commonActions,
  selectors as commonSelectors
} from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import * as routesUtils from '~/utils/routes';
import App from './App';

const mapStateToProps = (state, { location, route }) => {
  const {
    route: { id: routeId }
  } = routesUtils.getCurrentRoute(route, location);

  return {
    isHome: routeId === 'home',
    profile: commonSelectors.profile(state),
    error: commonSelectors.error(state),
    alert: commonSelectors.alert(state),
    header: commonSelectors.header(state),
    isStudio: commonSelectors.isStudio(state),
    contextPath: commonSelectors.contextPath(state),
    featureFlags: featureFlagsSelectors.featureFlags(state),
    previousRoutesSection: commonSelectors.previousRoutesSection(state),
    isPortalReArchitectureEnabled: sessionSelectors.isPIRAEnabled(state),
    routingContext: commonSelectors.routingContext(state),
    location
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCloseAlert: () => dispatch(commonActions.closeAlert()),
  onSignout: () => {
    dispatch(analyticsActions.analyticsLogout());
    dispatch(commonActions.logout());
  },
  goBack: () => dispatch(goBack()),
  push: (path) => dispatch(push(path)),
  setDocumentMeta: (header) => dispatch(commonActions.setHeader(header))
});

export default compose(
  withRouter,
  withPageTracking,
  withTimeTracking,
  connect(mapStateToProps, mapDispatchToProps, null, {
    context: RootStoreContext
  })
)(App);
