import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorBoundary, SkipLinks } from '@mulesoft/exchange-ui-components';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import DeprecationHeader from '~/components/AssetDetail/DeprecationHeader';
import DraftHeader from '~/components/AssetDetail/DraftHeader';
import Header from '~/components/AssetDetail/Header/Header.container';
import Reviews from '~/components/AssetDetail/Reviews';
import Description from '~/components/AssetDetail/Description';
import styles from './AssetDetail.css';

const AssetDetail = ({
  asset,
  children = null,
  canEdit,
  hideRating,
  isDraft,
  isEdit,
  isHomePage,
  isTypeLoaded,
  portal = {
    draftStatus: 'PUBLISHED'
  },
  isPublicPortal,
  openModal,
  onDiscardDraft,
  onRatingChange,
  profile,
  route,
  showHeaderActions = false,
  isPortalReArchitectureEnabled
}) => {
  if (isPortalReArchitectureEnabled) {
    return (
      <article
        data-test-id="asset-content"
        data-type-loaded={isTypeLoaded}
        aria-label={asset.name}
        className={classNames(styles.assetContentArticle, {
          [styles.isEdit]: isEdit
        })}
      >
        <SkipLinks
          links={[
            {
              id: 'version-agnostic-information',
              text: 'Jump to basic asset info'
            },
            { id: 'asset-page-content', text: 'Jump to page content' },
            {
              id: 'asset-minor-versions-dropdown',
              text: 'Jump to version navigation'
            },
            { id: 'sidebar', text: 'Jump to sidebar' },
            { id: 'reviews-list', text: 'Jump to reviews' },
            { id: 'asset-actions', text: 'Jump to actions' }
          ]}
        />
        <ErrorBoundary>{children}</ErrorBoundary>
        <Reviews
          display-if={!isEdit && !isDraft && !hideRating}
          asset={asset}
          onRatingChange={onRatingChange}
        />
      </article>
    );
  }

  return (
    <article
      data-test-id="asset-content"
      data-type-loaded={isTypeLoaded}
      aria-label={asset.name}
      className={classNames({
        [styles.isEdit]: isEdit
      })}
    >
      <SkipLinks
        links={[
          { id: 'asset-header', text: 'Jump to basic asset info' },
          { id: 'asset-page-content', text: 'Jump to page content' },
          { id: 'sidebar', text: 'Jump to sidebar' },
          { id: 'reviews-list', text: 'Jump to reviews' },
          { id: 'asset-detail', text: 'Jump to detail' },
          { id: 'asset-actions', text: 'Jump to actions' }
        ]}
      />
      <DraftHeader
        display-if={canEdit}
        asset={asset}
        isDraft={isDraft}
        isEdit={isEdit}
        onDiscardDraft={onDiscardDraft}
        hasUnpublishedChanges={portal.draftStatus !== 'PUBLISHED'}
      />
      <DeprecationHeader
        display-if={asset.status === 'deprecated' && !isDraft}
        isAdmin={canEdit}
        asset={asset}
        openModal={openModal}
      />
      <Header
        asset={asset}
        showActions={showHeaderActions}
        canEdit={canEdit}
        isDraft={isDraft}
        isEdit={isEdit}
        isPublicPortal={isPublicPortal}
        hideRating={hideRating}
        hideReviewLink={hideRating}
        profile={profile}
        route={route}
      />

      <Description display-if={isHomePage} asset={asset} canEdit={canEdit} />
      <div className={styles.content}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>

      <Reviews
        display-if={!isEdit && !isDraft && !hideRating}
        asset={asset}
        onRatingChange={onRatingChange}
      />
    </article>
  );
};

AssetDetail.propTypes = {
  asset: AssetPropType,
  children: PropTypes.node,
  hideRating: PropTypes.bool,
  isDraft: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isEdit: PropTypes.bool,
  showHeaderActions: PropTypes.bool,
  isTypeLoaded: PropTypes.bool,
  isPublicPortal: PropTypes.bool,
  isPortalReArchitectureEnabled: PropTypes.bool,
  canEdit: PropTypes.bool,
  portal: PropTypes.object,
  profile: PropTypes.object,
  openModal: PropTypes.func,
  onDiscardDraft: PropTypes.func,
  onRatingChange: PropTypes.func,
  route: PropTypes.object
};

export default AssetDetail;
