export default {
  NEW_ASSET_CLICKED: 'New asset clicked',
  GRID_VIEW_CLICKED: 'Grid view clicked',
  LIST_VIEW_CLICKED: 'List view clicked',
  RESULTS_END_REACHED: 'Results end reached',
  FILTERS_CLEARED: 'Filters cleared',
  SORTING_CHANGE: 'Sorting change',
  HOME_PAGE_LOADED_BY_MASTER_ORG_ADMIN:
    'Home Page loaded - by Master Org Exchange Admin',
  HOME_PAGE_LOADED: 'Home Page loaded'
};
