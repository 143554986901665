/*
  eslint
    react/prop-types: 0,
*/

import PublicPortalApp from '~/components/PublicPortalApp';
import NotFound from '~/components/NotFound';

import CustomLayout from '~/layouts/common/Custom';
import Home from '~/layouts/public/Home';
import ClientApplicationList from '~/layouts/public/ClientApplicationList';
import ClientApplicationDetail from '~/layouts/public/ClientApplicationDetail';
import CustomPage from '~/layouts/public/CustomPage';
import AssetDetail from '~/layouts/public/AssetDetail';
import Page from '~/layouts/public/AssetDetail/Page';
import EditHome from '~/layouts/public/EditHome';
import EditClientApplicationList from '~/layouts/public/EditClientApplicationList';
import EditCustomPage from '~/layouts/public/EditCustomPage';
import APIGroupDetail from '~/layouts/public/APIGroupDetail';

import { redirect } from '~/routes/utils';
import { removeTrailingSlashes } from '~/utils/url';

function setSectionId(routesList) {
  routesList.forEach((route) => {
    // eslint-disable-next-line no-param-reassign
    route.sectionId = 'assetPortal';

    if (route.routes) {
      setSectionId(route.routes);
    }
  });

  return routesList;
}

// eslint-disable-next-line react/prop-types
export default (context) => {
  const rootPath = context.isForwardedPath
    ? `${removeTrailingSlashes(context.path)}/`
    : `${context.path}/portals/:domain/`;
  const versionGroupPath = `${rootPath}:groupId/:assetId/version/:versionGroup/`;
  const minorVersionPath = `${rootPath}:groupId/:assetId/minor/:minorVersion/`;
  const versionPath = `${rootPath}:groupId/:assetId/:version([0-9]+\\.[0-9]+\\.[0-9]+[a-zA-Z0-9\\-\\.]*)/`;

  const getPageRoutes = (basePath) => [
    {
      name: 'Asset Portal Page',
      path: basePath,
      exact: true,
      id: 'page',
      component: Page
    },
    {
      name: 'Asset Portal Page',
      path: `${basePath}pages/:pagePath+/`,
      exact: true,
      component: Page,
      id: 'page'
    }
  ];
  const getAssetDetailRoutes = (basePath, includeRoot) => {
    const result = [
      {
        name: 'Asset Detail',
        path: includeRoot ? `${rootPath}:groupId/:assetId/` : basePath,
        component: AssetDetail,
        id: 'assetDetail',
        routes: [
          ...(includeRoot
            ? [
                {
                  path: `${rootPath}:groupId/:assetId/`,
                  exact: true,
                  name: 'Asset Portal Page',
                  id: 'page',
                  component: Page
                }
              ]
            : []),
          ...getPageRoutes(basePath),
          {
            name: 'Asset API Group detail',
            path: `${basePath}detail/`,
            exact: true,
            component: APIGroupDetail,
            id: 'apiGroup'
          },
          {
            path: basePath,
            component: CustomLayout,
            isPublicPortal: true,
            isDraft: false
          }
        ]
      }
    ];

    return setSectionId(result);
  };

  const routes = [
    {
      component: PublicPortalApp,
      path: rootPath,
      routes: [
        {
          path: rootPath,
          exact: true,
          name: 'Portal Home',
          id: 'home',
          component: Home
        },
        {
          path: `${rootPath}applications/`,
          exact: true,
          name: 'Client Applications List',
          id: 'applications',
          sectionId: 'applications',
          component: ClientApplicationList
        },
        {
          path: `${rootPath}applications/:applicationId/`,
          exact: true,
          name: 'Client Applications Detail',
          id: 'applicationDetail',
          sectionId: 'applications',
          component: ClientApplicationDetail
        },
        {
          name: 'View Custom Page',
          path: `${rootPath}pages/:pagePath/`,
          component: CustomPage,
          id: 'customPage',
          sectionId: 'customPage'
        },
        {
          name: 'Portal Home Customization',
          path: `${rootPath}customize/`,
          exact: true,
          component: EditHome,
          id: 'homeEdit',
          sectionId: 'home'
        },
        {
          name: 'Customize Client Applications List',
          path: `${rootPath}customize/applications/`,
          exact: true,
          component: EditClientApplicationList,
          id: 'applicationsEdit',
          sectionId: 'applications'
        },
        {
          name: 'Edit Custom Page',
          path: `${rootPath}customize/pages/:pagePath/`,
          exact: true,
          component: EditCustomPage,
          id: 'customPageEdit',
          sectionId: 'customPage'
        },
        redirect({
          from: `${rootPath}:groupId/:assetId/api/:versionGroup/console/summary/`,
          to: `${rootPath}:groupId/:assetId/version/:versionGroup/console/summary/`
        }),
        redirect({
          from: `${rootPath}:groupId/:assetId/api/:versionGroup/console/method/:modelId`,
          to: `${rootPath}:groupId/:assetId/version/:versionGroup/console/method/:modelId`
        }),
        redirect({
          from: `${rootPath}:groupId/:assetId/api/:versionGroup/console/:modelType/:modelId/`,
          to: `${rootPath}:groupId/:assetId/version/:versionGroup/console/:modelType/:modelId/`
        }),
        redirect({
          from: `${context.path}/:groupId/:assetId/minor/:minorVersion/policy/usages/`,
          to: `${context.path}/:groupId/:assetId/minor/:minorVersion/usages/`
        }),
        redirect({
          from: `${rootPath}:groupId/:assetId/api/:versionGroup/`,
          to: `${rootPath}:groupId/:assetId/version/:versionGroup/`
        }),
        redirect({
          from: `${rootPath}:groupId/:assetId/api/:versionGroup/pages/:pagePath+/`,
          to: `${rootPath}:groupId/:assetId/version/:versionGroup/pages/:pagePath+/`
        }),
        redirect({
          from: `${rootPath}:groupId/:assetId/api/:versionGroup/settings/`,
          to: `${rootPath}:groupId/:assetId/version/:versionGroup/settings/`
        }),
        ...getAssetDetailRoutes(versionGroupPath),
        ...getAssetDetailRoutes(versionPath),
        ...getAssetDetailRoutes(minorVersionPath, true),
        {
          path: `${context.path}/`,
          component: NotFound
        }
      ]
    }
  ];

  return routes;
};
