import { getModalTarget } from '~/utils/modals';
import * as actionTypes from './actionTypes';

const initialState = {
  modalStack: [],
  targetElementAfterClose: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_MODAL_PROPS: {
      const { modalType, modalProps } = action;

      return {
        ...state,
        modalStack: state.modalStack.map((modalParams) => {
          if (modalParams.modalType !== modalType) {
            return modalParams;
          }

          return {
            ...modalParams,
            modalProps: {
              ...modalParams.modalProps,
              ...modalProps
            }
          };
        })
      };
    }

    case actionTypes.PUSH_MODAL: {
      const { modalType, modalComponent, modalProps } = action;

      return {
        ...state,
        modalStack: [
          { ...getModalTarget({ modalType, modalComponent }), modalProps }
        ].concat(state.modalStack)
      };
    }

    case actionTypes.OPEN_MODAL: {
      const { modalType, modalComponent, modalProps, targetElementAfterClose } =
        action;

      return {
        ...state,
        modalStack: [
          { ...getModalTarget({ modalType, modalComponent }), modalProps }
        ],
        targetElementAfterClose
      };
    }

    case actionTypes.CLOSE_MODAL: {
      return {
        ...state,
        modalStack: state.modalStack.slice(1),
        targetElementAfterClose:
          state.modalStack.length === 1 ? null : state.targetElementAfterClose
      };
    }

    case actionTypes.CLOSE_ALL_MODALS: {
      return {
        ...state,
        modalStack: [],
        targetElementAfterClose: null
      };
    }

    default:
      return state;
  }
}
