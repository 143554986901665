import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ConfirmModal from '@mulesoft/exchange-ui-components/lib/components/ConfirmModal';

class ResetClientSecretConfirmModal extends PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired
  };

  handleCancel = () => {
    const { application, onCancel } = this.props;

    onCancel({
      trackPayloadProps: {
        applicationId: application.id,
        closeReason: 'cancel'
      }
    });
  };

  renderBody() {
    const { application } = this.props;

    return (
      <div>
        <p>
          Are you sure you want to reset client secret for application{' '}
          <strong>{application.name}</strong>?
        </p>
        <p>
          Applications using the existing client secret will no longer be able
          to access any of the APIs for which they had been registered.
        </p>
      </div>
    );
  }

  render() {
    return (
      <ConfirmModal
        title="Reset client secret"
        confirmButtonText="Reset client secret"
        body={this.renderBody()}
        isDanger
        {...this.props}
        onCancel={this.handleCancel}
      />
    );
  }
}

export default ResetClientSecretConfirmModal;
