import config from 'config';
import fetchFactory from '@mulesoft/anypoint-fetch';
import { isClient } from '~/utils/window';

export const fetch = fetchFactory({
  config: {
    ignoreContextValidation: true,
    userAgent: isClient() ? null : config.userAgent,
    credentials: 'same-origin'
  }
});

export const defaultHeaders = {
  accept: 'application/json',
  'user-agent': config.userAgent
};
