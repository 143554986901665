import 'core-js/stable';
import 'focus-visible/dist/focus-visible';
import 'regenerator-runtime/runtime';
import 'wicg-inert/dist/inert';

const isSafari = /^((?!chrome|android).)*safari/i.test(
  global.navigator.userAgent
);

if (isSafari) {
  const getOwnPropertyFn = Object.getOwnPropertyDescriptor;

  Object.getOwnPropertyDescriptor = (obj, prop) => {
    const result = getOwnPropertyFn(obj, prop);

    if (result) {
      return result;
    }

    // workaround to fix Polymer component breaking React in Safari
    if (!result && obj && obj.is === 'iron-input' && obj.extends === 'input') {
      return {};
    }

    return result;
  };
}
