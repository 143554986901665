import config from 'config';
import equal from 'deep-equal';
import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';

const rendersMap = {};

const compareProps = (props, nextProps, componentName, isState = false) => {
  Object.keys(props).forEach((propKey) => {
    if (
      props[propKey] !== nextProps[propKey] &&
      equal(props[propKey], nextProps[propKey])
    ) {
      const rendersMapKey = `${componentName}:${propKey}:${
        isState ? 'state' : 'prop'
      }`;

      const timeout = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.warn(
          `Warning: ${
            isState ? 'state ' : ''
          } prop "${propKey}" in component ${componentName} is deep equal but not the same instance and is causing unneeded re-renders${
            rendersMap[rendersMapKey].count > 1
              ? ` (actually ${rendersMap[rendersMapKey].count})`
              : ''
          }`
        );

        delete rendersMap[rendersMapKey];
      }, 10);

      if (!rendersMap[rendersMapKey]) {
        rendersMap[rendersMapKey] = {
          count: 1,
          timeout
        };
      } else {
        clearTimeout(rendersMap[rendersMapKey].timeout);

        rendersMap[rendersMapKey].count += 1;
        rendersMap[rendersMapKey].timeout = timeout;
      }
    }
  });
};

export class PureComponentPerf extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      shallowCompare(this.props, nextProps) ||
      shallowCompare(this.state, nextState);
    const componentName = this.constructor.displayName || this.constructor.name;

    if (shouldUpdate) {
      compareProps(this.props, nextProps, componentName);

      if (this.state && nextState) {
        compareProps(this.state, nextState, componentName, true);
      }
    }

    return shouldUpdate;
  }
}

// eslint-disable-next-line no-underscore-dangle
if (process.env.__DEVELOPMENT__ && config.isPerfAuditEnabled) {
  // eslint-disable-next-line no-console
  console.info('Performance audits are enabled');

  React.PureComponent = PureComponentPerf;
}
