const ENTER_KEY_CODE = 13;

export const isClick = (event) => event.type === 'click';

export const isEnterKeyDown = (event) => {
  return event.type === 'keydown' && event.keyCode === ENTER_KEY_CODE;
};

export const onEnterKey = (callback) => (event) => {
  if (event.keyCode === ENTER_KEY_CODE && callback) {
    event.preventDefault();

    callback(event);
  }

  return null;
};

export const preventDefault = (event) => event.preventDefault();
