import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as pagesSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';

export function redirectIfDefaultPageEmpty({
  targetPath,
  pushFn,
  ...assetParams
}) {
  return (dispatch, getState) => {
    const state = getState();
    const page = pagesSelectors.page(state, assetParams);
    const asset = assetsSelectors.asset(state, assetParams);

    const isAssetHomeEmpty = page.html === '';

    const canEditAsset =
      asset.permissions &&
      asset.permissions.find((permission) =>
        ['edit', 'admin'].includes(permission)
      );

    if (isAssetHomeEmpty && !canEditAsset && asset.type === 'rest-api') {
      pushFn(targetPath);
    }
  };
}
