import PropTypes from 'prop-types';
import React from 'react';
import AssetCard from '~/components/Home/AssetCard';

class AssetCardWrapper extends React.Component {
  static propTypes = {
    onAssetClick: PropTypes.func,
    organization: PropTypes.object,
    groupId: PropTypes.string,
    assetId: PropTypes.string,
    type: PropTypes.string
  };

  onNavigateAsset = () => {
    const { organization, groupId, assetId, type } = this.props;

    this.props.onAssetClick({ organization, groupId, assetId, type });
  };

  render() {
    const { organization, onAssetClick, ...props } = this.props;

    return (
      <AssetCard
        onNavigateAsset={this.onNavigateAsset}
        organization={organization.name}
        organizationId={organization.id}
        {...props}
      />
    );
  }
}

export default AssetCardWrapper;
