import { compose } from 'redux';
import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as commonSelectors } from '~/domains/common';
import { actions as modalActions } from '~/domains/modal';
import {
  actions as clientApplicationsActions,
  selectors as clientApplicationsSelectors
} from '~/domains/clientApplications';
import withLayoutContext from '~/components/LayoutContextProvider';
import { setModalType, registerModal } from '~/components/Modals';
import DeleteApplicationConfirmModal from './DeleteApplicationConfirmModal';

const mapStateToProps = (state) => ({
  profile: commonSelectors.profile(state),
  isLoading: clientApplicationsSelectors.isDeletingApplication(state)
});

const mapDispatchToProps = (dispatch) => ({
  onCancel: (params) => dispatch(modalActions.closeModal(params)),
  onConfirm: (application, listPath) =>
    dispatch(
      clientApplicationsActions.deleteClientApplication(application, listPath)
    )
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onConfirm: () =>
    dispatchProps.onConfirm(
      ownProps.application,
      ownProps.getPath('applications', {
        organization: stateProps.profile.organization
      })
    )
});

export default compose(
  registerModal,
  setModalType('DeleteApplicationConfirmModal'),
  withLayoutContext(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  })
)(DeleteApplicationConfirmModal);
