export function getNameValidations(currentName, items) {
  return {
    isNotEmpty: {
      validate: ({ value }) => !!value,
      message: 'Saved search should not be empty'
    },
    nonExistentName: {
      validate: ({ value }) =>
        value === currentName ||
        !items.some((savedSearch) => savedSearch.name === value),
      message: 'Saved search already exists'
    },
    isTrimmed: {
      validate: ({ value }) => value && value.trim() === value,
      message: 'Saved search should be trimmed'
    }
  };
}
