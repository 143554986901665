import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import DownloadIcon from '@mulesoft/anypoint-icons/lib/svg/download-small.svg';
import ArrowIcon from '@mulesoft/anypoint-icons/lib/svg/arrow-down-small.svg';
import Popover from '@mulesoft/anypoint-components/lib/Popover';
import MenuItem from '@mulesoft/anypoint-components/lib/MenuItem';
import Menu from '@mulesoft/anypoint-components/lib/Menu';
import Tooltip from '@mulesoft/anypoint-components/lib/Tooltip';
import {
  AssetPortalContext,
  RouterContext,
  UserContext
} from '@mulesoft/exchange-react-hooks';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import { getTypeFiles } from '~/portals/files';
import * as filesUtils from '~/utils/files';
import styles from './Download.css';

const buttonStyles = Button.themes.DefaultTheme;

const Download = ({ asset, onDownload }) => {
  const { isStudio, studioMetadata } = React.useContext(UserContext);
  const { getPath } = React.useContext(RouterContext);
  const portalConfiguration = React.useContext(AssetPortalContext);

  const popoverContainer = React.useRef(null);

  if (!asset?.files) {
    return null;
  }

  const handleDownload = (event, file) => {
    event.preventDefault();

    if (!file.url) {
      onDownload(asset, isStudio, file);
    }

    filesUtils.download(file, isStudio, getPath);
  };

  const { action = 'Download', files } = getTypeFiles(
    portalConfiguration,
    asset,
    studioMetadata,
    'public-portal-actions'
  );
  const getMenuItemContent = (file) => {
    const disabledTooltip = 'File is not available.';
    const menuItemText = file.dropdownLabel || `As ${file.label}`;

    if (file.isDisabled) {
      return (
        <Tooltip
          position="left"
          content={<div className={styles.tooltip}>{disabledTooltip}</div>}
        >
          <span className={classNames(styles.label, styles.disabledItem)}>
            {menuItemText}
          </span>
        </Tooltip>
      );
    }

    return (
      <a
        href={filesUtils.getFileURL(file, getPath)}
        className={styles.popoverAnchor}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => handleDownload(event, file)}
        onKeyDown={eventHandlerUtils.onEnterKey((event) =>
          handleDownload(event, file)
        )}
      >
        {menuItemText}
      </a>
    );
  };

  if (!files.length) {
    return null;
  }

  if (files.length === 1) {
    return (
      <div>
        <a
          data-test-id="asset-action-download"
          className={classNames(
            styles.action,
            buttonStyles.button,
            buttonStyles.md,
            buttonStyles.noFill,
            buttonStyles.tertiary
          )}
          href={filesUtils.getFileURL(files[0], getPath)}
          onClick={(event) => handleDownload(event, files[0])}
        >
          <Icon size="xs">
            <DownloadIcon />
          </Icon>
          <span className={styles.label}>{action}</span>
        </a>
      </div>
    );
  }

  return (
    <div ref={popoverContainer}>
      <Popover
        getPopoverContainer={() => popoverContainer?.current}
        testId="asset-action-download-many"
        anchorPosition="br"
        popupPosition="tr"
        triggerOn={['click']}
        content={
          <Menu>
            {files.map((file) => (
              <MenuItem
                testId={`${file.classifier}-file`}
                key={file.classifier}
                disabled={file.isDisabled}
              >
                {getMenuItemContent(file)}
              </MenuItem>
            ))}
          </Menu>
        }
      >
        <div
          className={classNames(
            styles.action,
            styles.download,
            buttonStyles.button,
            buttonStyles.md,
            buttonStyles.noFill,
            buttonStyles.tertiary
          )}
        >
          <Icon size="xs">
            <DownloadIcon />
          </Icon>
          <span className={styles.label}>{action}</span>
          <Icon size="xs">
            <ArrowIcon />
          </Icon>
        </div>
      </Popover>
    </div>
  );
};

Download.propTypes = {
  asset: PropTypes.object,
  onDownload: PropTypes.func
};

export default Download;
