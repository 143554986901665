import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import { EVENT_TYPES } from '~/analytics/events';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import { selectors as localStorageSelectors } from '~/domains/localStorage';
import {
  selectors as searchSelectors,
  actions as searchActions
} from '~/domains/search';
import { actions as modalActions } from '~/domains/modal';
import { getPresetLinkToEnabledMap } from '~/utils/navigation';
import Sidebar from './NavigationSidebar';
import ANGOptOutModal from '~/components/Modals/ANGOptOutModal';
import { selectors as customizationSelectors } from '~/domains/customization';

const mapStateToProps = (state, { location }) => {
  const featureFlags = featureFlagsSelectors.featureFlags(state);
  const isReuseMetricsEnabled = sessionSelectors.isMetricsEnabled(state);

  return {
    isInitialized: localStorageSelectors.localStorageIsLoaded(state),
    isPublicPortalEnabled:
      customizationSelectors.isProfilePublicPortalEnabled(state),
    isAnnouncementVisible:
      searchSelectors.isANGSearchOptOutBannerVisible(state) ||
      searchSelectors.isANGSearchOptInBannerVisible(state),
    isANGSearchEnabled: searchSelectors.isANGSearchEnabled(state),
    isReuseMetricsEnabled,
    location,
    profile: commonSelectors.profile(state),
    isStudio: commonSelectors.isStudio(state),
    isANGSearchOptInVisible: searchSelectors.isANGSearchOptInVisible(state),
    isANGSearchOptOutVisible: searchSelectors.isANGSearchOptOutVisible(state),
    presetLinkToEnabledMap: getPresetLinkToEnabledMap(featureFlags)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onActivateANGSearch: () => dispatch(searchActions.optInToANGSearch()),
  onShowANGSearchOptIn: (isAnnouncementVisible) =>
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.ANG_OPT_IN_VIEWED, {
        announcementVisible: isAnnouncementVisible
      })
    ),
  onShowANGSearchOptOut: (isAnnouncementVisible) =>
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.ANG_OPT_OUT_VIEWED, {
        announcementVisible: isAnnouncementVisible
      })
    ),
  onDeactivateANGSearch: () =>
    dispatch(modalActions.openModal({ modalType: ANGOptOutModal.modalType }))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onShowANGSearchOptIn: () =>
    dispatchProps.onShowANGSearchOptIn(stateProps.isAnnouncementVisible),
  onShowANGSearchOptOut: () =>
    dispatchProps.onShowANGSearchOptOut(stateProps.isAnnouncementVisible)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  })
)(Sidebar);
