import { actions as clientApplicationsActions } from '~/domains/clientApplications';
import { selectors as commonSelectors } from '~/domains/common';
import { isLoggedIn } from '~/utils/validations/profile';

const resolve = async ({ dispatch, getState, params }) => {
  const profile = commonSelectors.profile(getState());

  isLoggedIn(profile);

  await dispatch(
    clientApplicationsActions.getClientApplication({
      masterOrganizationId: profile.organization.id,
      applicationId: params.applicationId
    })
  );
};

export default resolve;
