import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import MoreIcon from '@mulesoft/anypoint-icons/lib/svg/more-small.svg';
import EditIcon from '@mulesoft/anypoint-icons/lib/svg/edit-small.svg';
import Tooltip from '@mulesoft/anypoint-components/lib/Tooltip';
import Popover from '@mulesoft/anypoint-components/lib/Popover';
import MenuItem from '@mulesoft/anypoint-components/lib/MenuItem';
import Menu from '@mulesoft/anypoint-components/lib/Menu';
import ApiGroupVisibilityToggle from '~/portals/api-group/Detail/ApiGroupCardTitle/ApiGroupVisibilityToggle';
import { apiManagerApiGroupUri } from '~/portals/api-group/utils';
import { isClick, isEnterKeyDown } from '~/utils/eventHandlers';
import styles from './ApiGroupCardTitle.css';

class ApiGroupCardTitle extends React.Component {
  static propTypes = {
    asset: PropTypes.object,
    apiGroupInstance: PropTypes.object.isRequired,
    organizationDomain: PropTypes.string.isRequired,
    isAdminMode: PropTypes.bool.isRequired,
    testId: PropTypes.string
  };

  static defaultProps = {
    testId: 'api-group-card-title'
  };

  preventCardToggle = (event) => {
    if (isClick(event) || isEnterKeyDown(event)) {
      event.stopPropagation();
    }
  };

  renderName() {
    const { apiGroupInstance, testId } = this.props;
    const { name, deprecated } = apiGroupInstance;

    return (
      <span className={styles.name}>
        {name}
        <Tooltip
          className={styles.tooltip}
          content="It is not possible to request access to deprecated instances."
          display-if={deprecated}
        >
          <span
            data-test-id={`${testId}-deprecated-pill`}
            className={styles.deprecated}
          >
            deprecated
          </span>
        </Tooltip>
      </span>
    );
  }

  renderEnvironmentType() {
    const { apiGroupInstance, isAdminMode } = this.props;
    const { environmentType } = apiGroupInstance;

    return (
      <span display-if={isAdminMode} className={styles.environmentType}>
        <span>Environment type:</span>
        {environmentType || '-'}
      </span>
    );
  }

  renderMenu() {
    const { apiGroupInstance, organizationDomain, testId } = this.props;
    const apiManagerURI = apiManagerApiGroupUri({
      organizationDomain,
      environmentId: apiGroupInstance.environmentId,
      organizationId: apiGroupInstance.organizationId,
      apiGroupInstanceId: apiGroupInstance.id
    });

    return (
      <span
        tabIndex="0"
        role="button"
        aria-label="Open menu"
        onKeyDown={this.preventCardToggle}
        onClick={this.preventCardToggle}
        className={styles.moreOptions}
        ref={(element) => {
          this.popoverContainer = element;
        }}
      >
        <Popover
          getPopoverContainer={() => this.popoverContainer}
          popupPosition="tr"
          anchorPosition="bc"
          triggerOn={['click']}
          testId={`${testId}-popover`}
          content={
            <Menu className={styles.menu}>
              <MenuItem
                testId={`${testId}-api-manager-link`}
                key={`${testId}-api-manager-link`}
              >
                <a
                  className={styles.apiManagerLink}
                  href={apiManagerURI}
                  rel="noopener noreferrer"
                  target="_blank"
                  data-theme="primary"
                >
                  <Icon className={styles.editIcon} size="xs">
                    <EditIcon />
                  </Icon>
                  Edit in API Manager
                </a>
              </MenuItem>
            </Menu>
          }
        >
          <Icon className={styles.moreIcon} size="s">
            <MoreIcon />
          </Icon>
        </Popover>
      </span>
    );
  }

  render() {
    const { asset, apiGroupInstance, isAdminMode, testId } = this.props;

    return (
      <span className={styles.container}>
        {this.renderName()}
        {this.renderEnvironmentType()}
        <div display-if={isAdminMode} className={styles.together}>
          <ApiGroupVisibilityToggle
            asset={asset}
            apiGroupInstance={apiGroupInstance}
            testId={`${testId}-toggle`}
          />
          {this.renderMenu()}
        </div>
      </span>
    );
  }
}

export default ApiGroupCardTitle;
