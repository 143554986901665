import config from 'config';
import memoize from 'memoize-one';
import { parseSearch } from '~/utils/location';
import * as objectsUtils from '~/utils/objects';
import { getPath } from '~/utils/routes';
import { ALL_ASSETS_LINK_ID, ANG_EXTERNAL_QUERY_PARAMS } from './constants';
import { getMuleSoftLink } from './links';
import { getPipeline } from './pipeline';

const defaultLink = {
  name: 'Assets',
  title: 'Assets'
};

/**
 * Get links for a given profile
 * @param {Object} profile
 */
export const getLinks = memoize(
  (profile, location, isANGSearchEnabled, presetLinkToEnabledMap = {}) => {
    const query = parseSearch(location.search);
    const { pathname } = location;
    const isHomePage = getPath('home') === pathname;
    const pipeline = getPipeline(profile, isHomePage);

    const links = pipeline.reduce(
      (accum, adder) =>
        adder({
          links: accum,
          profile,
          query,
          pathname,
          isHomePage,
          isANGSearchEnabled,
          presetLinkToEnabledMap
        }),
      []
    );

    if (links.length) {
      links[0].hierarchy = 0;
    }

    return links;
  }
);

export const getHomeLink = memoize(
  (profile, location, isPublicPortal, featureFlags, presetLinkToEnabledMap) => {
    if (isPublicPortal) {
      return defaultLink;
    }

    const { presetLinks } = config;
    const query = objectsUtils.omit(
      parseSearch(location.search),
      ...ANG_EXTERNAL_QUERY_PARAMS
    );

    const links = getLinks(
      profile,
      location,
      featureFlags?.isANGSearchEnabled,
      presetLinkToEnabledMap
    );
    const link = links.find(({ query: linkQuery }) =>
      objectsUtils.shallowEquals(
        objectsUtils.omit(linkQuery ?? {}, ...ANG_EXTERNAL_QUERY_PARAMS),
        query
      )
    );

    if (link) {
      return link;
    }

    if (profile) {
      return (
        links.find(
          ({ isActive, query: { show } }) =>
            isActive && show !== ALL_ASSETS_LINK_ID
        ) ?? defaultLink
      );
    }

    return (
      presetLinks.find(
        ({ id }) => presetLinkToEnabledMap[id] && id === query.show
      ) || getMuleSoftLink(query, profile)
    );
  }
);

export function getPathAfterOrganizationChange(
  homeQuery,
  activeOrganization,
  newOrganization
) {
  const homePathName = getPath('home');

  if (!homeQuery) {
    return homePathName;
  }

  if (homeQuery.organizationId === activeOrganization) {
    return {
      pathname: homePathName,
      query: {
        organizationId: newOrganization
      }
    };
  }

  if (homeQuery.organizationId === config.mulesoft.id) {
    return {
      pathname: homePathName,
      query: {
        organizationId: config.mulesoft.id
      }
    };
  }

  return homePathName;
}

export const getPresetLinkToEnabledMap = memoize((featureFlags) => {
  return config.presetLinks.reduce(
    (accum, { id, featureFlag }) => ({
      ...accum,
      [id]: featureFlag ? featureFlags[featureFlag] : true
    }),
    {}
  );
});
