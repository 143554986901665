import * as actions from './actionTypes';

const initialState = {
  pendingActions: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_PENDING_ACTION:
      return {
        ...state,
        pendingActions: state.pendingActions.concat(action.action)
      };

    case actions.CLEAN_PENDING_ACTIONS:
      return {
        ...state,
        pendingActions: []
      };

    default:
      return state;
  }
}
