import semver from 'semver';
import config from 'config';
import { omit } from '~/utils/objects';
import { getListTypes } from '~/utils/types';
import { buildFiltersFromPreset } from '~/utils/ang/ANGFilters';
import {
  getActiveOrganizationId,
  getMasterOrganizationId
} from '~/utils/organization';
import {
  ALL_ORGANIZATION_LINK_ID,
  ACTIVE_ORGANIZATION_LINK_ID,
  MULESOFT_ORGANIZATION_LINK_ID,
  SHARED_WITH_ME_LINK_ID
} from '~/utils/navigation/constants';

const organizationSearchFilters = [
  'rootOrganizationId',
  'organizationId',
  'show'
];

const searchFilters = [...organizationSearchFilters, 'sharedWithMe'];

const { presetLinks } = config;

const filtersByShow = (show, profile) => {
  switch (show) {
    case ALL_ORGANIZATION_LINK_ID:
      return {
        rootOrganizationId: getMasterOrganizationId(profile.organization)
      };

    case ACTIVE_ORGANIZATION_LINK_ID:
      return { organizationId: getActiveOrganizationId(profile) };

    case MULESOFT_ORGANIZATION_LINK_ID:
      return profile ? { organizationId: config.mulesoft.id } : {};

    case SHARED_WITH_ME_LINK_ID:
      return { sharedWithMe: true };

    default: {
      const presetLink = presetLinks.find(({ id }) => id === show);

      return presetLink
        ? buildFiltersFromPreset(presetLink.filters, profile)
        : {};
    }
  }
};

// Transform query parameters into filters for assets list
export const queryToFilters = ({
  publicOnly,
  domain,
  query,
  studioType,
  profile
}) => {
  const filters = { ...query };
  const { show } = filters;

  filters.type = studioType || getListTypes(filters.type);

  if (publicOnly && domain) {
    filters.domain = domain;

    return filters;
  }

  const hasOrganizationFilter = organizationSearchFilters.some(
    (organizationFilter) => filters[organizationFilter] !== undefined
  );

  if (show) {
    return { ...filters, ...filtersByShow(show, profile) };
  }

  if (!hasOrganizationFilter && profile && !filters.show) {
    filters.rootOrganizationId = getMasterOrganizationId(profile.organization);
  }

  return filters;
};

export const removeFilters = (query) => omit(query, ...searchFilters);

export function getMinorVersion(version) {
  return `${semver.major(version)}.${semver.minor(version)}`;
}
