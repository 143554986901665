import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import * as requestAccessActions from '@mulesoft/exchange-assets-definitions/lib/common/store/requestAccess/actions';
import RequestAPIGroupAccessModal from '@mulesoft/exchange-assets-definitions/lib/api-group/Actions/RequestAPIGroupAccessModal';
import {
  withModal,
  withRouter as withRouterActions
} from '@mulesoft/exchange-react-hooks';
import withLayoutContext from '~/components/LayoutContextProvider';
import { selectors as commonSelectors } from '~/domains/common';
import { isExternalSharedAsset } from '~/utils/assets';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import { hasManagedApiInstances } from '~/utils/APIInstances';
import RequestAccessButton from './RequestAccessButton';

const mapStateToProps = (state, { asset, isPublicPortal }) => {
  const profile = commonSelectors.profile(state);

  if (!asset) {
    return {
      hasManagedInstances: false,
      isExternalAsset: false
    };
  }

  return {
    hasManagedInstances:
      hasManagedApiInstances({
        asset,
        isPublicPortal,
        filterDeprecated: true
      }) ||
      (reducerManagerSelectors.isTypeLoaded(state, asset.type) &&
        apiGroupSelectors.hasAPIGroupInstances(state, {
          ...asset,
          filterDeprecated: true
        })),
    isExternalAsset: isExternalSharedAsset({ asset, profile }),
    isPortalReArchitectureEnabled: sessionSelectors.isPIRAEnabled(state)
  };
};

const mapDispatchToProps = (dispatch, { router: { getPath }, openModal }) => ({
  onRequestAccess: (asset) => {
    const openModalFn = () => {
      openModal(RequestAPIGroupAccessModal, { asset });
    };

    dispatch(
      requestAccessActions.openRequestAccessModal({
        asset,
        getPath,
        openModal: openModalFn
      })
    );
  }
});

export default compose(
  withModal,
  withRouter,
  withRouterActions,
  withLayoutContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(RequestAccessButton);
