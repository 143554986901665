import { connect } from 'react-redux';
import { actions as assetsActions } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import Description from '~/components/AssetDetail/Description/Description';

const mapDispatchToProps = (dispatch) => ({
  onSaveDescription: (asset, description) =>
    dispatch(assetsActions.updateDescription({ asset, description }))
});

export default connect(null, mapDispatchToProps)(Description);
