import { compose } from 'redux';
import { connect } from 'react-redux';
import { withModal } from '@mulesoft/exchange-react-hooks';
import { setModalType, registerModal } from '~/components/Modals';
import ConfirmDeletePageModal from './ConfirmDeletePageModal';

const mapDispatchToProps = (dispatch, { onDeletePage, closeModal }) => ({
  onCloseDeleteModal: () => closeModal(),
  onDeletePage
});

export default compose(
  registerModal,
  setModalType('ConfirmDeletePageModal'),
  withModal,
  connect(null, mapDispatchToProps)
)(ConfirmDeletePageModal);
