import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Tabs from '@mulesoft/anypoint-components/lib/Tabs';
import TabList from '@mulesoft/anypoint-components/lib/TabList';
import Tab from '@mulesoft/anypoint-components/lib/Tab';
import { NavLink } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import styles from './NavbarMenu.css';

class NavbarMenu extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    customization: PropTypes.object,
    pages: PropTypes.array,
    getPath: PropTypes.func,
    isOpenMobileMenu: PropTypes.bool,
    isEditMode: PropTypes.bool,
    organization: PropTypes.object
  };

  static defaultProps = {
    isOpenMobileMenu: false,
    pages: [],
    organization: {}
  };

  render() {
    const {
      pages,
      isOpenMobileMenu,
      organization,
      isEditMode,
      getPath,
      customization,
      profile
    } = this.props;
    const hasCustomStyles =
      customization &&
      customization.navbar &&
      (!!customization.navbar.textColor ||
        !!customization.navbar.textColorActive);
    const portalHomeURL = getPath('home', { organization }, { isEditMode });
    let customStyles = null;

    if (hasCustomStyles) {
      customStyles = StyleSheet.create({
        link: {
          backgroundColor: customization.navbar.backgroundColor,
          color: customization.navbar.textColor,
          ':hover': {
            color: customization.navbar.textColorActive,
            borderColor: customization.navbar.textColorActive
          }
        },
        linkActive: {
          backgroundColor: customization.navbar.backgroundColor,
          color: customization.navbar.textColorActive,
          borderColor: customization.navbar.textColorActive
        }
      });
    }

    const activeClass = customStyles
      ? css(customStyles.linkActive)
      : styles.activePage;

    return (
      <Tabs
        stretch={false}
        className={classNames(styles.container, {
          [styles.isOpenMobileMenu]: isOpenMobileMenu
        })}
      >
        <TabList className={styles.list}>
          <Tab className={styles.tab}>
            <NavLink
              exact
              to={portalHomeURL}
              className={customStyles && css(customStyles.link)}
              activeClassName={activeClass}
            >
              Home
            </NavLink>
          </Tab>
          <Tab className={styles.tab}>
            <NavLink
              data-test-id="my-applications-navbar-link"
              display-if={!!profile}
              to={getPath('applications', { organization }, { isEditMode })}
              className={customStyles && css(customStyles.link)}
              activeClassName={activeClass}
            >
              My applications
            </NavLink>
          </Tab>
          {pages.map((page, key) => {
            const url = getPath(
              'customPage',
              { organization, pagePath: page.path },
              { isEditMode }
            );

            return (
              <Tab key={`${page.path}-${key}`} className={styles.tab}>
                <NavLink
                  to={url}
                  title={page.name}
                  className={customStyles && css(customStyles.link)}
                  activeClassName={activeClass}
                >
                  {page.name}
                </NavLink>
              </Tab>
            );
          })}
        </TabList>
      </Tabs>
    );
  }
}

export default NavbarMenu;
