import isKeyCombo from 'is-key-combo';
import PropTypes from 'prop-types';
import React from 'react';
import { Prompt } from 'react-router-dom';
import Button from '@mulesoft/anypoint-components/lib/Button';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import DeletePage from '~/components/DeletePage';
import PageEditor from '~/components/PageEditor';
import { getPath } from '~/utils/routes';
import { isAPIGroup } from '~/utils/types';
import { isTermsPage, replaceNameInPath } from '~/utils/page';
import PageName from '~/components/PageName';
import styles from './StatefulPageEdit.css';

class PageEdit extends React.Component {
  static propTypes = {
    asset: AssetPropType,
    isLoading: PropTypes.bool,
    isContentLoading: PropTypes.bool,
    hasUnpublishedChanges: PropTypes.bool,
    page: PropTypes.object,
    pages: PropTypes.arrayOf(PropTypes.object),
    onSavePage: PropTypes.func,
    onRenamePage: PropTypes.func,
    onFetchNotebookClient: PropTypes.func,
    onFileUpload: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
    onDiscardChanges: PropTypes.func,
    onPageContentChange: PropTypes.func,
    onAddNotebook: PropTypes.func,
    onPlayNotebook: PropTypes.func,
    onPlayNotebookSnippet: PropTypes.func,
    canDeletePage: PropTypes.bool,
    canRenamePage: PropTypes.bool,
    hasTitle: PropTypes.bool,
    hasUnderlyingTerms: PropTypes.bool
  };

  static defaultProps = {
    page: {}
  };

  state = {
    markdown: this.props.page.markdown,
    isTryingToLeave: false,
    renamePageObject: null
  };

  componentDidMount() {
    global.addEventListener('beforeunload', this.handleLeavePage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.shouldUpdateMarkdown(nextProps)) {
      this.setState(
        {
          markdown: nextProps.page.markdown
        },
        () => this.props.onPageContentChange({ hasUnsavedChanges: false })
      );
    }
  }

  shouldComponentUpdate(nextProps) {
    return !!nextProps.page;
  }

  componentWillUnmount() {
    global.removeEventListener('beforeunload', this.handleLeavePage);
  }

  shouldUpdateMarkdown = (nextProps) => {
    if (!nextProps.page) {
      return false;
    }

    const currentPage = this.props.page;
    const nextPage = nextProps.page;
    const hasMarkdownChanged = currentPage.markdown !== nextPage.markdown;
    const hasPageChanged = currentPage.name !== nextPage.name;

    return hasMarkdownChanged || hasPageChanged;
  };

  isForgettingToSave = () => {
    const { isTryingToLeave, markdown } = this.state;
    const { page } = this.props;

    if (!page) {
      return false;
    }

    const isMarkdownChanged =
      (markdown || '').trim() !== (page.markdown || '').trim();

    return !isTryingToLeave && isMarkdownChanged && !page.isRenamed;
  };

  handleLeavePage = (event) => {
    if (this.isForgettingToSave()) {
      this.setState({ isTryingToLeave: false });
      const leaveEditMessage =
        'You have unsaved changes. Would you like to leave anyway?';

      if (event) {
        /* eslint-disable no-param-reassign */
        event.returnValue = leaveEditMessage;
      }

      return leaveEditMessage;
    }

    this.setState({ isTryingToLeave: false });
    delete event.returnValue;

    // eslint-disable-next-line
    return;
  };

  handleChange = ({ value }) => {
    this.props.onPageContentChange({
      hasUnsavedChanges: this.props.page.markdown !== value
    });
    this.setState({ markdown: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleSaveChanges();
  };

  handleKeyDown = (e) => {
    if (isKeyCombo(e, 'cmd+enter')) {
      this.handleSaveChanges();
    }
  };

  handleDiscard = () => {
    const { asset, page, hasUnpublishedChanges } = this.props;
    const params = { ...asset, pagePath: page.path };

    this.setState({ isTryingToLeave: true, renamePageObject: null }, () =>
      this.props.onDiscardChanges({ hasUnpublishedChanges, params })
    );
  };

  handleSaveChanges = async () => {
    const { asset, onSavePage, onRenamePage, page } = this.props;
    let { renamePageObject } = this.state;
    const { markdown } = this.state;
    const {
      organization: { id: organizationId },
      groupId,
      assetId,
      version,
      minorVersion,
      versionGroup,
      type
    } = asset;
    let pagePath = page.path;

    this.setState({
      isTryingToLeave: true
    });

    // This is to rewrite the path of the page to allow legacy pages with _ to be saved
    const shouldUpdatePath = page.path.includes('_');

    if (shouldUpdatePath) {
      renamePageObject = {
        pagePath: page.name,
        pageName: page.name,
        oldPagePath: page.path
      };
    }

    if (renamePageObject) {
      const response = await onRenamePage({
        type,
        organizationId,
        groupId,
        assetId,
        version,
        minorVersion,
        versionGroup,
        ...renamePageObject
      });

      // after successful update we want to redirect to updated page
      pagePath = response.pagePath; // eslint-disable-line prefer-destructuring
    }

    onSavePage({
      type,
      organizationId,
      groupId,
      assetId,
      version,
      minorVersion,
      versionGroup,
      pagePath,
      markdown
    });
  };

  // this func has to be async, because of different use cases of PageName component
  handleRenamePage = async ({ pagePath, pageName, oldPagePath }) => {
    this.setState({
      renamePageObject: {
        pagePath: replaceNameInPath({
          oldPagePath,
          pagePath
        }),
        pageName,
        oldPagePath
      }
    });

    return true;
  };

  renderEditor = () => {
    const {
      asset,
      page,
      pages,
      isLoading,
      onFetchNotebookClient,
      onFileUpload,
      onAddNotebook,
      onPlayNotebook,
      onPlayNotebookSnippet,
      hasUnderlyingTerms
    } = this.props;
    const params = {
      ...asset,
      pagePath: page.path
    };
    const draftURL = getPath('pageDraft', params);
    const showNotebookOption =
      asset.type === 'rest-api' || asset.type === 'custom';

    return (
      <div data-test-id="editor" className={styles.editorPage}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          data-test-id="page-edit-form"
          method="POST"
          className={styles.pageEditForm}
          action={`${draftURL}?_method=PUT`}
          onSubmit={this.handleSubmit}
          onKeyDown={this.handleKeyDown}
        >
          <PageEditor
            className={styles.editor}
            markdown={this.state.markdown}
            name="markdown"
            onChange={this.handleChange}
            showNotebookOption={showNotebookOption}
            onFetchNotebookClient={onFetchNotebookClient}
            onUpload={onFileUpload}
            onAddNotebook={onAddNotebook}
            onPlayNotebook={onPlayNotebook}
            onPlayNotebookSnippet={onPlayNotebookSnippet}
            asset={asset}
            page={page}
            pages={pages}
          />
          <span
            className={styles.underlyingApisNote}
            display-if={
              isAPIGroup(asset) && isTermsPage(page) && hasUnderlyingTerms
            }
            data-test-id="underlying-apis-note"
          >
            <strong>NOTE:</strong> Terms and conditions of underlying APIs will
            be automatically displayed underneath group terms and conditions.
          </span>
          <div className={styles.actions}>
            <Button
              testId="discard-button"
              aria-describedby="discard-button-description"
              type="button"
              kind="secondary"
              noFill
              onClick={this.handleDiscard}
            >
              Discard changes
            </Button>
            <span id="discard-button-description" className="visually-hidden">
              Opens discard changes modal
            </span>
            <Button
              testId="save-as-draft-button"
              kind="primary"
              type="submit"
              isLoading={isLoading}
            >
              {isLoading ? 'Saving ...' : 'Save as draft'}
            </Button>
          </div>
        </form>
      </div>
    );
  };

  renderSpinner = () => (
    <div className={styles.spinner}>
      <Spinner size="l" />
    </div>
  );

  render() {
    const {
      asset,
      onOpenDeleteModal,
      page,
      pages,
      isContentLoading,
      canDeletePage,
      canRenamePage,
      hasTitle
    } = this.props;

    return (
      <div data-test-id="page-edit" className={styles.pageEdit}>
        <Prompt
          message={() => {
            if (this.isForgettingToSave()) {
              return 'You have unsaved changes. Would you like to leave anyway?';
            }

            return true;
          }}
        />
        <div
          display-if={!isContentLoading}
          data-test-id="page-name-bar"
          className={styles.pageNameBar}
        >
          <PageName
            asset={asset}
            page={page}
            pages={pages}
            onRenamePage={this.handleRenamePage}
            display-if={!isContentLoading && hasTitle}
            canRenamePage={canRenamePage}
          />
          <DeletePage
            asset={asset}
            display-if={!isContentLoading && canDeletePage}
            onOpenDeleteModal={onOpenDeleteModal}
          />
        </div>
        {isContentLoading ? this.renderSpinner() : this.renderEditor()}
      </div>
    );
  }
}

export default PageEdit;
