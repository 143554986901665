import { format } from 'url';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isAbsolutePath } from '~/utils/url';

export const preventLinkNavigation = (event) => {
  if (event.target.tagName === 'A') {
    const href = event.target.getAttribute('href');
    const isRelativeURL = !isAbsolutePath(href);

    if (href && isRelativeURL) {
      event.preventDefault();

      let { pathname } = global.location;
      const { protocol, host, hash, query } = global.location;
      const [newPathname, newHash] = href.split('#');

      if (!newPathname && newHash) {
        global.location.href = href;

        return;
      }

      if (pathname[pathname.length - 1] !== '/') {
        pathname = `${pathname}/`;
      }

      const url = format({
        protocol,
        host,
        query,
        pathname: newPathname ? `${pathname}${newPathname}` : pathname,
        hash: newHash || hash
      });

      global.location.href = url;
    }
  }
};

const RelativeLinkTrap = ({ children }) => {
  const preventLinkNavigationOnEnter = useCallback((event) => {
    if (event.key === 'Enter') {
      preventLinkNavigation(event);
    }
  }, []);

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <div
      data-test-id="relative-link-trap-wrapper"
      onClick={preventLinkNavigation}
      onKeyDown={preventLinkNavigationOnEnter}
    >
      {children}
    </div>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions */
};

RelativeLinkTrap.propTypes = {
  children: PropTypes.node.isRequired
};

export default RelativeLinkTrap;
