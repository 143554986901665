import { combineReducers } from 'redux';
import { reducer as actionifyReducer } from '@mulesoft/actionify';
import { reducer as assetsReducer } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { reducer as categoriesReducer } from '@mulesoft/exchange-ui-portals-store/lib/domains/categories';
import { reducer as identitiesReducer } from '@mulesoft/exchange-ui-portals-store/lib/domains/identities';
import { reducer as pagesReducer } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { reducer as portalsReducer } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { reducer as reviewsReducer } from '@mulesoft/exchange-ui-portals-store/lib/domains/reviews';
import { reducer as reducerManagerReducer } from '../reducerManager';
import { reducer as sessionReducer } from './session';

export const reducers = {
  actionify: actionifyReducer,
  assets: assetsReducer,
  categories: categoriesReducer,
  identities: identitiesReducer,
  pages: pagesReducer,
  portals: portalsReducer,
  reducerManager: reducerManagerReducer,
  reviews: reviewsReducer,
  session: sessionReducer
};

export default () => combineReducers(reducers);
