export const FETCH_SAVED_SEARCHES_REQUEST = 'Fetch Saved Searches Request';
export const FETCH_SAVED_SEARCHES_SUCCESS = 'Fetch Saved Searches Success';
export const FETCH_SAVED_SEARCHES_FAILURE = 'Fetch Saved Searches Failure';
export const FETCH_CREATE_SAVED_SEARCHES_SUCCESS =
  'Fetch Create Saved Searches Success';
export const FETCH_DELETE_SAVED_SEARCH_REQUEST =
  'Fetch Delete Saved Search Request';
export const FETCH_EDIT_SAVED_SEARCH_REQUEST =
  'Fetch Edit Saved Search Request';

export const FETCH_USER_SAVED_SEARCHES_SUCCESS =
  'Fetch User Saved Searches Success';
export const FETCH_CREATE_USER_SAVED_SEARCHES_SUCCESS =
  'Fetch Create User Saved Searches Success';
export const FETCH_DELETE_USER_SAVED_SEARCH_REQUEST =
  'Fetch Delete User Saved Search Request';
export const FETCH_EDIT_USER_SAVED_SEARCH_REQUEST =
  'Fetch Edit User Saved Search Request';
export const FETCH_USER_SAVED_SEARCHES_REQUEST =
  'Fetch User Saved Searches Request';
export const FETCH_USER_SAVED_SEARCHES_FAILURE =
  'Fetch User Saved Searches Failure';
