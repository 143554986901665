import PropTypes from 'prop-types';
import React from 'react';
import Select from '@mulesoft/anypoint-components/lib/Select';
import UnderlyingAPITermsList from '@mulesoft/exchange-assets-definitions/lib/api-group/Actions/RequestAPIGroupAccessModal/UnderlyingAPITermsList';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import styles from './UnderlyingAPITermsSelector.css';

class UnderlyingAPITermsSelector extends React.PureComponent {
  static propTypes = {
    asset: AssetPropType,
    apiGroupInstances: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  constructor(props) {
    super(props);

    const { apiGroupInstances } = this.props;

    if (apiGroupInstances.length === 1) {
      // eslint-disable-next-line react/state-in-constructor
      this.state = {
        apiGroupInstanceId: apiGroupInstances[0].id.toString()
      };
    }
  }

  state = {};

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.apiGroupInstances.length !== nextProps.apiGroupInstances.length
    ) {
      if (nextProps.apiGroupInstances.length === 1) {
        // eslint-disable-next-line react/state-in-constructor
        this.setState({
          apiGroupInstanceId: nextProps.apiGroupInstances[0].id.toString()
        });
      }
    }
  }

  mapAPIGroupInstances = () => {
    const { apiGroupInstances } = this.props;

    return apiGroupInstances.map((instance) => ({
      value: instance.id,
      label: instance.name
    }));
  };

  setValue = ({ value }) => {
    this.setState({
      apiGroupInstanceId: value
    });
  };

  render() {
    const { apiGroupInstances, asset } = this.props;
    const { apiGroupInstanceId } = this.state;

    return (
      <React.Fragment>
        <div className={styles.underlyingApisLabel}>
          The following group instances include underlying APIs with terms and
          conditions.
        </div>
        <div className={styles.underlyingTermsDisclaimer}>
          When requesting access to a group instance, you accept the terms and
          conditions of all APIs in the group.
        </div>
        <Select
          className={styles.groupInstanceSelect}
          name="instanceId"
          disabled={apiGroupInstances.length < 2}
          options={this.mapAPIGroupInstances()}
          placeholder="Select a group instance..."
          testId="select-terms-api-group-instance"
          onChange={this.setValue}
          value={apiGroupInstanceId}
          autosize={false}
        />
        <div
          display-if={apiGroupInstanceId}
          className={styles.underlyingListTitle}
        >
          API terms and conditions
        </div>
        <UnderlyingAPITermsList
          asset={asset}
          display-if={apiGroupInstanceId}
          apiGroupInstanceId={parseInt(apiGroupInstanceId, 10)}
        />
      </React.Fragment>
    );
  }
}

export default UnderlyingAPITermsSelector;
