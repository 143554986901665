import config from 'config';
import debug from 'debug';
import { fetch } from '~/utils/fetch';
import { getAPIUrl } from '~/utils/api';
import { handleUploadResourceError } from '~/utils/errors';

const log = debug('exchange:customization:service');

function getStatusURL({ organizationDomain, context }) {
  return getAPIUrl({
    context,
    pathname: `/api/v2/portals/${organizationDomain}/status`
  });
}

function getPortalURL({ organizationDomain, context }) {
  return getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationDomain}/portal`
  });
}

function getPortalDraftURL({ organizationDomain, context }) {
  return getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationDomain}/portal/draft`
  });
}

export function getCustomization({ organizationDomain, context }) {
  log(`Getting customization draft for org ${organizationDomain}`, {
    context
  });

  const url = getPortalURL({ organizationDomain, context });

  return fetch(url, { context }).then((res) => res.json());
}

export function getCustomizationStatus({ organizationDomain, context }) {
  log(`Getting customization status for org ${organizationDomain}`, {
    context
  });

  const url = getStatusURL({ organizationDomain, context });
  const headers = { accept: 'text/plain' };

  return fetch(url, { context, headers }).then((res) => res.text());
}

export async function getCustomizationDraft({ organizationDomain, context }) {
  log(`Getting customization draft for org ${organizationDomain}`, {
    context
  });

  const url = getPortalDraftURL({ organizationDomain, context });

  const response = await fetch(url, { context });
  const portalContent = await response.json();
  const pagesOrderRevision = response.headers.get('x-pages-order-revision');

  return {
    ...portalContent,
    pagesOrderRevision
  };
}

export function updateCustomizationDraft({
  organizationDomain,
  data,
  context
}) {
  log(`Updating customization draft for org ${organizationDomain}`, {
    data,
    context
  });

  const url = getPortalDraftURL({ organizationDomain, context });
  const body = JSON.stringify(data);

  return fetch(url, {
    method: 'PUT',
    body,
    context
  }).then(() => data);
}

export function publishCustomizationDraft({ organizationDomain, context }) {
  log(`Publishing customization draft for org ${organizationDomain}`, {
    context
  });

  const url = getPortalURL({ organizationDomain, context });

  return fetch(url, { method: 'PATCH', context });
}

export function deleteCustomizationDraft({ organizationDomain, context }) {
  log(`Deleting customization draft for org ${organizationDomain}`, {
    context
  });

  const url = getPortalDraftURL({ organizationDomain, context });

  return fetch(url, {
    method: 'DELETE',
    context
  });
}

export function getDomain({ organizationDomain, context }) {
  log(`Getting domain for org ${organizationDomain}`);

  const url = getAPIUrl({
    context,
    pathname: `/api/v1/organizations/${organizationDomain}/portal/domain`
  });

  return fetch(url, {
    headers: {
      accept: 'text/plain'
    },
    context
  }).then((res) => res.text());
}

export function createDraftPage({ pagePath, organizationDomain, context }) {
  log(`Creating page ${pagePath} for org ${organizationDomain}`, {
    context
  });

  const url = `${getPortalDraftURL({ organizationDomain, context })}/pages`;
  const body = JSON.stringify({ pagePath });

  return fetch(url, {
    method: 'POST',
    body,
    context
  }).then((res) => res.json());
}

export function renameDraftPage({
  pagePath,
  oldPagePath,
  organizationDomain,
  context
}) {
  log(
    `Renaming page ${oldPagePath} to ${pagePath} for org ${organizationDomain}`,
    {
      context
    }
  );

  const url = `${getPortalDraftURL({ organizationDomain, context })}/pages`;
  const headers = { 'x-move-source': encodeURI(oldPagePath) };
  const body = JSON.stringify({ pagePath });

  return fetch(url, {
    method: 'POST',
    body,
    context,
    headers
  }).then((res) => res.json());
}

export function deleteDraftPage({ pagePath, organizationDomain, context }) {
  log(`Deleting page ${pagePath} for org ${organizationDomain}`, {
    context
  });

  const url = `${getPortalDraftURL({
    organizationDomain,
    context
  })}/pages/${encodeURIComponent(pagePath)}`;

  return fetch(url, { method: 'DELETE', context });
}

function getPageText({
  pagePath,
  organizationDomain,
  context,
  contentType,
  isDraft
}) {
  const baseURL = isDraft
    ? getPortalDraftURL({ organizationDomain, context })
    : getPortalURL({ organizationDomain, context });
  const url = `${baseURL}/pages/${encodeURIComponent(pagePath)}`;
  const headers = { accept: `text/${contentType}` };

  return fetch(url, { context, headers }).then((res) => res.text());
}

export function getPageMarkdown({ pagePath, organizationDomain, context }) {
  log(`Getting markdown for page ${pagePath} for org ${organizationDomain}`, {
    context
  });

  return getPageText({
    pagePath,
    organizationDomain,
    context,
    contentType: 'markdown'
  });
}

export function getPageHTML({ pagePath, organizationDomain, context }) {
  log(`Getting HTML for page ${pagePath} for org ${organizationDomain}`, {
    context
  });

  return getPageText({
    pagePath,
    organizationDomain,
    context,
    contentType: 'html'
  });
}

export function getDraftPageMarkdown({
  pagePath,
  organizationDomain,
  context
}) {
  log(
    `Getting markdown for draft page ${pagePath} for org ${organizationDomain}`,
    {
      context
    }
  );

  return getPageText({
    pagePath,
    organizationDomain,
    context,
    contentType: 'markdown',
    isDraft: true
  });
}

export function updateDraftPageMarkdown({ page, organizationDomain, context }) {
  log(`Updating markdown for page ${page.path} for org ${organizationDomain}`, {
    context
  });

  const url = `${getPortalDraftURL({
    organizationDomain,
    context
  })}/pages/${encodeURIComponent(page.path)}`;
  const headers = { 'content-type': 'text/markdown' };

  return fetch(url, {
    method: 'PUT',
    body: page.markdown,
    context,
    headers
  }).then(() => page);
}

export async function savePagesOrder({
  organizationDomain,
  pages,
  pagesOrderRevision,
  context
}) {
  log(`Reordering pages for org ${organizationDomain}`, {
    pages,
    pagesOrderRevision,
    context
  });

  const url = `${getPortalDraftURL({
    organizationDomain,
    context
  })}/pagesOrder`;
  const headers = {};
  const body = JSON.stringify(pages.map((page) => page.path));

  if (pagesOrderRevision) {
    headers['x-pages-order-revision'] = pagesOrderRevision;
  }

  const response = await fetch(url, { context, method: 'PUT', body, headers });
  const newPagesOrderRevision = response.headers.get('x-pages-order-revision');

  return {
    pagesOrderRevision: newPagesOrderRevision
  };
}

export async function uploadResource({ context, organizationDomain, file }) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/portals/${organizationDomain}/draft/resources`
  });
  const formData = new global.FormData();

  log('uploading customization resource', { organizationDomain });

  formData.append('data', file);

  let response;

  try {
    response = await fetch(url, {
      context,
      method: 'POST',
      headers: { 'content-type': null },
      body: formData
    });
  } catch (err) {
    handleUploadResourceError(err, {
      url: config.docsLinks.customizationResources
    });
  }

  const { path } = await response.json();

  return {
    url: path,
    key: path
  };
}

export async function getResource({
  context,
  organizationDomain,
  resourceName,
  isDraft
}) {
  const url = getAPIUrl({
    context,
    pathname: `/api/v2/portals/${organizationDomain}${
      isDraft ? '/draft' : ''
    }/resources/${encodeURIComponent(resourceName)}`
  });

  log('getting customization resource', { organizationDomain, isDraft });

  return fetch(url, {
    context,
    headers: { accept: '*/*' }
  });
}
