import React from 'react';
import DocumentationIcon from '@mulesoft/anypoint-icons/lib/svg/documentation-medium.svg';
import BusinessGroupIcon from '@mulesoft/anypoint-icons/lib/svg/businessgroup-small.svg';
import SupportIcon from '@mulesoft/anypoint-icons/lib/svg/support.svg';

export const getDefaultCards = (getPath) => [
  {
    text: 'Documentation',
    url: 'https://docs.mulesoft.com/',
    icon: <DocumentationIcon />
  },
  {
    text: 'Home',
    url: getPath('home'),
    icon: <BusinessGroupIcon />
  },
  {
    text: 'Support portal',
    url: 'https://support.mulesoft.com/',
    icon: <SupportIcon />
  }
];
