import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import { selectors as pagesSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import { isAPIGroup } from '~/utils/types';
import { isTermsPage, replacePredefinedPageName } from '~/utils/page';
import * as routesUtils from '~/utils/routes';
import DeletePage from './DeletePage';

const mapStateToProps = (state, { match, asset }) => {
  const params = routesUtils.decodeParams(match.params);
  const page = replacePredefinedPageName(
    pagesSelectors.page(state, {
      ...params,
      isDraft: true
    })
  );

  return {
    isTermsPage: isTermsPage(page),
    isAPIGroup: asset && isAPIGroup(asset),
    hasUnderlyingTerms:
      asset &&
      isAPIGroup(asset) &&
      reducerManagerSelectors.isTypeLoaded(state, asset.type) &&
      apiGroupSelectors.hasUnderlyingTerms(state, asset)
  };
};

export default compose(withRouter, connect(mapStateToProps))(DeletePage);
