import { connect } from 'react-redux';
import RootStoreContext from '~/contexts/RootStoreContext';
import * as selectors from '~/domains/customization/selectors';
import WelcomeSection from './WelcomeSection';

const mapStateToProps = (state, { isEditMode }) => {
  const heroImage = selectors.heroImage(state, { isEditMode });
  const title = selectors.welcomeTitle(state, { isEditMode });
  const text = selectors.welcomeText(state, { isEditMode });
  const textColor = selectors.textColor(state, { isEditMode });

  return {
    isEditMode,
    heroImage,
    title,
    text,
    textColor
  };
};

export default connect(mapStateToProps, null, null, {
  context: RootStoreContext
})(WelcomeSection);
