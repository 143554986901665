import React from 'react';
import Metrics from '~/components/AssetMetrics';
import AssetBaseLayout from '../AssetBaseLayout';

class MetricsLayout extends AssetBaseLayout {
  constructor(props) {
    super(props);

    this.hideRating = true;
  }

  renderComponent() {
    const { route } = this.props;

    return <Metrics route={route} />;
  }
}

export default MetricsLayout;
