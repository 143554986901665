import { connect } from 'react-redux';
import { compose } from 'redux';
import { setAnnouncement } from '~/components/Announcements/registry';
import RootStoreContext from '~/contexts/RootStoreContext';
import { actions as localStorageActions } from '~/domains/localStorage';
import {
  selectors as searchSelectors,
  actions as searchActions
} from '~/domains/search';
import ANGSearchOptOut from './ANGSearchOptOut';

const OPT_OUT_BANNER_DISABLED_KEY = 'isANGSearchOptOutBannerDisabled';

const condition = searchSelectors.isANGSearchOptOutBannerVisible;

const mapDispatchToProps = (dispatch) => ({
  onDeactivateANGSearch: () => dispatch(searchActions.optOutOfANGSearch()),
  onClose: () =>
    dispatch(localStorageActions.setItem(OPT_OUT_BANNER_DISABLED_KEY, true))
});

export default compose(
  setAnnouncement(condition),
  connect(null, mapDispatchToProps, null, { context: RootStoreContext })
)(ANGSearchOptOut);
