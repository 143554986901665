import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import memoize from 'memoize-one';
import * as apiGroupSelectors from '@mulesoft/exchange-assets-definitions/lib/api-group/store/selectors';
import { selectors as assetsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/assets';
import { selectors as pagesSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/pages';
import { selectors as portalsSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/portals';
import { selectors as sessionSelectors } from '@mulesoft/exchange-ui-portals-store/lib/domains/session';
import { selectors as commonSelectors } from '~/domains/common';
import { EVENT_TYPES } from '~/analytics/events';
import withLayoutContext from '~/components/LayoutContextProvider';
import { withAssetFromRootStore } from '~/contexts/RootStoreContext';
import { actions as analyticsActions } from '~/domains/analytics';
import { selectors as reducerManagerSelectors } from '~/portals/store/reducerManager';
import * as notebooksUtils from '~/portals/utils/notebooks';
import {
  isDefaultPage,
  isTermsPageName,
  replacePredefinedPageName
} from '~/utils/page';
import { notebooksRanMode } from '~/analytics/events/notebooks';
import portalsDefinition from '~/portals/definition';
import * as routesUtils from '~/utils/routes';
import * as typesUtils from '~/utils/types';
import Page from './Page';

const memoizedPage = memoize((page) => replacePredefinedPageName(page));

const mapStateToProps = (
  state,
  { match, location, route, isReadOnly, assetFromRootStore }
) => {
  const params = routesUtils.getAssetParams(match, location);
  const asset = assetsSelectors.asset(state, params) || assetFromRootStore;
  const isAPIGroup = typesUtils.isAPIGroup(asset);
  const { isDraft } = route;
  const pageFromStore = pagesSelectors.page(state, {
    ...params,
    isDraft
  });
  const page = memoizedPage(pageFromStore);
  const pathToEdit = page && getEditPath(asset, page.path);
  const isAdmin = assetsSelectors.canAdmin(state, params);
  const isContributor = assetsSelectors.canEdit(state, params);
  const isMarketingSite = sessionSelectors.isMarketingSite(state);
  const isVanityDomainSession = commonSelectors.isVanityDomainSession(state);

  return {
    isAPIGroup,
    isDraft,
    pagePath: params.pagePath,
    domain: isVanityDomainSession ? params.domain : null,
    hasUnsavedChanges: pagesSelectors.pageUI(state).hasUnsavedChanges,
    isContentLoading: !page || portalsSelectors.isPortalLoading(state),
    canEdit: isReadOnly ? false : assetsSelectors.canEdit(state, params),
    page,
    pathToEdit,
    hasTitle: page && !isDefaultPage(page),
    hasUnderlyingTerms:
      isAPIGroup && reducerManagerSelectors.isTypeLoaded(state, asset.type)
        ? apiGroupSelectors.hasUnderlyingTerms(state, asset)
        : false,
    isAdmin,
    isContributor,
    isMarketingSite,
    isPortalReArchitectureEnabled: sessionSelectors.isPIRAEnabled(state)
  };
};

const memoizedDispatchProps = memoize((dispatch, domain, viewMode) => ({
  onFetchNotebookClient: (payload) =>
    dispatch(notebooksUtils.fetchNotebookClient({ domain, ...payload })),
  onPlayNotebook: (stateProps) =>
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.NOTEBOOK_RAN, {
        mode: viewMode,
        isAdmin: stateProps.isAdmin,
        isContributor: stateProps.isContributor
      })
    ),
  onPlayNotebookSnippet: (stateProps) =>
    dispatch(
      analyticsActions.trackEvent(EVENT_TYPES.NOTEBOOK_SNIPPET_RAN, {
        mode: viewMode,
        isAdmin: stateProps.isAdmin,
        isContributor: stateProps.isContributor
      })
    )
}));

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { domain }
    },
    route
  }
) => {
  const { isDraft } = route;
  const viewMode = isDraft ? notebooksRanMode.DRAFT : notebooksRanMode.VIEW;

  return memoizedDispatchProps(dispatch, domain, viewMode);
};

function getEditPath(asset, pagePath) {
  const { type, groupId, assetId, version, versionGroup, minorVersion } = asset;

  const params = {
    type,
    groupId,
    assetId,
    version,
    minorVersion,
    versionGroup,
    pagePath
  };

  return routesUtils.getPath('pageEdit', params);
}

const mapContextToProps = ({ isPublicPortal }) => ({
  isReadOnly: isPublicPortal
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onPlayNotebook: () => dispatchProps.onPlayNotebook(stateProps),
  onPlayNotebookSnippet: () => dispatchProps.onPlayNotebookSnippet(stateProps)
});

const componentResolver = (props) => {
  /* eslint-disable react/prop-types */
  const portalDefinition = props.asset && portalsDefinition[props.asset.type];
  const PortalTermsPage = portalDefinition && portalDefinition.TermsPage;

  if (isTermsPageName(props.pagePath) && PortalTermsPage) {
    return <PortalTermsPage {...props} />;
  }

  return <Page {...props} />;
  /* eslint-enable react/prop-types */
};

export default compose(
  withAssetFromRootStore,
  withRouter,
  withLayoutContext(mapContextToProps),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(componentResolver);
