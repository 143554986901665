import PropTypes from 'prop-types';
import React from 'react';
import config from 'config';
import classNames from 'classnames';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import TooltipLabel from '@mulesoft/exchange-ui-components/lib/components/TooltipLabel';
import TrashIcon from '@mulesoft/anypoint-icons/lib/svg/trash-small.svg';
import * as eventHandlerUtils from '~/utils/eventHandlers';
import styles from './DeletePage.css';

class DeletePage extends React.PureComponent {
  static propTypes = {
    onOpenDeleteModal: PropTypes.func,
    isTermsPage: PropTypes.bool,
    isAPIGroup: PropTypes.bool,
    hasUnderlyingTerms: PropTypes.bool
  };

  isAPIGroupTermsPageWithUnderlyingTerms = () => {
    const { isTermsPage, isAPIGroup, hasUnderlyingTerms } = this.props;

    return isTermsPage && isAPIGroup && hasUnderlyingTerms;
  };

  handleDeleteClick = () => {
    const { onOpenDeleteModal } = this.props;

    if (!this.isAPIGroupTermsPageWithUnderlyingTerms()) {
      onOpenDeleteModal();
    }
  };

  renderButton = () => {
    return (
      <div
        className={classNames(styles.deletePage, {
          [styles.disabled]: this.isAPIGroupTermsPageWithUnderlyingTerms()
        })}
        tabIndex="0"
        role="button"
        aria-label="Delete Page"
        data-test-id="delete-page-button"
        aria-describedby="delete-page-description"
        onClick={this.handleDeleteClick}
        onKeyDown={eventHandlerUtils.onEnterKey(this.handleDeleteClick)}
      >
        <span id="delete-page-description" className="visually-hidden">
          Opens delete page modal
        </span>
        <Icon size="xs" className={styles.icon}>
          <TrashIcon />
        </Icon>
        <span data-test-id="delete-page-text">Delete Page</span>
      </div>
    );
  };

  renderTooltipContent = () => {
    return (
      <div
        data-test-id="delete-page-disabled-tooltip"
        className={styles.tooltip}
      >
        <span>
          This page was automatically generated and cannot be deleted because
          one or more group instances have underlying APIs with terms and
          conditions.
        </span>
        <a
          href={config.docsLinks.describeAPIGroup}
          className={styles.linkAnchor}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </div>
    );
  };

  render() {
    const button = this.renderButton();

    return this.isAPIGroupTermsPageWithUnderlyingTerms() ? (
      <div className={styles.tooltipContainer}>
        <TooltipLabel
          testId="delete-page-disabled"
          tooltipContent={this.renderTooltipContent()}
          offset={[80, 0]}
        >
          {button}
        </TooltipLabel>
      </div>
    ) : (
      button
    );
  }
}

export default DeletePage;
