import PropTypes from 'prop-types';
import React from 'react';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import CreateReview from '~/components/AssetDetail/Reviews/CreateReview';
import Review from '~/components/AssetDetail/Reviews/Review';
import * as refUtils from '~/utils/refs';
import styles from './Reviews.css';

const isUserFirstReview = (reviews, profile) => {
  return !reviews?.some((review) => review.userId === profile.id);
};

class Reviews extends React.Component {
  static propTypes = {
    asset: AssetPropType,
    reviews: PropTypes.arrayOf(PropTypes.object),
    profile: PropTypes.object,
    onCreateReview: PropTypes.func,
    onLoadReviews: PropTypes.func,
    onDeleteReview: PropTypes.func,
    onEditReview: PropTypes.func,
    onRatingChange: PropTypes.func
  };

  static defaultProps = {
    reviews: [],
    onLoadReviews: () => {}
  };

  componentDidMount() {
    const { isCompleted } = this.props.asset;

    if (isCompleted) {
      this.props.onLoadReviews(this.props.asset);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isCompleted } = this.props.asset;

    const shouldReloadReviews = !isCompleted && nextProps.asset.isCompleted;

    if (shouldReloadReviews) {
      this.props.onLoadReviews(nextProps.asset);
    }
  }

  createReviewRef = React.createRef();

  focusCreate = () => {
    refUtils.focus(this.createReviewRef);
  };

  render() {
    const {
      asset,
      reviews,
      profile,
      onDeleteReview,
      onEditReview,
      onCreateReview,
      onRatingChange
    } = this.props;
    const isFirstReview = reviews && !reviews.length;

    return (
      <section
        className={styles.reviews}
        data-test-id="reviews"
        id="reviews-list"
        aria-labelledby="reviews-heading"
      >
        <div data-test-id="reviews-section">
          <hr />
          <h2 id="reviews-heading">Reviews</h2>
        </div>
        <CreateReview
          ref={this.createReviewRef}
          display-if={profile && isUserFirstReview(reviews, profile)}
          asset={asset}
          isFirstReview={isFirstReview}
          profile={profile}
          onCreateReview={onCreateReview}
          onRatingChange={onRatingChange}
        />
        <div display-if={!isFirstReview} data-test-id="reviews-list">
          {reviews?.map((review) => (
            <Review
              key={review.createdDate}
              review={review}
              asset={asset}
              onDeleteReview={onDeleteReview}
              onEditReview={onEditReview}
              onUnmount={this.focusCreate}
            />
          ))}
        </div>
        <div
          display-if={isFirstReview && !profile}
          data-test-id="empty-reviews-list"
          className={styles.empty}
        >
          {`${asset.name} doesn't have any reviews yet`}
        </div>
      </section>
    );
  }
}

export default Reviews;
