import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import ArrowDownIcon from '@mulesoft/anypoint-icons/lib/svg/arrow-down-small.svg';
import styles from './CollapsibleCard.css';

class CollapsibleCard extends React.Component {
  static propTypes = {
    title: PropTypes.any,
    children: PropTypes.node,
    isExpanded: PropTypes.bool,
    onOpen: PropTypes.func,
    className: PropTypes.string,
    testId: PropTypes.string
  };

  static defaultProps = {
    title: '',
    isExpanded: false
  };

  state = {
    isExpanded: this.props.isExpanded
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isExpanded } = this.props;

    if (nextProps.isExpanded !== isExpanded) {
      this.setState({ isExpanded: nextProps.isExpanded });
    }
  }

  toggle = () => {
    if (!this.state.isExpanded && this.props.onOpen) {
      this.props.onOpen();
    }

    this.setState((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded
    }));
  };

  render() {
    const { title, children, className, testId } = this.props;
    const { isExpanded } = this.state;

    return (
      <React.Fragment>
        <button
          type="button"
          className={classNames(className, styles.header)}
          onClick={this.toggle}
          data-test-id={`collapsible-card-header-${testId}`}
          aria-expanded={isExpanded}
        >
          <Icon
            className={classNames(styles.icon, {
              [styles.closed]: !isExpanded
            })}
            size="xs"
            data-test-id="collapsible-card-expandable-icon"
          >
            <ArrowDownIcon />
          </Icon>
          <span
            className={styles.title}
            data-test-id={`collapsible-card-title-${testId}`}
          >
            {title}
          </span>
        </button>
        <div
          display-if={isExpanded}
          className={styles.content}
          data-test-id={`collapsible-card-content-${testId}`}
        >
          {children}
        </div>
      </React.Fragment>
    );
  }
}

export default CollapsibleCard;
