import React from 'react';
import CreateAsset from '~/components/CreateAsset';
import CreateAssetSidebar from '~/components/CreateAsset/Sidebar';
import BaseLayout from '~/layouts/BaseLayout';

class CreateAssetLayout extends BaseLayout {
  renderSidebar() {
    const { route } = this.props;

    return <CreateAssetSidebar route={route} />;
  }

  renderMain() {
    const { route } = this.props;

    return <CreateAsset route={route} />;
  }
}

export default CreateAssetLayout;
