import { insertInArray, replaceInArray } from '~/utils/arrays';
import * as actions from './actionTypes';

const initialState = {
  data: {},
  userList: {}
};

export default function reducer(state = initialState, action) {
  const findIndexToInsertSavedSearch = (savedSearch) => {
    const nameToInsert = action.savedSearch.name;
    const currentName = savedSearch.name;

    if (currentName.toLowerCase() > nameToInsert.toLowerCase()) {
      return true;
    }

    if (currentName.toLowerCase() === nameToInsert.toLowerCase()) {
      return currentName < nameToInsert;
    }

    return false;
  };

  const editSavedSearchNewList = (currentList) => {
    const { query } = action;
    const index = currentList.findIndex(
      (savedSearch) => savedSearch.id === action.id
    );
    const item = { ...currentList[index], ...query };

    return replaceInArray(currentList, item, { index });
  };

  switch (action.type) {
    case actions.FETCH_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.organizationId]: {
            searches: action.savedSearches,
            hasAll: action.hasAll,
            isFetching: false
          }
        }
      };

    case actions.FETCH_SAVED_SEARCHES_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.organizationId]: {
            ...state.data[action.organizationId],
            isFetching: true
          }
        }
      };

    case actions.FETCH_CREATE_SAVED_SEARCHES_SUCCESS: {
      const organization = state.data[action.organizationId];
      const currentList = organization ? organization.searches : [];
      const newList = insertInArray(currentList, action.savedSearch, {
        findFn: findIndexToInsertSavedSearch
      });

      return {
        ...state,
        data: {
          ...state.data,
          [action.organizationId]: {
            ...state.data[action.organizationId],
            searches: newList
          }
        }
      };
    }

    case actions.FETCH_DELETE_SAVED_SEARCH_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          [action.organizationId]: {
            ...state.data[action.organizationId],
            searches: state.data[action.organizationId].searches.filter(
              (savedSearch) => savedSearch.id !== action.id
            )
          }
        }
      };

    case actions.FETCH_EDIT_SAVED_SEARCH_REQUEST: {
      const currentList = state.data[action.organizationId].searches;
      const newList = editSavedSearchNewList(currentList);

      return {
        ...state,
        data: {
          ...state.data,
          [action.organizationId]: {
            ...state.data[action.organizationId],
            searches: newList
          }
        }
      };
    }

    case actions.FETCH_CREATE_USER_SAVED_SEARCHES_SUCCESS: {
      const currentList = state.userList.searches || [];
      const newList = insertInArray(currentList, action.savedSearch, {
        findFn: findIndexToInsertSavedSearch
      });

      return {
        ...state,
        userList: {
          ...state.userList,
          searches: newList
        }
      };
    }

    case actions.FETCH_USER_SAVED_SEARCHES_REQUEST:
      return {
        ...state,
        userList: {
          ...state.userList,
          isFetching: true
        }
      };

    case actions.FETCH_USER_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        userList: {
          isFetching: false,
          searches: action.savedSearches,
          hasAll: action.hasAll
        }
      };

    case actions.FETCH_DELETE_USER_SAVED_SEARCH_REQUEST:
      return {
        ...state,
        userList: {
          ...state.userList,
          searches: state.userList.searches.filter(
            (savedSearch) => savedSearch.id !== action.id
          )
        }
      };

    case actions.FETCH_EDIT_USER_SAVED_SEARCH_REQUEST: {
      const newList = editSavedSearchNewList(state.userList.searches);

      return {
        ...state,
        userList: {
          ...state.userList,
          searches: newList
        }
      };
    }

    default:
      return state;
  }
}
