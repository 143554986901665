export default {
  PAGE_CREATED: 'Page created',
  PAGE_RENAMED: 'Page renamed',
  PAGE_DELETED: 'Page deleted',
  PAGE_UPDATED: 'Page updated',
  PAGE_DELETE_MODAL_OPENED: 'Page delete modal opened',
  PAGE_DELETE_MODAL_CLOSED: 'Page delete modal closed',
  DRAFT_PUBLISHED: 'Draft published',
  MEDIA_SELECTED: 'Media selected'
};
